<!--
@file Status edit dialog content
@copyright Digital Living Software Corp. 2014-2016
-->

<md-dialog class="pip-dialog pip-status-edit-dialog layout-column" md-theme="{{theme}}">
    <div class="pip-header">
        <h3 class="m0 text-title">{{::'UPDATE_STATUS' | translate}}</h3>
    </div>
    <div class="pip-body pip-list">
        <div class="pip-content">
            <div>
                <md-input-container class="md-block">
                    <label>{{::'STATUS' | translate}}</label>
                    <md-select ng-model="status"
                               ng-change="onStatusChange()"
                               class="w-stretch theme-text-primary ng-touched">
                        <md-option ng-value="opt.id"
                                   ng-repeat="opt in statusCollection track by opt.id">
                            {{ ::opt.name }}
                        </md-option>
                    </md-select>
                </md-input-container>
            </div>
            <div >
                <p class="text-caption text-grey bm0 bm8 ng-binding">{{::'PROGRESS' | translate}}</p>
                <md-slider md-discrete
                           ng-model="progress"
                           ng-change="progressChanged()"
                           step="10" min="0" max="100" aria-label="rating" class="tm8">
                </md-slider>
            </div>
        </div>
    </div>
    <div class="pip-footer">
        <div>
            <md-button class="pip-cancel" ng-click="onCancelClick()">{{::'CANCEL' | translate}}</md-button>
            <md-button class="pip-submit md-accent" ng-click="onApplyClick()">{{::'APPLY' | translate}}</md-button>
        </div>
    </div>
</md-dialog>