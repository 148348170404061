/**
 * @file Party list control
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo
 * - Improve samples in sampler app
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipPartyList", ['pipCore']);

    thisModule.directive('pipPartyList',  
        function () {
            return {
                restrict: 'EA',
                scope: true,
                templateUrl: 'parties/party_list.html',
                controller: 'pipPartyListController'
            }
        }
    );

    thisModule.controller('pipPartyListController',
        ['$scope', '$element', '$attrs', '$parse', 'pipUtils', '$state', function ($scope, $element, $attrs, $parse, pipUtils, $state) {
            var partiesGetter = $parse($attrs.pipParties);

            // Set parties
            $scope.parties = partiesGetter($scope);

            // Add class
            $element.addClass('pip-party-list');
            
            $scope.partyState = $attrs.pipPartyState;

            // Also optimization to avoid watch if it is unnecessary
            if (pipUtils.toBoolean($attrs.pipRebind)) {
                $scope.$watch(partiesGetter, function (newValue) {
                    $scope.parties = partiesGetter($scope);
                });
            }
            
            $scope.onPartyClick = function($event, partyId) {
                if ($attrs.pipPartyState && partyId) $state.go($attrs.pipPartyState, {party_id: partyId});
            }; 
            
        }]
    );  

})();

