/**
 * @file Events_common, service function for events
 * @copyright Digital Living Software Corp. 2014-2015
 * todo
 * Service  не прошел первую стадию рефакторинга, а именнно написание функций должны быть function getVisionName (item)
 * мне кажется стоит разнести эти сервисы по разным файлам, потому-что файл большой и ореинтироваться в нем сейчас трудновато
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipEventsUAService', []);

    thisModule.factory('EventUAService',
        ['pipEventsData', 'pipTranslate', 'EventsDateService', '$rootScope', 'AccessoryService', 'EventsCreateNewService', 'pipToasts', 'pipEnums', 'pipCollections', 'pipDates', 'pipStrings', 'ParentsService', 'pipUtils', '$state', function (pipEventsData, pipTranslate, EventsDateService,  $rootScope, AccessoryService, EventsCreateNewService,
                  pipToasts, pipEnums, pipCollections, pipDates, pipStrings, ParentsService, pipUtils, $state) {

            function processError(error) {
                if (error.data.code) {
                    var errString = 'PLANS_ERROR_' + error.data.code;
                } else {
                    var errString = 'PLANS_ERROR_' + error.status;
                }
                errString = pipTranslate.translate(errString);
                pipToasts.showError(errString, null, null, null, error);
            }

            function getVisionName(item) {
                if (!item) return '';
                var date = item.start ? new Date(item.start) : null;
                return date != null ? date.getFullYear() + ' / ' + item.title : item.title;
            }

            function getEventPriority(event) {
                if (((event.importance == pipEnums.IMPORTANCE.HIGH) &&
                    (event.urgency == pipEnums.URGENCY.HIGH)) &&
                    (event.status != pipEnums.EXECUTION_STATUS.CANCELED))
                    return 3;
                if ((((event.importance == pipEnums.IMPORTANCE.HIGH) && (event.urgency != pipEnums.IMPORTANCE.HIGH)) ||
                    ((event.importance != pipEnums.URGENCY.HIGH) && (event.urgency == pipEnums.URGENCY.HIGH))) &&
                    (event.status != pipEnums.EXECUTION_STATUS.CANCELED))
                    return 2;
                return 1;
            }

            function clearEvent(event) {
                if (!event) return;

                delete event.source;
                delete event.partyId;
                delete event.parties;
                delete event.groups;
                delete event.default;
                delete event.parent_id;
                delete event.parent_name;
                delete event.parent_ref_type;
                delete event.parent_source;
                delete event.display;
                delete event.combinedParent;
            }

            function getSelectedDateJSON(date) {
                var dateStr = null;
                if (_.isDate(date)) {
                    dateStr = date.toJSON();
                }
                return dateStr;
            }

            function getEvents(item, refType, partyId, callback) {
                if (!item) return;
                pipEventsData.readEvents({
                    item: {
                        party_id: partyId,
                        ref_id: item.id,
                        ref_type: refType
                    }
                }, function (actions) {
                    if (callback) callback(actions);
                    return actions;
                }, processError);
            }

            function onUpdateEventProto(params, successCallback, errorCallback) {
                clearEvent(params.item);
                pipEventsData.updateEvent(params, successCallback, function(error){
                    processError(error);
                    errorCallback
                });
            }

            function getParentFromTree(parents_ids, parentTree) {
                if (parents_ids === undefined || parents_ids === null || parents_ids.length == 0) return null;

                var i,
                    parens = null,
                    parentsLevel = -1;

                for (i = 0; i < parents_ids.length; i++) {
                    if (parents_ids[i] && parents_ids[i].parent_id) {
                        var goal = _.find(parentTree, {id: parents_ids[i].parent_id});
                        if (goal && goal.level !== undefined && goal.level > parentsLevel) {
                            parentsLevel =  goal.level;
                            parens = _.cloneDeep(goal);
                        }
                    }
                }

                return parens;
            }

            function setParent(item, parents, parentTree, visions, notParent) {
                if (!item) return;

                item.parent_source = null;
                item.parent_name = '';
                item.parent_id = null;
                item.task = null;
                var i,
                    goalId = null,
                    taskParent = null;

                var task = _.find(item.refs, {ref_sub_type: 'task'});
                if (task) {
                    item.task = _.cloneDeep(task);
                }

                var parent;
                for (i = 0; i < item.refs.length; i++) {
                    if (item.refs[i].ref_sub_type != 'task' && item.refs[i].parent) {
                        parent = _.find(parents, {id: item.refs[i].ref_id});
                        if (parent && parent.id) break;
                    }
                }

                if (parent && parent.id !== undefined) {
                    item.parent_source = _.cloneDeep(parent);
                    item.parent_name = parent.title || '';
                    item.parent_id = parent.id;
                } else {
                    // search parent from parents
                    taskParent = getParentFromTree(item.parents, parentTree);
                   
                    if (taskParent) {
                        item.parent_source = _.cloneDeep(taskParent);
                        item.parent_name = taskParent.title || '';
                        item.parent_id = taskParent.id;
                    }
                }

                if (!item.parent_id && item.task) {
                    item.parent_name = item.task.ref_name || '';
                    item.parent_id = item.task.ref_id;
                }

                if (item.category == pipEnums.EVENT_CATEGORY.YEARLY) {
                    var vision = _.find(item.refs, { ref_type: pipEnums.REFERENCE_TYPE.VISION });
                    if (vision && vision.ref_id) {
                        item.vision = _.find(visions, {id: vision.ref_id});
                        item.vision.combineName = getVisionName(item.vision);
                        item.vision.collapse = false;
                    }
                }

                if (item.parent_source) item.parent_source.collapse = false;
            }

            function setComplete(item) {
                if (!item) return;
                item.isComplete = (item.status == pipEnums.EXECUTION_STATUS.COMPLETED
                || item.status == pipEnums.EXECUTION_STATUS.CANCELED);
            }

            function setAction(event, partyId) {
                if (!event || !event.contribs) return;
                event.isAction = _.find(event.contribs, {party_id: partyId, accept: 'invited'}) ? true : false;
            }

            // calculate priority event into event list
            function calcPriority(item) {
                if (!item) return;
                item.priority = getEventPriority(item);
                var
                    isCompleted =
                        (item.status == pipEnums.EXECUTION_STATUS.CANCELED ||
                        item.status == pipEnums.EXECUTION_STATUS.COMPLETED) ? 1 : 0;
                var
                    isPending = item.isAction ? 0 : 1;

                item.event_priority = 'p' + isPending + isCompleted + item.start + (3 - item.priority);
            }

            function getNewEvent(item, partyId, isTomorrow) {
                var startDate,
                    endDate,
                    newItem = _.cloneDeep(item);

                if (isTomorrow) {        
                    if (item.all_day || item.category != pipEnums.EVENT_CATEGORY.DAILY) {
                        startDate = EventsDateService.getNextDate(item.start, item.category);
                        if (startDate.getTimezoneOffset() > 0) startDate = EventsDateService.getNextDate(startDate, item.category);
                        endDate = EventsDateService.getNextDate(startDate, item.category);
                    } else {
                        startDate = pipDates.addHours(item.start, 24);
                        endDate = pipDates.addHours(item.end, 24);
                    }
                    newItem.start = startDate;
                    newItem.end = endDate;
                } 
                
                EventsCreateNewService.clearEvent(newItem);

                newItem.party_id = partyId;

                return newItem;
            }

            function onDoItTomorrowMove(item, partyId, transaction, successCallback, errorCallback) {
                if (_.isEmpty(item) || !partyId) return;
                if (!item.category) return;

                var newItem = getNewEvent(item, partyId, true);
                pipEventsData.updateEvent(
                    {
                        transaction: transaction,
                        item: newItem
                    },
                    function (result) {
                        if (successCallback) successCallback(result);
                    },
                    function (err) {
                        processError(err);
                        if (errorCallback) errorCallback(err);
                    }
                );
            }

            function onDoItTomorrowCopy(item, partyId, transaction, onEdit, successCallback, errorCallback) {
                if (_.isEmpty(item) || !partyId) return;
                if (!item.category) return;

                var newItem = getNewEvent(item, partyId, !onEdit);

                if (newItem.status == pipEnums.EXECUTION_STATUS.COMPLETED || newItem.status == pipEnums.EXECUTION_STATUS.CANCELED) {
                    newItem.status = pipEnums.EXECUTION_STATUS.NEW;
                    newItem.progress = 0;
                }

                delete newItem.id;
                delete newItem.created;
                ParentsService.addParentEvent(newItem, item, 'event', false, false);
                EventsCreateNewService.clearEvent(newItem);
                pipEventsData.createEventWithFiles(
                    {
                        transaction: transaction,
                        item: newItem
                    },
                    function (result) {
                        var message = pipUtils.sprintf(pipTranslate.translate('PLANS_COPY_RESULT'), result.title);
                        
                        if (onEdit) {
                            onEdit(null, result);
                            return;
                        } 
                        else AccessoryService.onShowToast(message, 'message');
                        if (successCallback) successCallback(result);
                    },
                    function (err) {
                        processError(err);
                        if (errorCallback) errorCallback(err);
                    }
                );
            }

            function updateAcceptance(refs, item) {
                if (!item) return;
                if (refs.action == 'accept') {
                    _.each(item.contribs, function (contrib) {
                        if ((contrib.accept == 'invited') && (contrib.party_id = refs.party_id)) {
                            item.accept = 'accepted';
                            item.isAction = false;
                        }
                    });
                    return item;
                }

                return null;
            }

            function nextCategory(category) {
                switch (category) {
                    case pipEnums.EVENT_CATEGORY.WEEKLY:
                        return pipEnums.EVENT_CATEGORY.MONTHLY;
                    case pipEnums.EVENT_CATEGORY.MONTHLY:
                        return pipEnums.EVENT_CATEGORY.YEARLY;
                    case pipEnums.EVENT_CATEGORY.DAILY:
                        return pipEnums.EVENT_CATEGORY.WEEKLY;
                    default:
                        return null;
                }
            }

            // определяет является ли период текущим
            function isNotActivePeriod(item, category, startDate) {
                if (!category || !item || !startDate) return false;

                var dateStart = EventsDateService.getStartDate(startDate, category),
                    dateEnd = EventsDateService.getEndDate(startDate, category),
                    from, to, start, end,
                    result;

                from = new Date(item.start);
                to = new Date(item.end);

                if (item.all_day || category != pipEnums.EVENT_CATEGORY.DAILY) {
                    result = (to.getTime() <= dateStart.getTime()) || (dateEnd.getTime() <= from.getTime());

                } else {
                    start =  new Date(from.getTime() - from.getTimezoneOffset() * 60000);
                    end =  new Date(to.getTime() - to.getTimezoneOffset() * 60000);
                    result = (end.getTime() <= dateStart.getTime()) || (dateEnd.getTime() <= start.getTime());
                }

                return result;
            }

            function getActionParam(item, partyId, transaction) {
                return {
                    item: {
                        transaction: transaction,
                        party_id: partyId,
                        id: item.id
                    }
                };
            }

            function onRejectProto(event, item,  partyId, transaction, successCallback, errorCallback) {
                if (event) event.stopPropagation();
                pipEventsData.rejectEvent(
                    getActionParam(item, partyId, transaction),
                    function (result) {
                        $rootScope.$broadcast('pipAutoPullChanges');
                        if (successCallback) successCallback(result);
                    },
                    function (error) {
                        processError(error);
                        if (errorCallback) errorCallback(error);
                    }
                );
            }

            function onAcceptProto(event, item,  partyId, transaction, successCallback, errorCallback) {
                if (event) event.stopPropagation();
                pipEventsData.acceptEvent(
                    getActionParam(item, partyId, transaction),
                    function (result) {
                        $rootScope.$broadcast('pipAutoPullChanges');
                        if (successCallback) successCallback(result);
                    },
                    function (error) {

                        processError(error);
                        if (errorCallback) errorCallback(error);
                    }
                );
            }

            function prepareEventItem(item, parentCollection, parentTree, visions, notParent) {
                if (!item) return;
                
                setComplete(item);
                item.isAction = AccessoryService.isPending(item, $rootScope.$party.id);
                setParent(item, parentCollection, parentTree, visions, notParent);
                calcPriority(item);
                item.unqContribsCount = AccessoryService.getContributorsCount(item.contribs);
                item.isNotContributor = _.find(item.contribs, {party_id: $rootScope.$party.id}) ? false : true;
                item.collapse = false;
            }

            function searchByTitle(query, collection) {
                var result = null;
                if (!query || !collection || collection.length == 0) return result;

                var queryLowercase = query.toLowerCase();
                var resultArray = _.filter(collection, function (item) {
                        return (item.title && item.title.toLowerCase() == queryLowercase);
                    }) || [];

                if (resultArray.length > 0) result = _.cloneDeep(resultArray[0]);

                return result;
            }

            function setNewParent(addedParent, addedParentName, parentCollection, tasksCollection, eventParent) {
                var newParent, parent, task, goal, goalName;

                // if (addedParent && (addedParent.id || addedParent.ref_id)) {
                //     newParent = addedParent;
                // } else {
                    if (addedParent || addedParentName) {
                        goalName = (addedParent && addedParent.title) ? addedParent.title : addedParentName;
                        // проверить поиск в другом регистре !! искать в lowercase
                        parent = eventParent && eventParent.title.toLowerCase() == goalName.toLowerCase() ? eventParent : null;

                        if (!parent) {
                            if (addedParent && addedParent.type == pipEnums.GOAL_TYPE.TASK) {
                                task = searchByTitle(goalName, tasksCollection);
                            } else {
                                goal = searchByTitle(goalName, parentCollection);
                            }
                        }
                      
                        if (goal || task || parent) {
                            if (goal) {
                                newParent = _.cloneDeep(goal);
                            } else {
                                if (task) {
                                    newParent = _.cloneDeep(task);
                                    newParent.ref_sub_type = pipEnums.GOAL_TYPE.TASK;
                                    newParent.ref_type = pipEnums.REFERENCE_TYPE.GOAL
                                } else {
                                    newParent = _.cloneDeep(parent);
                                    newParent.ref_sub_type = parent.type == pipEnums.GOAL_TYPE.TASK ? parent.type : null;
                                    newParent.ref_type = pipEnums.REFERENCE_TYPE.GOAL
                                }

                            }
                        } else {
                            newParent = {
                                id: '',
                                title: addedParentName || '',
                                ref_type: pipEnums.REFERENCE_TYPE.GOAL
                            };
                        }
                    }
                // } 

                return newParent;               
            }

            function saveRefsWithNewParent(data, parent, newParent) {
                        var subRecord = null, overRecord = null, refs = [];

                            // remove parent and leave reference to events
                            refs = _.filter(data.refs, function (ref) {
                                return ref.ref_type != pipEnums.REFERENCE_TYPE.AREA &&
                                    ref.ref_type != pipEnums.REFERENCE_TYPE.GOAL &&
                                    ref.ref_sub_type != pipEnums.GOAL_TYPE.TASK;
                            });

                            if (parent && parent.contentDetails) {
                                if (parent.contentDetails.withParents) {
                                    overRecord = {
                                        ref_id: null,
                                        ref_name: parent.contentDetails.title,
                                        ref_type: parent.contentDetails.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.REFERENCE_TYPE.GOAL : parent.contentDetails.type,
                                        ref_sub_type: parent.contentDetails.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null
                                    };
                                } else {
                                    subRecord = {
                                        ref_id: null,
                                        ref_name: parent.contentDetails.title,
                                        ref_type: parent.contentDetails.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.REFERENCE_TYPE.GOAL : parent.contentDetails.type,
                                        ref_sub_type: parent.contentDetails.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null
                                    };
                                }
                            }

                            if (parent && parent.option) {
                                refs.push({
                                    ref_id: null,
                                    ref_name: newParent.title,
                                    ref_type: parent.option.name == pipEnums.GOAL_TYPE.TASK ? pipEnums.REFERENCE_TYPE.GOAL : parent.option.name,
                                    ref_sub_type: parent.option.name == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null,
                                    parent: true,
                                    subRecord: subRecord,
                                    overRecord: overRecord
                                });
                            }

                            return refs;
            }

            function saveRefs(data, parent) {
                var refs = [];
                    refs = _.filter(data.refs, function (ref) {
                        return ref.ref_type != pipEnums.REFERENCE_TYPE.AREA && ref.ref_type != pipEnums.REFERENCE_TYPE.GOAL;
                    });
                    if (parent)
                        refs.push({
                            ref_id: parent.id,
                            ref_name: parent.title,
                            ref_type: parent.ref_type,
                            ref_sub_type: parent.ref_sub_type,
                            parent: true
                        });

                return refs;                        
            }

            function changeAllDay(data) {
                if (!data.all_day) {
                    var startTime, endMinutes, endTime;
                    var start = new Date();

                    start = new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate());
                    var startMinutes = EventsDateService.getStartHalfHours(new Date());
                    if (startMinutes > 23 * 60) { // переносим на самое начало следующего дня
                        startTime = pipDates.toStartDay(start, 1000 * 31); // берем начало следующего дня
                        startMinutes = 0;
                        endMinutes = 30;
                    } else {
                        startTime = pipDates.toStartDay(start);
                        endMinutes = startMinutes + 30;
                    }

                    data.endDate = data.startDate;
                    data.startTime = startMinutes === null ? null : startMinutes;
                    data.endTime = endMinutes === null ? null : endMinutes;


                    data.duration = data.endDate.getTime() + endMinutes * 60 * 1000 -
                        data.startDate.getTime() - startMinutes * 60 * 1000;

                    if (data.duration < 30 * 60 * 1000) {
                        data.duration = 30 * 60 * 1000;
                    }
                } else {
                    data.endDate = pipDates.toStartDay(data.endDate);
                    data.startDate = pipDates.toStartDay(data.startDate);
                    data.duration = data.endDate.getTime() - data.startDate.getTime();
                }
            }

            // shedure and calendar functions

            function onEventSelectionProto(category, successCallback) {

                if (category == pipEnums.EVENT_CATEGORY.YEARLY)
                    return;
                if ($scope.overall.nextPeriodCollection.length == 0) return;

                var chooseEvents = [];
                EventSelectionDialog.show({
                        event: event,
                        eventCollection: $scope.overall.nextPeriodCollection,
                        category: EventUAService.nextCategory($scope.selected.navId),
                        party: $scope.party,
                        itemCollection: $scope.selected.eventCollection,
                        date: $scope.selected.date,
                        chooseEvents: chooseEvents
                    },
                    function (result) {
                        // обработка групповых операций
                        var tid = $scope.transaction.begin('CREATING');
                        var startDate = EventsDateService.getStartDate($scope.selected.date, $scope.selected.navId),
                            endDate = EventsDateService.getEndDate(startDate, $scope.selected.navId);

                        async.eachSeries(result.chooseEvents, function (newItem, callback) {
                            if (!newItem.checkItem) {
                                callback();
                            } else {
                                // Создаем новую задачу на основе существующей
                                var event = EventsCreateNewService.createFromMajorEvent({
                                    event: newItem,
                                    startDate: startDate,
                                    endDate: endDate,
                                    category: $scope.selected.navId,
                                    partyId: $scope.party.id
                                });
                                EventsCreateNewService.clearEvent(event);
                                pipEventsData.createEvent({
                                        item: event
                                    },
                                    function (result) {
                                        $scope.prepareEventItem(result, $scope.parentCollection, $scope.parentTree, $scope.visions, true);
                                        $scope.overall.eventCollection.push(result);
                                        $scope.selected.itemId = result.id;

                                        callback();
                                    },
                                    function (err) {
                                        processError(err);
                                        callback(err)
                                    }
                                );
                            }
                        }, function (err) {
                            if ($scope.transaction.aborted(tid)) return;
                            if (!err) {
                                // все добавилось норамльно
                                $scope.selectItems();
                                $scope.transaction.end();
                                if (successCallback) successCallback(true);
                            } else {
                                $scope.transaction.end(err);
                            }
                        });
                    }
                );
            }

            return {

                getSelectedDateJSON: getSelectedDateJSON,
                getEvents: getEvents,
                onUpdateEventProto: onUpdateEventProto ,

                setParent: setParent,
                setComplete: setComplete ,
                setAction: setAction ,
                calcPriority: calcPriority ,

                onDoItTomorrowMove: onDoItTomorrowMove,
                onDoItTomorrowCopy: onDoItTomorrowCopy,

                updateAcceptance: updateAcceptance ,

                nextCategory: nextCategory ,

                isNotActivePeriod: isNotActivePeriod,

                onRejectProto: onRejectProto,
                onAcceptProto: onAcceptProto,

                prepareEventItem: prepareEventItem,
                getEventPriority: getEventPriority,

                setNewParent: setNewParent,
                saveRefs: saveRefs,
                saveRefsWithNewParent: saveRefsWithNewParent,

                changeAllDay: changeAllDay,

                // shedure and calendar functions

            }
        }]
    );

})();