<div class="pip-ref-item flex-fixed layout-row layout-align-start-center"
     xxxxxmd-ink-ripple
     ng-repeat="item in taskCollection track by $index"
     ng-mousedown="onClick($event, $index)"
     ng-class="{'divider-bottom' : $index === bulk.index && !item.empty,
                                                'divider-top' : $index !== 0 && $index === bulk.index && !item.empty}">

    <md-button class="md-icon-button left-icon"
               ng-show="item.empty"
               ng-disabled="ngDisabled()"
               md-ink-ripple
               ng-click="onAddItem()"
               ng-focus="onItemFocus($index)"
               tabindex="-1"
               aria-label="PLUS">
        <md-icon class="text-grey" md-svg-icon="icons:plus"></md-icon>
    </md-button>
    <md-checkbox ng-model="item.isComplete"
                 ng-show="!item.empty"
                 aria-label="COMPLETE"
                 ng-focus="onItemFocus($index)"
                 ng-change="onChecked(item)"
                 ng-disabled="ngDisabled()">
    </md-checkbox>


    <div class="pip-content rp24 rm8 layout-column flex"
         ng-class="{'pip-completed-event' : item.isComplete }">

        <div class="flex">
            <md-input-container md-no-float class="flex">
                <textarea ng-model="item.obj.title"
                          name="{{'text' + $index}}"
                          aria-label="TEXT"
                          class="pip-task-text"
                          ng-class="{'empty-item': item.empty}"
                          ng-focus="onItemFocus($index)"
                          ng-change="onChangeItem($index)"
                          ng-keydown="onTextareaKeyDown($event, $index)"
                          placeholder="{{::bulk.plaseholder | translate}}"
                          id="{{'task-title-' + $index}}"
                          ng-disabled="ngDisabled()">
                </textarea>
            </md-input-container>
        </div>
        <!--<p class="pip-title" ng-if=>{{item.obj.title}} </p>-->
        <pip-priority-status-edit class="pip-subtitle"
                                  ng-if="item.obj.title && !item.empty"
                                  ng-disabled="ngDisabled()"
                                  pip-entity="item.obj"
                                  pip-rebind="true"
                                  pip-show-progress="false"
                                  pip-change="onPriorityStatusChange(item)">
        </pip-priority-status-edit>


    </div>

    <md-button class="md-icon-button right-icon" md-ink-ripple
               ng-click="onDeleteItem($index, item)"
               ng-disabled="ngDisabled()"
               tabindex="-1"
               ng-focus="onItemFocus($index)"
               ng-show="!item.empty && $index === bulk.index"
               aria-label="DELETE-ITEM">
        <md-icon class="text-grey" md-svg-icon="icons:cross-circle"></md-icon>
    </md-button>

</div>
