/**
 * @file Connections data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipConnectionsData', ['pipRest', 'pipDataModel', 'pipConnectionsCache']);
    
    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var connectionsApi = {
                connections: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/connections/:id');
                },

                connectionBlocks: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/blocks/:id');
                }
         };
        
         pipRestProvider.addApi(connectionsApi);
    }]);

    thisModule.provider('pipConnectionsData', function () {
        
        // Read all connections
        this.readConnectionsResolver = function() {
            return /* @ngInject */ ['$stateParams', 'pipDataSession', 'pipConnectionsCache', function($stateParams, pipDataSession, pipConnectionsCache) {
                return pipConnectionsCache.readConnections({
                    item: { party_id: $stateParams.managed_party_id || pipDataSession.partyId() }
                });
            }];
        };

        this.$get = ['$stateParams', '$rootScope', '$state', 'pipDataSession', 'pipDataModel', 'pipConnectionsCache', function($stateParams, $rootScope, $state, pipDataSession, pipDataModel, pipConnectionsCache) {
            return {
                partyId: pipDataSession.partyId(),
                
                readManagedConnections: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.item = params.item || { party_id: $stateParams.managed_party_id || pipDataSession.partyId() };
                    return pipConnectionsCache.readConnections(params, successCallback, errorCallback);
                },
                
                readConnections: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.item = params.item || { party_id: pipDataSession.partyId() };
                    return pipConnectionsCache.readConnections(params, successCallback, errorCallback);
                },

                readUserConnections: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.item = params.item || { party_id: $rootScope.$user.id };
                    return pipConnectionsCache.readConnections(params, successCallback, errorCallback);
                },

                getConnectionsPage: function (params, successCallback, errorCallback) {
                    params.resource = 'connections';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    return pipDataModel.page(params, successCallback, errorCallback);
                },

                updateConnection: function (params, successCallback, errorCallback) {
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.resource = $state.current.name.split('.')[0];
                    pipDataModel.update(
                        params,
                        pipConnectionsCache.onConnectionUpdate(params, successCallback),
                        errorCallback
                    );
                },

                deleteConnection: function (params, successCallback, errorCallback) {
                    if (!params.resource) params.resource = 'connections';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.remove(
                        params,
                        pipConnectionsCache.onConnectionDelete(params, successCallback),
                        errorCallback
                    );
                },

                readConnectionBlocks: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.item = params.item || { party_id: pipDataSession.partyId() };
                    params.force = true;
                    return pipConnectionsCache.readConnectionBlocks(params, successCallback, errorCallback);
                },

                connectionBlock: function (params, successCallback, errorCallback) {
                    params.resource = 'connectionBlocks';
                    pipDataModel.create(params, successCallback, errorCallback);
                },

                deleteConnectionsBlocks: function (params, successCallback, errorCallback) {
                    if (!params.resource) params.resource = 'connectionBlocks';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.remove(params, successCallback, errorCallback);
                }        

            }
        }];
    });

})();
