/**
 * @file Members data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipMembersData', ['pipRest', 'pipDataModel', 'pipConnectionsCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var membersApi = {
                members: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/members/:id');
                },

                connections: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/connections/:id');
                },

                acceptMember: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/members/:id/accept');
                }
         };
        
         pipRestProvider.addApi(membersApi);
    }]);
    
    thisModule.provider('pipMembersData', function () {

        // Read all member connections
        this.readMembersResolver = function() {
            return /* @ngInject */ ['$stateParams', 'pipConnectionsCache', 'pipDataSession', function($stateParams, pipConnectionsCache, pipDataSession) {
                return pipConnectionsCache.readMembers({
                    item: { party_id: pipDataSession.partyId() }
                });
            }];
        };

        // Read specific member connection
        this.readMemberResolver = function() {
            return /* @ngInject */ ['$stateParams', 'pipRest', 'pipConnectionsCache', 'pipDataSession', function($stateParams, pipRest, pipConnectionsCache, pipDataSession) {
                var params = {
                    party_id: pipDataSession.partyId(),
                    id: $stateParams.id
                };
// todo, read from cache
                return pipRest.members().get(
                    params,
                    pipConnectionsCache.onConnectionUpdate(params)
                ).$promise;
            }];
        };

        // Read all partners and members
        this.readCollaboratorsResolver = function() {
            return /* @ngInject */ ['$stateParams', 'pipConnectionsCache', 'pipDataSession', function($stateParams, pipConnectionsCache, pipDataSession) {
                return pipConnectionsCache.readCollaborators({
                    item: { party_id: pipDataSession.partyId() }
                });
            }];
        };

        this.$get = ['$stateParams', '$rootScope', '$state', 'pipDataModel', 'pipConnectionsCache', 'pipDataSession', function($stateParams, $rootScope, $state, pipDataModel, pipConnectionsCache, pipDataSession) {
            return {
                partyId: pipDataSession.partyId(),

                readCollaborators: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.item = params.item || { party_id: pipDataSession.partyId() };
                    return pipConnectionsCache.readCollaborators(params, successCallback, errorCallback);
                },

                readMembers: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.item = params.item || { party_id: pipDataSession.partyId() };
                    return pipConnectionsCache.readMembers(params, successCallback, errorCallback);
                },

                readMember: function (params, successCallback, errorCallback) {
                    params.resource = 'connections';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.id = params.item.id || $stateParams.id;
                    return pipDataModel.readOne(
                        params,
                        pipConnectionsCache.onConnectionUpdate(params, successCallback),
                        errorCallback
                    );
                },
                
                createMember: function (params, successCallback, errorCallback) {
                    params.resource = 'members';
                    params.item = params.item || {};
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.create(
                        params,
                        pipConnectionsCache.onConnectionCreate(params, successCallback),
                        errorCallback
                    );
                },

                acceptMember: function (params, successCallback, errorCallback) {
                    params.resource = 'acceptMember';
                    pipDataModel.update(
                        params,
                        pipConnectionsCache.onConnectionUpdate(params, successCallback),
                        errorCallback
                    );
                }

            }
        }];
    });

})();
