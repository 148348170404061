/**
 * @file Posts data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipPostsData', ['pipRest', 'pipDataModel', 'pipCacheTag', 'pipPostsCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var postsApi = {
               feeds: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/feeds/:id');
                },

                publicPosts: function (url) {
                    return this.createCollection(url, '/api/posts/:id');
                },

                allFeedPosts: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/feeds/posts/:id');
                },

                feedPosts: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/feeds/:feed_id/posts/:id',
                        {
                            id: '@id',
                            party_id: '@party_id',
                            feed_id: '@feed_id'
                        }
                    );
                },

                postComments: function (url) {
                    return this.createCollection(url, '/api/parties/:party_id/posts/:id/comments/:comment_id',
                        {
                            party_id: '@party_id',
                            id: '@id',
                            comment_id: '@comment_id'
                        }
                    );
                },

                postCheers: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/posts/:post_id/cheers',
                        {
                            party_id: '@party_id',
                            post_id: '@post_id'
                        }
                    );
                },

                postBoos: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/posts/:post_id/boos',
                        {
                            party_id: '@party_id',
                            post_id: '@post_id'
                        }
                    );
                }
         };
        
         pipRestProvider.addApi(postsApi);
    }]);
    
    thisModule.provider('pipPostsData', function () {

        var DEFAULT_TAKE = 15;
        var BUFFER_SIZE = 15;
        var MIN_BUFFER_SIZE = 7;

        // this.readPostResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipEnums, pipRest) {
        //         if (!$stateParams.id) return null;

        //         var params = {
        //             party_id: pipDataSession.partyId(),
        //             id: $stateParams.id
        //         };
                
        //         return pipRest.posts().get(params).$promise;
        //     };
        // };

        // this.readPostsPageResolver = function() {
        //     return /* @ngInject */ function($stateParams,pipRest) {
        //         var feeds = $stateParams.category ?  $stateParams.category : 'all';
        //         var take = ( $stateParams.startTake && $stateParams.startTake > Number(DEFAULT_TAKE)) ?
        //             $stateParams.startTake : Number(DEFAULT_TAKE);
        //         take += Number(BUFFER_SIZE);

        //         var params = {
        //             party_id: pipDataSession.partyId(),
        //             search: $stateParams.search,
        //             skip: 0,
        //             take: take,
        //             paging: 1
        //         };

        //         switch (feeds) {
        //             case 'my_posts':
        //                 return pipRest.posts().get(params).$promise;
        //                 break;
        //             case 'all':
        //                 return pipRest.allFeedPosts().get(params).$promise;
        //                 break;
        //             case 'announces':
        //                 return [];
        //                 break;
        //             default:
        //                 params.feed_id = feeds;
        //                 return pipRest.feedPosts().get(params).$promise;
        //                 break;
        //         }
        //     };
        // };

        // this.readFeedsResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest) {
        //         return pipRest.feeds().query({
        //             party_id: pipDataSession.partyId()
        //         }).$promise;
        //     };
        // };

        this.$get = ['$stateParams', 'pipDataModel', 'pipCacheTag', 'pipPostsCache', 'pipDataSession', function($stateParams, pipDataModel, pipCacheTag, pipPostsCache, pipDataSession) {
            return {
                readPostsPage: function (params, successCallback, errorCallback) {
                    var feeds,
                        take = $stateParams.startTake ?
                                Number($stateParams.startTake) + Number(BUFFER_SIZE) :
                                Number(DEFAULT_TAKE) + Number(BUFFER_SIZE);

               
                    if (params.category) {
                        feeds = params.category;
                    } else {
                        feeds = $stateParams.category ?  $stateParams.category : 'all'
                    }

                    if (feeds === 'announces') {
                        var result = { data: [], total: 0 };
                        if (successCallback) successCallback(result);

                        return result;
                    }

                    params.resource = 'posts';
                    params.item = params.item || {};  
                    params.item.party_id = params.item.party_id ? params.item.party_id : pipDataSession.partyId();                    
                    params.item.skip = params.item.skip ? params.item.skip : 0;
                    params.item.take = params.item.take ? params.item.take : 15;
                    // params.item.paging = params.item.paging ? params.item.paging : 1;                    
                    params.item.paging = 0;                    
                    params.item.search = params.item.search ? params.item.search : $stateParams.search;

                    if (feeds == 'all'){
                        params.resource = 'allFeedPosts';
                    }
                    if(feeds == 'my_posts') {
                        params.item.party_id = pipDataSession.partyId();
                    }
                    if (feeds != 'my_posts' && feeds != 'all'){
                       params.resource = 'feedPosts';
                        params.item.feed_id = feeds;
                    }
                    
                    return pipPostsCache.readPosts(params, successCallback, errorCallback);
                },

                readPost: function(params, successCallback, errorCallback) {
                    params.resource = 'posts';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.id = params.item.id || $stateParams.id;
                    return pipDataModel.readOne(
                        params,
                        pipPostsCache.onPostUpdate(params, successCallback),
                        errorCallback
                    );
                },

                readFeeds: function (params, successCallback, errorCallback) {
                    params.resource = 'feeds';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    return pipPostsCache.readFeeds(params, successCallback, errorCallback);
                },

                createPost: function(params, successCallback, errorCallback) {
                    params.resource = 'posts';
                    params.item.party_id = pipDataSession.partyId();
                    params.item.creator_id = pipDataSession.partyId();
                    pipDataModel.create(
                        params,
                        pipPostsCache.onPostCreate(params, successCallback),
                        errorCallback
                    );
                },

                updatePost: function(params, successCallback, errorCallback) {
                    params.resource = 'posts';
                    params.item.party_id = pipDataSession.partyId();
                    params.item.creator_id = pipDataSession.partyId();
                    pipDataModel.update(
                        params,
                        pipPostsCache.onPostUpdate(params, successCallback),
                        errorCallback
                    );
                },

                createPostWithFiles: function(params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(params, function() {
                        params.resource = 'posts';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;
                        params.item.party_id = pipDataSession.partyId();
                        params.item.creator_id = pipDataSession.partyId();
                        pipDataModel.create(
                            params,
                            pipPostsCache.onPostCreate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                updatePostWithFiles: function(params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(params, function() {
                        params.resource = 'posts';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;
                        params.item.party_id = pipDataSession.partyId();
                        params.item.creator_id = pipDataSession.partyId();
                        pipDataModel.update(
                            params,
                            pipPostsCache.onPostUpdate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                cheersPost: function(params, successCallback, errorCallback) {
                    params.resource = 'postCheers';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.create(params, successCallback, errorCallback);
                },

                uncheersPost: function(params, successCallback, errorCallback) {
                    params.resource = 'postCheers';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.remove(params, successCallback, errorCallback);
                },

                boosPost: function(params, successCallback, errorCallback) {
                    params.resource = 'postBoos';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.create(params, successCallback, errorCallback);
                },

                unboosPost: function(params, successCallback, errorCallback) {
                    params.resource = 'postBoos';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.remove(params, successCallback, errorCallback);
                },

                deletePost: function(params, successCallback, errorCallback) {
                    var feeds = params.category ? params.category : $stateParams.category || 'all';
                    params.item = params.item || { };
                    params.item.party_id = pipDataSession.partyId();

                    switch (feeds) {
                        case 'my_posts':
                            params.resource = 'posts';
                            break;
                        case 'all':
                            params.resource = 'allFeedPosts';
                            break;
                        case 'announces':
                            return;
                        default:
                            params.feed_id = feeds;
                            params.resource = 'feedPosts';
                            break;
                    }

                    pipDataModel.remove(params,  pipPostsCache.onPostDelete(params, successCallback), errorCallback);
                },

                getPostsPage: function(params, successCallback, errorCallback) {
                    var feeds;
                    
                    if (params.category) {
                        feeds = params.category;
                    } else {
                        feeds = $stateParams.category ?  $stateParams.category : 'all'
                    }                    

                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    switch (feeds) {
                        case 'my_posts':
                            params.resource = 'posts';
                            break;
                        case 'all':
                            params.resource = 'allFeedPosts';
                            break;
                        case 'announces':
                            var result = [];
                            if (successCallback) successCallback(result);
                            return;
                        default:
                            params.resource = 'feedPosts';
                            params.item.feed_id = feeds;
                            break;
                    }
                    // todo backend do not return paging
                    params.item.paging = 0
                    if (params.item.paging == 1) {
                        pipDataModel.get(params, successCallback, errorCallback);
                    }else {
                        pipDataModel.read(params, successCallback, errorCallback);
                    }
                },

                // выбор новостей по связанной сущности
                getPosts: function(params, successCallback, errorCallback) {
                    params.resource = 'posts';
                    params.item = params.item ? params.item : {};
                    params.item.party_id = params.item.party_id ? params.item.party_id : pipDataSession.partyId();
 
                    return pipPostsCache.readPosts(params, successCallback, errorCallback);
                },

                // выбор новостей по связанной сущности
                getPost: function(params, successCallback, errorCallback) {
                    params.resource = 'posts';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();
                    pipDataModel.readOne(params, successCallback, errorCallback);
                },

                addComment: function(params, successCallback, errorCallback) {
                    params.resource = 'postComments';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.create(params, successCallback, errorCallback);
                },

                deleteComment: function(params, successCallback, errorCallback) {
                    params.resource = 'postComments';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.remove(params, successCallback, errorCallback);
                },

                editComment: function(params, successCallback, errorCallback) {
                    params.resource = 'postComments';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.update(params, successCallback, errorCallback);
                },

                createBuzz: function(params, successCallback, errorCallback) {
                    params.resource = 'posts';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();
                    if (params.post )
                        params.item.id = params.post.id;
                    pipDataModel.create(params, successCallback, errorCallback);
                },

                createFeeds: function(params, successCallback, errorCallback) {
                    params.resource = 'feeds';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.create(params, successCallback, errorCallback);
                },

                updateFeeds: function(params, successCallback, errorCallback) {
                    params.resource = 'feeds';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.update(params, successCallback, errorCallback);
                },

                deleteFeeds: function(params, successCallback, errorCallback) {
                    params.resource = 'feeds';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.remove(params, successCallback, errorCallback);
                },

                getFeed: function(params, successCallback, errorCallback) {
                    params.resource = 'feeds';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();
                    pipDataModel.readOne(params, successCallback, errorCallback);
                },

                getBufferSize: function() {
                    return BUFFER_SIZE;
                },

                getDefaultTake: function() {
                    return DEFAULT_TAKE;
                },

                getMinBufferSize: function() {
                    return MIN_BUFFER_SIZE;
                }
            };
        }];
        this.$get.$inject = ['$stateParams', 'pipDataModel', 'pipCacheTag', 'pipPostsCache', 'pipDataSession'];
    });

})();
