/**
 * @file Party edit dialog
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo
 * - Improve sample in sampler app
 */

/* global $, angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipPartyEditDialog',
        ['ngMaterial', 'pipAvatar', 'pipCore', 'pipLifeCommons.Templates']);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            'PARTY_ADD_PARTY': 'Add party',
            'ALL': 'All',
            'SELECTED': 'Selected',
            'AVAILABLE': 'Available',
            'SELECT': 'Select',
            'CANCEL': 'Cancel',
            'PARTY_NAME_PLACEHOLDER': 'Filter by name',
            'PARTY_TITLE': 'Choose parties',
            'PARTY_DIALOG_CONTACTS_NONE': 'Unfortunately, you have no partners to show here. Find partners at connections to add them to participants list later here.',
            'PARTY_DIALOG_GOTO_CONNECTIONS': 'Go To Connections'
        });
        pipTranslateProvider.translations('ru', {
            'PARTY_ADD_PARTY': 'Добавить партнера',
            'ALL': 'Все',
            'SELECTED': 'Выделенные',
            'AVAILABLE': 'Доступные',
            'SELECT': 'Выбрать',
            'CANCEL': 'Отменить',
            'PARTY_NAME_PLACEHOLDER': 'Отфильтруйте по имени',
            'PARTY_TITLE': 'Выберите партнеров',
            'PARTY_DIALOG_CONTACTS_NONE': 'К сожалению, у вас нет партнеров, чтобы показать их здесь. Найти партнеров можно в связях, чтобы добавить их позже в список участников.',
            'PARTY_DIALOG_GOTO_CONNECTIONS': 'Перейти на партнеров'
        });
    }]);

    thisModule.factory('pipPartyEditDialog',
        ['$mdDialog', function ($mdDialog) {
            return {
                show: function (params, successCallback, cancelCallback) {
                    $mdDialog.show({
                        targetEvent: params.event,
                        controller: 'pipPartyEditDialogController',
                        templateUrl: 'parties/party_edit_dialog.html',
                        locals: {
                            title: params.title,
                            parties: params.parties,
                            partyOptions: params.partyOptions,
                            optionTitle: params.optionTitle,
                            optionDefaults: params.option,
                            confirm: params.confirm || 'SELECT'
                        },
                        clickOutsideToClose: true
                    })
                        .then(function (result) {
                            if (successCallback) {
                                successCallback(result);
                            }
                        }, function () {
                            if (cancelCallback) {
                                cancelCallback();
                            }
                        });
                }
            };
        }]
    );

    thisModule.controller('pipPartyEditDialogController',
        ['$scope', '$rootScope', '$timeout', '$mdDialog', '$state', 'pipTransaction', 'title', 'parties', 'partyOptions', 'optionTitle', 'optionDefaults', 'confirm', function ($scope, $rootScope, $timeout, $mdDialog,  $state, pipTransaction, title, parties,
                  partyOptions, optionTitle, optionDefaults, confirm) {

            $scope.theme = $rootScope.$theme;
            $scope.title = title || 'PARTY_TITLE';
            $scope.optionTitle = optionTitle;
            $scope.optionDefaults = optionDefaults;
            $scope.parties = parties ? _.clone(parties) : [];
            $scope.nameFilter = '';
            $scope.confirmButton = confirm;
            $scope.options = _.map(partyOptions, function (party) {
                var index = _.findIndex(parties, {party_id: party.party_id});
                return {
                    party_id: party.party_id,
                    party_name: party.party_name,
                    selected: index != null && index >= 0
                };
            });
            $scope.options = _.sortBy($scope.options, sortOption);

            $scope.selected = {};
            $scope.selected.checkedFilter = 'all';
            $scope.selected.options = $scope.options;
            $scope.selected.optionIndex = 0;
            $scope.selected.item = _.cloneDeep($scope.selected.options[$scope.selected.optionIndex]);
            $scope.selectedCount = calculateSelected($scope.selected.options);

            setTimeout(focusInput, 500);

            $scope.onNameFilterChange = _.debounce(function() {
                $scope.selected.options = selectOptions();
                $scope.$apply();
            }, 200);

            $scope.onOptionsKeyPress = onOptionsKeyPress;
            $scope.onOptionToggle = onOptionToggle;
            $scope.onCancel = onCancel;
            $scope.onChoose = onChoose;
            $scope.onRemove = onRemove;
            $scope.onChangeCriteria = onChangeCriteria;
            $scope.onGotoConnections = onGotoConnections;

            $scope.isEmptyParticipants = isNoParticipants();

            return;

            function isNoParticipants() {
                var includeParty = _.findIndex($scope.parties, {id: $rootScope.$party.id});

                if ($scope.parties.length == 0 || (includeParty == -1 && $scope.parties.length == 1)) {
                    return true;
                } else {
                    return false;
                }
            }

            function sortOption(option) {
                return option.party_name;
            }

            function calculateSelected(collection) {
                var count = 0;

                _.each(collection, function (item) {
                    if (item.selected) {
                        count += 1;
                    }
                });

                return count;
            }

            function filterOption(option) {
                if ($scope.nameFilter == null || ($scope.nameFilter == '' && $scope.selected.checkedFilter == 'all'))
                    return true;
                var
                    value1 = option.party_name.toLowerCase(),
                    value2 = $scope.nameFilter.toLowerCase();

                var check1, check2;

                check1 = value1.indexOf(value2) >= 0;
                check2 = (option.selected &&  $scope.selected.checkedFilter == 'selected') ||
                (!option.selected &&  $scope.selected.checkedFilter == 'available') ||
                $scope.selected.checkedFilter == 'all';

                return (check1 && check2);
            };

            function selectOptions() {
                var option = $scope.selected.options[$scope.selected.optionIndex];
                $scope.selected.options = _.filter($scope.options, filterOption);
                $scope.selected.optionIndex = _.findIndex($scope.selected.options, {party_id:
                    option ? option.party_id : null});

                if ($scope.selected.optionIndex < 0) $scope.selected.optionIndex = 0;
                if ($scope.selected.options && $scope.selected.options.length > 0)
                    $scope.selected.item =  _.cloneDeep($scope.selected.options[$scope.selected.optionIndex]);

                return $scope.selected.options;
            };

            function toggleOption(option) {
                var item = _.cloneDeep(option);

                if (item.selected) {
                    if (_.find($scope.parties, {party_id: item.party_id}) == null) {
                        // Find previous item
                        var party = _.find(parties, {party_id: item.party_id});

                        if (!party || !party.party_id) {
                            party = {
                                party_id: item.party_id,
                                party_name: item.party_name
                            };
                        }
                        $scope.parties.push(_.cloneDeep(party));
                    }
                } else {
                    if (_.findIndex($scope.parties, {party_id: option.party_id}) >= 0)
                        _.remove($scope.parties, {party_id: option.party_id});
                }
                $scope.selectedCount = calculateSelected($scope.selected.options);
            }

            // Setting focus to input control
            function focusInput() {
                var input = $('.pip-party-edit-dialog input');
                input.focus();
            };

            function onRemove(event, party) {
                _.remove($scope.parties, {party_id: party.party_id});
                var option = _.find($scope.options, {party_id: party.party_id});
                if (option) option.selected = false;

            };

            function onOptionsKeyPress(event) {
                if (!event) return;
                event.stopPropagation();
                event.preventDefault();
                if (event.index !== undefined)
                    var option = $scope.selected.options[index];
                if (option) {
                    option.selected = !option.selected;
                    toggleOption(option);
                }
            };

            function onOptionToggle($index, option) {
                $scope.selected.optionIndex = $index;
                if (option) {
                    option.selected = !option.selected;
                    toggleOption(option);
                }
                $scope.selected.item = _.cloneDeep($scope.selected.options[$scope.selected.optionIndex]);

                if ($scope.selectedCount == 0) {
                    $scope.selected.checkedFilter = 'all';
                    selectOptions();
                }
            }

            function onCancel() {
                $mdDialog.cancel();
            }

            function onChoose() {
                $mdDialog.hide({
                    parties: $scope.parties,
                    option: $scope.option
                });
            }

            function onGotoConnections() {
                $state.go('partners.list');
                $mdDialog.cancel();
            }

            function onChangeCriteria ($event) {
                if ($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                }

                $scope.selected.checkedFilter = $scope.selected.checkedFilter == 'all' ? 'selected' : 'all';
                $scope.selected.options = selectOptions();
            }
        }]
    );

})();
