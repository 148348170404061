<div class="h72 p24-flex rp24-flex w-stretch  layout-row layout-align-start-center flex"
     ng-if="!$mdMedia('xs')">

    <div class="layout-row layout-align-start-center"
         ng-repeat="item in titleCollection" 
         ng-click="onStateClick($index)"
         ng-class="{'flex': $index !== titleCollection.length - 1, 'pointer': $index < state - 1}">
        <div class="flex-fixed">
            <div class="pip-bullet" 
                ng-class="{'color-accent-bg': state > $index, 'pip-bullet-secondary': state <= $index, 'lm0': $index === 0}">
                {{ ::($index + 1) }}
            </div>
        </div>
        <div  class="flex-fixed pip-wizard-content" 
              ng-class="{'opacity-secondary': $index > state - 1}">
            <div class="text-overflow" ng-class="state === $index + 1? 'text-subhead2' : 'text-subhead1'" >
                {{ ::item.title | translate }}
            </div>
            <div class="text-overflow" ng-if="item.subTitle && state < $index + 1" ng-bind-html="item.subTitle">
            </div>    
        </div> 
        <div class="pip-divider rm8 lm8 flex "
             ng-if='$index !== titleCollection.length - 1'></div>
    </div>         
</div>    

<div class="h48 p24-flex rp24-flex w-stretch layout-row layout-align-start-center flex"
     ng-if="$mdMedia('xs')">
     
     <div class="text-body2 flex ">
         {{ ::titleCollection[state-1].title | translate }}
     </div>
         
     <div class="text-body2 opacity-secondary">
         {{ ::headerStep }}
     </div>    
     
</div>     