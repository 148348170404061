<!--
@file Parties edit dialog content
@copyright Digital Living Software Corp. 2014-2016
-->

<md-dialog class="pip-dialog pip-party-edit-dialog layout-column"
           width="500" md-theme="{{theme}}">
    <md-dialog-content class="pip-body lp0 rp0 tp0 pip-scroll" ng-if="!isEmptyParticipants">
        <div class="pip-header ">
            <h3 class="m0 text-title bm24">{{::title | translate}}</h3>
            <div class="bm8" ng-hide="!optionTitle">
                <md-checkbox ng-model="optionDefaults" class="lm0 rm0">
                <span class="text-subhead1">
                    {{::optionTitle | translate}}
                </span>
                </md-checkbox>
            </div>
            <div class="pip-party-container divider-bottom" pip-focused>
                <div class="w-stretch layout-row layout-start-center">
                    <input type="text" ng-model="nameFilter"
                           autofocus="true"
                           placeholder="{{::'PARTY_NAME_PLACEHOLDER' | translate}}"
                           ng-change="onNameFilterChange()"/>

                    <md-icon class="text-grey pointer icon-secondary md-primary"  ng-click=""
                             md-svg-icon="icons:search-square"></md-icon>
                </div>
            </div>
        </div>
        <md-list pip-selected="selected.optionIndex"
                 pip-selected-id="selected.item.party_id"
                 pip-enter-space-press="onOptionsKeyPress($event)">
            <div class="pip-content tm8 bm8">
                <div ng-repeat="option in selected.options track by option.party_id"
                     ng-click="onOptionToggle($index, option)"
                     class="pip-list-item pip-selectable p0"
                     aria-label="{{::option.party_name}}"
                     md-ink-ripple="false">

                    <div class="pip-list-item">
                        <pip-avatar class="pip-face"
                                    pip-party-id="option.party_id"
                                    pip-party-name="option.party_name">
                        </pip-avatar>
                        <div class="pip-option-title">
                            {{::option.party_name}}
                        </div>
                        <md-checkbox ng-model="option.selected"
                                     class="pip-checkbox"
                                     ng-change="onOptionToggle($index, option)"
                                     md-no-ink="true" tabindex="-1"
                                     aria-label="{{::option.party_name}}">
                        </md-checkbox>
                    </div>
                </div>
            </div>
        </md-list>
    </md-dialog-content>
    <div class="pip-footer layout-row layout-align-start-center" ng-if="!isEmptyParticipants">
        <div class="flex-fixed" ng-show="selectedCount > 0">
            <md-button ng-click="onChangeCriteria($event)">
                {{ selected.checkedFilter == 'selected' ? 'ALL' : 'SELECTED' | translate }}
                <span ng-hide="selected.checkedFilter == 'selected'">({{ selectedCount }})</span>
            </md-button>
        </div>
        <span class="flex"></span>
            <md-button class="pip-cancel" ng-click="onCancel()">
                {{::'CANCEL' | translate}}
            </md-button>
            <md-button class="pip-submit md-accent" ng-click="onChoose()">
                {{::confirmButton | translate}}
            </md-button>
    </div>

            <div class="pip-header " ng-if="isEmptyParticipants">
            <h3 class="m0 text-title bm24">{{::title | translate}}</h3>
        </div>
    <div class="text-center pip-empty layout-column layout-align-center-center flex" 
         style="margin: 0 auto"
         ng-if="isEmptyParticipants">
        

        <img src="images/ES_Surprised.svg" class="pip-pic">

        <p class="pip-text lp24-flex rp24-flex"> {{::'PARTY_DIALOG_CONTACTS_NONE' | translate}} </p>
        <div class="bp24-flex">
                <md-button class="md-accent md-raised flat" 
                            ng-click="onGotoConnections()">
                    {{::'PARTY_DIALOG_GOTO_CONNECTIONS' | translate}}
                </md-button>
        </div>
    </div>
</md-dialog>
<!--ES_Surprised.svg-->