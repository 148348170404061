/**
 * @file Blur event for md-autocomplete
 * @copyright Digital Living Software Corp. 2015-2016
 */

/* global _, angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipInputBlur', []);

    thisModule.directive('pipInputBlur',
        ['$timeout', function ($timeout) {
            return {
                restrict: 'A',
                link: function (scope, element, attributes) {

                    $timeout(function () {
                        angular.element(element[0].querySelector('input.md-input')).bind('blur', function () {
                            $timeout(function () {
                                scope.$eval(attributes.pipInputBlur);
                            }, 100);
                        });
                    }, 0);
                }
            };
        }]
    );

})();


