/**
 * @file Activity history (recent activities) control
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo
 * - Add sample to sampler app
 * - Add parameters as scope
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipRecentActivities", 
        ['pipUtils', 'pipActivityFormat', 'pipTransactions', 'pipToasts', 'pipTranslate']);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {

        pipTranslateProvider.translations('en', {
            'ACTIVITY_SHOW_MORE': 'Show more',
            'RECENT_ACTIVITIES' : 'History',
            'ACTIVITIES_NONE': 'History is temporary unavailable'
        });

        pipTranslateProvider.translations('ru', {
            'ACTIVITY_SHOW_MORE': 'Показать больше',
            'RECENT_ACTIVITIES' : 'История',
            'ACTIVITIES_NONE': 'Нет активности'
        });

    }]);

    thisModule.directive('pipRecentActivities',
        function () {
            return {
                restrict: 'EA',
                scope: {
                    start: '=?pipStart',
                    src: '=pipSrc',
                    id: '=pipId',
                    take: '=',
                    paging: '=pipPaging',
                    pipUpdate: '=',
                    transaction: '=pipTransaction',
                    pipShowMoreButton: '=',
                    title: '=pipActivitiesTitle',
                    noActivitiesString: '=pipNoActivitiesTitle'
                },
                templateUrl: 'activities/recent_activities.html',
                controller: 'pipRecentActivitiesController'
            }
        }
    );

    thisModule.controller('pipRecentActivitiesController',
        ['$scope', '$element', '$attrs', '$parse', '$http', 'pipUtils', 'pipActivityFormat', 'pipTransaction', 'pipToasts', 'pipTranslate', function ($scope, $element, $attrs, $parse, $http, pipUtils, pipActivityFormat, pipTransaction, pipToasts, pipTranslate) {
            var
                iconMap = {
                    'signup' : 'person-star',
                    'signin' : 'enter',
                    'signout' : 'exit',
                    'password changed' : 'key',
                    'pwd recovered' : 'key-reset',
                    'email verified' : 'message-check',
                    'settings changed' : 'config',
                    'partnered' : 'person-plus',
                    'teamed up' : 'people-plus',
                    'followed' : 'search-person',
                    'disconnected' : 'person-minus',
                    'created' : 'star-1',
                    'deleted' : 'trash',
                    'accepted' : 'plus',
                    'rejected' : 'minus',
                    'joined' : 'handshake',
                    'completed' : 'check',
                    'canceled' : 'cross',
                    'progress' : 'progress',
                    'buzzed' : 'forward-all',
                    'commented' : 'more',
                    'cheered' : 'cheer'
                };

            $scope.url = $scope.src;
            $scope.transaction = $scope.transaction ? $scope.transaction : pipTransaction('recent_activities', $scope);
            $scope.activities = [];
            $scope.requsetExecuted = false;
            $scope.start = $scope.start || 0;
            $scope.takeCount = $scope.pipTake ? $scope.pipTake : 25;
            $scope.showButton = pipUtils.toBoolean($scope.pipShowMoreButton) !== false;
            $scope.ACTIVITIES_NONE = $scope.noActivitiesString !== undefined ? $scope.noActivitiesString : 'ACTIVITIES_NONE';
            $scope.activitiesTitle = $scope.title !== undefined ? $scope.title : 'RECENT_ACTIVITIES';

            $scope.getPreviousActivities = getPreviousActivities;

            // Also optimization to avoid watch if it is unnecessary
            if (pipUtils.toBoolean($attrs.pipRebind)) {
                $scope.$watch('pipUpdate', function(newValue) {
                    if(newValue && (newValue == 1 || newValue == true)) {
                        $scope.getPreviousActivities();
                        $scope.pipUpdate = false;
                    }
                });
                $scope.$watch('src', function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        $scope.url = $scope.src;
                        $scope.start = $scope.start ? $scope.start : 0;
                        $scope.activities = [];
                        $scope.requsetExecuted = false;
                        $scope.totalCount = null;
                        $scope.getPreviousActivities();
                    }
                });
            }
            $element.addClass('pip-recent-activities');

            getPreviousActivities();

            return;

            // Translate function
            function generateActivities(activities) {
                for (var i = 0; i < activities.length; i++) {
                    $scope.activities.push({
                        activityString: pipActivityFormat.formatActivity(activities[i]),
                        time: activities[i].time,
                        icon: iconMap[activities[i].type],
                        party_name: activities[i].party_name
                    });
                };
                $scope.start = $scope.activities.length;
            };

            // Get function
            function getPreviousActivities() {
                var isEntity = !!$scope.id;
                if ($scope.url.length == 0 || !isEntity) return;
                if ($scope.totalCount)
                    if ($scope.totalCount <= $scope.activities.length) return;
                $scope.transaction.begin('TRANSLATING');
                // var requestParams = $scope.paging ? '?paging=1&skip=' + $scope.start + '&take=' + $scope.takeCount : '';
                var requestParams = $scope.paging ? '?skip=' + $scope.start + '&take=' + $scope.takeCount : '';
                var requestUrl =  $scope.url + requestParams;
                $http['get'](requestUrl)
                    .success(function (activities) {
                        // generateActivities($scope.paging ? activities.data : activities);
                        generateActivities(activities);
                        // $scope.totalCount = $scope.paging ? activities.total : false;
                        $scope.totalCount = false;
                        $scope.requsetExecuted = true;
                        $scope.transaction.end();
                    })
                    .error(function (error) {
                        // TODO get error later
                        var message = pipTranslate.translate('ERROR_1000');
                        $scope.requsetExecuted = true;
                        pipToasts.showError(message, null, null, null, error);
                        $scope.transaction.end();
                    }
                );
            };
        }]
    );

})();

