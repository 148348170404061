/**
 * @file REST API error descriptions
 * @copyright Digital Living Software Corp. 2014-2015
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipStringsErrors', ['pipTranslate']);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            // Access errors
            'ERROR_400': 'Only owner or manager access is allowed',
            // Server errors
            'ERROR_1000': 'Unknown error',
            // Access control errors
            'ERROR_1001': 'Entity was not found or access is not allowed',
            'ERROR_1002': 'Only owner or contributor access is allowed',
            // User errors
            'ERROR_1100': 'Missing email',
            'ERROR_1101': 'Missing name',
            'ERROR_1102': 'Missing password',
            'ERROR_1103': 'Password should be 5 to 20 symbols long',
            'ERROR_1104': 'Email is already registered',
            'ERROR_1105': 'Old and new passwords are identical',
            'ERROR_1106': 'User was not found',
            'ERROR_1107': 'Invalid password',
            'ERROR_1108': 'Invalid password recovery code',
            'ERROR_1109': 'Password recovery code expired',
            'ERROR_1110': 'Account is locked',
            'ERROR_1111': 'Number of attempts exceeded. Account was locked',
            'ERROR_1112': 'Account is not active',
            'ERROR_1113': 'Invalid email verification code',
            'ERROR_1114': 'Invalid email',

            'ERROR_1300': 'Missing email',                                          // Was 1100
            'ERROR_1301': 'Invalid email',                                          // Was 1100
            'ERROR_1302': 'Missing name',                                           // Was 1101
            'ERROR_1303': 'Missing password',                                       // Was 1102
            'ERROR_1304': 'Password should be 5 to 20 symbols long',                // Was 1103
            'ERROR_1305': 'Email is already registered',                            // Was 1104
            'ERROR_1306': 'Missing email',                                          // Was 1100
            'ERROR_1307': 'Party was not found',                                    // Was 1300
            'ERROR_1308': 'Deleting this party is not allowed',                     // Was 1304
            // Connection errors
            'ERROR_1400': 'Connection was not found',
            'ERROR_1401': 'Connection was blocked by the party',
            'ERROR_1402': 'Missing connection owner',
            'ERROR_1403': 'Missing connection party',
            'ERROR_1404': 'Circular connection is now possible',
            'ERROR_1405': 'Parties are already connected',
            'ERROR_1406': 'Connection with the party is blocked',
            'ERROR_1407': 'Membership is not allowed between people',
            'ERROR_1408': 'Connection is already established',
            'ERROR_1409': 'Connection is allowed by invitation only',
            'ERROR_1410': 'Connection was not found',
            // Messages errors
            'ERROR_1200': 'Sender was not found',
            'ERROR_1201': 'Receiver was not found',
            'ERROR_1202': 'Message must have at least one recipient',
            'ERROR_1203': 'Message was not found',


            // todo использовать коды ошибок
            'ERROR_NO_RESPONSE': 'Server does not respond. Check server URL.',
            'ERROR_EMAIL_REGISTERED': 'This email already registered. Please choose another email',
            'ERROR_EMAIL_INVALID': 'This is not a valid email. Please enter a valid email',
            'ERROR_WRONG_IMAGE_FILE': 'Incorrect image file. Please, choose correct image file',
            'ERROR_TRANSACTION_IN_PROGRESS': 'Transaction is in progress. Please, abort or wait until it is finished',
            'ERROR_ADMIN_ONLY_ACCESS': 'Only administrators access allowed'
        });

        pipTranslateProvider.translations('ru', {
            // Access errors
            'ERROR_400': 'Только владелец или менеджер имеет доступы',
            // Server errors
            'ERROR_1000': 'Неизвестная ошибка',
            // Access control errors
            'ERROR_1001': 'Объект не найден или доступ не разрешен',
            'ERROR_1002': 'Доступ разрешен только владелецу или участнику',
            // User errors
            'ERROR_1100': 'Электронная почта не найдена',
            'ERROR_1101': 'Имя не найдено',
            'ERROR_1102': 'Отсутствует пароль',
            'ERROR_1103': 'Пароль должен быть длиной от 5 до 20 символов',
            'ERROR_1104': 'Электронная почта уже зарегистрирована',
            'ERROR_1105': 'Старый и новый пароль одинаковые',
            'ERROR_1106': 'Учетная запись не найдена',
            'ERROR_1107': 'Не верный пароль',
            'ERROR_1108': 'Не верный код восстановления пароля',
            'ERROR_1109': 'Срок восстановления пароля с этим кодом истек',
            'ERROR_1110': 'Учетная запись заблокирована',
            'ERROR_1111': 'Количество попыток превышено. Учетная запись заблокирована',
            'ERROR_1112': 'Учетная запись не активна',
            'ERROR_1113': 'Не верный код верификации электронной почты',
            'ERROR_1114': 'Не верный e-mail',
            // Entity handling errors

            'ERROR_1300': 'Электронная почта не найдена',                             // Was 1100
            'ERROR_1301': 'Не верный e-mail',                                          // Was 1100
            'ERROR_1302': 'Не задано имя',                                           // Was 1101
            'ERROR_1303': 'Не задан пароль',                                       // Was 1102
            'ERROR_1304': 'Пароль должен быть длиной от 5 до 20 символов',                // Was 1103
            'ERROR_1305': 'Электронная почта уже зарегистрирована',                // Was 1104
            'ERROR_1306': 'Электронная почта не найдена',                         // Was 1100
            'ERROR_1307': 'Участник не найден',                                    // Was 1300
            'ERROR_1308': 'Удаление этого участника не допускается',                 // Was 1304
            // Connection errors
            'ERROR_1400': 'Участник уже был заблокирован',
            'ERROR_1401': 'Подключение было заблокировано участником',
            'ERROR_1402': 'Отсутствует владелец связи',
            'ERROR_1403': 'Отсутствует участник связи',
            'ERROR_1404': 'Цикличиское соединение недопустимо',
            'ERROR_1405': 'Участники уже связаны',
            'ERROR_1406': 'Связь с участником заблокирована',
            'ERROR_1407': 'Членство не допускается между людьми',// todo 'Membership is not allowed between people',
            'ERROR_1408': 'Связь уже создана',//'Connection is already established',
            'ERROR_1409': 'Подключение разрешается только по приглашению',//'Connection is allowed by invitation only',
            'ERROR_1410': 'Связь не найдена',//'Connection was not found',
            // Messages errors
            'ERROR_1200': 'Отправитель не найден',
            'ERROR_1201': 'Получатель не найден',
            'ERROR_1202': 'Сообщение должно иметь по крайней мере одного получателя',
            'ERROR_1203': 'Сообщение не найдено',

            // todo использовать коды

            'ERROR_NO_RESPONSE': 'Сервер не отвечает. Проверьте URL сервера.',
            'ERROR_EMAIL_REGISTERED': 'Этот почтовый адрес уже зарегистрирован. Выберете другой адрес',
            'ERROR_EMAIL_INVALID': 'Это не правильный почтовый адрес. Введите правильный адрес',
            'ERROR_WRONG_IMAGE_FILE': 'Неправильный файл с изображением. Выберете правильный файл',
            'ERROR_TRANSACTION_IN_PROGRESS': 'Транзакция еще не завершена. Прервите или подождите её окончания',
            'ERROR_ADMIN_ONLY_ACCESS': 'Разрешен доступ только администраторам'
        });

    }]);

})();