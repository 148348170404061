<!--
@file Priority edit dialog content
@copyright Digital Living Software Corp. 2014-2016
-->

<md-dialog class="pip-dialog pip-priority-edit-dialog layout-column" md-theme="{{theme}}">
    <div class="pip-header">
        <h3 class="m0 text-title">{{::priorityTitle | translate}}</h3>
    </div>
    <div class="pip-body pip-list">
        <div class="pip-content">
            <div class="h48 layout-row layout-align-start-center"  ng-show="showImportance && showUrgency">
                <p class="text-caption text-grey m0">{{::'IMPORTANCE' | translate}}</p>
            </div>
            <pip-toggle-buttons ng-model="importance"
                                ng-show="showImportance"
                                pip-rebind="true"
                                pip-buttons="importanceCollection">
            </pip-toggle-buttons>
            <div class="h48 layout-row layout-align-start-center" ng-show="showImportance && showUrgency">
                <p class="text-caption text-grey m0">{{::'URGENCY' | translate}}</p>
            </div>
            <pip-toggle-buttons ng-model="urgency"
                                ng-show="showUrgency"
                                pip-rebind="true"
                                pip-buttons="urgencyCollection">
            </pip-toggle-buttons>
        </div>
    </div>
    <div class="pip-footer">
        <div>
            <md-button class="pip-cancel" ng-click="onCancelClick()">{{::'CANCEL' | translate}}</md-button>
            <md-button class="pip-submit md-accent" ng-click="onApplyClick()">{{::'APPLY' | translate}}</md-button>
        </div>
    </div>
</md-dialog>