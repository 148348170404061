<!--
@file Delete Confirmation dialog template
@copyright Digital Living Software Corp. 2014-2016
-->

<md-dialog class="pip-dialog pip-confirmation-dialog layout-column" width="400" md-theme="{{theme}}">
    <div class="pip-header text-subhead1">
        <h3 class="m0 text-title">{{ ::title | translate }}</h3>
    </div>
    <div class="pip-footer" >
            <md-button ng-click="onCancel()">
                {{ ::cancel | translate }}
            </md-button>
            <md-button class="md-accent"  ng-if="hasFewContribs" ng-click="onReject()">
                {{ ::reject | translate }}
            </md-button>
            <md-button class="md-accent" ng-click="onDelete()">
                {{ ::delete | translate }}
            </md-button>
    </div>
</md-dialog>
