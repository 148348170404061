/**
 * @file Status edit dialog
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo
 * - Improve sample in sampler app
 * - Pass array of statuses with defaults instead of status collection
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipStatusEditDialog', 
        ['ngMaterial', 'pipCore', 'pipLifeCommons.Templates']);

    thisModule.factory('pipStatusEditDialog',
        ['$mdDialog', function ($mdDialog) {
            return {
                show: function (params, successCallback, cancelCallback) {
                    $mdDialog.show({
                        targetEvent: params.event,
                        controller: 'pipStatusEditDialogController',
                        templateUrl: 'status_edit_dialog/status_edit_dialog.html',
                        locals: {
                            status: params.status,
                            progress: params.progress,
                            statusCollection: params.statusCollection
                        },
                        clickOutsideToClose: true
                    })
                    .then(function (result) {
                        if (successCallback) {
                            successCallback(result);
                        }
                    }, function () {
                        if (cancelCallback) {
                            cancelCallback();
                        }
                    });
                }
            };
        }]
    );

    thisModule.controller('pipStatusEditDialogController', 
        ['$scope', '$rootScope', '$mdDialog', 'status', 'progress', 'statusCollection', 'pipEnums', function ($scope, $rootScope, $mdDialog, status, progress, statusCollection, pipEnums) {

            $scope.theme = $rootScope.$theme;
            $scope.status = status != pipEnums.EXECUTION_STATUS.ASSIGNED ? status : pipEnums.EXECUTION_STATUS.IN_PROGRESS;
            $scope.progress = progress;
            $scope.statusCollection = _.filter(statusCollection, function(item) {
                return item.id != pipEnums.EXECUTION_STATUS.ASSIGNED;
            });

            $scope.progressChanged = progressChanged;

            $scope.onStatusChange = onStatusChange;
            $scope.onStatusChange = onStatusChange;
            $scope.onApplyClick = onApplyClick;
            $scope.onCancelClick = onCancelClick;

            return;

            function progressChanged() {
                if ($scope.status == 'new') {
                    $scope.status = $scope.progress > 0 ? pipEnums.EXECUTION_STATUS.IN_PROGRESS : $scope.progress;
                }
            }

            function onStatusChange() {
                if ($scope.status == pipEnums.EXECUTION_STATUS.NEW) $scope.progress = 0;
                if ($scope.status == pipEnums.EXECUTION_STATUS.COMPLETED) $scope.progress = 100;
            }

            function onApplyClick() {
                $mdDialog.hide({ 
                    status: $scope.status, 
                    progress: $scope.progress 
                });
            }

            function onCancelClick() {
                $mdDialog.cancel();
            }
        }]
    );

})();
