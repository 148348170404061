/**
 * @file Settings contact info controller
 * @copyright Digital Living Software Corp. 2014-2015
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipSettingsContactInfo', []);


    thisModule.config(['pipSettingsProvider', function (pipSettingsProvider) {
        pipSettingsProvider.addTab({
            state: 'contact_info',
            index: 1,
            title: 'SETTINGS_CONTACT_INFO_TITLE',
            stateConfig: {
                url: '/contact_info',
                controller: 'pipSettingsContactInfoController',
                templateUrl: 'settings/settings_contact_info.html',
                auth: true
            }
        });
    }]);

    thisModule.controller('pipSettingsContactInfoController',
        ['$scope', '$rootScope', 'pipTranslate', 'pipEnums', 'pipContactsData', 'pipTransaction', function ($scope, $rootScope, pipTranslate, pipEnums, pipContactsData, pipTransaction) {

            $rootScope.settingsContactInfoInitialized = false;
            $rootScope.$routing = true;

            $scope.emailTypes = pipTranslate.translateSet(pipEnums.EMAIL_TYPES);
            $scope.phoneTypes = pipTranslate.translateSet(pipEnums.PHONE_TYPES);
            $scope.addressTypes = pipTranslate.translateSet(pipEnums.ADDRESS_TYPES);
            $scope.imsTypes = pipTranslate.translateSet(pipEnums.MESSANGER_TYPES);
            $scope.websiteTypes = pipTranslate.translateSet(pipEnums.WEB_ADDRESS_TYPES);
            $scope.transaction = pipTransaction('contact_info.update', $scope);

            $scope.onAddPhone = onAddPhone;
            $scope.onAddEmail = onAddEmail;
            $scope.onAddAddress = onAddAddress;
            $scope.onAddAccount = onAddAccount;
            $scope.onAddWebsite = onAddWebsite;
            $scope.onSaveChanges = onSaveChanges;

            $scope.onPictureCreated = onPictureCreated;
            $scope.onPictureChanged = onPictureChanged;
         
            Initialize();

            return;


            function Initialize() {
                pipContactsData.readContacts(
                    {
                        item: {
                            party_id: $rootScope.$party.id
                        }
                    },
                    function (contacts) {
                        $scope.contact = contacts.name ? contacts : createEmptyContacts();
                        $scope.originalContact = angular.toJson($scope.contact);
                        $scope.$avatarReset = false;

                        $rootScope.settingsContactInfoInitialized = true;
                        $rootScope.$routing = false;
                    }, function (error) {
                        $rootScope.settingsContactInfoInitialized = true;
                        $rootScope.$routing = false;
                        // no connection error
                        if (error && (error.status === -1 || error.code === -1)) {
                            $state.go('errors_no_connection', {
                                error: error
                            });
                        } else { // unknown error
                            $state.go('errors_unknown', {
                                error: error
                            });
                        }
                    }
                );
            }

            //-----------------------------

            function onPictureCreated ($event) {
                $scope.picture = $event.sender;
                $scope.picture.save(
                    // Success callback
                    function () {},
                    // Error callback
                    function (error) {}
                );
            };

            function onPictureChanged ($control) {
                $scope.picture.save(
                    // Success callback
                    function () {
                    },
                    // Error callback
                    function (error) {
                        console.error(error);
                    }
                );
            };

            function createEmptyContacts() {
                pipContactsData.createContacts(
                    {
                        transaction: $scope.transaction,
                        item: {
                            party_id: $rootScope.$party.id,
                            for_party_id: $rootScope.$party.id,
                            name: $rootScope.$party.name
                        }
                    },
                    function (savedContact) {
                        $scope.contact = savedContact;
                        $scope.originalContact = angular.toJson($scope.contact);
                        return $scope.contact;
                    },
                    function (error) {
                        var message = 'ERROR_' + error.status || error.data.status_code;
                        $scope.onShowToast(message, 'error');
                        $scope.contact = angular.fromJson($scope.originalContact);
                    }
                );
            };

            function onAddPhone(newPhone) {
                if (newPhone == undefined || newPhone == '') return;

                if(!$scope.contact.phones)
                    $scope.contact.phones = [];
                $scope.contact.phones.push({
                    type: ($scope.newPhoneType) ? $scope.newPhoneType : 'mobile',
                    number: newPhone
                });

                $scope.showPhoneAddBlock = false;
                $scope.newPhoneType = 'mobile';
                $scope.newPhone = '';
                $scope.onSaveChanges('phones', 0, 'number');
            };

            function onAddEmail(newEmail) {
                if (newEmail == undefined || newEmail == '') return;

                if(!$scope.contact.emails)
                    $scope.contact.emails = [];

                $scope.contact.emails.push({
                    type: ($scope.newEmailType) ? $scope.newEmailType : 'home',
                    email: newEmail
                });

                $scope.showEmailAddBlock = false;
                $scope.newEmailType = 'home';
                $scope.newEmail = '';
                $scope.onSaveChanges('emails', 0, 'email');
            };

            function onAddAddress(newAddress) {
                if (newAddress == undefined || newAddress == '') return;

                if(!$scope.contact.addresses)
                    $scope.contact.addresses = [];

                $scope.contact.addresses.push({
                    type: ($scope.newAddressType) ? $scope.newAddressType : 'home',
                    address: newAddress
                });

                $scope.showAddressAddBlock = false;
                $scope.newAddressType = 'home';
                $scope.newAddress = '';
                $scope.onSaveChanges('addresses', 0, 'address');
            };

            function onAddAccount(newAccount) {
                if (newAccount == undefined || newAccount == '') return;

                if(!$scope.contact.ims)
                    $scope.contact.ims = [];

                $scope.contact.ims.push({
                    type: ($scope.newAccountType) ? $scope.newAccountType : 'skype',
                    user: newAccount
                });

                $scope.showAccountAddBlock = false;
                $scope.newAccountType = 'skype';
                $scope.newAccount = '';
                $scope.onSaveChanges('ims', 0, 'user');
            };

            function onAddWebsite(newWebsite) {
                if (newWebsite == undefined || newWebsite == '') return;

                if(!$scope.contact.websites)
                    $scope.contact.websites = [];

                $scope.contact.websites.push({
                    type: ($scope.newWebsiteType) ? $scope.newWebsiteType : 'profile',
                    url: newWebsite
                });

                $scope.showWebsiteAddBlock = false;
                $scope.newWebsiteType = 'home';
                $scope.newWebsite = '';
                $scope.onSaveChanges('websites', 0, 'url');
            };

            function onSaveChanges(contactListName, index, contactElementName) {
                if ($scope.contact[contactListName][index][contactElementName] == '') {
                    $scope.contact[contactListName].splice(index, 1);
                }

                var contact = angular.toJson($scope.contact);

                if (contact != $scope.originalContact) {
                    $scope.originalContact = contact;
                    pipContactsData.updateContact(
                        {
                            transaction: $scope.transaction,
                            item: $scope.contact
                        },
                        function () {
                            // Do nothing
                        },
                        function (error) {
                            var message = 'ERROR_' + error.status || error.data.status_code;
                            $scope.onShowToast(message, 'error');
                        }
                    );
                }
            };
        }]
    );

})();
