/**
 * @file extend mdChips control (add chips onBlur )
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("mdChipsExtend", []);

    thisModule.directive('mdChips',
        ['$timeout', function ($timeout) {
            return {
                restrict: 'E',
                require: 'mdChips', // Extends the original mdChips directive
                link: function (scope, element, attributes, mdChipsCtrl) {
                    var mouseUpActions = [];

                    mdChipsCtrl.onInputBlur = function(event) {
                        this.inputHasFocus = false;

                        mouseUpActions.push((function() {
                            var chipBuffer = this.getChipBuffer();
                            if (chipBuffer != "") { // REQUIRED, OTHERWISE YOU'D GET A BLANK CHIP
                                this.appendChip(chipBuffer);
                                this.resetChipBuffer();
                            }
                        }).bind(this));
                    };

                    var backdropElement = $('.pip-md-chips-blur');
                    if (backdropElement && backdropElement[0])
                        backdropElement[0].addEventListener('click', function(event) {
                            while (mouseUpActions.length > 0) {
                                var action = mouseUpActions.splice(0, 1)[0];
                                $timeout(function() {
                                    $timeout(action);
                                });
                            }
                        }, false);
                    //window.addEventListener('click', function(event) {
                    //    while (mouseUpActions.length > 0) {
                    //        var action = mouseUpActions.splice(0, 1)[0];
                    //        $timeout(function() {
                    //            $timeout(action);
                    //        });
                    //    }
                    //}, false);
                }
            }
        }]
    );

})();
