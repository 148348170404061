/**
 * @file Task list control
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipTaskList", []);

    /**
     *
     */
    thisModule.directive('pipTaskList',
        function () {
            return {
                restrict: 'EA',
                replace: false,
                scope: {
                    taskTemplete: '=pipTaskTemplete',
                    itemPlaceholder: '=pipBulkItemPlaceholder',
                    itemTitle: '=pipBulkItemTitle',
                    ngDisabled: '&',
                    pipChanged: '=',
                    pipActionPlanCollection: '='
                },
                templateUrl: 'new_record_wizard/task_list.html',
                controller: 'pipTaskListController'
            }
        }
    );

    thisModule.controller('pipTaskListController',
        ['$scope', '$element', '$attrs', '$document', 'pipUtils', '$rootScope', 'pipTranslate', 'pipEnums', 'GoalsUtilsService', function ($scope, $element, $attrs, $document, pipUtils, $rootScope, pipTranslate, pipEnums, GoalsUtilsService) {

            $scope.bulk = {};
            $scope.taskCollection = [];
            $scope.bulk.index = -1;
            $scope.bulk.plaseholder = !!$scope.itemPlaceholder ? $scope.itemPlaceholder : 'NEW_TASK';

            $scope.bulk.isChanged = false;

            if ($scope.taskTemplete) {
                $scope.newTask = _.cloneDeep($scope.taskTemplete);
            } else {
                $scope.newTask = {
                    type: pipEnums.GOAL_TYPE.TASK,
                    title: title,
                    status: pipEnums.EXECUTION_STATUS.NEW,
                    content: [],
                    progress: 0,
                    importance: pipEnums.IMPORTANCE.NORMAL,
                    urgency: pipEnums.URGENCY.NORMAL,
                    all_day: false,
                    contribs: [{
                        party_id: $rootScope.$party.id,
                        party_name: $rootScope.$party.name,
                        accept: 'accepted',
                        role: 'accountable'
                    }],
                    refs: [],
                    contrib_def: true,
                    share_def: true
                };
            }
            generateTasksList($scope.pipActionPlanCollection);

            $scope.onTextareaKeyDown = onTextareaKeyDown;
            $scope.onAddItem = onAddItem;
            $scope.onChangeItem = onChangeItem;
            $scope.onPriorityStatusChange = onPriorityStatusChange;
            $scope.onClick = onClick;
            $scope.onDeleteItem = onDeleteItem;
            $scope.onChecked = onChecked;
            $scope.onItemFocus = onItemFocus;
            $scope.isDisabled = isDisabled;


            // Watch for options changes
            if (pipUtils.toBoolean($attrs.pipRebind)) {
                $scope.$watchCollection('pipActionPlanCollection', function (newValue) {
                    if (!$scope.bulk.isChanged) {
                        generateTasksList($scope.pipActionPlanCollection);
                    } else {
                        $scope.bulk.isChanged = false;
                    }
                });
            }

            $scope.onTaskListChange = _.debounce(onTaskListChange , 200);

            // Add class
            $element.addClass('pip-task-list');

            return;

            function getId() {
                var id = -1;

                _.each($scope.taskCollection, function(item) {
                    if ( item.item_id !== null && item.item_id !== undefined && id < item.item_id ) {
                        id = item.item_id;
                    }
                });

                return id + 1;
            };

            function filterAcceptance(collection) {
                var result = _.filter(collection, function(item){
                    return item.accept;
                });

                return result;
            }

            function getNewTask(title) {
                var newTask = _.cloneDeep($scope.newTask);
                newTask.title = title;

                return newTask;
            }

            function getNewItem(title, isEmpty) {
                var item = {
                    item_id: getId(),
                    isComplete: false,
                    empty: !!isEmpty,
                    obj: getNewTask(title)
                };
                return item;
            }

            function getTasksCollection(collection) {
                var tasks, result = [];

                GoalsUtilsService.prepareGoalListAcceptance(collection);
                tasks = filterAcceptance(collection);

                _.each(tasks, function(task) {
                    var item = {
                        item_id: getId(),
                        isComplete: task.status === pipEnums.EXECUTION_STATUS.COMPLETED || task.status === pipEnums.EXECUTION_STATUS.CANCELED,
                        empty: false,
                        obj: task
                    };

                    result.push(item);
                });

                result.push(getNewItem('', true));

                return result;
            }

            function generateTasksList(tasks) {
                $scope.taskCollection = getTasksCollection(tasks);

                if ($scope.taskCollection.length > 1) {
                    $scope.bulk.index = 0;
                }

            }

            function isDisabled() {
                if ($scope.ngDisabled) {
                    return $scope.ngDisabled();
                } else {
                    return false;
                }
            };

            function onItemFocus(index) {
                if (isDisabled()) return;
                $scope.bulk.index = index;
            };


            function onTextareaKeyDown($event, index) {
                if (isDisabled()) return;
                if ($scope.bulk.index == -1) return;
            }


            function onAddItem() {
                var title;

                title = $scope.itemTitle ? $scope.itemTitle : pipTranslate.translate('TASKS_NEW_TASK_TITLE');
                addItem(title, $scope.bulk.index - 1);
            };

            function onChangeItem(index) {
                if (index > -1 && $scope.taskCollection[index] && $scope.taskCollection[index].empty) {
                    $scope.taskCollection[index].empty = false;
                    $scope.taskCollection.push(getNewItem('', true));
                }
                $scope.onTaskListChange();
            };

            function onPriorityStatusChange(item) {
                if (!item) { return; }

                item.isComplete = item.obj.status === pipEnums.EXECUTION_STATUS.COMPLETED || item.obj.status === pipEnums.EXECUTION_STATUS.CANCELED,
                    $scope.onTaskListChange();
            };

            function onClick($event, index) {
                if (isDisabled()) return;
                $scope.bulk.index = index;

            };

            function onDeleteItem(index, item) {

                if ($scope.taskCollection.length == 1) {
                    $scope.taskCollection[0].text = '';
                    $scope.taskCollection[0].checked = false;
                    $scope.taskCollection[0].empty = true;
                    $scope.bulk.index = 0;
                } else {
                    if (index >= 0 && index <= $scope.taskCollection.length)
                        $scope.taskCollection.splice(index, 1);
                    else return;
                }

                if ($scope.bulk.index >= $scope.taskCollection.length)
                    $scope.bulk.index = $scope.taskCollection.length - 1;

                setFocus($scope.bulk.index, 0);

                $scope.onTaskListChange();
            };

            function onChecked(item) {
                if (!item) return;

                if (item.isComplete) {
                    item.obj.status = pipEnums.EXECUTION_STATUS.COMPLETED,
                        item.obj.progress = 100;
                } else {
                    item.obj.status = pipEnums.EXECUTION_STATUS.IN_PROGRESS,
                        item.obj.progress = 0;
                }
                $scope.onTaskListChange();
            };

            function setFocus(index) {
                if (index > -1) {
                    setTimeout(function () {
                        var nextElement = angular.element('#task-title-' + index);
                        if (nextElement) {
                            nextElement.focus();
                        }
                    },  50);
                }
            };

            function updateContents() {
                $scope.bulk.isChanged = true;
                $scope.pipActionPlanCollection = [];
                var content = [], i;
                for (i = 0; i < $scope.taskCollection.length; i++) {
                    if ($scope.taskCollection[i] && !$scope.taskCollection[i].empty) {
                        $scope.pipActionPlanCollection.push($scope.taskCollection[i].obj);
                    }
                }
                // $scope.pipActionPlanCollection = content;
            };

            function onTaskListChange() {
                $scope.$apply(function() {
                    updateContents();
                    if ($scope.pipChanged) {
                        $scope.pipChanged($scope.pipActionPlanCollection);
                    }
                });
            };

            function addItem(title, index) {
                var newItem = getNewItem(title, false);
                if (index > -1) $scope.bulk.index = index;

                if ($scope.taskCollection.length < 2) {
                    $scope.taskCollection.unshift(newItem);
                }
                else {
                    $scope.taskCollection.splice($scope.bulk.index + 1, 0, newItem);
                }
                $scope.bulk.index += 1;
                setFocus($scope.bulk.index);

                $scope.onTaskListChange();
            }

        }]
    );

})();
