<div class="pip-contact-info" ng-if="!$routing">
    <md-progress-linear ng-show="transaction.busy()" md-mode="indeterminate" class="pip-progress-top">
    </md-progress-linear>
    <div class="bm12 layout-row">
        <div class="md-tile-left" style="margin-right: 16px">
            <pip-avatar-edit pip-party-id="$party.id" ng-if="!$avatarReset"
                             pip-created="onPictureCreated($event)" pip-changed="onPictureChanged($control, $event)">
            </pip-avatar-edit>
        </div>

        <div class="md-tile-content tp0 layout-align-center">
            <h3 class="tm16 bm8 text-one-line">{{$party.name}}</h3>

            <p class="text-primary text-overflow m0"> {{::'SETTINGS_BASIC_INFO_FROM' | translate}} {{$user.signup | formatLongDate }}</p>
        </div>
    </div>
    <div class="layout-row" ng-repeat="email in contact.emails">
        <md-input-container class="rm16 flex" ng-class="{ 'md-input-focused' : inputFocus,
         'md-input-has-value' : email.email.length > 0 }">
            <label>{{::'SETTINGS_CONTACT_INFO_EMAIL' | translate}}</label>
            <input type="email" ng-focus="inputFocus = true"
                   ng-blur="inputFocus = false;onSaveChanges('emails', $index, 'email')"
                   class="flex" ng-model="email.email">

        </md-input-container>
        <md-input-container class="md-block pip-select">
            <md-select ng-model="email.type" placeholder="{{emailTypes[0].name}}"
                       ng-change="onSaveChanges('emails', $index, 'email')">
                <md-option ng-value="eType.id" ng-repeat="eType in emailTypes">{{:: eType.name }}</md-option>
            </md-select>
        </md-input-container>

    </div>

    <div class="layout-row" ng-show="contact.emails.length == 0 || showEmailAddBlock">
        <md-input-container class="rm16  flex" ng-class="{ 'md-input-focused' : inputEmailFocus,
         'md-input-has-value' : newEmail.length > 0 }">
            <label>{{::'SETTINGS_CONTACT_INFO_EMAIL' | translate}}</label>
            <input type="email" ng-focus="inputEmailFocus = true"
                   ng-blur="inputEmailFocus = false; showEmailAddBlock=false; onAddEmail(newEmail);" ng-model="newEmail">
        </md-input-container>

        <md-input-container class="md-block pip-select">
            <md-select ng-model="newEmailType" placeholder="{{emailTypes[0].name}}">
                <md-option ng-value="eType.id" ng-repeat="eType in emailTypes">{{:: eType.name }}</md-option>
            </md-select>
        </md-input-container>
    </div>

    <md-button ng-show="contact.emails.length > 0 && !showEmailAddBlock" class="md-raised bm12"
               ng-click="newEmail=''; showEmailAddBlock = true">{{ 'SETTINGS_CONTACT_INFO_ADD_EMAIL' |
        translate }}
    </md-button>

    <div class="layout-row" ng-repeat="phone in contact.phones">
        <md-input-container class="rm16  flex"  ng-class="{ 'md-input-focused' : inputFocus,
         'md-input-has-value' : phone.number.length > 0 }">
            <label>{{::'SETTINGS_CONTACT_INFO_PHONE' | translate}}</label>
            <input type="tel" ng-focus="inputFocus = true"
                   ng-blur="inputFocus = false; onSaveChanges('phones', $index, 'number')" ng-model="phone.number">
        </md-input-container>

        <md-input-container class="md-block pip-select">
            <md-select ng-model="phone.type" placeholder="{{phoneTypes[0].name}}"
                       ng-change="onSaveChanges('phones', $index, 'number')">
                <md-option ng-value="phone.id" ng-repeat="phone in phoneTypes">{{:: phone.name }}</md-option>
            </md-select>
        </md-input-container>
    </div>

    <div class="layout-row" ng-show="contact.phones.length == 0 || showPhoneAddBlock">
        <md-input-container class="rm16  flex" ng-class="{ 'md-input-focused' : inputPhoneFocus,
         'md-input-has-value' : newPhone.length > 0 }">
            <label>{{::'SETTINGS_CONTACT_INFO_PHONE' | translate}}</label>
            <input type="tel" ng-focus="inputPhoneFocus = true"
                   ng-blur="inputPhoneFocus = false; showPhoneAddBlock = false; onAddPhone(newPhone);" ng-model="newPhone">
        </md-input-container>

        <md-input-container class="md-block pip-select">
            <md-select ng-model="newPhoneType" placeholder="{{phoneTypes[0].name}}">
                <md-option ng-value="phone.id" ng-repeat="phone in phoneTypes">{{:: phone.name }}</md-option>
            </md-select>
        </md-input-container>
    </div>

    <md-button ng-show="contact.phones.length > 0 && !showPhoneAddBlock" class="md-raised bm12"
               ng-click="newPhone =''; showPhoneAddBlock = true">{{ 'SETTINGS_CONTACT_INFO_ADD_PHONE' |translate }}
    </md-button>

    <div class="layout-row layout-align-start-end" ng-repeat="address in contact.addresses">
        <md-input-container class="rm16 pip-address flex" ng-class="{ 'md-input-focused' : inputFocus,
         'md-input-has-value' : address.address.length > 0 }">
            <label>{{::'SETTINGS_CONTACT_INFO_ADDRESS' | translate}}</label>
            <textarea ng-model="address.address"
                      ng-blur="inputFocus = false; onSaveChanges('addresses', $index, 'address')">
            </textarea>
        </md-input-container>

        <md-input-container class="md-block pip-select">
            <md-select ng-model="address.type" placeholder="{{addressTypes[0].name}}"
                       ng-change="onSaveChanges('addresses', $index, 'address')">
                <md-option ng-value="addresses.id" ng-repeat="addresses in addressTypes">{{:: addresses.name }}</md-option>
            </md-select>
        </md-input-container>
    </div>

    <div class="layout-row layout-align-start-end" ng-show="contact.addresses.length == 0 || showAddressAddBlock">
        <md-input-container class="rm16 pip-address  flex" ng-class="{ 'md-input-focused' : inputAddressFocus,
         'md-input-has-value' : newAddress.length > 0 }">
            <label>{{::'SETTINGS_CONTACT_INFO_ADDRESS' | translate}}</label>
            <textarea ng-model="newAddress"
                      ng-blur="inputAddressFocus = false; showAddressAddBlock=false; onAddAddress(newAddress);">
            </textarea>
        </md-input-container>

        <md-input-container class="md-block pip-select">
            <md-select ng-model="newAddressType" placeholder="{{addressTypes[0].name}}">
                <md-option ng-value="phone.id" ng-repeat="phone in addressTypes">{{ phone.name }}</md-option>
            </md-select>
        </md-input-container>
    </div>

    <md-button ng-show="contact.addresses.length > 0 && !showAddressAddBlock" class="md-raised bm12"
               ng-click="newAddress = ''; showAddressAddBlock = true">{{ 'SETTINGS_CONTACT_INFO_ADD_ADDRESS' | translate }}
    </md-button>

    <div class="layout-row" ng-repeat="account in contact.ims">
        <md-input-container class="rm16  flex"
                            ng-class="{ 'md-input-focused' : inputFocus, 'md-input-has-value' : account.user.length > 0 }">
            <label>{{::'SETTINGS_CONTACT_INFO_ACCOUNT_NAME' | translate}}</label>
            <input type="text" ng-focus="inputFocus = true"
                   ng-blur="inputFocus = false;onSaveChanges('ims', $index, 'user')" ng-model="account.user">
        </md-input-container>


        <md-input-container class="md-block pip-select">
            <md-select ng-model="account.type" ng-change="onSaveChanges('ims', $index, 'user')"
                       placeholder="{{imsTypes[0].name}}">
                <md-option ng-value="ims.id" ng-repeat="ims in imsTypes">{{ ims.name }}</md-option>
            </md-select>
        </md-input-container>
    </div>

    <div class="layout-row" ng-show="contact.ims.length == 0 || showAccountAddBlock">
        <md-input-container class="rm16  flex" ng-class="{ 'md-input-focused' : inputAccountFocus,
         'md-input-has-value' : newAccount.length > 0 }">
            <label>{{::'SETTINGS_CONTACT_INFO_ACCOUNT_NAME' | translate}}</label>
            <input type="text" ng-focus="inputAccountFocus = true"
                   ng-blur="inputAccountFocus = false; showAccountAddBlock=false; onAddAccount(newAccount);" ng-model="newAccount">
        </md-input-container>


        <md-input-container class="md-block pip-select">
            <md-select ng-model="newAccountType"
                       placeholder="{{imsTypes[0].name}}">
                <md-option ng-value="ims.id" ng-repeat="ims in imsTypes">{{:: ims.name }}</md-option>
            </md-select>
        </md-input-container>
    </div>

    <md-button ng-show="contact.ims.length > 0 && !showAccountAddBlock" class="md-raised bm12"
               ng-click="newAccount = ''; showAccountAddBlock = true">{{ 'SETTINGS_CONTACT_INFO_ADD_ACCOUNT' | translate }}
    </md-button>

    <div class="layout-row" ng-repeat="website in contact.websites">
        <md-input-container class="rm16  flex"
                            ng-class="{ 'md-input-focused' : inputFocus,'md-input-has-value' : website.url.length > 0 }">
            <label>{{::'SETTINGS_CONTACT_INFO_URL' | translate}}</label>
            <input type="url" ng-focus="inputFocus = true"
                   ng-blur="inputFocus = false;onSaveChanges('websites', $index, 'url')" ng-model="website.url">
        </md-input-container>

        <md-input-container class="md-block pip-select">
            <md-select ng-model="website.type" ng-change="onSaveChanges('websites', $index, 'url')"
                       placeholder="{{websiteTypes[0].name}}">
                <md-option ng-value="website.id" ng-repeat="website in websiteTypes">{{:: website.name }}</md-option>
            </md-select>
        </md-input-container>
    </div>

    <div class="layout-row" ng-show="contact.websites.length == 0 || showWebsiteAddBlock">
        <md-input-container class="rm16  flex " ng-class="{ 'md-input-focused' : inputWebsiteFocus,
         'md-input-has-value' : newWebsite.length > 0 }">
            <label>{{::'SETTINGS_CONTACT_INFO_URL' | translate}}</label>
            <input type="url" ng-focus="inputWebsiteFocus = true"
                   ng-blur="inputWebsiteFocus = false; showWebsiteAddBlock=false; onAddWebsite(newWebsite);" ng-model="newWebsite"/>
        </md-input-container>


        <md-input-container class="md-block pip-select">
            <md-select ng-model="newWebsiteType" placeholder="{{websiteTypes[0].name}}">
                <md-option ng-value="website.id" ng-repeat="website in websiteTypes">{{:: website.name }}</md-option>
            </md-select>
        </md-input-container>
    </div>

    <md-button ng-show="contact.websites.length > 0 && !showWebsiteAddBlock" class="md-raised bm24-flex"
               ng-click="newWebsite=''; showWebsiteAddBlock = true">{{ 'SETTINGS_CONTACT_INFO_ADD_URL' |
        translate }}
    </md-button>

</div>