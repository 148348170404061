/**
 * @file Notifications data cache
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipNotificationsCache', ['pipDataCache']);

    thisModule.service('pipNotificationsCache',
        ['pipEnums', 'pipDataCache', 'pipCacheTag', function(pipEnums, pipDataCache, pipCacheTag) {

            return {
                readManagedNotifications: readManagedNotifications
            };
            //-------------

            function readManagedNotifications(params, successCallback, errorCallback) {
                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            }

        }]
    );

})();

