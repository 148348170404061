/**
 * @file Filter to format user activities
 * @copyright Digital Living Software Corp. 2014-2016
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipActivityFilters', ['pipActivityFormat']);

    thisModule.filter('formatActivity', 
        ['pipActivityFormat', function (pipActivityFormat) {
            return function(value, format) {
                return pipActivityFormat.formatActivity(value);  
            };
        }]
    );

    thisModule.filter('formatActivityType', 
        ['pipActivityFormat', function (pipActivityFormat) {
            return function(value, format) {
                return pipActivityFormat.formatActivityType(value);  
            };
        }]
    );

})();
