/**
 * @file Priority formatting service
 * @copyright Digital Living Software Corp. 2014-2016
 */
 
 /* global _, angular */
 
(function () {
    'use strict';

    var thisModule = angular.module('pipPriorityFormat', ['pipCore']);

	thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {

        pipTranslateProvider.translations('en', {
            // Mixed importance and urgency
            'IMPORTANT_URGENT' : 'Important and urgent',
            'IMPORTANT' : 'Important',
            'IMPORTANT_NOT_URGENT' : 'Important, but not urgent',
            'URGENT' : 'Urgent',
            'NORMAL_PRIORITY' : 'Normal priority',
            'NOT_URGENT' : 'Not urgent',
            'NOT_IMPORTANT_URGENT' : 'Not important and urgent',
            'NOT_IMPORTANT' : 'Not important',
            'NOT_IMPORTANT_NOT_URGENT' : 'Not important, not urgent'
        });

        pipTranslateProvider.translations('ru', {
            // Mixed importance and urgency
            'IMPORTANT_URGENT' : 'Важно и срочно',
            'IMPORTANT' : 'Важно',
            'IMPORTANT_NOT_URGENT' : 'Важно, но несрочно',
            'URGENT' : 'Срочно',
            'NORMAL_PRIORITY' : 'Обычный приоритет',
            'NOT_URGENT' : 'Несрочно',
            'NOT_IMPORTANT_URGENT' : 'Неважно, но срочно',
            'NOT_IMPORTANT' : 'Неважно',
            'NOT_IMPORTANT_NOT_URGENT' : 'Неважно и несрочно'
        });
		
	}]);

    thisModule.factory('pipPriorityFormat',
        ['pipEnums', 'pipTranslate', function (pipEnums, pipTranslate) {

            return {
                formatPriority: formatPriority,
                formatEntityPriority: formatEntityPriority
            };

            function formatPriority(importance, urgency, showImportance, showUrgency) {
                if (showImportance === false && showUrgency === false) return '';
                var LOW_LIMIT = 333, HIGH_LIMIT = 777;
                var priorityMap = {};

                priorityMap[pipEnums.IMPORTANCE.HIGH] = {};
                priorityMap[pipEnums.IMPORTANCE.HIGH][pipEnums.URGENCY.HIGH] = 'IMPORTANT_URGENT';
                priorityMap[pipEnums.IMPORTANCE.HIGH][pipEnums.URGENCY.NORMAL] = showImportance === false ? 'URGENT' : 'IMPORTANT';
                priorityMap[pipEnums.IMPORTANCE.HIGH][pipEnums.URGENCY.LOW] = 'IMPORTANT_NOT_URGENT';
                priorityMap[pipEnums.IMPORTANCE.NORMAL] = {};
                priorityMap[pipEnums.IMPORTANCE.NORMAL][pipEnums.URGENCY.HIGH] = 'URGENT';
                priorityMap[pipEnums.IMPORTANCE.NORMAL][pipEnums.URGENCY.NORMAL] = 'NORMAL_PRIORITY';
                priorityMap[pipEnums.IMPORTANCE.NORMAL][pipEnums.URGENCY.LOW] = 'NOT_URGENT';
                priorityMap[pipEnums.IMPORTANCE.LOW] = {};
                priorityMap[pipEnums.IMPORTANCE.LOW][pipEnums.URGENCY.HIGH] = 'NOT_IMPORTANT_URGENT';
                priorityMap[pipEnums.IMPORTANCE.LOW][pipEnums.URGENCY.NORMAL] = showImportance === false ? 'NOT_URGENT' : 'NOT_IMPORTANT';
                priorityMap[pipEnums.IMPORTANCE.LOW][pipEnums.URGENCY.LOW] = 'NOT_IMPORTANT_NOT_URGENT';

                if (importance != 1 && importance != 500 && importance != 1000) {
                    if (importance === null || importance === undefined) {
                        importance = pipEnums.IMPORTANCE.NORMAL;
                    } else {
                        if (importance < LOW_LIMIT) {
                            importance = pipEnums.IMPORTANCE.LOW;
                        } else {
                            if (importance >= LOW_LIMIT && importance <= HIGH_LIMIT) {
                                importance = pipEnums.IMPORTANCE.NORMAL;
                            } else {
                                if (importance > HIGH_LIMIT) {
                                    importance = pipEnums.IMPORTANCE.HIGH;
                                }
                            }
                        }
                    }
                }

                if (urgency != 1 && urgency != 500 && urgency != 1000 ) {
                    if (urgency === null || urgency === undefined) {
                        urgency = pipEnums.URGENCY.NORMAL;
                    } else {
                        if (urgency < LOW_LIMIT) {
                            urgency = pipEnums.URGENCY.LOW;
                        } else {
                            if (urgency >= LOW_LIMIT && urgency <= HIGH_LIMIT) {
                                urgency = pipEnums.URGENCY.NORMAL;
                            } else {
                                if (urgency > HIGH_LIMIT) {
                                    urgency = pipEnums.URGENCY.HIGH;
                                }
                            }
                        }
                    }
                }
                return priorityMap[importance][urgency];
            };

            function formatEntityPriority(entity, showImportance, showUrgency) {
                if (!entity || !entity.importance || !entity.importance) return '';
                return pipTranslate.translate(formatPriority(entity.importance, entity.urgency, showImportance, showUrgency));
            };
        }]
    );
    
})();
