/**
 * @file Connections data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipFollowingsData', ['pipRest', 'pipDataModel', 'pipConnectionsCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var visionsApi = {
                connections: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/connections/:id');
                },

                followers: function(url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/followers/:id');
                },

                following: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/following/:id');
                }
         };
        
         pipRestProvider.addApi(visionsApi);
    }]);

    thisModule.provider('pipFollowingsData', function () {

        // Read all following connections
        // this.readFollowingsResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, pipConnectionsCache) {
        //         return pipConnectionsCache.readFollowings({
        //             item: { party_id: pipDataSession.partyId() }
        //         });
        //     };
        // };

        // // Read specific following
        // this.readFollowingResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, pipConnectionsCache) {
        //         var params = {
        //             party_id: pipDataSession.partyId(),
        //             id: $stateParams.id
        //         };

        //         return pipRest.following().get(
        //             params,
        //             pipConnectionsCache.onConnectionUpdate(params)
        //         ).$promise;
        //     };
        // };

        // // Read all follower connections
        // this.readFollowersResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest) {
        //         return pipRest.followers().query({
        //             party_id: pipDataSession.partyId()
        //         }).$promise;
        //     };
        // };

        // // Read specific follower
        // this.readFollowerResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest) {
        //         return pipRest.followers().get({
        //             party_id: pipDataSession.partyId(),
        //             id: $stateParams.id
        //         }).$promise;
        //     };
        // };

        this.$get = ['$stateParams', '$rootScope', '$state', 'pipDataModel', 'pipConnectionsCache', 'pipDataSession', function($stateParams, $rootScope, $state, pipDataModel, pipConnectionsCache, pipDataSession) {
            return {
                partyId: pipDataSession.partyId(),

                readFollowings: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.item = params.item || { party_id: pipDataSession.partyId() };
                    return pipConnectionsCache.readFollowings(params, successCallback, errorCallback);
                },

                readFollowers: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.item = params.item || { party_id: pipDataSession.partyId() };
                    params.resource = 'connections';
                    return pipConnectionsCache.readFollowers(params, successCallback, errorCallback);
                },

                readFollower: function (params, successCallback, errorCallback) {
                    params.resource = 'connections';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.id = params.item.id || $stateParams.id;
                    return pipDataModel.readOne(
                        params,
                        pipConnectionsCache.onConnectionUpdate(params, successCallback),
                        errorCallback
                    );
                },

                readFollowing: function (params, successCallback, errorCallback) {
                    params.resource = 'followers';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.id = params.item.id || $stateParams.id;
                    return pipDataModel.readOne(
                        params,
                        pipConnectionsCache.onConnectionUpdate(params, successCallback),
                        errorCallback
                    );
                },
                
                createFollowing: function (params, successCallback, errorCallback) {
                    params.resource = 'following';
                    params.item = params.item || {};
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    return pipDataModel.create(
                        params,
                        pipConnectionsCache.onConnectionCreate(params, successCallback),
                        errorCallback
                    );
                }

            }
        }];
        this.$get.$inject = ['$stateParams', '$rootScope', '$state', 'pipDataModel', 'pipConnectionsCache', 'pipDataSession'];
    });

})();
