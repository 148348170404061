/**
 * @file Entry pages (signin, signup) logic
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipMobileEntry', 
        [
            'ui.router', 'ngMessages', 
            
            'pipCore', 'pipRest', 'pipBasicControls', 'pipLocations', 'pipPictures', 'pipRest.State',
            'pipEntry.Strings', 'pipEntry.Common', 'pipData',
             'pipEntry.VerifyEmail', 'pipEntry.Templates',

            'pipMobileEntry.Strings', 'pipMobileEntry.PostSignup', 'pipMobileEntry.RecoverPassword', 'pipMobileEntry.ResetPassword',
            'pipMobileEntry.Signin', 'pipMobileEntry.Signup'
        ]);

    thisModule.provider('pipMobileEntry', function() {
        var defaultClass = '',
            appLogo = '';

        this.setClass = function(value) {
            defaultClass = value; 
        };

        this.setAppLogo = function(value) {
            appLogo = value; 
        };

        this.$get = function () {
            return {
                getClass: function() {
                    return defaultClass;
                },
                getAppLogo: function () {
                    return appLogo;
                }
            };
        }
    });

    thisModule.config(
        ['$stateProvider', '$locationProvider', '$httpProvider', 'pipAuthStateProvider', 'pipMobileEntryProvider', 'pipDataPartyProvider', function ($stateProvider, $locationProvider, $httpProvider, pipAuthStateProvider, pipMobileEntryProvider, pipDataPartyProvider) {

            // Switch to HTML5 routing mode
            //$locationProvider.html5Mode(true);

            // Configure module routes for all users
            $stateProvider
                .state('signin', {
                    url: '/signin?email&server_url&redirect_to',
                    auth: false,
                    controller: 'pipSigninController',
                    templateUrl: 'mobile_entry/signin/signin.html'
                })
                .state('recover_password', {
                    url: '/recover_password?server_url&email',
                    auth: false,
                    controller: 'pipRecoverPasswordController',
                    templateUrl: 'mobile_entry/recover_password/recover_password.html'
                })
                .state('reset_password', {
                    url: '/reset_password?server_url&email&code',
                    auth: false,
                    controller: 'pipResetPasswordController',
                    templateUrl: 'mobile_entry/reset_password/reset_password.html'
                })
                .state('signout', { 
                    url: '/signout',
                    auth: false
                })
                .state('signup', {
                    url: '/signup?name&email&server_url',
                    auth: false,
                    controller: 'pipSignupController',
                    templateUrl: 'mobile_entry/signup/signup.html'
                })
                .state('post_signup', {
                    url: '/post_signup?party_id',
                    auth: false,
                    controller: 'pipPostSignupController',
                    templateUrl: 'mobile_entry/post_signup/post_signup.html',
                    resolve: {
                        $party: pipDataPartyProvider.readPartiesResolver() 
                        // /* @ngInject */ function ($rootScope, $stateParams, pipRest, pipDataSession) {
                        //     var userId = pipDataSession.userId();
                        //     var partyId = $stateParams.party_id || userId;

                        //     if (partyId != userId)
                        //         throw('ERROR_NOT_ALLOWED');
                        //     return pipRest.parties().get({ id: partyId }).$promise;
                        // }
                    }
                })
                .state('verify_email', {
                    url: '/verify_email?server_url&email&code',
                    auth: false,
                    controller: 'pipVerifyEmailController',
                    templateUrl: 'mobile_entry/verify_email/verify_email.html'
                })
                .state('verify_email_success', {
                    url: '/verify_email_success',
                    auth: false,
                    controller: 'pipVerifyEmailSuccessController',
                    templateUrl: 'mobile_entry/verify_email/verify_email_success.html'
                });

            // Set default paths and states
            pipAuthStateProvider.signinState('signin');
            pipAuthStateProvider.signoutState('signout');
        }]
    );
    
})();