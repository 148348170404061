<!--
@file History activities (recent activities) control content
@copyright Digital Living Software Corp. 2014-2016
-->
<div class="layout-column layout-align-start-start flex">
	<div class="text-title bm16">
		{{::activitiesTitle | translate}}
	</div>

	<div ng-repeat="activity in activities track by $index"
		 class="pip-activities">

		<div class="pip-ref-item">
			<md-icon class="rm24" md-svg-icon="icons:{{activity.icon}}"></md-icon>
			<div class="pip-content">
				<p class="pip-title">{{::activity.activityString}}</p>
				<p class="pip-subtitle">{{ activity.time | formatElapsedInterval }} * {{ ::activity.party_name }}</p>
			</div>
		</div>
		<div class="bottom-divider" ng-show="$index < activities.length - 1"></div>
	</div>
	<div class="text-center pip-empty" ng-if="activities.length == 0 && requsetExecuted">
		<img src="../images/ES_Surprised.svg" width="320" height="250" style="margin: 0 auto">
		<div class="pip-text">{{ ::ACTIVITIES_NONE | translate}}</div>
	</div>

	<a ng-click="getPreviousActivities()"
	   class="pointer"
	   ng-show="showButton && totalCount && start < totalCount">
		{{::'ACTIVITY_SHOW_MORE' | translate}}
	</a>
</div>