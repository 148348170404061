<div class="w-stretch" >
    <!-- join options -->
    <div class="h72 pip-accented-box rp24-flex lp24-flex layout-row layout-align-start-center"
             ng-if="selected.showJoinMethod">
             
        <div class="text-subhead1 opacity-primary flex rm16"
             ng-bind-html="selected.joinMethodDiscription">
        </div>
        <md-button class="m0 md-raised md-accent flat flex-fixed"
                   ng-click="onJoinMethodChange($event)"
                   ng-disabled="entity.contrib_def || transaction.busy()"
                   aria-label="CHANGE_TYPE">
                   
            {{::'CHANGE' | translate}}
        </md-button>
    </div>
       
    <!-- default switch -->    
    <div class="h72 rp24-flex lp24-flex layout-row layout-align-start-center">
        <md-checkbox class="m0" 
            id="checkbox_id"
            ng-if="selected.showDefault"
            ng-model="entity.contrib_def" 
            ng-click="ignoreClick($event)"
            nd-disable="transaction.busy()"
            ng-change="onOptionChange(entity.contrib_def)"
            aria-label="CHECK">

            <span class="text-body2 color-secondary-text lm8">
                {{::'PARTICIPANT_CONFIDENT_DEFAULT' | translate}}
            </span>
        </md-checkbox>  

        <div class="flex"></div>
        <md-button class="m0 md-raised md-accent flat flex-fixed"
                   ng-click="onParticipantEdit($)"
                   ng-disabled="entity.contrib_def || transaction.busy()"
                   aria-label="CHANGE_TYPE">
            {{::'WIZARD_ADD_PARTICIPANTS' | translate}}
        </md-button>    
    </div>    

            <div class="rp24-flex lp24-flex" ng-if="selected.pending.length > 0">
                <div class="layout-row layout-align-start-center h48 m0 text-body2 color-secondary-text participant-title">
                    {{::'PARTICIPANT_PENDING' | translate}}
                </div>
                <div class="" ng-repeat="pendingParty in selected.pending track by pendingParty.party_id">
                    <div class="pip-ref-item layout-row layout-align-start-center">
                        <pip-avatar class="pip-face rm16"
                                    pip-party-id="pendingParty.party_id"
                                    pip-party-name="pendingParty.party_name">
                        </pip-avatar>
                        <div class="pip-content color-secondary-text flex">
                            <p  class="pip-title">{{::pendingParty.party_name}}</p>
                            <p class="pip-subtitle"
                                ng-if="selected.showRole"
                                ng-click="onRoleChange($event, pendingParty)">
                                <a class="text-uppercase ">
                                    {{getRoleString(pendingParty) }}
                                </a>
                            </p>
                        </div>
                        <div class="button-cross" style="margin-right: -16px;">
                            <md-button class="md-icon-button"
                                    ng-click="onRemovePending($event, pendingParty)"
                                    ng-if="!entity.contrib_def && pendingParty.party_id !== selected.party.id"
                                    ng-disabled="transaction.busy()"
                                    aria-label="REMOVE">
                                <md-icon md-svg-icon="icons:cross"></md-icon>
                            </md-button>
                        </div>
                    </div>
                    <div ng-class="{'pip-participant-divider': $index !== selected.pending.length - 1 }"
                         class="flex"></div>
                </div>
                <div class="h72" ng-if="selected.accepted.length === 0"></div>
            </div>
            <!-- active party -->
            <div class="rp24-flex lp24-flex" ng-if="selected.accepted.length > 0" >
                <p class="m0 text-body2 h48 color-secondary-text participant-title" >
                    {{::'PARTICIPANT_ACTIVE' | translate}}
                </p>
                <div ng-repeat="acceptedParty in selected.accepted track by acceptedParty.party_id" >
                    <div class="pip-ref-item layout-row layout-align-start-center">
                        <pip-avatar class="pip-face rm16"
                                    pip-party-id="acceptedParty.party_id"
                                    pip-party-name="acceptedParty.party_name" >
                        </pip-avatar>
                        <div class="pip-content flex">
                            <p class="pip-title">{{::acceptedParty.party_name}}</p>
                            <p class="pip-subtitle"
                                ng-if="selected.showRole"
                                ng-click="onRoleChange($event, acceptedParty)">
                                <a class="text-uppercase ">
                                    {{getRoleString(acceptedParty) }}
                                </a>
                            </p>
                        </div>
                        <div class="button-cross" style="margin-right: -16px;">
                            <md-button class="md-icon-button"
                                    ng-click="onRemoveAccepted($event, acceptedParty)"
                                    ng-disabled="transaction.busy()"
                                    ng-if="!entity.contrib_def && acceptedParty.party_id !== selected.party.id"
                                    aria-label="REMOVE">
                                <md-icon md-svg-icon="icons:cross"></md-icon>
                            </md-button>
                        </div>
                    </div>
                    <div ng-class="{'pip-participant-divider': $index !== selected.accepted.length - 1 }"
                         class="flex"></div>
                </div>
                <div class="h72"></div>
            </div>
</div>    