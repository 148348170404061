<!--
@file Conversion dialog wizard
@copyright Digital Living Software Corp. 2016
-->

<md-dialog class="pip-dialog pip-parent-convert-wizard layout-column"
           min-width="450" md-theme="{{theme}}">
    <div class="pip-wrapper">
        <md-dialog-content class="pip-wrapper-box">
            <div class="pip-body p0 pip-scroll" ng-if="step==='choose_type'">
                <div class="pip-header animate-title" ng-class="{'show-header': step==='choose_type'}">
                    <h3 class="m0 text-title">
                        {{::title | translate}}
                    </h3>
                    <p class="tp16 pip-subtitle">
                        <span pip-translate-html="{{::subtitle | translate}}"/>
                    </p>

                </div>
                <div class="pip-divider"></div>
                <div class="pip-content">
                    <div class="pip-list md-primary" md-no-ink="true" ng-keydown="onKeyDown($event)"
                                    ng-keypress="onKeyPress($event)" tabindex="0">
                        <div ng-repeat="option in convertedCollection"
                             class="pip-list-item layout-row layout-align-start-center" md-ink-ripple
                            ui-event="{ click: 'onOptionSelect($event, option)' }"
                            ng-class="{ selected: option.name == selectedOptionName,
                                    'divider-bottom': $index < convertedCollection.length - 1 }" >

                                <div class="pip-content lp24-flex rp24-flex flex">
                                    <div class="pip-title" ng-if="option.title">
                                        {{::option.title | translate}}
                                    </div>
                                    <div class="pip-subtitle" ng-if="option.subtitle">
                                        <span pip-translate-html="{{::option.subtitle | translate}}"/>
                                    </div>
                                    <div class="pip-text" ng-if="option.text">
                                        <span pip-translate-html="{{::option.text | translate}}"/>
                                    </div>
                                </div>
                                <div class="flex-fixed rp24-flex" >
                                    <md-icon md-svg-icon="icons:chevron-right"></md-icon>
                                </div>    
                        </div>
                    </div>
                </div>
            </div> 

            <div class="pip-body p0 pip-scroll" ng-if="step==='task_content'">
                <div class="pip-header animate-title" ng-class="{'show-header': step==='task_content'}">
                    <h3 class="m0 text-title" >
                        <md-button  class="md-icon-button m0" style="margin-left: -8px!important;"
                                        ng-click="onWizardBackClick()"
                                        aria-label="{{ ::'CANCEL' | translate }}">
                                <md-icon class="text-grey" md-svg-icon="icons:arrow-left"></md-icon>
                            </md-button>
                            <span pip-translate-html="{{::optionDetails.detailsTitle | translate}}"/>
                    </h3>
                    <p class="tp8 pip-subtitle">
                        <span pip-translate-html="{{::subDetailsTitle | translate}}"/>
                    </p>
                </div>
                        <div class="tp8 lp24-flex rp24-flex" ng-if="optionDetails.withParents">
                            <md-autocomplete class="pip-no-hint-input text-subhead1"
                                            md-selected-item="task.parent"
                                            md-search-text="task.searchText"
                                            md-selected-item-change="onItemChange()"
                                            md-select-on-match="true"
                                            md-match-case-insensitive="true"
                                            pip-input-blur="onBlur()"
                                            md-no-cache="true"
                                            md-items="item in getMatches()"
                                            md-item-text="item.title"
                                            md-floating-label="{{::optionDetails.placeholder | translate}}"
                                            md-menu-class="autocomplete-custom-template">
                                <span md-highlight-text="task.searchText">{{item.translateTitle}}</span>
                                <md-item-template>
                                    <md-icon md-svg-icon="icons:{{item.icon}}"></md-icon>
                                    <span>{{item.translateTitle}}</span>
                                </md-item-template>
                            </md-autocomplete>
                       <div class="pip-hint pip-error-text color-error" ng-show="recordNotFound && contextNotEmpty">
                           <span pip-translate-html="{{::optionDetails.detailsErrorHint | translate}}"/>
                        </div>
                        </div>

                        <div class="tp8 bp24 lp24-flex rp24-flex" ng-if="!optionDetails.withParents">
                            <div class="divider-bottom" > 
                             <input  style="border: none;" 
                                    class=" color-content-bg text-subhead1 bm4 w-stretch"
                                    ng-model="task.name"
                                    ng-change="onTaskChange()"
   
                                    placeholder="{{::optionDetails.placeholder | translate}}"
                                    type="text" tabindex="1" />
                            </div>
                        </div>

                <div class="pip-divider"></div>
                <div class="pip-content" >
                    <div class="pip-list md-primary" md-no-ink="true">
                            <!--parent record not found -->
                        <div ng-repeat="option in optionDetails.detailsOptions" 
                             class="pip-list-item divider-bottom layout-row layout-align-start-center"
                             md-ink-ripple
                             ng-show="recordNotFound && contextNotEmpty"
                             ui-event="{ click: 'onOptionDetailsSelect($event, option)' }">

                                <div class="pip-content lp24-flex rp24-flex  flex">
                                    <div class="pip-title" ng-if="option.title">
                                        {{::option.title | translate}}
                                    </div>
                                    <div class="pip-subtitle" ng-if="option.subtitle">
                                        <span pip-translate-html="{{::option.subtitle | translate}}"/>
                                    </div>
                                    <div class="pip-text" ng-if="option.text">
                                        <span pip-translate-html="{{::option.text | translate}}"/>
                                    </div>
                                </div>
                                <div class="flex-fixed rp24-flex" >
                                    <md-icon md-svg-icon="icons:chevron-right"></md-icon>
                                </div>    
                        </div>

                        <!--parent record found -->
                        <div class="pip-list-item divider-bottom" md-ink-ripple
                            ng-show="!recordNotFound && contextNotEmpty"
                            ui-event="{ click: 'onTaskWithParent($event)' }">

                            <div class="pip-content lp24-flex rp24-flex flex layout-row layout-align-start-center">
                                <div class="pip-title flex">
                                    <span pip-translate-html="{{::detailsButton | translate}}"/>
                                </div>
                                <div class="flex-fixed">
                                    <md-icon md-svg-icon="icons:chevron-right"></md-icon>
                                </div>                        
                            </div>
                        </div>     

                        <div class="pip-list-item divider-bottom color-accent" md-ink-ripple
                            ui-event="{ click: 'onTaskByEvent($event)' }"
                            ng-show="!contextNotEmpty && eventTitle && selectedOptionName != 'task'">

                            <div class="pip-content lp24-flex rp24-flex flex layout-row layout-align-start-center">
                                <div class="pip-title flex">
                                    <span pip-translate-html="{{::'ADD_DEFAULT_TASK_FOR_EVENT' | translate}}"/>
                                </div>
                                 <div class="flex-fixed">
                                    <md-icon md-svg-icon="icons:chevron-right"></md-icon>
                                </div> 
                            </div>                                
                        </div>

                        <div class="pip-list-item " md-ink-ripple
                            ui-event="{ click: 'onTaskWithoutContext($event)' }">

                            <div class="pip-content lp24-flex rp24-flex flex layout-row layout-align-start-center">
                                <div class="pip-title flex">
                                    <span pip-translate-html="{{optionDetails.detailsCancelButton | translate}}"/>
                                </div>
                                 <div class="flex-fixed">
                                    <md-icon md-svg-icon="icons:chevron-right"></md-icon>
                                </div> 
                            </div>                                
                        </div>
                    </div>
                </div>            
            </div>    

            <div class="h8"></div>
        </md-dialog-content>
    </div>
</md-dialog>