/**
 * @file Application Side Nav service
 * @copyright Digital Living Software Corp. 2014-2015
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipLifeSideNav.Service', ['pipAssert', 'pipDebug']);

    thisModule.provider('pipLifeSideNav', ['pipAssertProvider', 'pipDebugProvider', function (pipAssertProvider, pipDebugProvider) {
        var config = {
            // Theme to be applied to the header
            theme: 'blue',
            // Sections with navigation links
            sections: []
        };

        this.theme = theme;
        this.sections = sections;

        this.$get = ['$rootScope', '$mdSidenav', function ($rootScope, $mdSidenav) {
            $rootScope.$on('pipLifeSideNavOpen', open);
            $rootScope.$on('pipLifeSideNavClose', close);

            return {
                config: getConfig,
                theme: setTheme,
                sections: setSections,
                open: open,
                close: close,
                toggle: toggle
            };
            //---------------------------------------------------------------------------------------

            function getConfig() {
                return config;  
            }
                            
            function setTheme(newTheme) {
                theme(newTheme);
                sendConfigEvent();  
                return config.theme;
            }
                            
            function setSections(newSections) {
                sections(newSections);
                sendConfigEvent();
                return config.sections;  
            }
                            
            function sendConfigEvent() {
                $rootScope.$broadcast('pipLifeSideNavChanged', config);
            }

            function open(event) {
                $mdSidenav('pip-life-sidenav').open();
            }
                 
            function close(event) {
                $mdSidenav('pip-life-sidenav').close();
            }

            function toggle() {
                $mdSidenav('pip-life-sidenav').toggle();
            }
        }];

        function theme(newTheme) {
            config.theme = newTheme || config.theme;
            return config.theme;            
        }

        function validateSections(sections) {
            pipAssertProvider.isArray(sections, 'pipLifeSideNavProvider.sections or pipLifeSideNav.sections: sections should be an array');
            _.each(sections, function (section, number) {
                if (section.access) {
                    pipAssertProvider.isFunction(section.access, 'pipLifeSideNavProvider.sections or pipLifeSideNav.sections: in section number '
                        + number + " with title " + section.title + ' access should be a function');
                }
                if (section.links) {
                    pipAssertProvider.isArray(section.links, 'pipLifeSideNavProvider.sections or pipLifeSideNav.sections: in section number '
                        + number + " with title " + section.title + ' links should be an array');
                    _.each(section.links, function (link) {
                        if (link.access) pipAssertProvider.isFunction(link.access, 'pipLifeSideNavProvider.sections or pipLifeSideNav.sections: in section number '
                            + number + " with title " + section.title + ' in link with title ' + link.title + ' access should be a function');
                    });
                }
            });
        }

        function sections(newSections) {
            if (pipDebugProvider.enabled()) validateSections(newSections);

            if (_.isArray(newSections))
                config.sections = newSections;
            return config.sections;
        }
    }]);

})();
