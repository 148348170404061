/**
 * @file directive change height of element when inner element height changed
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global _, angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipWrapper', []);

    thisModule.directive('pipWrapper',
        function () {
            return {
                restrict: 'C',
                link: function (scope, element) {
                    // todo: set element name as attribute
                    var innerElement = element.find('md-dialog-content');

                    scope.$watch(
                        function () {
                            return innerElement[0].offsetHeight;
                        },
                        function (value) {
                            element.css('height', value + 'px');

                            scope.status = value;
                        },
                        true
                    );

                }
            };
        }
    );

})();
