/**
 * @file Filter to format Visibility
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipVisibilityFilters', ['pipVisibilityFormat']);

    thisModule.filter('formatVisibility',
        ['pipVisibilityFormat', function (pipVisibilityFormat) {
            return function(value, format) {
                return pipVisibilityFormat.formatVisibility(value);
            };
        }]
    );
    
    thisModule.filter('formatDetailVisibility',
        ['pipVisibilityFormat', function (pipVisibilityFormat) {
            return function(value, format) {
                return pipVisibilityFormat.formatDetailVisibility(value);
            };
        }]
    );

})();
