<!--
@file Side Nav component
@copyright Digital Living Software Corp. 2014-2015
-->

<md-sidenav class="md-sidenav-left md-whiteframe-z2 pip-life-sidenav color-content-bg"
    md-component-id="pip-life-sidenav"
    ng-if="!$partialReset" 
    pip-focused>

    <md-toolbar class="pip-sidenav-header"
                ng-class="{'pip-sidenav-owner': $party.id == $user.id}"
                md-theme="{{ $theme|| $user.theme || config.theme }}"
                ng-if="!!$party">

            <md-button class="pip-sidenav-party md-icon-button pip-animated"
                       ng-click="onUserClick()"
                       ng-class="{'pip-another-party': $party.id != $user.id}"
                       aria-label="current party">
                <pip-avatar
                            pip-party-id="$user.id"
                            pip-default-icon="icon-person"
                            pip-party-name="$user.name"
                            pip-rebind-avatar="true"
                            pip-rebind="true">
                </pip-avatar>
            </md-button>

            <md-button class="pip-sidenav-party pip-second-party pip-animated md-icon-button"
                       ng-click="onPartyClick()"
                       ng-if="$party.id != $user.id"
                       aria-label="current user">
                <pip-avatar class="pic-pic pip-face-ld"
                            pip-default-icon="icon-person"
                            pip-rebind="true"
                            pip-rebind-avatar="true"
                            pip-party-id="$party.id"
                            pip-party-name="$party.name">
                </pip-avatar>
            </md-button>

        <div class="layout-row layout-align-space-between-center">
            <div class="pip-sidenav-party-text">
                <a class="pip-sidenav-party-pri cursor-pointer"
                   ng-click="onPartyClick()">{{ $party.name }}</a>
            </div>
            <md-button class="md-icon-button pip-animated pip-icon-party"  ng-class="{'pip-rotated': connectionsOpen}"
                       ng-show="connections.length > 0"
                       ng-click="openConnections()">
                <md-icon md-svg-icon="icons:triangle-down"></md-icon>
            </md-button>
        </div>
    </md-toolbar>

    <md-list class="pip-sections-container tm8">

        <div class="pip-sections-absolute pip-links pip-animated"
             ng-hide="connectionsOpen && connections.length > 0">
            <div class="pip-section" ng-repeat="section in config.sections track by $index"
                 ng-if="!(section.access && !section.access($party, $user, section))">

                <md-divider ng-if="$index > 0 && !isSectionEmpty(section.links)"></md-divider>
                <md-subheader ng-if="section.title">{{::section.title | translate}}</md-subheader>

                <md-list-item class="pip-focusable no-border"
                              ng-repeat="link in section.links track by $index"
                              ng-click="onLinkClick($event, link)"
                              ng-if="!(link.access && !link.access($party, $user, link))">
                    <md-icon md-svg-icon="{{link.icon}}"
                             ng-if="!!link.icon"
                             class="tm0 bm0"></md-icon>
                    <p>{{::link.title | translate}}</p>
                </md-list-item>
            </div>
        </div>

        <div class="pip-sections-absolute pip-connections pip-animated"
             ng-show="connections && connections.length > 0 && connectionsOpen">
            <md-list-item class="pip-focusable no-border h56"
                          ng-repeat="connection in connections track by $index"
                          ng-click="onPersonClick($event, connection)">
                <pip-avatar class="pic-pic pip-face rm16"
                            pip-default-icon="icon-person"
                            pip-rebind="true"
                            pip-rebind-avatar="true"
                            pip-party-id="connection.to_party_id"
                            pip-party-name="connection.to_party_name">
                </pip-avatar>
                <p class="text-overflow">{{::connection.to_party_name}}</p>
            </md-list-item>
        </div>
    </md-list>

</md-sidenav>
