<!--
@file Signup page
@copyright Digital Living Software Corp. 2014-2016
-->

<div class="pip-card-container pip-entry pip-mobile-entry pip-scroll scroll-y"
        ng-class="styleClass">
        <div class="layout-column">
                <div class="task-entry-header">
                        <div class="h48 text-headline layout-row layout-align-center-center"> {{::'SIGNUP_MOBILE_TITLE' | translate}}</div>
                        <img ng-src="{{appLogo}}" class="pip-pic tp24-flex bp24-flex block" ng-if="!isIe">
                        <div class="ie-logo tp24-flex bm8" ng-if="isIe"></div>
                        <div class="text-subhead1">{{::'SIGNUP_MOBILE_SUBTITLE' | translate}}</div>
                </div>        
                <div class="befor-signup-panel">
                </div>
                <pip-signup-panel class="text-subhead1"
                                pipfixedServerUrl="fixedServerUrl"
                                pip-remember="'true'"
                                pip-hide-elements="{title: true, server: true, agreement: true, signin: true, passwordConfirm: true, progress: true, hint: true}">
                </pip-signup-panel>
                <div class="task-entry-footer">
                <div class="text-body1  h48 layout-row layout-align-center-center">
                        {{::'SIGNUP_MOBILE_SUBTITLE1' | translate}}&nbsp;&nbsp;
                        <a ng-click="gotoSignin()" href="">
                                {{::'SIGNUP_SIGNIN_HERE' | translate}}
                        </a> 
                </div>

                <div class="text-body1 tp24-flex">
                        {{::'SIGNUP_MOBILE_AGREEMENT_TEXT1' | translate}}&nbsp;&nbsp;
                        <a href="#/legal/privacy">
                                {{::'SIGNUP_MOBILE_AGREEMENT_TEXT2' | translate}}
                        </a>&nbsp; 
                        
                        {{::'SIGNUP_MOBILE_AGREEMENT_TEXT3' | translate}}&nbsp; 
                        <a href="#/legal/services">
                                {{::'SIGNUP_MOBILE_AGREEMENT_TEXT4' | translate}}
                        </a>                 
                </div> 
                </div> 
        </div> 
</div>

