<a ng-click="onPriorityClick()"
   ng-if="showImportance || showUrgency"
   ng-class="{'disabled':isDisabled()}">
    {{ priority | translate }}
</a>
<span ng-if="showStatus && (showImportance || showUrgency)"> • </span>
<a ng-click="onStatusClick()"
   ng-class="{'disabled':isDisabled()}"
   ng-if="showStatus">
    {{ translatedStatus }}
<span ng-if="progress > -1 && status != 'new' && status != 'completed' && status != 'canceled' ">
    - {{ progress }}%
</span>
</a>
<md-progress-linear ng-if="showProgressLine && (progress > -1 && status != 'new' && status != 'completed' && status != 'canceled' )"
                    ng-value="progress" md-mode="determinate">
</md-progress-linear>