<pip-avatar class="pip-face-sm"
            ng-class="{ lm8: !$first }"
            ng-if="!isChip"
            ng-repeat="party in limit track by party.party_id"
            pip-party-id="party.party_id"
            pip-party-name="party.party_name"
            pip-rebind="true">
</pip-avatar>
<div class="pip-more" ng-show="more > 0" ng-if="!isChip" >
    <p class="m0">
        {{ ::moreString }}
    </p>
</div>
<div class="pip-chip text-overflow" ng-if="isChip">
    <pip-avatar class="pip-face-sm"
                pip-party-id="data.party_id"
                pip-party-name="data.party_name"
                pip-rebind="true">
    </pip-avatar>
    <span>
        {{::data.party_name}}
    </span>
</div>