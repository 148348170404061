<!--
@file Verify email page
@copyright Digital Living Software Corp. 2014-2016
-->

<div class="pip-card-container pip-outer-scroll pip-entry">
    <pip-card width="400">
        <div class="pip-body">
            <div class="pip-content">
                <md-progress-linear ng-show="transaction.busy()" md-mode="indeterminate" class="pip-progress-top" >
                </md-progress-linear>

                <h2>{{'VERIFY_EMAIL_TITLE' | translate}}</h2>

                <p class="title-padding">{{'VERIFY_EMAIL_TEXT_1' | translate}} </p>

                <form name='form' novalidate>
                    <div ng-messages="form.$serverError" class="text-error bm8">
                        <div ng-message="ERROR_1000">{{::'ERROR_1000' | translate}}</div>
                        <div ng-message="ERROR_1110">{{::'ERROR_1110' | translate}}</div>
                        <div ng-message="ERROR_1111">{{::'ERROR_1111' | translate}}</div>
                        <div ng-message="ERROR_1112">{{::'ERROR_1112' | translate}}</div>
                        <div ng-message="ERROR_-1">{{::'ERROR_SERVER' | translate}}</div>
                        <div ng-message="ERROR_UNKNOWN">
                            {{ form.$serverError.ERROR_UNKNOWN | translate }}
                        </div>
                    </div>

                    <a ng-hide="showServerUrl || fixedServerUrl" ng-click="showServerUrl = true" href="">      
                        {{'ENTRY_CHANGE_SERVER' | translate}}
                    </a>
                    <div ng-show="showServerUrl">
                        <md-autocomplete
                                ng-initial autofocus tabindex="1"
                                class="pip-combobox w-stretch bm8"
                                name="server"
                                ng-enabled="!transaction.busy()"
                                placeholder="{{::'ENTRY_SERVER_URL' | translate}}"
                                md-no-cache="true"
                                md-selected-item="data.serverUrl"
                                md-search-text="selected.searchURLs"
                                md-items="item in getMatches()"
                                md-item-text="item"
                                md-selected-item-change="onServerUrlChanged()"
                                md-delay="200"
                                ng-model="data.serverUrl"
                                pip-clear-errors>
                            <span md-highlight-text="selected.searchURLs">{{item}}</span>
                        </md-autocomplete>
                    </div>

                    <md-input-container class="pip-no-hint" style="padding-bottom: 4px!important;">
                        <label>{{::'EMAIL' | translate}}</label>
                        <input name="email" type="email" ng-model="data.email" required step="any"  pip-clear-errors
                               ng-disabled="transaction.busy()" tabindex="2" />

                        <div ng-messages="touchedErrorsWithHint(form, form.email)" ng-if="!form.email.$pristine" class="md-input-error">
                            <div ng-message="hint" class="pip-input-hint">{{::'HINT_EMAIL' | translate}}</div>
                            <div ng-message="required">{{::'ERROR_EMAIL_INVALID' | translate }}</div>
                            <div ng-message="email">{{::'ERROR_EMAIL_INVALID' | translate }}</div>
                            <div ng-message="ERROR_1100">{{::'ERROR_1100' | translate}}</div>
                            <div ng-message="ERROR_1104">{{::'ERROR_1104' | translate}}</div>
                            <div ng-message="ERROR_1305">{{::'ERROR_1305' | translate}}</div>
                            <div ng-message="ERROR_1106">{{::'ERROR_1106' | translate}}</div>
                        </div>
                    </md-input-container>

                    <md-input-container class="pip-no-hint">
                        <label>{{::'ENTRY_RESET_CODE' | translate}}</label>
                        <input name="code" ng-disabled="transaction.busy()"
                               ng-model="data.code" required tabindex="3" />

                        <div ng-messages="touchedErrorsWithHint(form, form.code)" ng-if="!form.fullName.$pristine" class="md-input-error">
                            <div ng-message="hint" class="pip-input-hint">{{::'ENTRY_RESET_CODE' | translate}}</div>
                            <div ng-message="required">{{::'ERROR_CODE_INVALID' | translate }}</div>
                            <div ng-message="ERROR_1108">{{::'ERROR_1108' | translate}}</div>
                        </div>
                    </md-input-container>

                    <p> 
                        {{'VERIFY_EMAIL_TEXT_21' | translate}} 
                        <a ng-click="onRecover()" class="pointer" href="">{{'VERIFY_EMAIL_RESEND' | translate}}</a>
                        {{'VERIFY_EMAIL_TEXT_22' | translate}} 
                    </p>
                </form>
            </div>
        </div>
        <div class="pip-footer">
            <md-button ng-click="goBack()" ng-hide="transaction.busy()" class="rm8" aria-label="CANCEL">
                {{::'CANCEL' | translate}}
            </md-button>
            <md-button class="md-accent" ng-click="onVerify()" ng-hide="transaction.busy()" aria-label="VERIFY"
                ng-disabled="data.code.length == 0 || data.email.length == 0 || (!data.email && form.$pristine) || (!data.code)">
                {{::'VERIFY' | translate}}
            </md-button>
            <md-button class="md-warn " ng-show="transaction.busy()" ng-click="transaction.abort()" aria-label="ABORT">
                {{::'CANCEL' | translate}}
            </md-button>
        </div>
    </pip-card>
</div>