/**
 * @file New Record Wizard Participants panel
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global _, angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipRecordWizardParticipantsPanel',
        []);

    thisModule.config(['pipTranslateProvider', function (pipTranslateProvider) {

        pipTranslateProvider.translations('en', {
            '': ''
        });

        pipTranslateProvider.translations('ru', {
            '': ''
        });

    }]);

    thisModule.directive('pipRecordWizardParticipantsPanel',
        function () {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    entity: '=pipEntity',
                    parties: '=pipParties',
                    showJoinMethod: '=pipShowJoinMethod',
                    addAcceptedParticipants: '=pipAddAcceptedParticipants',
                    showRole: '=?pipShowRole',
                    showDefault: '=pipShowDefault',
                    transaction: '=pipTransaction'
                },
                templateUrl: 'new_record_wizard/record_wizard_participans_panel.html',
                controller: 'pipRecordWizardParticipantsPanelController'
            };
        }
    );

    thisModule.controller('pipRecordWizardParticipantsPanelController',
        ['$scope', '$element', 'pipEnums', '$rootScope', '$mdMedia', 'pipTranslate', 'CommonCollectionService', 'pipOptionsBigDialog', 'pipPartyEditDialog', 'pipCollections', function ($scope, $element, pipEnums, $rootScope, $mdMedia, pipTranslate, CommonCollectionService,
                  pipOptionsBigDialog, pipPartyEditDialog, pipCollections) {

            var DEFAULT_ROLE = 'default';

            $element.addClass('pip-record-wizard-participans-panel');

            $scope.responsibilityCollection = CommonCollectionService.getResponsibilityCollections();

            $scope.joinMethodCollection = [];
            _.each(pipEnums.JOIN_METHOD, function (value) {
                $scope.joinMethodCollection.push({
                    id: value,
                    name: value,
                    title: 'JOIN_METHOD_' + value.toUpperCase(),
                    subtitle: 'DESCRIPTION_JOIN_METHOD_' + value.toUpperCase()
                });
            });

            $scope.buttonTitle = 'APPLY';
            $scope.dialogTitle = 'PARTICIPANT_DIALOG_TITLE';
            $scope.selected = {};
            initData();

            $scope.DEFAULT_ROLE = DEFAULT_ROLE;

            $scope.$watch('entity', function (newValue) {
                initData();
            });

            $scope.onOptionChange = onOptionChange;
            $scope.onJoinMethodChange = onJoinMethodChange;
            $scope.onParticipantEdit = onParticipantEdit;
            $scope.onRemovePending = onRemovePending;
            $scope.onRemoveAccepted = onRemoveAccepted;
            $scope.onRespondibility = onRespondibility;
            $scope.onMenuClick = onMenuClick;
            $scope.onRoleChange = onRoleChange;

            $scope.getRoleString = getRoleString;

            return;

            function initData() {
                if (!$scope.entity) { return; }

                $scope.selected.party = $rootScope.$party;
                $scope.selected.showRole = $scope.showRole !== false;

                $scope.selected.showJoinMethod = $scope.showJoinMethod !== false;
                $scope.selected.showDefault = $scope.showDefault !== false;
                // $scope.selected.contrib_def = $scope.entity.contrib_def;

                $scope.entity.join = $scope.entity.join === undefined || $scope.entity.join === null
                    ? pipEnums.JOIN_METHOD.INVITE : $scope.entity.join;

                setJoinMethodDescription();

                $scope.entity.contribs = fillParticipantCollections($scope.entity.contribs);
                prepareParticipant($scope.parties);
            }

            function setContribs(acceptor, donor) {
                acceptor.contribs = donor.contribs ? _.cloneDeep(donor.contribs) : [];
                acceptor.contrib_def = acceptor.contrib_def === undefined || acceptor.contrib_def === null ? true : acceptor.contrib_def;
                acceptor.join = donor.join !== undefined && donor.join !== null ? donor.join : null;
            }

            function onOptionChange(value) {
                if (value) {
                    setContribs($scope.entity, $scope.entity.combinedParent || {});
                    initData();
                }
            }

            function getRoleString(party) {
                if (party.default_role) {
                    return party.role ? pipTranslate.translate('PARTICIPANT_ROLE_DEFAULT') +
                    ' (' + pipTranslate.translate('PARTICIPANT_ROLE_' + party.role.toUpperCase()) + ')'
                        : pipTranslate.translate('PARTICIPANT_ROLE_DEFAULT') +
                    ' (' + pipTranslate.translate('PARTICIPANT_ROLE_UNDEFINED_IN_LIST') + ')';
                }

                return party.role ? pipTranslate.translate('PARTICIPANT_ROLE_' + party.role.toUpperCase())
                    : pipTranslate.translate('PARTICIPANT_ROLE_UNDEFINED');
            }

            function onJoinMethodChange(event) {
                _.each($scope.joinMethodCollection, function (item) {
                    item.active = $scope.entity.join === item.id;
                });
                pipOptionsBigDialog.show(
                    {
                        event: event,
                        title: 'CHOOSE_JOIN_METHOD',
                        options: $scope.joinMethodCollection,
                        appleButtonTitle: 'SHARING_CHANGE'
                    },
                    function (result) {
                        $scope.entity.join = result.option.id;
                        setJoinMethodDescription();
                    }
                );
            }

            function onRoleChange($event, party) {
                var collection = _.cloneDeep($scope.responsibilityCollection),
                    currentIndex = _.findIndex(collection, {name: party.role});

                if (currentIndex > -1) {
                    collection[currentIndex].active = true;
                }
                pipOptionsBigDialog.show(
                    {
                        event: $event,
                        noActions: true,
                        noTitle: true,
                        hint: 'PARTICIPANT_ROLE_HINT',
                        options: collection
                    },
                    function (option) {
                        var optionName = option ? option.option.name : null;

                        if (optionName) {
                            onRespondibility(party, optionName, $event);
                        }
                    }
                );
            }

            function onRespondibility(item, responsible, $event) {
                var contribIndex;

                if ($event) {
                    $event.stopPropagation();
                }

                if (item.role === responsible && item.default_role !== true) { return; }

                item.role = responsible;
                contribIndex = _.findIndex($scope.entity.contribs, {party_id: item.party_id});

                if (contribIndex !== null) {
                    if (item.role === String(pipEnums.CONSTRIBUTOR_ROLES.UNDEFINED)) {
                        delete $scope.entity.contribs[contribIndex].role;
                        delete $scope.entity.contribs[contribIndex].role_from_id;
                        $scope.entity.contribs[contribIndex].default_role = false;
                    } else {
                        $scope.entity.contribs[contribIndex].role = item.role;
                    }

                    if (item.role === DEFAULT_ROLE) {
                        delete $scope.entity.contribs[contribIndex].role;
                        $scope.entity.contribs[contribIndex].default_role = true;
                    } else {
                        $scope.entity.contribs[contribIndex].default_role = false;
                        delete $scope.entity.contribs[contribIndex].role_from_id;
                        item.default_role = false;
                    }
                }
                pipCollections.replaceBy($scope.selected.pending, 'party_id', item);
                pipCollections.replaceBy($scope.selected.accepted, 'party_id', item);
            }

            function onMenuClick($mdOpenMenu) {
                $mdOpenMenu();
            }

            function onParticipantEdit(event) {
                pipPartyEditDialog.show(
                    {
                        title: $scope.dialogTitle,
                        parties: $scope.entity.contribs,
                        partyOptions: $scope.selected.parties,
                        optionTitle: null,
                        option: null,
                        event: event
                    },
                    function (result) {
                        saveParticipants(result.parties);
                    }
                );
            }

            function saveParticipants(participants, withoutCurrent) {
                $scope.entity.contribs = replaceParticipants(participants);
                $scope.entity.contribs = fillParticipantCollections($scope.entity.contribs);
            }

            function onRemovePending(event, party) {
                if ($scope.transaction && $scope.transaction.busy()) { return; }
                removeParticipants($scope.selected.pending, party);
            }

            function onRemoveAccepted(event, party) {
                if ($scope.transaction && $scope.transaction.busy()) { return; }
                removeParticipants($scope.selected.accepted, party);
            }

            function setJoinMethodDescription() {
                var item = _.find($scope.joinMethodCollection, {id: $scope.entity.join});

                item.subtitle = item.subtitle ? item.subtitle : '';
                $scope.selected.joinMethodDiscription = pipTranslate.translate(item.subtitle);
            }

            function prepareParticipant(parties) {
                $scope.selected.parties = _.map(
                    _.uniq(
                        _.filter(
                            parties,
                            function (item) {
                                return item.type !== 'follow' && item.accept === 'accepted';
                            }),
                        'to_party_id'),
                    function (item) {
                        return {
                            party_id: item.to_party_id,
                            party_name: item.to_party_name
                        };
                    }
                );
                // remove self
                _.remove($scope.selected.parties, {party_id: $scope.selected.party.id});
            }

            function fillParticipantCollections(collection) {
                var participants;

                $scope.selected.pending = [];
                $scope.selected.accepted = [];

                participants = _.sortBy(collection, function (item) {
                    return item.party_name;
                });

                _.each(participants, function (party) {
                    if (party.accept === 'accepted') {
                        $scope.selected.accepted.push(_.cloneDeep(party));
                    } else {
                        $scope.selected.pending.push(_.cloneDeep(party));
                    }
                });

                return participants;
            }

            function replaceParticipants(newCollection) {
                var result = [], party;

                _.each(newCollection, function (item) {
                    party = _.find($scope.entity.contribs, {party_id: item.party_id});
                    if (!party) {
                        result.push({
                            party_id: item.party_id,
                            party_name: item.party_name,
                            accept: $scope.addAcceptedParticipants || item.party_id === $rootScope.$user.id
                                ? 'accepted' : ''
                        });
                    } else {
                        result.push(party);
                    }
                });

                return result;
            }

            function removeParticipants(collection, party) {
                _.remove($scope.entity.contribs, {party_id: party.party_id});
                _.remove(collection, {party_id: party.party_id});
            }

        }]
    );

})();
