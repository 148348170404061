/**
 * @file String resources for entry pages
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';
    
    var thisModule = angular.module('pipMobileEntry.Strings', []);

    thisModule.config(['pipTranslateProvider', function (pipTranslateProvider) {

        // Set translation strings for the module
        pipTranslateProvider.translations('en', {

            // signup
            'SIGNUP_MOBILE_SUBTITLE': 'Register new Pip.Life account',
            'SIGNUP_MOBILE_TITLE': 'Welcome to',
            'SIGNUP_MOBILE_SUBTITLE1': 'Already have account?',
            'SIGNUP_MOBILE_AGREEMENT_TEXT1': 'By clicking Sign Up, I agree to the',
            'SIGNUP_MOBILE_AGREEMENT_TEXT2': 'PRIVACY STATEMENT',
            'SIGNUP_MOBILE_AGREEMENT_TEXT3': 'and',
            'SIGNUP_MOBILE_AGREEMENT_TEXT4': 'PIP.LIFE SERVICES AGREEMENT',
            'SIGNUP_TITLE': 'REGISTER FOR FREE',
            // signin
            'SIGNIN_MOBILE_TITLE': 'Sign in using Pip.Life account',
            'SIGNIN_MOBILE_SUBTITLE1': 'New to Pip.Life?',
            'SIGNIN_MOBILE_FORGOT': 'FORGOT PASSWORD?',
            'SIGNIN_SIGNUP_HERE': 'REGISTER FOR FREE'
        });

        pipTranslateProvider.translations('ru', {
  
              // signup
            'SIGNUP_MOBILE_SUBTITLE': 'Зарегистрировать новый Pip.Life аккаунт',
            'SIGNUP_MOBILE_TITLE': 'Добро пожаловать в',
            'SIGNUP_MOBILE_SUBTITLE1': 'Уже имеете аккаунт?',
            'SIGNUP_MOBILE_AGREEMENT_TEXT1': 'Нажимая зарегистрироваться я соглашаюсь с',
            'SIGNUP_MOBILE_AGREEMENT_TEXT2': 'СОГЛАШЕНИЕ О КОНФИДЕНЦИАЛЬНОСТИ',
            'SIGNUP_MOBILE_AGREEMENT_TEXT3': 'и',
            'SIGNUP_MOBILE_AGREEMENT_TEXT4': 'СОГЛАШЕНИЕ ОБ УСЛУГАХ PIP.LIFE',
            'SIGNUP_TITLE': 'БЕСПЛАТНАЯ РЕГИСТРАЦИЯ',
            // signin
            'SIGNIN_MOBILE_TITLE': 'Войти при помощи учетной записи Pip.Life',
            'SIGNIN_MOBILE_SUBTITLE1': 'Новичок в Pip.Life?',
            'SIGNIN_MOBILE_FORGOT': 'ЗАБЫЛИ ПАРОЛЬ?',
            'SIGNIN_SIGNUP_HERE': 'РЕГИСТРАЦИЯ'
        });

    }]);

})();