/**
 * @file PipLife Parent service, function for auto create parents entities
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo Complete refactoring +
 */

/* global angular, async, _ */

(function () {
    'use strict';

    var thisModule = angular.module('pipParentsService', []);

    thisModule.config(['pipTranslateProvider', function (pipTranslateProvider) {

        pipTranslateProvider.translations('en', {
            'PARENT_GOALS_CREATE_ERROR': 'Error: Parent goals can not be created.',
            'PARENT_AREAS_CREATE_ERROR': 'Error: Parent areas can not be created.',
            'PARENT_VISIONS_CREATE_ERROR': 'Error: Parent visions can not be created.'
        });

        pipTranslateProvider.translations('ru', {
            'PARENT_GOALS_CREATE_ERROR': 'Ошибка: Не удалось создать родительскую цель.',
            'PARENT_AREAS_CREATE_ERROR': 'Ошибка: Не удалось создать родительскую область.',
            'PARENT_VISIONS_CREATE_ERROR': 'Ошибка: Не удалось создать родительское видиние.'
        });

    }]);

    thisModule.factory('ParentsService',
        ['pipStrings', 'pipToasts', 'pipTranslate', '$log', 'pipCollections', 'pipGoalsData', 'pipAreasData', 'pipEnums', 'pipVisionsData', function (pipStrings, pipToasts, pipTranslate, $log,
                  pipCollections, pipGoalsData, pipAreasData, pipEnums, pipVisionsData) {

            function getVisionName(vision) {
                var date = vision.start ? new Date(vision.start) : null;
                return date != null ? date.getFullYear() + ' • ' + vision.title : vision.title;
            };

            function updateRefs(refs, addedGoalsCollection) {
                _.each(refs, function(ref) {
                    if (!ref.ref_id) {
                        var parentGoal = _.find(addedGoalsCollection, {title: ref.ref_name});
                        if (parentGoal) {
                            ref.ref_id = parentGoal.id;
                        }
                    }
                });
            };

            function clearRefs(refs) {
                var actionRefs = [];
                _.each(refs, function(ref){
                    if (ref.ref_id) {
                        actionRefs.push(ref);
                    }
                });
                return actionRefs;
            };

            function getParentIds(refs) {
                var ids = [];
                refs.forEach(function (ref) {
                    ids.push(ref.ref_id);
                });
                return ids;
            };

            function createSuperGoal(goalName, params, sub_type) {
                var  newGoal = {
                    type: sub_type ? sub_type : pipEnums.GOAL_TYPE.GOAL,
                    title: goalName,
                    status: pipEnums.EXECUTION_STATUS.NEW,
                    content: [],
                    progress: 0,
                    importance: pipEnums.IMPORTANCE.NORMAL,
                    urgency: pipEnums.URGENCY.NORMAL,
                    contribs: _.cloneDeep(params.item.contribs),
                    contrib_def: true,
                    share_def: true
                };
                return newGoal;
            };

            var createSuperArea = function (areaName, params) {
                var  newArea = {
                    type: pipEnums.AREA_TYPE.AREA,
                    title: areaName,
                    status: pipEnums.EXECUTION_STATUS.NEW,
                    progress: 0,
                    importance: pipEnums.IMPORTANCE.NORMAL,
                    urgency: pipEnums.URGENCY.NORMAL,
                    contribs: _.cloneDeep(params.item.contribs),
                    contrib_def: true,
                    share_def: true
                };
                return newArea;
            };

            var createVision = function(visionName, params) {
                var  newVision = {
                    title: visionName,
                    type: 'overall',
                    content: [],
                    contribs: [],
                    contrib_def: false,
                    share_def: false,
                    share_groups: [],
                    share_parties: [],
                    tags: [],
                    start: new Date()
                };
                return newVision;
            };

            function createGoals(newGoalCollection, params, transaction, successCallback, errorCallback) {
                var addedGoals = [];

                var t = transaction, tid;

                // Start transaction if necessary
                // if (t && !params.skipTransactionBegin) {
                //     tid = params.transactionId = t.begin('CREATING');
                //     if (!tid) return;
                // }

                async.eachSeries(
                    newGoalCollection,
                    function (item, callback) {
                        if (!item.ref_name || item.ref_type != pipEnums.REFERENCE_TYPE.GOAL) {
                            callback();
                        } else {

                            var goal = createSuperGoal(item.ref_name, params, item.ref_sub_type);
                            pipGoalsData.createGoal(
                                {
                                    skipTransactionBegin: true,
                                    skipTransactionEnd: false,                                    
                                    item: goal
                                },
                                function (result) {
                                    result.ref_type = pipEnums.REFERENCE_TYPE.GOAL;
                                    result.ref_sub_type = result.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null;
                                    addedGoals.push(result);
                                    callback();
                                },
                                function (err) {
                                    callback
                                }
                            );
                        }
                    },
                    function (err) {
                        // if (t && tid && t.aborted(tid)) return;
                        if (!err) {
                            // все добавилось нормально, обновляем коллекции
                            if (successCallback) {
                                successCallback(addedGoals);
                            }
                            // if (t & !params.skipTransactionEnd) t.end();
                        } else {
                            if (errorCallback) errorCallback(err);
                            else pipToasts.showError(pipTranslate.translate('PARENT_GOALS_CREATE_ERROR'), null, null, null, err);
                            // if (t) t.end(err);
                        }
                    });
            };

            function getRefs(result, type) {
                var refs = [{
                    ref_id: result.id,
                    ref_name: result.title,
                    ref_type: type,
                    parent: true
                }];

                return refs;
            }

            // todo: return array of overGoal and overAreas
            function createGoalsMultiplicity(newGoalCollection, params, transaction, successCallback, errorCallback) {
                function addGoalCallback(result, type, callback) {
                    result.ref_type = type;
                    result.ref_sub_type = result.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null;
                    addedGoals.push(result);
                    if (callback) callback();
                }

                var addedGoals = [];

                // var t = transaction, tid;

                // // Start transaction if necessary
                // if (t && !params.skipTransactionBegin) {
                //     tid = params.transactionId = t.begin('CREATING');
                //     if (!tid) return;
                // }

                async.eachSeries(
                    newGoalCollection,
                    function (item, callback) {
                        if (!item.ref_name || item.ref_type != pipEnums.REFERENCE_TYPE.GOAL) {
                            callback();
                        } else {
                            //  create with overRecord
                            var goal = createSuperGoal(item.ref_name, params, item.ref_sub_type);
                            if (item.overRecord) {
                                var overItem;

                                if (item.overRecord.ref_type === pipEnums.REFERENCE_TYPE.GOAL) {
                                    overItem = createSuperGoal(item.overRecord.ref_name, params, item.overRecord.ref_type);

                                    pipGoalsData.createGoal(
                                        {
                                            skipTransactionBegin: true,
                                            skipTransactionEnd: false,
                                            item: overItem
                                        },
                                        function (result) {
                                            $log.log('create over goal', result);
                                            goal.refs = getRefs(result, item.overRecord.ref_type);
// todo: add result to addedGoals with false params
                                            pipGoalsData.createGoal(
                                                { 
                                                    skipTransactionBegin: true,
                                                    skipTransactionEnd: false,                                                    
                                                    item: goal 
                                                },
                                                function (result) { addGoalCallback(result, pipEnums.REFERENCE_TYPE.GOAL, callback); },
                                                function (err) { callback }
                                            );
                                        },
                                        function (err) {
                                            callback
                                        }
                                    );
                                } else if (item.overRecord.ref_type === pipEnums.REFERENCE_TYPE.AREA) {
                                    overItem = createSuperArea(item.overRecord.ref_name, params, item.overRecord.ref_type);

                                    pipAreasData.createArea(
                                        {
                                            skipTransactionBegin: true,
                                            skipTransactionEnd: false,                                            
                                            item: overItem
                                        },
                                        function (result) {
                                            $log.log('create over area', result);
                                            goal.refs = getRefs(result, item.overRecord.ref_type);
// todo: add result to addedGoals with false params
                                            pipGoalsData.createGoal(
                                                { 
                                                    skipTransactionBegin: true,
                                                    skipTransactionEnd: false,                                                    
                                                    item: goal 
                                                },
                                                function (result) { addGoalCallback(result, pipEnums.REFERENCE_TYPE.GOAL, callback); },
                                                function (err) { callback }
                                            );
                                        },
                                        function (err) {
                                            callback
                                        }
                                    );
                                }
                            } else if (item.subRecord) {
                                //  create with subRecord
                                pipGoalsData.createGoal(
                                    {
                                        skipTransactionBegin: true,
                                        skipTransactionEnd: false,                                        
                                        item: goal
                                    },
                                    function (result) {
                                        result.ref_type = pipEnums.REFERENCE_TYPE.GOAL;
                                        result.ref_sub_type = result.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null;
                                        result.ref_id = result.id;
                                        addedGoals.push(result);                                        
                                        $log.log('parent goal created', item.subRecord);
                                        // create subRecord - only task
                                        if (item.subRecord.ref_sub_type == pipEnums.GOAL_TYPE.TASK) {
                                            var subItem =  createSuperGoal(item.subRecord.ref_name, params, item.subRecord.ref_sub_type);
                                            subItem.refs = getRefs(result, pipEnums.REFERENCE_TYPE.GOAL);
                                            pipGoalsData.createGoal(
                                                {
                                                    skipTransactionBegin: true,
                                                    skipTransactionEnd: false,                                                    
                                                    item: subItem
                                                },
                                                function (result) {
                                                    result.ref_type = pipEnums.REFERENCE_TYPE.GOAL;
                                                    result.ref_sub_type = result.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null;
                                                    result.ref_id = result.id;
                                                    addedGoals.push(result);
                                                    $log.log('sub task created', result);
                                                    callback();
                                                }
                                            );
                                        } else {
                                            callback();
                                        }
                                    },
                                    function (err) {
                                        callback
                                    }
                                );

                            } else {
                                pipGoalsData.createGoal(
                                    {
                                        skipTransactionBegin: true,
                                        skipTransactionEnd: false,                                        
                                        item: goal
                                    },
                                    function (result) {
                                        result.ref_type = pipEnums.REFERENCE_TYPE.GOAL;
                                        result.ref_sub_type = result.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null;
                                        result.ref_id = result.id;
                                        addedGoals.push(result);
                                        callback();
                                    },
                                    function (err) {
                                        callback
                                    }
                                );
                            }
                        }
                    },
                    function (err) {
                        // if (t && tid && t.aborted(tid)) return;
                        if (!err) {
                            // все добавилось нормально, обновляем коллекции
                            if (successCallback) {
                                successCallback(addedGoals);
                            }
                            // if (t & !params.skipTransactionEnd) t.end();
                        } else {
                            if (errorCallback) errorCallback(err);
                            else pipToasts.showError(pipTranslate.translate('PARENT_GOALS_CREATE_ERROR'), null, null, null, err);
                            // if (t) t.end(err);
                        }
                    });
            };


            function createAreasMultiplicity(newAreaCollection, params, transaction, successCallback, errorCallback) {
                var addedAreas = [];

                // var t = transaction, tid;

                // Start transaction if necessary
                // if (t && !params.skipTransactionBegin) {
                //     tid = params.transactionId = t.begin('CREATING');
                //     if (!tid) return;
                // }

                async.eachSeries(
                    newAreaCollection,
                    function (item, callback) {
                        if (!item.ref_name || item.ref_type != pipEnums.REFERENCE_TYPE.AREA) {
                            callback();
                        } else {
                            //  create with overRecord
                            var area = createSuperArea(item.ref_name, params);
                            if (item.subRecord) {
                                 //  create with subRecord
                                pipAreasData.createArea(
                                    {
                                        skipTransactionBegin: true,
                                        skipTransactionEnd: false,                                        
                                        item: area
                                    },
                                    function (result) {
                                        result.ref_type = pipEnums.REFERENCE_TYPE.AREA;
                                        result.ref_id = result.id;
                                        addedAreas.push(result);
                                        $log.log('parent area created', item.subRecord);
                                        // create subRecord - only task
                                        if (item.subRecord.ref_sub_type === pipEnums.GOAL_TYPE.TASK) {
                                            var subItem =  createSuperGoal(item.subRecord.ref_name, params, item.subRecord.ref_sub_type);
                                            subItem.refs = getRefs(result, pipEnums.REFERENCE_TYPE.AREA);
                                            pipGoalsData.createGoal(
                                                {
                                                    skipTransactionBegin: true,
                                                    skipTransactionEnd: false,                                                    
                                                    item: subItem
                                                },
                                                function (result) {
                                                    result.ref_type = pipEnums.REFERENCE_TYPE.AREA;
                                                    result.ref_sub_type = result.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null;
                                                    result.ref_id = result.id;                                                    
                                                    addedAreas.push(result);
                                                    $log.log('sub task created', result);
                                                    callback();
                                                }
                                            );
                                        } else {

                                            callback();
                                        }
                                    },
                                    function (err) {
                                        callback
                                    }
                                );

                            } else {
                                pipAreasData.createArea(
                                    {
                                        skipTransactionBegin: true,
                                        skipTransactionEnd: false,                                        
                                        item: area
                                    },
                                    function (result) {
                                        result.ref_type = pipEnums.REFERENCE_TYPE.AREA;
                                        result.ref_id = result.id;                                        
                                        addedAreas.push(result);
                                        callback();
                                    },
                                    function (err) {
                                        callback
                                    }
                                );
                            }
                        }
                    },
                    function (err) {
                        // if (t && tid && t.aborted(tid)) return;
                        if (!err) {
                            // все добавилось нормально, обновляем коллекции
                            if (successCallback) {
                                successCallback(addedAreas);
                            }
                            // if (t & !params.skipTransactionEnd) t.end();
                        } else {
                            if (errorCallback) errorCallback(err);
                            else pipToasts.showError(pipTranslate.translate('PARENT_AREAS_CREATE_ERROR'), null, null, null, err);
                            // if (t) t.end(err);
                        }
                    });
            };

            function createAreas(newAreaCollection, params, transaction, successCallback, errorCallback) {
                // Если выбрано несколько ACTION то создаем для каждой отдельную задачу автоматически (без открытия)
                var addedAreas = [];

                // var t = transaction, tid;

                // Start transaction if necessary
                // if (t && !params.skipTransactionBegin) {
                //     tid = params.transactionId = t.begin('CREATING');
                //     if (!tid) return;
                // }

                async.eachSeries(
                    newAreaCollection,
                    function (item, callback) {
                        if (!item.ref_name || item.ref_type != pipEnums.REFERENCE_TYPE.AREA) {
                            callback();
                        } else {
                            var area = createSuperArea(item.ref_name, params);
                            pipAreasData.createArea(
                                {
                                    skipTransactionBegin: true,
                                    skipTransactionEnd: false,                                    
                                    item: area
                                },
                                function (result) {
                                    result.ref_type = pipEnums.REFERENCE_TYPE.AREA;
                                    addedAreas.push(result);
                                    callback();
                                },
                                function (err) {
                                    callback
                                }
                            );
                        }
                    },
                    function (err) {
                        // if (t && tid && t.aborted(tid)) return;
                        if (!err) {
                            // все добавилось нормально, обновляем коллекции
                            if (successCallback) {
                                successCallback(addedAreas);
                            }
                            // if (t & !params.skipTransactionEnd) t.end();
                        } else {
                            if (errorCallback) errorCallback(err);
                            else pipToasts.showError(pipTranslate.translate('PARENT_AREAS_CREATE_ERROR'), null, null, null, err);
                            // if (t) t.end(err);
                        }
                    });
            };

            function createVisions(newVisionsCollection, params, transaction, successCallback, errorCallback) {
                // Если выбрано несколько ACTION то создаем для каждой отдельную задачу автоматически (без открытия)
                var addedVisions = [];

                // var t = transaction, tid;

                // Start transaction if necessary
                // if (t && !params.skipTransactionBegin) {
                    // tid = params.transactionId = t.begin('CREATING');
                    // if (!tid) return;
                // }

                async.eachSeries(
                    newVisionsCollection,
                    function (item, callback) {
                        if (!item.ref_name || item.ref_type != pipEnums.REFERENCE_TYPE.VISION) {
                            callback();
                        } else {
                            var vision = createVision(item.ref_name, params);
                            pipVisionsData.createVision(
                                {
                                    skipTransactionBegin: true,
                                    skipTransactionEnd: false,                                    
                                    item: vision
                                },
                                function (result) {
                                    result.combineName = getVisionName(vision);
                                    result.ref_type = pipEnums.REFERENCE_TYPE.VISION;
                                    addedVisions.push(result);
                                    callback();
                                },
                                function (err) {
                                    callback
                                }
                            );
                        }
                    },
                    function (err) {
                        // if (t && tid && t.aborted(tid)) return;
                        if (!err) {
                            // все добавилось нормально, обновляем коллекции
                            if (successCallback) {
                                successCallback(addedVisions);
                            }
                            // if (t & !params.skipTransactionEnd) t.end();
                        } else {
                            if (errorCallback) errorCallback(err);
                            else pipToasts.showError(pipTranslate.translate('PARENT_VISIONS_CREATE_ERROR'), null, null, null, err);
                            // if (t) t.end(err);
                        }
                    });
            };

            function clearEntity(event) {
                if (!event) return;

                delete event.source;
                delete event.partyId;
                delete event.parent_id;
                delete event.parent_name;
                delete event.parent_ref_type;
                delete event.parent_source;
                delete event.visions;
                delete event.duration;
            };

            function saveRefs(refs) {
                var refsArray = [];
                refs.forEach(function (ref) {
                    if (ref && angular.isObject(ref)) {
                        refsArray.push({
                                ref_id: ref.id || ref.ref_id || null,
                                ref_name: ref.title || ref.ref_name || ref.combineName,
                                ref_type: ref.ref_type || pipEnums.REFERENCE_TYPE.GOAL,
                                ref_sub_type: ref.ref_sub_type,
                                parent: ref.parent || true,
                                overRecord: ref.overRecord,
                                subRecord: ref.subRecord
                            }
                        );
                    } else {
                        refsArray.push({
                                ref_id: null,
                                ref_name: ref,
                                ref_type: pipEnums.REFERENCE_TYPE.GOAL,
                                parent: true
                            }
                        );
                    }
                });
                return refsArray;
            };

            // get collection with new goal name
            function addNewEntity(entityCollection, params, saveFunction, transaction, errorCallback) {
                var newEntityCollection = [];

                _.each(params.item.refs, function(ref) {
                    if (!ref.ref_id) {
                        var queryLowercase = ref.ref_name ? ref.ref_name.toLowerCase() : '';
                        var parentGoal = _.find(entityCollection, function (parent) {
                            var title =  parent.type == pipEnums.AREA_TYPE.CATEGORY ? pipTranslate.translate(parent.title) : parent.title;
                            if (title) {
                                return (title.toLowerCase() == queryLowercase);
                            } else return false;

                        });
                        if (!parentGoal) {
                            newEntityCollection.push(ref);
                        }
                    }
                });
                if (newEntityCollection.length == 0 ) {
                    saveFunction([]);
                } else {
                    params.skipTransactionBegin = true;
                    params.skipTransactionEnd = false;
                    async.parallel([
                        function(callback) {
                            createGoalsMultiplicity(newEntityCollection, params, transaction,
                                function(addedGoalsCollection){
                                    callback(null, addedGoalsCollection);
                                }
                            );
                        },
                        function(callback) {
                            createAreasMultiplicity(newEntityCollection, params, transaction,
                                function(addedAreasCollection){
                                    callback(null, addedAreasCollection);
                                }
                            );
                        },
                        function(callback) {
                            createVisions(newEntityCollection, params, transaction,
                                function(addedVisionsCollection){
                                    callback(null, addedVisionsCollection);
                                }
                            );
                        }
                    ], function (err, results) {
                        // Дождались всех данных и приступаем к обработке
                        if (err) {
                            // if (transaction) transaction.end(err);
                            if (errorCallback) errorCallback(err)
                        } else {
                            var addedEntityCollection = _.union(results[0], results[1], results[2]);
                            // if (transaction) transaction.end();
                            saveFunction(addedEntityCollection);
                        }
                    });
                }
            };

            function addParentEvent(item, source) {
                var ref = {
                    ref_type: pipEnums.REFERENCE_TYPE.EVENT,
                    ref_id: source.id,
                    ref_name: source.title,
                    parent: false
                };

                item.refs.push(ref);
            };

            function setRefs(item, parents) {
                if (!item || !item.refs) return [];
                var tempRefs = [];
                item.refs.forEach(function(ref) {
                    var item = _.find(parents, {id: ref.ref_id});
                    if (item) {
                        var newRef = _.cloneDeep(item);
                        newRef.listTitle = item.listTitle || item.title;
                        newRef.ref_name = item.title;
                        newRef.ref_id = item.id;
                        newRef.ref_sub_type = ref.ref_sub_type;

                        tempRefs.push(newRef);
                    }
                });

                return tempRefs;
            }

            function setUndefinedRefs(item, parents) {
                if (!item || !item.refs) return [];
                var unavailableRefs = [];
                item.refs.forEach(function(ref) {
                    var item = _.find(parents, {id: ref.ref_id});
                    if (!item) {
                        unavailableRefs.push(ref);
                    }
                });

                return unavailableRefs;
            }

            return {

                createGoals: createGoals,
                createAreas: createAreas,
                createVisions: createVisions,

                updateRefs: updateRefs,
                setRefs: setRefs,
                setUndefinedRefs: setUndefinedRefs,
                clearRefs: clearRefs,
                getParentIds: getParentIds,

                getVisionName: getVisionName,

                saveRefs: saveRefs,
                clearEntity: clearEntity,

                addNewEntity: addNewEntity,
                addParentEvent: addParentEvent

            }
        }]
    );

})();
