/**
 * @file Contacts data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipContactsData', ['pipRest', 'pipDataModel']);
    
    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var contactsApi = {
                contacts: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/contacts/:id');
                },

                getOwnContacts: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/contacts/own');
                }
         };
        
         pipRestProvider.addApi(contactsApi);
    }]);

    thisModule.provider('pipContactsData', function () {

        this.$get = ['$stateParams', '$rootScope', '$state', 'pipDataSession', 'pipDataModel', 'pipConnectionsCache', function($stateParams, $rootScope, $state, pipDataSession, pipDataModel, pipConnectionsCache) {
            return {
                partyId: pipDataSession.partyId(),
                
                 readContacts: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.resource = 'getOwnContacts';
                    params.item = params.item || { party_id: pipDataSession.partyId() };
                    params.item.session_id = pipDataSession.sessionId();

                    return pipDataModel.readOne(params, successCallback, errorCallback);
                },

                createContacts: function (params, successCallback, errorCallback) {
                    params.resource = 'contacts';
                    pipDataModel.create(params, successCallback, errorCallback);
                },

                updateContact: function (params, successCallback, errorCallback) {
                    params.resource = 'contacts';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.update(params, successCallback, errorCallback);
                }        

            }
        }];
    });

})();
