/**
 * @file Ripple control
 * @copyright Digital Living Software Corp. 2014-2016
 * - add id for ripple element. this id can be used in the event for identifical rippled element
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipRippleButton", []);

    thisModule.directive('pipRippleButton',
        ['$timeout', '$interval', 'pipUtils', '$rootScope', function($timeout, $interval, pipUtils, $rootScope) {
            return {
                restrict: 'C',
                link: function(scope, element, attrs) {
                    var periodDuration = attrs.pipPeriodDuration || 0,
                        rippleStop,
                        offset = attrs.pipRippleOffset || null,
                        colorClass = attrs.pipRippleColorClass || 'color-accent-bg',
                        dellay = attrs.pipRippleDellay || 5000,
                        rippleByEvent = attrs.pipRippleEvent || null,
                        intervalStop;

                    if (rippleByEvent) {
                        $rootScope.$on(rippleByEvent, showRipple);
                    } else if (periodDuration ) {
                        intervalStop = $interval(showRipple, periodDuration);
                    } else{
                        $timeout(showRipple, dellay);
                    }

                    if (rippleByEvent) {
                        $rootScope.$on(rippleByEvent, showRipple);
                    }

                    return ;

                    function showRipple() {
                        if (!element[0]) return;

                        // stop periodical ripple
                        if (periodDuration) {
                            rippleStop = pipUtils.toBoolean(attrs.pipRippleStop);

                            if (rippleStop === true && intervalStop) {
                                $interval.cancel(intervalStop);

                                return;
                            }
                        }

                        var $div = angular.element('<div></div>'),
                            xPos = (element[0].offsetHeight - 70)/2, // 5 - start size
                            yPos = (element[0].offsetWidth - 70)/2;

                        $div.addClass('ripple-effect');
                        var $ripple = angular.element(".ripple-effect"),
                            size = element[0].offsetHeight > element[0].offsetWidth ? element[0].offsetHeight : element[0].offsetWidth ;

                        $ripple.css("height", size + 'px');
                        $ripple.css("width", size + 'px');

                        $div.addClass(colorClass);
                        $div.css({
                            top: xPos,
                            left: yPos
                        })
                            .appendTo(element[0]);

                        $timeout(function() {
                            $div.remove();
                        }, 900);
                    }
                }
            }
        }]
    );

})();