/**
 * @file Partners data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipPartnersData', ['pipRest', 'pipDataModel', 'pipConnectionsCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var partnersApi = {
                connections: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/connections/:id');
                },

                partners: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/partners/:id');
                },

                acceptPartner: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/partners/:id/accept');
                }
         };
        
         pipRestProvider.addApi(partnersApi);
    }]);
    
    thisModule.provider('pipPartnersData', function () {

        // // Read all partner connections
        // this.readPartnersResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipRest, pipConnectionsCache) {
        //         return pipConnectionsCache.readPartners({
        //             item: { party_id: pipDataSession.partyId() }
        //         });
        //     };
        // };

        // // Read specific partner connection
        // this.readPartnerResolver = function () {
        //     return  /* @ngInject */ function ($stateParams, pipRest, pipConnectionsCache) {
        //         var params = {
        //             party_id: pipDataSession.partyId(),
        //             id: $stateParams.id || $stateParams.connection_id
        //         };

        //         return pipRest.partners().get(
        //             params,
        //             pipConnectionsCache.onConnectionUpdate(params)
        //         ).$promise;
        //     };
        // };

        this.$get = ['$stateParams', '$rootScope', '$state', 'pipDataModel', 'pipConnectionsCache', 'pipDataSession', function($stateParams, $rootScope, $state, pipDataModel, pipConnectionsCache, pipDataSession) {
            return {
                partyId: pipDataSession.partyId(),

                readPartners: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.item = params.item || { party_id: pipDataSession.partyId() };
                    return pipConnectionsCache.readPartners(params, successCallback, errorCallback);
                },
                
                readPartner: function (params, successCallback, errorCallback) {
                    params.resource = 'connections';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.id = params.item.id || $stateParams.id;
                    return pipDataModel.readOne(
                        params,
                        pipConnectionsCache.onConnectionUpdate(params, successCallback),
                        errorCallback
                    );
                },
                
                createPartner: function (params, successCallback, errorCallback) {
                    params.resource = 'partners';
                    params.item = params.item || {};
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.create(
                        params,
                        pipConnectionsCache.onConnectionCreate(params, successCallback),
                        errorCallback
                    );
                },

                acceptPartner: function (params, successCallback, errorCallback) {
                    params.resource = 'acceptPartner';
                    pipDataModel.update(
                        params,
                        pipConnectionsCache.onConnectionUpdate(params, successCallback),
                        errorCallback
                    );
                }

            }
        }];
        this.$get.$inject = ['$stateParams', '$rootScope', '$state', 'pipDataModel', 'pipConnectionsCache', 'pipDataSession'];
    });

})();
