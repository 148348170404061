/**
 * @file Goals data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipGoalsData', ['pipRest', 'pipDataModel', 'pipGoalsCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var goalsApi = {
            goals: function (url) {
                return this.createPartyCollection(url, '/api/parties/:party_id/goals/:id');
            },

            acceptGoal: function (url) {
                return this.createPartyCollection(url, '/api/parties/:party_id/goals/:id/accept');
            },

            rejectGoal: function (url) {
                return this.createPartyCollection(url, '/api/parties/:party_id/goals/:id/reject');
            },

            goalComments: function (url) {
                return this.createCollection(url, '/api/parties/:party_id/goals/:parent_id/comments/:id',
                    {
                        party_id: '@party_id',
                        id: '@id',
                        parent_id: '@parent_id'
                    }
                );
            }            
        };

        pipRestProvider.addApi(goalsApi);
    }]);

    thisModule.provider('pipGoalsData', function () {
        var DEFAULT_TAKE = 20;
        var BUFFER_SIZE = 20;
        var MIN_BUFFER_SIZE = 1;

        function getTasksStatus(status, pipEnums) {
            var arrStatus = {
                0: pipEnums.EXECUTION_STATUS.NEW + ','
                + pipEnums.EXECUTION_STATUS.IN_PROGRESS + ','
                + pipEnums.EXECUTION_STATUS.ON_HOLD + ',' + pipEnums.EXECUTION_STATUS.VERIFYING,
                1: pipEnums.EXECUTION_STATUS.NEW,
                2: pipEnums.EXECUTION_STATUS.ON_HOLD,
                3: pipEnums.EXECUTION_STATUS.IN_PROGRESS,
                4: pipEnums.EXECUTION_STATUS.CANCELED + ',' + pipEnums.EXECUTION_STATUS.COMPLETED,
                5: null
            };

            if (status == null || status == undefined || !arrStatus[status]) return arrStatus[0];
            else return arrStatus[status];
        };

        function getTasksResponsible(responsible) {
            var arrResponsible = {
                0: null,
                1: 'responsible,accountable',
                2: 'undefined',
                3: 'consulted,informed'
            };

            if (responsible == null || responsible == undefined) return arrResponsible[1];
            else return arrResponsible[responsible];
        };

        function getTasksSort(sort) {
            var arrSort = {0: 'refs.ref_name', 1: 'title', 2: '-importance -urgency -created title', 3: '-created'};

            if (sort == null || sort == undefined || !arrSort[sort]) return arrSort[3];
            else return arrSort[sort];
        };

        // this.readTasksResolver = function (pipEnums) {
        //     return /* @ngInject */ function ($stateParams, pipEnums, pipRest) {
        //         var take = $stateParams.startTake ?
        //         Number($stateParams.startTake) + Number(BUFFER_SIZE) :
        //         Number(DEFAULT_TAKE) + Number(BUFFER_SIZE);

        //         return pipRest.goals().get({
        //             party_id: pipDataSession.partyId(),
        //             type: pipEnums.GOAL_TYPE.TASK,
        //             search: $stateParams.search,
        //             status: getTasksStatus($stateParams.status, pipEnums),
        //             role: getTasksResponsible($stateParams.role),
        //             sort: getTasksSort($stateParams.sort),// ? $stateParams.sort : '-created',
        //             skip: 0,
        //             take: take,
        //             paging: 1
        //         }).$promise;
        //     };
        // };

        // this.readTaskResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipRest, pipDataModel) {
        //         var id = $stateParams.task_id ? $stateParams.task_id : $stateParams.id;
        //         return pipDataModel.readOne({
        //             resource: 'goals',
        //             item: {
        //                 party_id: pipDataSession.partyId(),
        //                 type: 'task', id: id
        //             }
        //         }).$promise;
        //     };
        // };

        // this.readGoalsResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipRest, pipGoalsCache) {
        //         return pipGoalsCache.readGoals({
        //             item: {party_id: pipDataSession.partyId()}
        //         });
        //     };
        // };

        // // todo dublicate with areas_data
        // this.readEntityResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipEnums, pipDataModel, pipRest) {
        //         if (!$stateParams.ref_id || !$stateParams.ref_type) return null;

        //         // huk for actions
        //         var resourceName = $stateParams.ref_type == pipEnums.GOAL_TYPE.TASK
        //             ? pipEnums.REFERENCE_TYPE.GOAL : $stateParams.ref_type;

        //         var params = {};
        //         params.resource = resourceName + 's';
        //         params.item = {};
        //         // for messages: $stateParams.managed_party_id
        //         params.item.party_id = $stateParams.managed_party_id || pipDataSession.partyId();
        //         ;
        //         params.item.id = $stateParams.ref_id;

        //         return pipDataModel.readOne(params).$promise;
        //     };
        // };

        // this.readGoalResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipEnums, pipRest, pipGoalsCache) {
        //         if (!$stateParams.id) return null;

        //         var params = {
        //             party_id: pipDataSession.partyId(),
        //             id: $stateParams.id
        //         };
        //         return pipRest.goals().get(
        //             params,
        //             pipGoalsCache.onGoalUpdate(params)
        //         ).$promise;
        //     };
        // };

        this.$get = ['$stateParams', 'pipEnums', 'pipDataModel', 'pipGoalsCache', 'pipDataSession', function ($stateParams, pipEnums, pipDataModel, pipGoalsCache, pipDataSession) {
            return {
                partyId: pipDataSession.partyId(),

                readGoals: function (params, successCallback, errorCallback) {
                    params.resource = 'goals';
                    params.party_id = pipDataSession.partyId();
                    params.type = $stateParams.type;
                    params.parent_id = $stateParams.parent_id;
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();

                    return pipGoalsCache.readGoals(params, successCallback, errorCallback);
                },

                readEntity: function (params, successCallback, errorCallback) {
                    params.item = params.item ? params.item : {};
                    var ref_id, ref_type;

                    ref_id = $stateParams.ref_id ? $stateParams.ref_id : params.ref_id;
                    ref_type = $stateParams.ref_type ? $stateParams.ref_type : params.ref_type;

                    if (!ref_id || !ref_type) {
                        if (successCallback) successCallback(null);

                        return null;
                    }

                    // huk for actions
                    var resourceName = ref_type == pipEnums.GOAL_TYPE.TASK
                        ? pipEnums.REFERENCE_TYPE.GOAL : ref_type;

                    params.resource = resourceName + 's';

                    // for messages: $stateParams.managed_party_id
                    params.item.party_id = params.item.party_id ? params.item.party_id : $stateParams.managed_party_id || pipDataSession.partyId();
                    params.item.id = ref_id;

                    return pipDataModel.readOne(params, successCallback, errorCallback);
                },

                readTasks: function (params, successCallback, errorCallback) {
                    params.resource = 'goals';
                    params.item = params.item || { };
                    params.item.party_id = pipDataSession.partyId();
                    params.item.type = pipEnums.GOAL_TYPE.TASK;
 
                    return pipGoalsCache.readTasks(params, successCallback, errorCallback);
                },

                readTasksPaging: function (params, successCallback, errorCallback) {
                    var take = $stateParams.startTake ?
                    Number($stateParams.startTake) + Number(BUFFER_SIZE) :
                    Number(DEFAULT_TAKE) + Number(BUFFER_SIZE);

                    params.resource = 'goals';

                    params.item = params.item || {};
                    params.item.party_id = params.item.party_id ? params.item.party_id : pipDataSession.partyId();
                    params.item.type = pipEnums.GOAL_TYPE.TASK;
                    params.item.status = params.item.status !== undefined && params.item.status !== null ? getTasksStatus(params.item.status, pipEnums) : getTasksStatus($stateParams.status, pipEnums);
                    params.item.role = params.item.role !== undefined && params.item.role !== null ? getTasksResponsible(params.item.role) : getTasksResponsible($stateParams.role);
                    params.item.sort = params.item.sort !== undefined && params.item.sort !== null ? getTasksSort(params.item.sort) : getTasksSort($stateParams.sort);
                    params.item.search = params.item.search ? params.item.search : $stateParams.search;
                    params.item.skip = params.item.skip ? params.item.skip : 0;
                    params.item.take = params.item.take ? params.item.take : take;
                    // params.item.paging = params.item.paging ? params.item.paging : 1;
                    params.item.paging = 0;
      
                    return pipGoalsCache.readTasks(params, successCallback, errorCallback);
                },

                getGoalsPage: function (params, successCallback, errorCallback) {
     
                    params.resource = 'goals';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.page(params, successCallback, errorCallback);
                },

                // todo может обойтись только 1 функцией?
                getTasksPage: function (params, successCallback, errorCallback) {
    
                    params.resource = 'goals';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.status = getTasksStatus(params.item.status || 5, pipEnums);
                    params.item.role = getTasksResponsible(params.item.role || 0);
                    params.item.sort = getTasksSort(params.item.sort || 0);
                    // todo pagging not worked on backend
                    params.item.paging = 0;
                    return pipGoalsCache.readTasks(params, successCallback, errorCallback);
                },

                readGoal: function (params, successCallback, errorCallback) {
                    params.resource = 'goals';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.id = params.item.id || $stateParams.id;
                    return pipDataModel.readOne(
                        params,
                        pipGoalsCache.onGoalUpdate(params, successCallback),
                        errorCallback
                    );
                },

                readTask: function (params, successCallback, errorCallback) {
                    params = params || {};
                    params.item = params.item || {};
                    return pipDataModel.readOne({
                        resource: 'goals',
                        item: {
                            party_id: params.item.party_id || pipDataSession.partyId(),
                            type: pipEnums.GOAL_TYPE.TASK,
                            id: params.item.id || $stateParams.task_id
                        }
                    }, successCallback, errorCallback);
                },

                createGoal: function (params, successCallback, errorCallback) {
                    params.resource = 'goals';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.create(
                        params,
                        pipGoalsCache.onGoalCreate(params, successCallback),
                        errorCallback
                    );
                },

                createGoalWithFiles: function (params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(params, function () {
                        params.resource = 'goals';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;
                        params.item.party_id = pipDataSession.partyId();
                        pipDataModel.create(
                            params,
                            pipGoalsCache.onGoalCreate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                updateGoal: function (params, successCallback, errorCallback) {
                    params.resource = 'goals';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.update(
                        params,
                        pipGoalsCache.onGoalUpdate(params, successCallback),
                        errorCallback
                    );
                },

                updateGoalWithFiles: function (params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(params, function () {
                        params.resource = 'goals';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;
                        params.item.party_id = pipDataSession.partyId();
                        pipDataModel.update(
                            params,
                            pipGoalsCache.onGoalUpdate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                deleteGoal: function (params, successCallback, errorCallback) {
                    params.resource = 'goals';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.remove(
                        params,
                        pipGoalsCache.onGoalDelete(params, successCallback),
                        errorCallback
                    );
                },

                acceptGoal: function (params, successCallback, errorCallback) {
                    params.resource = 'acceptGoal';
                    pipDataModel.create(
                        params,
                        pipGoalsCache.onGoalUpdate(params, successCallback),
                        errorCallback
                    );
                },

                rejectGoal: function (params, successCallback, errorCallback) {
                    params.resource = 'rejectGoal';
                    pipDataModel.create(
                        params,
                        pipGoalsCache.onGoalDelete(params, successCallback),
                        errorCallback
                    );
                },

                addComment: function(params, successCallback, errorCallback) {
                    params.resource = 'goalComments';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.create(params, successCallback, errorCallback);
                },

                deleteComment: function(params, successCallback, errorCallback) {
                    params.resource = 'goalComments';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.remove(params, successCallback, errorCallback);
                },

                editComment: function(params, successCallback, errorCallback) {
                    params.resource = 'goalComments';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.update(params, successCallback, errorCallback);
                },

                getBufferSize: function () {
                    return BUFFER_SIZE;
                },

                getDefaultTake: function () {
                    return DEFAULT_TAKE;
                },

                getMinBufferSize: function () {
                    return MIN_BUFFER_SIZE;
                }
            };
        }];
        this.$get.$inject = ['$stateParams', 'pipEnums', 'pipDataModel', 'pipGoalsCache', 'pipDataSession'];
    });

})();