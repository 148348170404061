/**
 * @file Party select control
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo
 * - Improve samples in sampler app
 * - Replace dropdown with md-select
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipPartySelect", ['pipAvatar', 'pipDropdown']);

    thisModule.directive('pipPartySelect',
        function () {
            return {
                restrict: 'EA',
                scope: {
                    ngDisabled: '&',
                    pipChange: '&',
                    pipPartyId: '=',
                    pipPartyName: '=',
                    pipPartyOptions: '&'
                },
                templateUrl: 'parties/party_select.html',
                controller: 'pipPartySelectController' 
            };
        }
    );

    thisModule.controller('pipPartySelectController',
        ['$scope', '$element', '$attrs', 'pipUtils', function ($scope, $element, $attrs, pipUtils) {
            var
                $control = $element.children('.pip-dropdown-control');
            $scope.partyOptions = $scope.pipPartyOptions();

            if ((!$scope.partyOptions || $scope.partyOptions.length == 0) && $scope.pipPartyId && $scope.pipPartyName) {
                $scope.partyOptions = [{
                    party_id: $scope.pipPartyId,
                    party_name: $scope.pipPartyName
                }];
                $scope.selectedOption = 0;
                $scope.hideSelectIcon = true;
            } else {
                $scope.selectedOption = _.findIndex($scope.partyOptions, {party_id: $scope.pipPartyId});

                $scope.selectedOption = $scope.selectedOption == -1 ? 0 : $scope.selectedOption;
                $scope.hideSelectIcon = pipUtils.toBoolean($scope.ngDisabled()) || (!$scope.partyOptions || $scope.partyOptions.length < 2)
            }

            $scope.onOptionSelect = onOptionSelect;
            $scope.onKeyDown = onKeyDown;

            // Add class
            $element.addClass('pip-party-select');

            return ;

            function onOptionSelect(event) {

                if ($scope.selectedOption === undefined || $scope.selectedOption < 0
                    || $scope.selectedOption > $scope.partyOptions.length) return;

                $scope.pipPartyId = $scope.partyOptions[$scope.selectedOption].party_id;
                $scope.pipPartyName = $scope.partyOptions[$scope.selectedOption].party_name;

                // Raise ng-change event
                if ($scope.pipChange) {
                    event = event ? event : {};
                    event.partyId = $scope.pipPartyId;
                    event.partyName = $scope.pipPartyName;
                    $scope.pipChange({ $event: event });
                }
            };

            function onKeyDown($event, option) {
                if ($event.keyCode == 13 || $event.keyCode == 32) {
                    setTimeout(function() {
                        $control.trigger('click');
                        if ($event) {
                            $event.stopPropagation();
                            $event.preventDefault();
                        }
                    }, 0);
                }
            };

        }]
    );

})();
