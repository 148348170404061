/**
 * @file Posts data cache
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipPostsCache', ['pipDataCache', 'pipCacheTag']);

    thisModule.service('pipPostsCache',
        ['pipEnums', 'pipDataCache', 'pipCacheTag', function(pipEnums, pipDataCache, pipCacheTag) {

            return {
                readPosts: readPosts,
                readFeeds: readFeeds,
                onPostCreate: onPostCreate,
                onPostUpdate: onPostUpdate,
                onPostDelete: onPostDelete
            };
            //-------------

            function readPosts(params, successCallback, errorCallback) {
                params.resource = params.resource || 'posts';
                params.item = params.item || {};

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function readFeeds(params, successCallback, errorCallback) {
                params.resource = 'feeds';
                params.item = params.item || {};

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function onPostCreate(params, successCallback) {
                
                return pipDataCache.addDecorator(
                    'posts', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onPostUpdate(params, successCallback) {
                return pipDataCache.updateDecorator(
                    'posts', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onPostDelete(params, successCallback) {
                return pipDataCache.removeDecorator('notes', params, successCallback);
            };

        }]
    );

})();

