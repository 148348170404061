<md-dialog class="pip-dialog layout-column" width="500" min-height="500" md-theme="{{theme}}">
    <md-progress-linear ng-show="transaction.busy()"  md-mode="indeterminate" >
    </md-progress-linear>
    <div class="pip-header layout-column">
        <h3 class="w-stretch">{{::'CHOOSE_TITLES_TITLE' | translate}}</h3>
    </div>
    <div class="pip-body lp12" >
        <div class="layout-row  layout-align-start-center bm16">
            <md-button class="rm8 md-icon-button" ><span class="icon-people"></span></md-button>
            <div class="flex">
                <p class="m0 text-primary">{{::'CHOOSE_TITLES_PARTNERS_TITLE' | translate}}</p>
                <p class="m0 text-small text-grey">{{::'CHOOSE_TITLES_PARTNERS_CONTENT' | translate}} </p>
            </div>
        </div>

        <div class="layout-row layout-align-start-center bm16">
            <md-button class="rm8 md-icon-button" ><span class="text-grey icon-folder"></span></md-button>
            <div class="flex">
                <p class="m0 text-primary">{{::'CHOOSE_TITLES_NOTES_TITLE' | translate}}</p>
                <p class="m0 text-small text-grey">{{::'CHOOSE_TITLES_NOTES_CONTENT' | translate}} </p>
            </div>
        </div>

        <div class="layout-row layout-align-start-center bm16">
            <md-button class="rm8 md-icon-button" ><span class=" icon-news"></span></md-button>
            <div class="flex">
                <p class="m0 text-primary">{{::'CHOOSE_TITLES_POSTS_TITLE' | translate}}</p>
                <p class="m0 text-small text-grey">{{::'CHOOSE_TITLES_POSTS_CONTENT' | translate}} </p>
            </div>
        </div>

        <div class="layout-row layout-align-start-center bm16">
            <md-button class="rm8 md-icon-button" ><span class=" icon-task"></span></md-button>
            <div class="flex">
                <p class="m0 text-primary">{{::'CHOOSE_TITLES_EVENTS_TITLE' | translate}}</p>
                <p class="m0 text-small text-grey">{{::'CHOOSE_TITLES_EVENTS_CONTENT' | translate}} </p>
            </div>
        </div>

        <div class="layout-row layout-align-start-center bm8">
            <md-button class="rm8 md-icon-button" ><span class="icon-message"></span></md-button>
            <div class="flex">
                <p class="m0 text-primary">{{::'CHOOSE_TITLES_MESSAGES_TITLE' | translate}}</p>
                <p class="m0 text-small text-grey">{{::'CHOOSE_TITLES_MESSAGES_CONTENT' | translate}} </p>
            </div>
        </div>
        <div class="layout-row layout-align-start-center lp12 bm16">
            <md-button class="rm8 md-raised" >{{::'MORE' | translate}}</md-button>
            <md-button class="rm8 md-raised" >{{::'LESS' | translate}}</md-button>
        </div>

        <div class="layout-row layout-align-start-center bm16">
            <md-button class="rm8 md-icon-button" ><span class="icon-film"></span></md-button>
            <div class="flex">
                <p class="m0 text-primary">{{::'CHOOSE_TITLES_TIMELINE_TITLE' | translate}}</p>
                <p class="m0 text-small text-grey">{{::'CHOOSE_TITLES_TIMELINE_CONTENT' | translate}} </p>
            </div>
        </div>
    </div>

</md-dialog>
