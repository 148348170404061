/**
 * @file Filter to format priorities
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipPriorityFilters', ['pipPriorityFormat']);

    thisModule.filter('formatPriority',
        ['pipPriorityFormat', function (pipPriorityFormat) {
            return function(value, showImportance, showUrgency) {
                return pipPriorityFormat.formatEntityPriority(value, showImportance, showUrgency);
            };
        }]
    );


})();
