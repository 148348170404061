/**
 * @file Groups data cache
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipGroupsCache', ['pipDataCache']);

    thisModule.service('pipGroupsCache',
        ['pipDataCache', function(pipDataCache) {

            return {
                readGroups: readGroups,
                onGroupCreate: onGroupCreate,
                onGroupUpdate: onGroupUpdate,
                onGroupDelete: onGroupDelete
            };
            //-------------

            function readGroups(params, successCallback, errorCallback) {
                params.resource = 'groups';

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function onGroupCreate(params, successCallback) {
                return pipDataCache.addDecorator('groups', params, successCallback);
            };

            function onGroupUpdate(params, successCallback) {
                return pipDataCache.updateDecorator('groups', params, successCallback);
            };

            function onGroupDelete(params, successCallback) {
                return pipDataCache.removeDecorator('groups', params, successCallback);
            };

        }]
    );

})();

