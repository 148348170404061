/**
 * @file Participant combined list control
 * @description
 * It is used somewhere in messages ?
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipParticipantsListCombined", ['pipCore']);

    thisModule.directive('pipParticipantListCombined',
        function () {
            return {
                restrict: 'EA',
                scope: true,
                templateUrl: 'participants/participants_list_combined.html',
                controller: 'pipParticipantListCombinedController'
            }
        }
    );

    thisModule.controller('pipParticipantListCombinedController',
        ['$scope', '$element', '$attrs', '$parse', 'pipUtils', function ($scope, $element, $attrs, $parse, pipUtils) {
            var partiesGetter = $parse($attrs.pipParties);

            $scope.limit = [];
            $scope.more = 0;
            $scope.moreString = "";

            setParties();
            resize();
            $element.addClass('pip-participant-list-combined');

            $scope.getElementDimensions = getElementDimensions;

            $scope.$on('pipWindowResized', onResize);

            // Also optimization to avoid watch if it is unnecessary
            if (pipUtils.toBoolean($attrs.pipRebind)) {
                $scope.$watch(partiesGetter, function (newValue) {
                    setParties();
                    resize();
                });

                $scope.$watch($scope.getElementDimensions, function (newValue, oldValue) {
                    if (newValue && oldValue && oldValue.h == newValue.h && oldValue.w == newValue.w) return;
                    if ($scope.isChip) return;
                    resize();
                }, true);
            }

            return;

            function onResize() {
                if ($scope.isChip) return;
                resize();
                $scope.$apply();
            }

            // Set parties
            function setParties() {
                $scope.parties = partiesGetter($scope);
                $scope.isChip = $scope.parties.length == 1;
                $scope.data = {party_id: null, party_name: ''};
                if ($scope.isChip) {
                    $scope.data.party_id = $scope.parties[0].party_id;
                    $scope.data.party_name = $scope.parties[0].party_name;
                }
            };

            // Process resizing event
            function resize() {
                if ($scope.isChip) return;
                var
                    width = $element.width(),
                    maxCount = Math.floor((width + 8) / 48);

                if ($scope.parties == null || $scope.parties.length <= maxCount) {
                    $scope.limit = $scope.parties;
                    $scope.more = 0;
                    $scope.moreString = "";
                    return;
                }

                $scope.limit = _.take($scope.parties, maxCount - 1);
                $scope.more = $scope.parties.length - $scope.limit.length;

                if ($scope.more < 1000) {
                    $scope.moreString = "+ " + $scope.more;
                } else if ($scope.more < 1000000) {
                    $scope.moreString = "+ " + Math.floor($scope.more / 1000) + 'K';
                } else if ($scope.more < 1000000000) {
                    $scope.moreString = "+ " + Math.floor($scope.more / 1000000) + 'M';
                } else {
                    $scope.moreString = "+ ...";
                }
            };

            function getElementDimensions() {
                var dimension = {
                    'h': $element.height(),
                    'w': $element.width()
                };
                return dimension;
            };

        }]
    );

})();


