/**
 * @file PipLife Areas pages
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipAreasUtils', ['pipUtils']);

    thisModule.factory('AreasUtilsService', 
        ['pipAreasData', 'pipGoalsData', '$rootScope', 'pipCollections', 'NewsUAService', 'pipEnums', function(pipAreasData, pipGoalsData, $rootScope, pipCollections, NewsUAService, pipEnums) {

            var changeShowItems = function (itemIndex, itemCollection, onlyOpen) {
                if (itemCollection[itemIndex].sub_areas_count < 1) return;
                itemCollection[itemIndex].showAreas = itemCollection[itemIndex].showAreas ? (onlyOpen ? true : false) : true;
                var currentLevel = itemCollection[itemIndex].level;
                var i = itemIndex + 1;

                while (itemCollection[i].level > currentLevel) {
                    if (itemCollection[itemIndex].showAreas == true) {
                        if (currentLevel + 1 == itemCollection[i].level) {
                            itemCollection[i].showCurrent = true;
                        }
                    } else {
                        itemCollection[i].showCurrent = false;
                        itemCollection[i].showAreas = false;
                    }
                    i++;
                    if (i == itemCollection.length) break;
                }
            };

            var createAreaTree = function (areas) {
                var areaTree = [{
                    title: 'UNDEFINED',
                    sub_areas: [],
                    id: 1
                }];

                // Initialize links
                _.each(areas, function (area) {
                    area.super_areas = [];
                    area.sub_areas = [];
                    area.showAreas = false;
                });

                _.each(areas, function (area) {
                    // link with super goals only for non-categories
                    if (area.type != 'category') {
                        _.each(area.refs, function (item) {
                            var superArea = _.find(areas, function (area) {
                                return (area.id == item.ref_id);// && (item.parent); //item.ref_type ???
                             });
                            if (superArea) {
                                area.super_areas.push(superArea);
                                superArea.sub_areas = superArea.sub_areas || [];
                                superArea.sub_areas.push(area);
                            }
                        });
                    }

                    if (area.super_areas.length == 0) {
                        if (area.type == 'category')
                            areaTree.push(area);
                        else {
                            areaTree[0].sub_areas.push(area);
                        }
                    }
                });

                return areaTree;
            };

            var createAreaList = function (areaTree, excludeArea) {
                var result = [],
                    index = 0;

                var processAreaList = function (areaSubtree, excludeArea, level, result) {
                    _.each(areaSubtree, function (area) {
                        if (excludeArea && area.id == excludeArea.id) {
                            return;
                        }
                        delete area.$$hashKey;
                        var object = _.defaults(_.clone(area), {
                            sub_areas_count: area.sub_areas.length,
                            listIndex: index,
                            level: level,
                            showAreas: false,
                            showCurrent: level == 0 ? true : false
                        });
                        result.push(object);
                        index++;

                        if (area.sub_areas.length > 0) {
                            processAreaList(area.sub_areas, excludeArea, level + 1, result);
                        }
                    });
                };

                processAreaList(areaTree, excludeArea, 0, result);

                return result;
            };

            var createAreaShiftList = function (areaList) {
                var result = [];
                _.each(areaList, function (area){
                    var item = _.cloneDeep(area);
                    if (item.id == 1) return; // если это добавленная нами в дерево глобальная  "UNDEFINED"
                    item.shiftName = item.title;
                    for (var i = 0; i < item.level; i++)
                        item.shiftName = '...' + item.shiftName;
                    result.push(item);
                });
                return result;
            };

            var getSubAreas = function (areas, superArea) {
                var result = [];
                _.each(areas, function (area) {
                    if (area.type != 'category') {
                        _.each(area.refs, function (ref) {
                            if (superArea.id == ref.ref_id)
                                result.push(area);
                        });
                    }
                });

                return result;
            };

            var getTasks = function (item, transaction, force, callback, errorCallback) {
                pipGoalsData.readTasks({
                    item: {
                        ref_id: item.id
                    },
                    transaction: transaction,
                    force: force === true ? true : false 
                }, 
                function (tasks) {
                    if (callback) callback(tasks);
                    return tasks;
                }, 
                errorCallback);
            };

            function isComplited(collection) {
                if (!collection || !collection.length) return true;
                for (var j = 0; j < collection.length; j ++) {
                    // if not close or not close one of child
                    if (collection[j].status != 'completed' && collection[j].status != 'canceled' || !isComplited(collection[j].sub_areas)) return false;
                }
                return true;
            };

            function isOneParent(child) {
                return child.super_areas.length == 1;
            };                       

            return {
                
                setComplited : function (collection) {
                    for (var i = 0; i< collection.length; i ++) {
                        collection[i].sub_areas_complited = isComplited(collection[i].sub_areas);
                    }
                },
                
                filterComplited: function (collection) {
                    var result = _.filter(collection, function(item){
                        return !((item.status == 'completed' || item.status == 'canceled') && item.sub_areas_complited)
                    });
                    return result;                     
                },
                
                prepareAreaListAcceptance: function (itemList) {
                    _.each(itemList, function (item) {
                        var i = _.findIndex(item.contribs, {party_id: $rootScope.$party.id});
                        if (i > -1) item.accept = item.contribs[i].accept == 'accepted' ? true : false;
                    });
                },

                getParentAreasMatches: function (searchText, collection, filter) {
                    if (!searchText || searchText == '') return collection;
                    else if (filter)
                        return _.filter(collection, filter);
                },

                changeShowItems: function (itemIndex, itemCollection, onlyOpen) {
                    changeShowItems(itemIndex, itemCollection, onlyOpen);
                },

                openItem: function (item, itemCollection) {
                    var element = _.find(itemCollection, function(i) {return i.id == item.id; });

                    if (element.level < 1) return;
                    else {
                        var currentLevel = element.level;
                        var i = itemCollection.indexOf(element);
                        while (itemCollection[i].level != 0) {
                            i--;
                            if (i < 0) return;
                            if (itemCollection[i].level < currentLevel) {
                                currentLevel--;
                                changeShowItems(i, itemCollection, true);
                            }
                        }
                    }
                },

                createAreaTree: function (areas) {
                    return createAreaTree(areas);
                },

                createAreaList: function (areaTree, excludeArea) {
                    return createAreaList(areaTree, excludeArea);
                },

                createAreaShiftList: function (areaList) {
                    return createAreaShiftList(areaList);
                },

                createBreakdownCollection: function (areaId, itemCollection, includes) {
                    if (!areaId) return;

                    var includeSuperArea = includes === undefined ? true : includes;

                    var itemTree = createAreaTree(itemCollection);
                    var itemList = createAreaList(itemTree, null);

                    var result = [];

                    var getSubareas = function (area, result) {
                        _.each(area.sub_areas, function (item) {
                            result.push(_.find(itemList, function(i) { return i.id == item.id; }));
                        })
                    };

                    if (includeSuperArea) result.push(_.find(itemList, function(i) { return i.id == areaId; }));
                    getSubareas(_.find(itemList, function(i) { return i.id == areaId; }), result);

                    return result;
                },

                prepareAreaShiftList: function (itemCollection) {
                    var areaTree = createAreaTree(itemCollection);
                    var areaList = createAreaList(areaTree, null);
                    return createAreaShiftList(areaList);
                },

                getSubAreas: function (areas, superArea) {
                    return getSubAreas(areas, superArea);
                },

                getAreaIcons: function () {
                    return {
                        goal : "icon-goal",
                        accomplishment : "icon-check-all",
                        area : "icon-area",
                        objective : "icon-goal",
                        dream : "icon-lamp",
                        category : "icon-category"
                    };
                },

                getTasks:function (item, transaction, force, callback, errorCallback) {
                    return getTasks(item, transaction, force, callback, errorCallback);
                },

                convertToArea: function (item, entity, ref_type) {
                    var exclusions = ['creator_id', 'creator_name', 'created', 'updated', 'closed', 'type', 'party_id',
                        'category', 'subcategory', 'sender_id', 'sender_name', 'sender_email', 'sent', 'deleted', 'rec_parties',
                        'rec_extern', '$promise', '$resolved', 'id'];

                    for (var prop in entity) {
                        if (typeof(entity[prop]) != 'function')
                            if (exclusions.indexOf(prop) < 0) {
                               switch (prop) {
                                   case 'title' : item.title = _.clone(entity[prop]); break;
                                   case 'content' : item.content = _.cloneDeep(entity[prop]); break;
                                   default: item[prop] = _.cloneDeep(entity[prop]); break;
                               }
                            }
                    }

                    return item;
                },

                resetSubAreasCounts: function (itemList) {
                    _.each(itemList, function (item) {
                        item.sub_areas_count = 0;
                        _.each(item.sub_areas, function (sub_area) {
                            var patency = _.findIndex(itemList, {id: sub_area.id});
                            if (patency > -1) {
                                item.sub_areas_count += 1;
                            }
                        });
                    });
                },

                getParentCategoryId: function (itemList, itemIndex) {
                    if (!itemList.length || itemList.length < 1) return null;
                    if (!itemIndex) return itemList[0].id;

                    while (itemList[itemIndex] && itemList[itemIndex].level && itemList[itemIndex].level != 0)
                        itemIndex--;

                    return itemList[itemIndex] ? itemList[itemIndex].id : null;
                },

                setItemCounts: function (item, successCallback, force, goals, errorCallback) {
                    var force = force === true ? true : false;

                    function calcActions(item) {
                        item.sub_goals = _.union(item.actions, getSubAreas(goals, item));
                        item.subAreasCompleteCount = _.filter(item.sub_areas, function(a) { return  a.status == 'completed'; }).length;
                        item.subGoalsCompleteCount = _.filter(item.sub_goals, function(g) { return g.status == 'completed'; }).length;
                    }
                    async.parallel([
                        function (callback) {
                            if (!force) callback(null, item);
                            else 
                                pipAreasData.readArea(
                                    {
                                        item: {id: item.id}
                                    }, 
                                    function(area) {
                                        if (!area)  callback({empty: true}, null); // return empty object
                                        else  callback(null, area);
                                    },
                                    function(error) {
                                                callback(error, null);
                                            }
                                );
                    },
                        function (callback) {
                        if(item.actions && !force)  callback(null, item.actions);
                        else 
                            getTasks(
                                item, 
                                null, // transction
                                force, 
                                function (actions) {
                                    callback(null, actions);
                                }, 
                                function(error) {
                                    callback(error, null);
                                });

                    }, function (callback) {
                        if(item.posts && !force) callback(null,  item.posts);
                        else // todo add force params??
                            NewsUAService.getPosts(
                                item, 
                                pipEnums.REFERENCE_TYPE.AREA, 
                                $rootScope.$party.id, 
                                function (posts) {
                                callback(null,  posts);
                            },  
                                function(error) {
                                    callback(error, null);
                                }, 
                                force
                                );
                    }], function (error, result) {
                        if (successCallback && !error && result) {
                            item = result[0];
                            item.actions = result[1];
                            item.posts = result[2];
                            item.postsCount = item.posts.length;
                            calcActions(item);
                            successCallback(item);
                        }
                        if (errorCallback && error) errorCallback(error);
                    });
                },

                filterAreaTree: function (col, filterItem) {
                    var result = [];
                    var resultIndexes = [];

                    function addItem(item) {
                        if (resultIndexes.indexOf(item.listIndex) > -1) return;

                        result.push(item);
                        resultIndexes.push(item.listIndex);
                    }

                    col.forEach(function (item) {
                        if (filterItem(item)) {
                            addItem(item);

                            return;
                        }

                        if (item.sub_areas && item.sub_areas.length > 0) {
                            for(var i = 0; i < item.sub_areas.length; i++) {
                                var child = _.find(col, {id: item.sub_areas[i].id});

                                if (child)
                                    if (filterItem(child)) {
                                        addItem(item);
                                        addItem(child);

                                        return;
                                    }
                            }
                        }
                    });

                    return result;
                },

                setParentResource: function (item, parentList) {
                    item.parent_sources = [];

                    if (item.super_areas && item.super_areas.length > 0) {
                        item.super_areas.forEach(function (area) {
                            item.parent_sources.push(area);
                        });
                    }

                    if (item.refs) {
                        for (var i = 0; i < item.refs.length; i++) {
                            if (item.refs[i].parent) {
                                var parent = _.find(parentList, {id: item.refs[i].ref_id});
                                if (parent != null) {
                                    item.parent_sources.push(parent);
                                }
                            }
                        }
                        item.parent_sources = _.uniqBy(item.parent_sources, 'id');
                    }
                },

                selectItemAfterRemoving: function (list, itemIndex) {
                    var removedItemLevel = list[itemIndex].level;
                    var removedItemId = list[itemIndex].id;
                    var index = _.clone(itemIndex);
                    index++;

                    while (index != list.length && list[index].level > removedItemLevel) {
                        if(!isOneParent(list[index])) {
                            list[index].showCurrent = false;
                        }
                        list[index].level = 0;
                        index++;
                    }

                    if (list.length == 1)
                    return itemIndex;

                    if (itemIndex > 0 && (itemIndex + 1 >= list.length ||
                        list[itemIndex + 1].level != removedItemLevel))
                        itemIndex--;
                    else
                        itemIndex++;

                    return itemIndex;
                }
                                
            }
        
        }]
    );

})();