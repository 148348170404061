/**
 * @file Visions data cache
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipMessagesCache', ['pipDataCache', 'pipCacheTag']);

    thisModule.service('pipMessagesCache',
        ['pipEnums', 'pipDataCache', 'pipCacheTag', 'pipDataSession', function(pipEnums, pipDataCache, pipCacheTag, pipDataSession) {

            return {
                readManagedMessages: readManagedMessages,
                onMessageCreate: onMessageCreate,
                onMessageUpdate: onMessageUpdate,
                onMessageDelete: onMessageDelete
            };

            //-------------

            function readManagedMessages(params, successCallback, errorCallback) {
                params.item = params.item || {};
                params.item.party_id = params.item.party_id ? params.item.party_id : pipDataSession.userId();

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            // todo: new message place into cache for send messages  
            function onMessageCreate(params, successCallback) {
                return pipDataCache.addDecorator(
                    'sentManagedMessages',
                    params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onMessageUpdate(params, cacheName, successCallback) {
                return pipDataCache.updateDecorator(
                    cacheName, 
                    params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };  

            function onMessageDelete(params, cacheName, successCallback) {
                return pipDataCache.removeDecorator(cacheName, params, successCallback);
            };


        }]
    );

})();