/**
 * @file Sharing details control
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global _, angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipParentChipsComplex",
        ['pipUtils', 'pipRest.Enums', 'pipTranslate', 'pipControls', 'pipImageUtils']);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            'BOTTOM_UP_PLACEHOLDER': '+Area or goal'
        });
        pipTranslateProvider.translations('ru', {
            'BOTTOM_UP_PLACEHOLDER': '+Область или цель'
        });
    }]);

    thisModule.directive('pipParentChipsComplex',
        function () {
            return {
                restrict: 'E',
                scope: {
                    ngModel: '=',
                    entity: '=pipEntity', // item with subgoal/subareas
                    field: '=pipParentField', // field with subgoal/subarea list
                    parents: '=pipParents',
                    options: '=pipOptions', // for example ['goal', 'area', 'task']
                    sortParents: '&pipSort',
                    disableParentsFilter: '=pipDisableParentsFilter',
                    filterParents: '&pipFilterParents', // filter for parents, by default filtered  ACCOMPLISHMENT, old goal and old task
                    searchFilter: '&pipSearchFilter', // search filter
                    placeholder: '=?pipPlaceholder',
                    partyId: '=pipPartyId', // $rootScope.$party.id
                    ngDisabled: '&',
                    saveRecord: '&pipSaveRecord',
                    setValidator: '&pipControlValidator'
                },
                templateUrl: 'parent_chips/parent_chips_complex.html',
                controller: 'pipParentChipsComplexController'
            };
        }
    );

    thisModule.controller('pipParentChipsComplexController',
        ['$scope', '$element', '$attrs', 'pipEnums', 'pipAssert', 'pipTranslate', 'AccessoryService', 'pipConversionDialog', 'pipParentConvertWizard', function ($scope, $element, $attrs,pipEnums, pipAssert, pipTranslate, AccessoryService,  pipConversionDialog, pipParentConvertWizard) {

            checkParameter();

            // $scope.convertedRecord = [
            //     {
            //         name: pipEnums.REFERENCE_TYPE.GOAL,
            //         title: pipEnums.REFERENCE_TYPE.GOAL.toUpperCase(),
            //         subtitle: 'CONVERT_TO_GOAL_SUBTITLE'
            //     },
            //     {
            //         name: pipEnums.REFERENCE_TYPE.AREA,
            //         title: pipEnums.REFERENCE_TYPE.AREA.toUpperCase(),
            //         subtitle: 'CONVERT_TO_AREA_SUBTITLE'
            //     },
            //     {
            //         name: pipEnums.REFERENCE_TYPE.VISION,
            //         title: pipEnums.REFERENCE_TYPE.VISION.toUpperCase(),
            //         subtitle: 'CONVERT_TO_VISION_SUBTITLE'
            //     },
            //     {
            //         name: pipEnums.GOAL_TYPE.TASK,
            //         title: pipEnums.GOAL_TYPE.TASK.toUpperCase(),
            //         subtitle: 'CONVERT_TO_TASK_SUBTITLE'
            //     }
            // ];

            $scope.$control = {
                validate: validateChips
            };
            if ($scope.setValidator) {
                $scope.setValidator({
                    $control: $scope.$control
                });
            }


            initData();

            $scope.getMatches = getMatches;
            $scope.onAppend = onAppend;

            $scope.$watch('ngModel', initData);

            // Add class
            $element.addClass('pip-buttom-up-autocompete');

            return;

            function validateChips() {
                return !!$scope.new.searchText;
            }

            function checkParameter() {
                var ERROR_TITLE = 'pipContributorDetailsController error: ';

                pipAssert.isArray($scope.parents, 'pipToasts.showNotification: parents should be an array');
                pipAssert.isDef($scope.ngModel, 'pipToasts.showMessage: ng-model should be defined');
                pipAssert.isDef($scope.partyId, 'pipToasts.showMessage: pip-party-id should be defined');
            };

            function initData() {
                if (!$scope.ngModel) return;

                $scope.new = {};

                $scope.new.searchText = '';

                // set plaseholder or default
                $scope.placeholder = $scope.placeholder ? $scope.placeholder : 'BOTTOM_UP_PLACEHOLDER';
                // set sort or default
                $scope.sort = ($scope.sortParents && (typeof $scope.sortParents === 'function')) ? $scope.sortParents : sortDefault;
                // set parents filter or default
                $scope.filter = ($scope.filterParents && (typeof $scope.filterParents === 'function')) ? $scope.filterParents : filterDefault;
                // set search parent filter
                $scope.searchFilter = ($scope.searchFilter && (typeof $scope.searchFilter === 'function')) ? $scope.searchFilter : filterSearch;

                $scope.isDeleteSubRecord = !!($scope.entity && $scope.field && Array.isArray($scope.entity[$scope.field]));
                // $scope.convertedCollection = getConvertedCollection($scope.options, $scope.convertedRecord);

                if (!$scope.disableParentsFilter) $scope.parensCollection = _.filter(_.filter($scope.parents, filterAccepted), $scope.filter);
                else $scope.parensCollection = _.filter($scope.parents, filterAccepted);

                // set icon
                _.each($scope.parensCollection, function(item) {
                    item.icon = item.ref_type != 'vision' ? item.ref_type : 'film';
                });

                // delete editing item from parent list
                if ($scope.entity && $scope.entity.id )
                    _.remove($scope.parensCollection, {id: $scope.entity.id});
            };

            //+
            function sortDefault(item) {
                return item.title;
            };

            // +-
            function filterDefault(item) {
                if (item.type && item.type == pipEnums.GOAL_TYPE.ACCOMPLISHMENT) return false;
                if (item.type && (item.type == pipEnums.GOAL_TYPE.GOAL || item.type == pipEnums.GOAL_TYPE.TASK)
                    && item.closed) {
                    // 604800000 - ms in week
                    var clsd = new Date(item.closed),
                        now = new Date(),
                        week = 7 * 24 * 60 * 60 * 1000;

                    if(clsd.getTime() + week < now.getTime()) return false;
                }

                return true;
            };

            // +
            function filterAccepted(item) {
                return !AccessoryService.isPending(item, $scope.partyId);
            };

            //+
            function filterSearch(collection, refs, searchText) {
                var queryLowercase = searchText.toLowerCase(),
                    title, parent;

                var goalArray = _.filter(collection, function (item) {
                        // present in refs
                        parent = _.find(refs, function(parent) {
                            return item.id == parent.id || item.id == parent.ref_id;
                        });
                        if (parent) return false;

                        if (item.title) {
                            title = item.type == pipEnums.AREA_TYPE.CATEGORY ? pipTranslate.translate(item.title) : item.title;
                            return (title.toLowerCase().indexOf(queryLowercase) >= 0);
                        } else return false;
                    }) || [];

                // формируем спискок для вывода
                _.each(goalArray, function(item) {
                    item.translateTitle = item.type == pipEnums.AREA_TYPE.CATEGORY ? pipTranslate.translate(item.title) : item.title;
                });
                return goalArray;
            };

            function removeSubItem(parent, result, field) {
                if (parent[field]) {
                    parent[field].forEach(function (item) {
                        _.remove(result, {id: item.id});
                        return removeSubItem(item, result, field);
                    });
                }
                return result;
            };

            //+
            // function getConvertedCollection(options, convertedRecord) {
            //     if (!(options && Array.isArray(options) && options.length > 0))
            //         return convertedRecord;
            //     else return _.filter(convertedRecord, function(item) {
            //         return options.indexOf(item.name) > -1;
            //     });
            // };

            //+
            function filterSelected(parents, refs) {
                var parent;
                return _.filter(parents, function (item) {
                        parent = _.find(refs, function(ref) {
                            return item.id == ref.id || item.id == ref.ref_id;
                        });
                        return parent == undefined;
                    }) || [];
            };

            //+
            function getMatches() {
                // исключаем выбранные
                if (!$scope.new.searchText)
                    return filterSelected($scope.parensCollection, $scope.ngModel);

                var result = filterSearch($scope.parensCollection, $scope.ngModel, $scope.new.searchText);
                if ($scope.isDeleteSubRecord) {
                    removeSubItem($scope.entity, result, $scope.field);
                }

                // sort
                result = _.sortBy(result, $scope.sort);
                return result;
            }

            function saveCallback(obj) {
                if (obj && obj.callback) {
                    $scope.new.searchText = '';
                    setTimeout(function () {
                        $scope.$apply();
                        obj.callback(obj.params);
                    }, 50);
                }
            }

            // +
            function onAppend($chip){
                if ($scope.saveRecord && (typeof $scope.saveRecord != 'function')) return;

                var obj;

                if ($scope.saveRecord &&  (typeof $scope.saveRecord === 'function')) {
                    obj = $scope.saveRecord();
                }

                if ($chip && $chip.id) {
                    saveCallback(obj);
                    return $chip;
                }
                var queryLowercase = $chip.toLowerCase();

                // search in parentsCollection, translate category
                var ref = _.find($scope.parensCollection, function(parent) {
                    var title =  parent.type == pipEnums.AREA_TYPE.CATEGORY ? pipTranslate.translate(parent.title) : parent.title;
                    if (title) {
                        return (title.toLowerCase() == queryLowercase);
                    } else return false;
                });
                // check is added
                if (ref) {
                    if (_.findIndex($scope.ngModel, function(item) { return ref.id == item.id || ref.id == item.ref_id; }) < 0) {
                        // add if not found
                        ref.translateTitle = ref.type == pipEnums.AREA_TYPE.CATEGORY ? pipTranslate.translate(ref.title) : ref.title;
                        $chip = ref;
                        $scope.ngModel.push($chip);
                    }
                    else $chip = null; // record present, remove $chip

                    saveCallback(obj);
                    return $chip;
                }

                // $chip not found into parentsCollection - create new record
                // check dublicate in added records
                var ref = _.find($scope.ngModel, function(item) {
                    if (_.isString(item)) return (item.toLowerCase() == queryLowercase);
                    else return false;
                });

                if (ref) {
                    saveCallback(obj);
                    return null;
                }

                if ($scope.options.length == 1) {
                    var newChip = {
                        ref_name: $chip,
                        ref_type: $scope.options[0]
                    };
                    saveCallback(obj);
                    return newChip;
                } else {
                    pipParentConvertWizard.show(
                        {
                            event: null,
                            recordName: $chip,
                            options: $scope.options, //$scope.convertedCollection
                            subParentsCollection: $scope.parensCollection
                        },
                        function (result) {
                            if (!result) {
                                $scope.ngModel.pop();
                                saveCallback(obj);
                                return null;
                            }
                            var i = $scope.ngModel.length - 1;

                            var subRecord = null, overRecord = null;
                            if (result && result.contentDetails) {

                                if (result.contentDetails.withParents) {
                                    overRecord = {
                                        ref_id: null,
                                        ref_name: result.contentDetails.title,
                                        ref_type: result.contentDetails.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.REFERENCE_TYPE.GOAL : result.contentDetails.type,
                                        ref_sub_type: result.contentDetails.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null
                                    };
                                } else {
                                    subRecord = {
                                        ref_id: null,
                                        ref_name: result.contentDetails.title,
                                        ref_type: result.contentDetails.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.REFERENCE_TYPE.GOAL : result.contentDetails.type,
                                        ref_sub_type: result.contentDetails.type == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null
                                    };
                                }

                            }

                            if (i > -1 && $scope.ngModel && $scope.ngModel[i]) {
                                $scope.ngModel[i] = {
                                    ref_name: $chip,
                                    ref_type: result.option.name,
                                    ref_sub_type: result.option.name == pipEnums.GOAL_TYPE.TASK ? pipEnums.GOAL_TYPE.TASK : null,
                                    parent: true,
                                    subRecord: subRecord,
                                    overRecord: overRecord
                                };
                            }
                            saveCallback(obj);
                        },
                        function () {
                            $scope.ngModel.pop();
                            //            saveCallback(obj);
                        }
                    );
                }
                return $chip;
            }


        }]
    );
})();

