<!--
@file Password recovery page
@copyright Digital Living Software Corp. 2014-2016
-->

<div class="pip-card-container pip-outer-scroll pip-entry">
    <pip-card width="400">
        <pip-recover-password-panel
                pip-data="data"
                pip-created="$panel = $control">

        </pip-recover-password-panel>
        <div class="pip-footer">
            <md-button ng-hide="transaction.busy()" ng-click="goBack()" class="rm8" aria-label="CANCEL">
                {{::'CANCEL' | translate}}
            </md-button>

            <md-button ng-hide="transaction.busy()" class="md-accent" ng-click="onRecover()"
                       aria-label="RECOVER_PWD_RECOVER"
                       ng-disabled="(form.$pristine && !data.email) || data.serverUrl.length == 0 || data.email.length == 0">
                {{::'RECOVER_PWD_RECOVER' | translate}}
            </md-button>

            <md-button ng-show="transaction.busy()" class="md-warn" ng-click="transaction.abort()" aria-label="ABORT">
                {{::'CANCEL' | translate}}
            </md-button>
        </div>
    </pip-card>
</div>