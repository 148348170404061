<md-dialog class="pip-dialog layout-column" width="500" min-height="500" md-theme="{{theme}}">
    <md-progress-linear ng-show="transaction.busy()"  md-mode="indeterminate" >
    </md-progress-linear>
    <div class="pip-header layout-column">
        <h3 class="w-stretch">{{'EDIT_TITLES_TITLE' | translate}}</h3>
    </div>
    <div class="pip-body p0" >
        <md-select ng-model="type" md-selected-item-change="" class="w-stretch lp24-flex rp24-flex bm16">
            <md-option ng-value="type.id" ng-repeat="type in types">{{ type.name }}</md-option>
        </md-select>
        <md-select ng-model="display" md-selected-item-change="" class="w-stretch lp24-flex rp24-flex bm16">
            <md-option ng-value="type.id" ng-repeat="type in display">{{ type.name }}</md-option>
        </md-select>
        <md-select ng-model="due" md-selected-item-change="" class="w-stretch lp24-flex rp24-flex bm16">
            <md-option ng-value="type.id" ng-repeat="type in due">{{ type.name }}</md-option>
        </md-select>

        <md-radio-group class="pip-content bp0" ng-model="data.group1">
            <md-button ng-repeat="connection in options"
                       class="h56 lp24-flex rp24-flex w-stretch layout-row layout-align-space-between-center">
                <pip-avatar class="pip-face rm16"
                            pip-party-id="'{{connection.party_id}}'" pip-party-name="'{{connection.party_name}}'" pip-rebind="true">
                </pip-avatar>
                <p class="m0 tm2 text-name-near-face"> {{connection.party_name}}</p>
                <div class="flex"></div>
                <md-radio-button class="m0 lm8 w16" ng-init="value = connection.party_id; check = item.party_ids.indexOf(connection.party_id) > -1"
                            ng-change="changeConsist(value, check)"></md-radio-button>
            </md-button>
        </md-radio-group>

        <md-input-container class="rm24-flex lm24-flex">
            <label>{{::'FEED_NAME' | translate}}</label>
            <input type="text" ng-model="data.name" name="eventName"/>
        </md-input-container>

        <md-select ng-model="groupName" md-selected-item-change="" class="w-stretch lp24-flex rp24-flex bm16">
            <md-option >groupName</md-option>
        </md-select>

        <pip-toggle-buttons ng-hide="false" class="lm24-flex rm24-flex bm16"
                            ng-model="tileSize"
                            pip-buttons-collection="importanceCollection"
                            pip-rebind="true">
        </pip-toggle-buttons>
    </div>

</md-dialog>
