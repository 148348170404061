/**
 * @file Areas data cache
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipAreasCache', ['pipDataCache', 'pipCacheTag']);

    thisModule.service('pipAreasCache',
        ['pipEnums', 'pipDataCache', 'pipCacheTag', function(pipEnums, pipDataCache, pipCacheTag) {

            return {
                readAreas: readAreas,
                readCategories: readCategories,
                onAreaCreate: onAreaCreate,
                onAreaUpdate: onAreaUpdate,
                onAreaDelete: onAreaDelete
            };
            //-------------

            function readAreas(params, successCallback, errorCallback) {
                params.resource = 'areas';
                params.item = params.item || {};
                params.item.type = 'category,area';

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function readCategories(params, successCallback, errorCallback) {
                params.resource = 'areas';
                params.item = params.item || {};
                params.item.type = 'category,area';
                params.filter = function(data) {
                    return _.filter(data, function(item) {
                        return item.type == pipEnums.AREA_TYPE.CATEGORY;
                    });
                };

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function onAreaCreate(params, successCallback) {
                return pipDataCache.addDecorator(
                    'areas', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onAreaUpdate(params, successCallback) {
                return pipDataCache.updateDecorator(
                    'areas', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onAreaDelete(params, successCallback) {
                return pipDataCache.removeDecorator('areas', params, successCallback);
            };

        }]
    );

})();

