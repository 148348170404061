<md-chips class="w-stretch"
          ng-model="ngModel"
          md-autocomplete-snap=""
          md-transform-chip="onAppend($chip)"
          xxxxmd-on-append="onAppend($chip)"
          readonly="ngDisabled()"
          ng-disabled="ngDisabled()"
          md-require-match="">
    <md-autocomplete
            class="text-subhead1"
            md-selected-item="item"
            md-search-text="new.searchText"
            md-items="item in getMatches()"
            placeholder="{{:: placeholder | translate}}"
            md-delay="200"
            md-menu-class="autocomplete-custom-template"
            md-no-cache="true"
            ng-disabled="ngDisabled()">
        <span md-highlight-text="new.searchText">{{item.translateTitle}}</span>
        <md-item-template>
            <md-icon md-svg-icon="icons:{{item.icon}}"></md-icon>
            <span>{{ item.translateTitle }}</span>
        </md-item-template>
    </md-autocomplete>
    <md-chip-template>
        <span>
            {{($chip.translateTitle || $chip.ref_name || $chip)}}
        </span>
    </md-chip-template>
</md-chips>