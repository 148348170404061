/*
 * convert_to.js
 * Convert To dialog
 * (с) Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipConvertToDialogNew', [
        'ngMaterial', 'pipRest.Enums', 'pipAppBar', 'pipRest.State',
        'pipTranslate', 'pipDataModel', 'pipControls'
    ]);

    thisModule.factory('ConvertToDialogNew',
        ['$state', '$http', '$mdDialog', 'pipEnums', 'pipConversionDialog', '$rootScope', 'ConvertService', function ($state, $http, $mdDialog, pipEnums, pipConversionDialog, $rootScope,
                  ConvertService) {
            return {
                show: function (params) {
                    pipConversionDialog.show(
                        {
                            event: params.event,
                            options: params.options,
                            deleted: params.deleted ? params.deleted : false,
                            deleteTitle: params.deletedTitle ? params.deletedTitle : null
                        },
                        function (result) {
                            ConvertService.convertToProto(params, result.option);
                        }
                    );
                }
            };
        }]
    );

    thisModule.controller('PipConvertToDialogNewController',
        ['$scope', '$rootScope', '$state', '$http', '$mdDialog', 'pipTransaction', 'pipTranslate', 'item', 'importanceCollection', 'urgencyCollection', 'statusCollection', function ($scope, $rootScope, $state, $http, $mdDialog, pipTransaction, pipTranslate,
                  item,  importanceCollection, urgencyCollection, statusCollection) {
        }]
    );

})();
