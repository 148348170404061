/**
 * @file PipLife common collection, collection for tools
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo Complete refactoring +
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipCommonCollectionService', []);

    thisModule.factory('CommonCollectionService', 
        ['pipTranslate', 'pipStrings', 'pipEnums', function (pipTranslate, pipStrings, pipEnums) {

            var shareLevel = [
                    {id: 0, name: 'SHARE_LEVEL_0', icon: 'icon-world'},
                    {id: 1, name: 'SHARE_LEVEL_1', icon: 'icon-people-white'},
                    {id: 2, name: 'SHARE_LEVEL_2', icon: 'icon-people'},
                    {id: 3, name: 'SHARE_LEVEL_3', icon: 'icon-person'}
                ],
                importanceCollection = [
                    {id: pipEnums.IMPORTANCE.LOW, key: 'LOW'},
                    {id: pipEnums.IMPORTANCE.NORMAL, key: 'NORMAL'},
                    {id: pipEnums.IMPORTANCE.HIGH, key: 'HIGH'}
                ],
                urgencyCollection = [
                    {id: pipEnums.URGENCY.LOW, key: 'LOW'},
                    {id: pipEnums.URGENCY.NORMAL, key: 'NORMAL'},
                    {id: pipEnums.URGENCY.HIGH, key: 'HIGH'}
                ],
                statusCollection = [
                    { id: 'new', name: pipTranslate.translateById('STATUS', pipEnums.EXECUTION_STATUS.NEW) },
                    { id: 'in progress', name: pipTranslate.translateById('STATUS', pipEnums.EXECUTION_STATUS.IN_PROGRESS) },
                    { id: 'on hold', name: pipTranslate.translateById('STATUS', pipEnums.EXECUTION_STATUS.ON_HOLD) },
                    { id: 'verifying', name: pipTranslate.translateById('STATUS', pipEnums.EXECUTION_STATUS.VERIFYING) },
                    { id: 'completed', name: pipTranslate.translateById('STATUS', pipEnums.EXECUTION_STATUS.COMPLETED) },
                    { id: 'canceled', name: pipTranslate.translateById('STATUS', pipEnums.EXECUTION_STATUS.CANCELED) }
                ];


            function getShareLevelCollection() {
                var collection = _.cloneDeep(shareLevel);
                pipTranslate.translateObjects(collection, 'name', 'nameLocal');

                return collection;
            };

            function getImportanceCollection() {
                var collection = _.cloneDeep(importanceCollection);
                pipTranslate.translateObjects(collection, 'name', 'nameLocal');

                return collection;
            };

            function getUrgencyCollection() {
                var collection = _.cloneDeep(urgencyCollection);
                pipTranslate.translateObjects(collection, 'name', 'nameLocal');

                return collection;
            };

            function getStatusCollection() {
                return statusCollection;
            };

            function getStatusArray() {
                var result = [];
                _.each(pipEnums.EXECUTION_STATUSES, function (item) {
                    result[item] = pipTranslate.translateById('STATUS', item);
                });

                return result;
            };
            
            function getResponsibilityCollections() {
                var responsibilityCollection = [
                    {
                        name: 'default',
                        title: 'PARTICIPANT_ROLE_DEFAULT',
                        subtitle:'PARTICIPANT_ROLE_DEFAULT_DESCRIPTION',
                        active: false
                    },
                    {
                        name: 'undefined',
                        title: 'PARTICIPANT_ROLE_UNDEFINED_IN_LIST',
                        subtitle:'PARTICIPANT_ROLE_UNDEFINED_IN_LIST_DESCRIPTION',
                        active: false
                    },
                    {
                        name: 'accountable',
                        title: 'PARTICIPANT_ROLE_ACCOUNTABLE',
                        subtitle:'PARTICIPANT_ROLE_ACCOUNTABLE_DESCRIPTION',
                        active: false
                    },
                    {
                        name: 'responsible',
                        title: 'PARTICIPANT_ROLE_RESPONSIBLE',
                        subtitle:'PARTICIPANT_ROLE_RESPONSIBLE_DESCRIPTION',
                        active: false
                    },
                    {
                        name: 'consulted',
                        title: 'PARTICIPANT_ROLE_CONSULTED',
                        subtitle:'PARTICIPANT_ROLE_CONSULTED_DESCRIPTION',
                        active: false
                    },
                    {
                        name: 'informed',
                        title: 'PARTICIPANT_ROLE_INFORMED',
                        subtitle:'PARTICIPANT_ROLE_INFORMED_DESCRIPTION',
                        active: false
                    }
                ];
                
                return responsibilityCollection;
            }

            function getPostTypeCollection() {
                return [
                    { name: 'info', title: 'POST_TYPE_INFO',
                        subtitle:  pipTranslate.translate('DESCRIPTION_POST_INFO'), backgroundColor: '#5c6bc0' },
                    { name: 'report', title: 'POST_TYPE_REPORT',
                        subtitle:   pipTranslate.translate('DESCRIPTION_POST_REPORT'), backgroundColor: '#1976D2' },
                    { name: 'issue', title: 'POST_TYPE_ISSUE',
                        subtitle:   pipTranslate.translate('DESCRIPTION_POST_ISSUE'), backgroundColor: '#B71C1C' },
                    { name: 'question', title: 'POST_TYPE_QUESTION',
                        subtitle:   pipTranslate.translate('DESCRIPTION_POST_QUESTION'), backgroundColor: '#FB8C00' }
                ];
            };

            function getPostDescription() {
                return {
                    'info': 'DESCRIPTION_POST_INFO',
                    'report': 'DESCRIPTION_POST_REPORT',
                    'issue': 'DESCRIPTION_POST_ISSUE',
                    'question': 'DESCRIPTION_POST_QUESTION'
                };
            };

            return {
                getShareLevelCollection: getShareLevelCollection,
                getStatusArray: getStatusArray,
                getPostTypeCollection: getPostTypeCollection,
                getImportanceCollection: getImportanceCollection,
                getUrgencyCollection: getUrgencyCollection,
                getStatusCollection: getStatusCollection,
                getResponsibilityCollections: getResponsibilityCollections,
                getPostDescription: getPostDescription
            }
        }]
    );

})();
