/**
 * @file Entry signup controller
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipMobileEntry.Signup', ['pipEntry.Common', 'pipEmailUnique',  'pipSignupPanel',
        'pipPasswordMatch']);

    thisModule.controller('pipSignupController',
        ['$scope', '$state', 'pipMobileEntry', 'AccessoryService', function ($scope, $state, pipMobileEntry, AccessoryService) {

            $scope.styleClass = pipMobileEntry.getClass();
            $scope.appLogo = pipMobileEntry.getAppLogo();
            $scope.isIe = AccessoryService.isIe();

            $scope.gotoSignin = gotoSignin;

            return;

            function gotoSignin(){
                $state.go('signin',{});
            }

        }]
    );

})();