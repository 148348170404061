/**
 * @file News user interface service
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipNewsUAService', ['pipCommonServices']);

    thisModule.factory('NewsUAService',
        ['pipTranslate', 'ConvertToDialog', 'pipConfirmationDialog', 'pipToasts', 'pipPostsData', 'EventsCreateNewService', '$state', '$rootScope', 'pipConnectionsData', 'AccessoryService', 'pipStrings', 'pipCollections', 'pipEnums', function(pipTranslate, ConvertToDialog, pipConfirmationDialog, pipToasts,
                 pipPostsData, EventsCreateNewService, $state, $rootScope, pipConnectionsData,
                 AccessoryService, pipStrings, pipCollections, pipEnums) {

            var DEFAULT_COMMENTS_SIZE = 3;

            var setIsDeleteCommentsItem = function (collection) {
                _.each(collection, function (comment) {
                    comment.isDeleteComments = comment.creator_id == $rootScope.$party.id;
                    comment.isEdit = false;
                });
            };

            function clearPost(post) {
                if (!post) return;

                delete post.source;
                delete post.parent_name;
                delete post.parent_source;
                delete post.visions;
                delete post.isCheers;
                delete post.isBoos;
                delete post.isManager;
                delete post.isContributor;
                delete post.lastComments;
                delete post.tempRefs;
            }

            function setIsCheers(item) {
                if (!item) return;
                var myCheers = _.find(item.cheers, function(c) { return c.creator_id == $rootScope.$user.id; });
                item.isCheers = (myCheers !== null && myCheers !== undefined);
            }

            function setIsBoos(item) {
                if (!item) return;
                var myBoos = _.find(item.boos, function(b) { return b.creator_id == $rootScope.$user.id; });
                item.isBoos = (myBoos !== null && myBoos !== undefined);
            }

            function preparePost(item, parentsCollection) {
                if (!item) return;
                setIsCheers(item);
                setIsBoos(item);
                AccessoryService.setParent(item, parentsCollection);
                item.isManager = AccessoryService.isManagerAccess({id: item.creator_id}, $rootScope.$user);
                item.isContributor = AccessoryService.isContributorAccess({id: item.creator_id}, $rootScope.$user);
                _.each(item.buzzes, function (buzz) {
                    AccessoryService.setParent(buzz, parentsCollection);
                    buzz.collapse = false;
                });
                if (!item.lastComments) item.lastComments = getLastComments(item, DEFAULT_COMMENTS_SIZE);
            }

            // используется для двух панелей
            function getLastComments(item, count) {
                if (!item || !item.comments) return
                if (!count) count = 3;
                var result = _.cloneDeep(_.take(item.comments, count));
                setIsDeleteCommentsItem(result);
                return result;
            }

            function onSaveProto(params, successCallback, errorCallback) {
                if (!params) {
                    if (errorCallback)
                        errorCallback('empty data');
                    return;
                }
                clearPost(params.item);
                if (params && !params.isNew) {
                    pipPostsData.updatePostWithFiles(params, successCallback, errorCallback)
                } else {
                    pipPostsData.createPostWithFiles(params, successCallback, errorCallback);
                }
            }

            function onUpdatePostProto(params, successCallback, errorCallback) {
                clearPost(params.item);
                pipPostsData.updatePost(params, successCallback, errorCallback);
            }

            function onDeleteProto(event, params, successCallback, errorCallback) {
                if (event) event.stopPropagation();
                pipConfirmationDialog.show(
                    {
                        event: event,
                        title: 'POST_DELETE_TITLE',
                        message: 'POST_DELETE_TEXT',
                        item: params.item.title || params.item.content,
                        ok: 'DELETE',
                        cancel: 'CANCEL'
                    },
                    function () {
                        pipPostsData.deletePost(
                            params,
                            function (result) {
                                if (successCallback)
                                    successCallback(result);
                            },
                            function (err) {
                                if (errorCallback) {
                                    errorCallback(err);
                                }
                            }
                        );
                    }
                );
            }

            function getPosts(item, refType, partyId, callback, errorCallback, force) {
                pipPostsData.getPosts({
                    item: {
                        party_id: partyId,
                        ref_id: item.id,
                        ref_type: refType
                    },
                    force: force === true ? true : false
                }, function (posts) {
                    if (callback) callback(posts);
                    return posts;
                }, errorCallback);
            }

            function onParentDetails($event, item) {
                if ($event) $event.stopPropagation();
                switch (item.ref_type) {
                    case pipEnums.REFERENCE_TYPE.AREA:
                        $state.go('areas.view.split.details', {id: item.id, party_id: $rootScope.$party.id});
                        break;
                    case pipEnums.REFERENCE_TYPE.GOAL:
                        $state.go('goals.view.split.details', {id: item.id, party_id: $rootScope.$party.id});
                        break;
                    case pipEnums.REFERENCE_TYPE.VISION:
                        $state.go('timeline.view.split.details', {id: item.id, party_id: $rootScope.$party.id});
                        break;
                }
            }

            function duplicateFeedName(item, feeds) {
                var result = false;
                if (!item.title) return true;

                _.each(feeds, function (feed) {
                    if ((feed.id != item.id) &&
                        (feed.title && item.title.toLowerCase() == feed.title.toLowerCase())) {
                        result = true;
                    }
                });
                return result;
            }

            return {

                setIsCheers: setIsCheers,
                setIsBoos: setIsBoos,
                setIsDeleteCommentsItem: setIsDeleteCommentsItem,
                getLastComments: getLastComments ,
                preparePost: preparePost,
                onSaveProto: onSaveProto,
                onUpdatePostProto: onUpdatePostProto,
                onDeleteProto: onDeleteProto ,
                getPosts: getPosts,
                onParentDetails: onParentDetails,
                duplicateFeedName: duplicateFeedName,
                clearPost: clearPost

            }
        }]
    );

})();