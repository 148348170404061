/**
 * @file ??
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo Completely refactore this service. Move all functions to more appropriate places
 */

/* global angular, async, _ */

(function () {
    'use strict';

    var thisModule = angular.module('pipAccessoryService', []);

    thisModule.factory('AccessoryService', 
        ['pipDataConfig', 'pipStrings', 'pipEnums', 'pipToasts', 'pipTranslate', 'pipUtils', function (pipDataConfig, pipStrings, pipEnums, pipToasts, pipTranslate, pipUtils) {

            var isNotEmptyContent = function(item) {
                return (item.content && item.content.length > 0) || (item.refs && item.refs.length > 0);
            };

            function isEmpty(obj) {
                if (!obj) return true;

                for (var i in obj) {
                    return false;
                }
                return true;
            };

            function getTags(tags) {
                return tags ? _.map(tags.tags, function (num) {return num.tag;})  : [];
            };

            function isPending(event, partyId) {
                return _.find(event.contribs, {party_id: partyId, accept: 'accepted'}) ? false : true;
            };

            function prepareParentCollection(areas, goals, visions, partyId, withoutAccomplisment) {
                areas.forEach(function (area) {
                    area.ref_type = pipEnums.REFERENCE_TYPE.AREA;
                });
                if (!!withoutAccomplisment)
                    goals = _.filter(goals, function (goal) {
                        return goal.type != pipEnums.GOAL_TYPE.ACCOMPLISHMENT;
                    });

                goals.forEach(function (goal) {
                    goal.ref_type = pipEnums.REFERENCE_TYPE.GOAL;
                });
                visions.forEach(function (vision) {
                    vision.ref_type = pipEnums.REFERENCE_TYPE.VISION;
                });

                var collection = _.union(areas, goals, visions);
                if (partyId) {
                    var result = _.filter(collection, function (item) {
                        item.isAction = isPending(item, partyId);
                        return !item.isAction;
                    });
                    return result
                } else {
                    return collection;
                }
            };

            function prepareConnections(connections) {
                var result = _.filter(connections, {accept: 'accepted'});

                result.forEach(function (con) {
                    con.image = getImageUrl(con.to_party_id, con.to_party_name);
                });

                return result;
            };

            function prepareGroups(groups) {
                var result = _.cloneDeep(groups);

                _.each(result, function(group){
                    group.groupName = group.name + ' (' + group.party_ids.length + ')';
                });

                return result;
            };

            function getVisionName(vision) {
                var date = vision.start ? new Date(vision.start) : null;
                return date != null ? date.getFullYear() + ' • ' + vision.title : vision.title;
            };

            function prepareVisions(visions, partyId) {
                var result = [];

                _.each(visions, function (vision) {
                    if (!isPending(vision, partyId)) {
                        vision.combineName = getVisionName(vision);
                        vision.ref_type = pipEnums.REFERENCE_TYPE.VISION;
                        result.push(vision);
                    }
                });

                return result;
            };

            function indexOffContent(content, search) {
                if (!content) return false;
                if (!search) return true;

                if (Array.isArray(content)) {
                    var i, flag = false;
                    for (i = 0; i < content.length; i ++) {
                        if (content[i].type == 'text' && content[i].text.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                            flag = true;
                            break;
                        }
                    }
                } else {
                    flag = content && content.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }

                return flag;
            };

            function onShowToast(message, type, successCallback, cancelCallback) {
                if (!message) return;
                message = pipTranslate.translate(message, successCallback, cancelCallback);
                type = type || 'message';

                if (type == 'message') {
                    pipToasts.showMessage(message, successCallback, cancelCallback, null, 'Error:' + message);
                    return;
                }
                if (type == 'error') {
                    pipToasts.showError(message, successCallback, cancelCallback, null, 'Error:' + message);
                    return;
                }
            };

            var setParent = function(item, parents) {
                var parent_source = [], visions = [];
                if (!item || !parents || parents.length == 0) return;

                if (!item.refs || item.refs.length == 0) {

                    return;
                }
                // set areas && goals
                _.each(item.refs, function (ref) {
                    if (ref.parent) {
                        var parent = _.find(parents, {id: ref.ref_id});
                        if (parent && (parent.ref_type == pipEnums.REFERENCE_TYPE.AREA || parent.ref_type == pipEnums.REFERENCE_TYPE.GOAL)) {
                            parent.collapse = false;
                            parent_source.push(_.cloneDeep(parent));
                        }
                    }
                });

                // set visions
                _.each(item.refs, function (ref) {
                    if (ref.parent) {
                        var vision = _.find(parents, {id: ref.ref_id});
                        if (vision && vision.ref_type == pipEnums.REFERENCE_TYPE.VISION) {
                            vision.collapse = false;
                            visions.push(_.cloneDeep(vision));
                        }
                    }
                });

                item.parent_name = '';
                item.parent_source = _.uniqBy(parent_source, 'id');
                item.visions = _.uniqBy(visions, 'id');

                var prefix, i;
                for (i = 0; i < item.parent_source.length; i++) {
                    prefix = (i == item.parent_source.length - 1) ? '' : ' • ';
                    if (item.parent_source[i].type == pipEnums.AREA_TYPE.CATEGORY)
                        item.parent_name += pipTranslate.translate(item.parent_source[i].title) + prefix;
                    else
                        item.parent_name += item.parent_source[i].title + prefix;
                }

                for (i = 0; i < item.visions.length; i++) {
                    if (item.parent_name) item.parent_name += ' • ';
                    item.parent_name +=  item.visions[i].title;
                }
            };

            function ignoreClick($event) {
                if ($event) $event.stopPropagation();
            };

            function getContributorsCount(contribs) {
                var unqContribs = _.uniqBy(contribs, 'party_id'),
                    unqAccaptedContribs = _.uniqBy(_.filter(contribs, { accept: 'accepted'}), 'party_id'),
                    unqOtherContribs = unqContribs.length - unqAccaptedContribs.length,
                    result = '' + unqAccaptedContribs.length;

                    result = unqOtherContribs > 0 ?  result + '+' + unqOtherContribs : result;

                return result;
            };

            function onCollapse($event, item) {
                if ($event) $event.stopPropagation();
                if (isNotEmptyContent(item))
                    item.collapse = !item.collapse;
            };

            function isManagerAccess(party, user){
                if ( (party && user && party.id && user.id && party.id == user.id ) )
                    return true;

                if ( ! (user.party_access && user.party_access.length > 0) )
                    return false;

                var partyAccess = _.find(user.party_access, {party_id: party.id});
                return partyAccess && partyAccess.manager ? true : false;
            };

            function isContributorAccess(party, user){
                if ( (party && user && party.id && user.id && party.id == user.id ) )
                    return true;
                if ( ! (user.party_access && user.party_access.length > 0) )
                    return false;

                var partyAccess = _.find(user.party_access, {party_id: party.id});
                return partyAccess && partyAccess.contributor ? true : false;
            };

            function getImageUrl(partyId, partyName) {
                var colors = [
                    'rgba(239, 83, 80, 1)', 'rgba(236, 64, 122, 1)', 'rgba(171, 71, 188, 1)',
                    'rgba(126, 87, 194, 1)', 'rgba(92, 107, 192, 1)', 'rgba(3, 169, 244, 1)',
                    'rgba(0, 188, 212, 1)', 'rgba(0, 150, 136, 1)', 'rgba(76, 175, 80, 1)',
                    'rgba(139, 195, 74, 1)', 'rgba(205, 220, 57, 1)', 'rgba(255, 193, 7, 1)',
                    'rgba(255, 152, 0, 1)', 'rgba(255, 87, 34, 1)', 'rgba(121, 85, 72, 1)',
                    'rgba(96, 125, 139, 1)'
                ];

                var
                    serverUrl = pipDataConfig.serverUrl();

                // Timestamp to avoid caching images for too long
                var timestamp = Math.floor(new Date().getTime() / 60000) * 60000,
                    colorClassIndex = null,
                    chr = null,
                    url = null;

                if (partyId && partyName) {
                    colorClassIndex = pipStrings.hashCode(partyId) % colors.length;
                    chr = (partyName[0] || '?').toUpperCase();
                    url = serverUrl + '/api/parties/' + partyId
                    + '/avatar?default_template=letter&bg=' + colors[colorClassIndex]
                    + '&fg=white&chr=' + chr + '&timestamp=' + timestamp;
                }

                return url;
            };

            function setCollapsed(item, source) {
                if (!source) return;

                if (item.parent_source !== undefined && source.parent_source && source.parent_source.collapse !== undefined)
                    item.parent_source.collapse = source.parent_source.collapse;

                if (source.collapse !== undefined) item.collapse = source.collapse;

                if (item.buzzes && item.buzzes.length > 0 && source.buzzes && source.buzzes.length > 0 ) {
                    for (var i = 0; i < item.buzzes.length; i++) {
                        var buzz = _.find(source.buzzes, {id: item.buzzes[i].id});
                        if (!buzz) continue;

                        if (buzz.collapse)
                            item.buzzes[i].collapse = buzz.collapse;

                        if (buzz.parent_source && buzz.parent_source.length > 0) {
                            if (item.buzzes[i].parent_source && item.buzzes[i].parent_source.length > 0) {
                                var buzzParent = item.buzzes[i].parent_source;
                                for (var j = 0; j < buzzParent.length; j++) {
                                    var parent_source = _.find(buzz.parent_source, {id: buzzParent[j].id});
                                    if (parent_source && parent_source.collapse) buzzParent[j].collapse = parent_source.collapse;
                                }
                            }
                        }

                        if (buzz.visions && buzz.visions.length > 0) {
                            if (item.buzzes[i].visions && item.buzzes[i].visions.length > 0) {
                                var buzzesVisions = item.buzzes[i].visions;
                                for (var j = 0; j < buzzesVisions.length; j++) {
                                    var vision = _.find(buzz.visions, {id: buzzesVisions[j].id});
                                    if (vision && vision.collapse) buzzesVisions[j].collapse = vision.collapse;
                                }
                            }
                        }
                    }
                }

                if (item.parent_source && item.parent_source.length > 0 && source.parent_source && source.parent_source.length > 0 ) {
                    for (var i = 0; i < item.parent_source.length; i++) {
                        var parent_source = _.find(source.parent_source, {id: item.parent_source[i].id});
                        if (parent_source && parent_source.collapse)
                            item.parent_source[i].collapse = parent_source.collapse;
                    }
                }

                if (item.visions && item.visions.length > 0 && source.visions && source.visions.length > 0 ) {
                    for (var i = 0; i < item.visions.length; i++) {
                        var vision = _.find(source.visions, {id: item.visions[i].id});
                        if (vision && vision.collapse)
                            item.visions[i].collapse = vision.collapse;
                    }
                }
            };

            //-----------------------------------------

            function checkParameter(parameter, errorTitle, message) {
                if (parameter === undefined) {
                    throw new Error(errorTitle + message + ' attribute missed');
                    return false;
                }

                if (typeof(parameter) !== 'object' || Array.isArray(parameter)) {
                    throw new Error(errorTitle + message + ' must be object and must not be array');
                    return false;
                }

                return true;
            };


            function checkOneFunction(functionObject, functionName, errorTitle) {
                if (typeof(functionObject) === undefined) {
                    throw new Error(errorTitle + ' function ' + functionName +  ' missed');
                    return false;
                }

                if (typeof(functionObject) !== 'function') {
                    throw new Error(errorTitle +  ' ' + functionName + ' must be function');
                    return false;
                }

                return true;
            };

            function checkFunctions(object, errorTitle) {
                for(var key in object) {
                    if (!checkOneFunction(object[key], key, errorTitle)) return false;
                }

                return true;
            };


            function checkOneCollection(collection, collectionName, errorTitle) {
                if (typeof(collection) === undefined) {
                    throw new Error(errorTitle + ' collection ' + collectionName +  ' missed');
                    return false;
                }

                if (!Array.isArray(collection)) {
                    throw new Error(errorTitle +  ' ' + collectionName + ' must be collection');
                    return false;
                }

                return true;
            };

            function checkCollections(object, errorTitle) {
                for(var key in object) {
                    if (!checkOneCollection(object[key], key, errorTitle)) return false;
                }
                return true;
            };

            function checkOptions(object, errorTitle) {
                for(var key in object) {
                    if (typeof(object[key]) === undefined) {
                        throw new Error(errorTitle + key + ' property missed');
                        return false;
                    }
                }
                return true;
            };



///-----------------------

            var natives = {
                '[object Array]': 'array'
                , '[object RegExp]': 'regexp'
                , '[object Function]': 'function'
                , '[object Arguments]': 'arguments'
                , '[object Date]': 'date'
            };

            function getType (obj) {
                var str = Object.prototype.toString.call(obj);
                if (natives[str]) return natives[str];
                if (obj === null) return 'null';
                if (obj === undefined) return 'undefined';
                if (obj === Object(obj)) return 'object';
                return typeof obj;
            }

            function getName(name, endSymbol) {
                var end = endSymbol ? endSymbol : '';
                return name ? name + end : end;
            };

            // not undefined
            function pipValidateExist(variable, errorTitle, errorMessage) {
                if (typeof variable === undefined)
                    throw new Error(errorTitle + ' ' + errorMessage);
            };

            function pipValidateString(variable, errorTitle, variableName, errorMessage) {
                var messageMissing = 'Missing variable ' + getName(variableName, '.');
                pipValidateExist(variable, errorTitle, messageMissing);

                var message = errorMessage ? errorMessage : 'Variable ' + getName(variableName, ' ') + 'must be String.';
                if (!_.isString(variable))
                    throw new Error(errorTitle + ' ' + message);
            };

            function pipValidateBoolean(variable, errorTitle, variableName, errorMessage) {
                var messageMissing = 'Missing variable ' + getName(variableName, '.');
                pipValidateExist(variable, errorTitle, messageMissing);

                var message = errorMessage ? errorMessage : 'Variable ' + getName(variableName, ' ') + 'must be Boolean.';
                if (!_.isBoolean(variable))
                    throw new Error(errorTitle + ' ' + message);
            };

            function pipValidateNumber(variable, errorTitle, variableName, errorMessage) {
                var messageMissing = 'Missing variable ' + getName(variableName, '.');
                pipValidateExist(variable, errorTitle, messageMissing);

                var message = errorMessage ? errorMessage : 'Variable ' + getName(variableName, ' ') + 'must be Number.';
                if (!_.isNumber(variable))
                    throw new Error(errorTitle + ' ' + message);
            };

            function pipValidateDate(variable, errorTitle, variableName, errorMessage) {
                var messageMissing = 'Missing variable ' + getName(variableName, '.');
                pipValidateExist(variable, errorTitle, messageMissing);

                var message = errorMessage ? errorMessage : 'Variable ' + getName(variableName, ' ') + 'must be Date.';
                if (!_.isDate(variable))
                    throw new Error(errorTitle + ' ' + message);
            };

            function pipValidateObject(object, errorTitle, errorMessage, objectName) {
                var messageMissing = 'Missing object ' + getName(objectName, '.');
                pipValidateExist(object, errorTitle, messageMissing);

                var message = errorMessage ? errorMessage : getName(objectName, ' ') + 'must be Object.';
                if (!_.isObject(object))
                    throw new Error(errorTitle + ' ' + message);
            };

            function pipValidateFunction(func, errorTitle, errorMessage, functionName) {
                var messageMissing = 'Missing function ' + getName(functionName, '.');
                pipValidateExist(func, errorTitle, messageMissing);

                var message = errorMessage ? errorMessage : getName(functionName, ' ') + 'must be Function.';
                if (!_.isFunction(func))
                    throw new Error(errorTitle + ' ' + message);
            };

            function pipValidateArray(arr, errorTitle, errorMessage, arrayName) {
                var messageMissing = 'Missing array ' + getName(arrayName, '.');
                pipValidateExist(arr, errorTitle, messageMissing);

                var message = errorMessage ? errorMessage : getName(arrayName, ' ') + 'must be Array.';
                if (!Array.isArray(arr))
                    throw new Error(errorTitle + ' ' + message);
            };

            function pipValidateRegExp(regexp, errorTitle, errorMessage, regexpName) {
                var messageMissing = 'Missing regular expression  ' + getName(regexpName, '.');
                pipValidateExist(regexp, errorTitle, messageMissing);

                var message = errorMessage ? errorMessage : getName(regexpName, ' ') + 'must be Regular Expression.';
                if (!_.isRegExp(regexp))
                    throw new Error(errorTitle + ' ' + message);
            };

            function isIe() {
                var systemInfo = pipUtils.getBrowser(),
                    result = systemInfo.browser == 'ie';

                return result;
            }

            return {
                getTags: getTags,
                isNotEmptyContent: isNotEmptyContent,
                isEmpty: isEmpty,
                isPending: isPending,
                prepareParentCollection: prepareParentCollection,
                indexOffContent: indexOffContent,
                ignoreClick: ignoreClick ,
                getContributorsCount: getContributorsCount ,
                onCollapse: onCollapse ,
                isManagerAccess: isManagerAccess ,
                isContributorAccess: isContributorAccess ,
                getImageUrl: getImageUrl ,
                onShowToast: onShowToast,
                setParent: setParent,
                setCollapsed: setCollapsed,
                prepareConnections: prepareConnections,
                prepareGroups: prepareGroups,
                prepareVisions: prepareVisions,
                getVisionName: getVisionName,
                
                checkParameter: checkParameter,
                checkFunctions: checkFunctions,
                checkOptions: checkOptions,
                checkCollections: checkCollections,
                checkOneCollection: checkOneCollection,
                checkOneFunction: checkOneFunction,
                isIe: isIe,

                getType: getType,
                pipValidateExist: pipValidateExist,
                pipValidateString: pipValidateString,
                pipValidateBoolean: pipValidateBoolean,
                pipValidateNumber: pipValidateNumber,
                pipValidateDate: pipValidateDate,
                pipValidateObject: pipValidateObject,
                pipValidateFunction: pipValidateFunction,
                pipValidateArray: pipValidateArray,
                pipValidateRegExp: pipValidateRegExp
            }
        }]
    );

})();






