/**
 * @file New Record Wizard Visability panel
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global _, angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipRecordWizardVisabilityPanel', []);

    thisModule.directive('pipRecordWizardVisabilityPanel',
        function () {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    entity: '=pipEntity',
                    parties: '=pipParties',
                    groups: '=pipGroups',
                    defaultEntityOptions: '=?pipDefaultEntityOptions',
                    hideDefault: '=?pipHideDefault',
                    transaction: '=pipTransaction'
                },
                templateUrl: 'new_record_wizard/record_wizard_visability_panel.html',
                controller: 'pipRecordWizardVisabilityPanelController'
            };
        }
    );

    thisModule.controller('pipRecordWizardVisabilityPanelController',
        ['$scope', '$element', 'pipEnums', '$rootScope', '$mdMedia', '$state', '$window', 'pipTranslate', 'pipImageUtils', 'pipOptionsBigDialog', 'AccessoryService', 'pipDataAvatar', function ($scope, $element, pipEnums, $rootScope, $mdMedia, $state, $window, pipTranslate, pipImageUtils,
                  pipOptionsBigDialog, AccessoryService, pipDataAvatar) {

            checkParameter();

            $element.addClass('pip-record-wizard-visibility-panel');
            setTimeout(function () {
                var newVisibilityElement = $('.md-autocomplete-suggestions-container');

                newVisibilityElement.addClass('pip-record-wizard-visibility-panel');
            }, 1000);

            $scope.shareLevel = [
                {id: pipEnums.SHARE_LEVEL.WORLD, name: 'SHARE_LEVEL_0', icon: 'world', visible: true},
                {id: pipEnums.SHARE_LEVEL.OUTER, name: 'SHARE_LEVEL_1', icon: 'people-white', visible: true},
                {id: pipEnums.SHARE_LEVEL.INNER, name: 'SHARE_LEVEL_2', icon: 'people', visible: true},
                {id: pipEnums.SHARE_LEVEL.PRIVATE, name: 'SHARE_LEVEL_3', icon: 'person', visible: true}
            ];
            $scope.shareLevelCollection = _.cloneDeep($scope.shareLevel);

            $scope.confidentSecret = pipEnums.CONFIDENTIALITY.SECRET;
            $scope.confidentClassified = pipEnums.CONFIDENTIALITY.CLASSIFIED;
            $scope.selected = {};
            initData();

            $scope.onConfidentChange = onConfidentChange;
            $scope.getConnections = getConnections;
            $scope.onChangeShareLevel = onChangeShareLevel;
            $scope.onCheckboxClick = onCheckboxClick;
            // $scope.onPartyClick = onPartyClick;
            // $scope.onGroupClick = onGroupClick;
            $scope.isIe = isIe;

            $scope.$watch('entity', initData);
            $scope.$watchCollection('selected.share_connections', onChangeShareConnections);

            isIe();

            return;

            function isIe() {
                var sAgent = $window.navigator.userAgent,
                    idx = sAgent.indexOf('MSIE');

                return idx > 0 || $window.navigator.msManipulationViewsEnabled == true;
            }

            function checkParameter() {
                var ERROR_TITLE = 'pipContributorDetailsController error: ';

                AccessoryService.checkParameter($scope.entity, ERROR_TITLE, 'pip-entity');
                AccessoryService.checkOneCollection($scope.parties, 'pip-parties', ERROR_TITLE);
                AccessoryService.checkOneCollection($scope.groups, 'pip-groups', ERROR_TITLE);
            }

            function initData() {
                if (!$scope.entity) { return; }

                $scope.selected.hideDefault = $scope.hideDefault === false;
                $scope.selected.defaultEntityOptions = $scope.defaultEntityOptions !== false;

                $scope.entity.share_level =
                    $scope.entity.share_level === undefined || $scope.entity.share_level === null
                        ? pipEnums.SHARE_LEVEL.PRIVATE : $scope.entity.share_level;
                $scope.entity.confid = $scope.entity.confid === undefined || $scope.entity.confid === null
                    ? pipEnums.CONFIDENTIALITY.PUBLIC : $scope.entity.confid;
                $scope.entity.confid_min = $scope.entity.confid_min === undefined || $scope.entity.confid_min === null
                    ? pipEnums.CONFIDENTIALITY.PUBLIC : $scope.entity.confid_min;

                initConfident();

                $scope.entity.share_parties = $scope.entity.share_parties ? $scope.entity.share_parties : [];
                $scope.entity.share_groups = $scope.entity.share_groups ? $scope.entity.share_groups : [];

                $scope.selected.share_connections =
                    getShareCollection($scope.entity.share_parties, $scope.entity.share_groups);

                $scope.groups = $scope.groups || [];
                $scope.parties = $scope.parties || [];

                $scope.selected.parties = _.map(
                    _.uniq(
                        _.filter(
                            $scope.parties,
                            function (item) {
                                return item.type !== 'follow' && item.accept === 'accepted';
                            }
                        ),
                        'to_party_id'),
                    function (item) {
                        return {
                            party_id: item.to_party_id,
                            party_name: item.to_party_name
                        };
                    }
                );

                $scope.selected.cons = getShareCollection($scope.selected.parties, $scope.groups);
                shareSet($scope.entity.confid);
                $scope.showConfident = true;
            }

            function setShared(acceptor, donor) {
                acceptor.confid_min = donor.confid_min !== undefined ? donor.confid_min : null;
                acceptor.confid = donor.confid !== undefined ? donor.confid : null;
                acceptor.share_def = acceptor.share_def === undefined || acceptor.share_def === null ? true : acceptor.share_def;
                acceptor.share_level = donor.share_level !== undefined && donor.share_level !== null ? donor.share_level : null;
                acceptor.share_parties = donor.share_parties ? _.cloneDeep(donor.share_parties) : [];
                acceptor.share_groups = donor.share_groups ? _.cloneDeep(donor.share_groups) : [];
            }

            function onCheckboxClick(newValue) {
                if (newValue) {
                    setShared($scope.entity, $scope.entity.combinedParent || {});
                    initData();
                }
            }

            function initConfident() {
                $scope.confidentCollection = [];
                _.each(pipEnums.CONFIDENTIALITY, function (value) {
                    if ($scope.entity.confid_min && value > $scope.entity.confid_min) { return; }
                    $scope.confidentCollection.push({
                        id: value,
                        name: value,
                        title: 'CONFIDENTIALITY_' + value,
                        subtitle: 'DESCRIPTION_CONFIDENTIALITY_' + value,
                        active: $scope.entity.confid === value
                    });
                });
                $scope.selected.confidDiscription =
                    pipTranslate.translate($scope.confidentCollection[$scope.entity.confid].subtitle);
            }

            function shareSet(confident) {
                $scope.shareLevelCollection = [];
                $scope.shareLevelCollection = _.cloneDeep($scope.shareLevel);
                if (confident !== 0) {
                    $scope.shareLevelCollection[0].visible = false;
                    $scope.entity.share_level = $scope.entity.share_level === 0 ? 1 : $scope.entity.share_level;
                }
                $scope.selected.shareIndex = _.findIndex($scope.shareLevelCollection, {id: $scope.entity.share_level});
                pipTranslate.translateObjects($scope.shareLevelCollection, 'name', 'nameLocal');
            }

            function getShareCollection(parties, groups) {
                var result = [];

                _.each(groups, function (group) {
                    group.entity_type = 'group';
                    group.group_id = group.group_id ? group.group_id : group.id;
                    group.group_name = group.group_name ? group.group_name : group.name;
                    group.party_name = group.group_name;
                    result.push(group);
                });

                _.each(parties, function (party) {
                    party.entity_type = 'party';
                    party.image = pipDataAvatar.getAvatarUrl(party.party_id, party.party_name, $rootScope.$party.id);
                    result.push(party);
                });

                return result;
            }

            function getConnections($query) {
                var index, result, i, conItem;

                result = _.filter($scope.selected.cons, function (item) {
                    var name = item.party_name;

                    return name.toLowerCase().indexOf($query.toLowerCase()) > -1;
                });

                for (i = 0; i < $scope.selected.share_connections.length; i++) {
                    conItem = $scope.selected.share_connections[i];

                    if (conItem.enity_type === 'group') {
                        index = _.findIndex(result, {group_id: conItem.group_id});
                    } else {
                        index = _.findIndex(result, {party_id: conItem.party_id});
                    }
                    if (index > -1) {
                        result.splice(index, 1);
                    }
                }

                return result;
            }

            function onChangeShareLevel() {
                $scope.selected.shareIndex = _.findIndex($scope.shareLevelCollection, {id: $scope.entity.share_level});
            }

            function onChangeShareConnections() {
                onChangeShare();
            }

            function onConfidentChange($event) {
                pipOptionsBigDialog.show(
                    {
                        event: event,
                        title: 'VISIBILITY_CONFIDENT_DIALOG_TITLE',
                        options: $scope.confidentCollection,
                        appleButtonTitle: 'VISIBILITY_CHANGE'
                    },
                    function (result) {
                        $scope.entity.confid = result.option.id;
                        $scope.selected.confidDiscription =
                            pipTranslate.translate($scope.confidentCollection[$scope.entity.confid].subtitle);
                        shareSet($scope.entity.confid);
                    }
                );
            }

            function compareParties(arr1, arr2) {
                var i;

                if (arr1.length !== arr2.length) {
                    return false;
                }

                for (i = 0; i < arr1.length; i++) {
                    if (arr1[i].party_id !== arr1[i].party_id) {
                        return false;
                    }
                }

                return true;
            }

            function compareGroups(arr1, arr2) {
                var i, id1, id2;

                if (arr1.length !== arr2.length) {
                    return false;
                }

                for (i = 0; i < arr1.length; i++) {
                    id1 = arr1[i].id || arr1[i].group_id;
                    id2 = arr2[i].id || arr2[i].group_id;
                    if (id1 !== id2) {
                        return false;
                    }
                }

                return true;
            }

            function onChangeShare() {
                var groups = [],
                    parties = [],
                    i = 0;

                _.each($scope.selected.share_connections, function (item) {
                    if (!angular.isObject(item) || !item.party_id && !item.group_id) {
                        $scope.selected.share_connections.splice(i, 1);
                    }
                    i += 1;
                });

                $scope.selected.share_connections.forEach(function (con) {
                    if (con.entity_type === 'group') {
                        groups.push(con);
                    } else {
                        parties.push(con);
                    }
                });

                if (compareParties(parties, $scope.entity.share_parties) &&
                    compareGroups(groups, $scope.entity.share_groups)) {

                    return;
                }
                $scope.entity.share_parties = parties;
                $scope.entity.share_groups = groups;
            }

        }]
    );

})();
