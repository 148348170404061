
<div ng-repeat="comment in item.comments" class="layout-row lp24-flex rp24-flex bp16 pip-comments">
    <pip-avatar class="pip-face-sm rm24"
                pip-party-id="comment.creator_id"
                pip-id="comment.creator_id"
                pip-party-name="comment.creator_name"
                pip-rebind="true">
    </pip-avatar>
    <div class="lm8 flex">
        <div class="text-body2">{{::comment.creator_name}}
            <span class="opacity-secondary ">{{::comment.created | formatElapsedTime}}</span>
        </div>
        <div class="tm4 bm8">{{::comment.content[0].text}}</div>
        <pip-collage  pip-picture-ids="comment.content[1].pic_ids" pip-multiple="true"></pip-collage>
        <pip-document-list pip-documents="comment.content[2].docs"
                           pip-collapse="true"
                           ng-disabled="documentListDisabled"
                           ng-if="comment.content[2].docs.length > 0"
                           class="tm8">
        </pip-document-list>
    </div>
    <md-button class="md-icon-button md-icon-button-little p4 m0 flex-fixed"
               ng-click="onReplyClick(comment)"
               ng-if="comment.creator_id != user.id"
               aria-label="Reply">
        <md-icon md-svg-icon="icons:reply" class="icon-document"></md-icon>
    </md-button>


    <md-menu pip-prevent-drag="true" class="flex-fixed"
             ng-if="comment.creator_id  == user.id"
             md-position-mode="target-right target">
        <md-button class="md-icon-button rm0 p4 "
                   ng-click="$mdOpenMenu()"
                   ng-disabled="transaction.busy()"
                   aria-label="actions">
            <md-icon md-svg-icon="icons:vdots"></md-icon>
        </md-button>
        <md-menu-content width="3">
            <md-menu-item>
                <md-button
                        ng-click="onUpdate(comment)">
                    {{::'EDIT' | translate}}
                </md-button>
            </md-menu-item>

            <md-menu-item>
                <md-button
                        ng-click="deleteComment(comment)">
                    {{::'DELETE' | translate}}
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
</div>
<div ng-class="{'pip-comment-edit': update}">
<div class="w-stretch pip-comments-footer layout-row lp24-flex ">
    <pip-avatar class="pip-face-sm rm8"
                pip-party-id="user.id"
                pip-id="user.id"
                pip-party-name="user.name"
                pip-rebind="true">
    </pip-avatar>
    <div class="r-block"></div>
    <div class="layout-row pip-comment-content">
        <md-input-container class="m0 p0 lp8 flex input-comment" md-no-float>
                        <textarea id="input-comment"
                                  ng-model="$parent.commentInput"
                                  ng-disabled="transaction.busy()"
                                  placeholder="{{:: commetsPlaceholder}}"
                                  aria-label="a">
                        </textarea>
        </md-input-container>
        <md-button class="md-icon-button md-icon-button-little p4 m0"
                   ng-click="onAddComment(item, $parent.commentInput)"
                   ng-if="($parent.commentInput || document || camera)  && !update"
                   aria-label="SEND">

            <md-icon md-svg-icon="icons:send"></md-icon>
        </md-button>

        <md-button class="md-icon-button md-icon-button-little p4 m0"
                   ng-click="onUpdateComment(item)"
                   ng-if="($parent.commentInput || document || camera) && update"
                   aria-label="SEND">

            <md-icon md-svg-icon="icons:check"></md-icon>
        </md-button>

        <md-button class="md-icon-button md-icon-button-little p4 m0"
                   ng-click="addCallback()"
                   ng-if="update"
                   aria-label="SEND">

            <md-icon md-svg-icon="icons:cross"></md-icon>
        </md-button>

        <md-button class="md-icon-button md-icon-button-little p4 m0"
                   ng-click="openCamera()"
                   ng-if="!$parent.commentInput && !document && !camera"
                   aria-label="SEND">
            <md-icon md-svg-icon="icons:camera" class="icon-camera"></md-icon>
        </md-button>

        <md-button class="md-icon-button md-icon-button-little p4 m0"
                   ng-click="openDocument()"
                   ng-if="!$parent.commentInput && !camera && !document"
                   aria-label="SEND">
            <md-icon md-svg-icon="icons:document" class="icon-document"></md-icon>
        </md-button>

    </div>
</div>
<div class="layout-row" style="margin-top: 12px; padding-right:24px" ng-if="camera">
    <div class="" style="margin-left: 74px"></div>
    <pip-picture-list-edit pip-picture-ids="pictureIds"
                           pip-rebind="true"
                           pip-created="onPictureListCreated($event)">
    </pip-picture-list-edit>
</div>
<div class="layout-row" style="margin-top: 12px; padding-right:24px" ng-if="document">
    <div class="" style="margin-left: 74px"></div>
    <pip-document-list-edit pip-documents="documentsIds"
                            pip-rebind="true"
                            pip-created="onDocumentListCreated($event)"
                            pip-default-text="Click here or drag files to upload"
                            ng-disabled="documentListEditDisabled">
    </pip-document-list-edit>
</div>

</div>