/**
 * @file Quotes data cache
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipQuotesCache', ['pipDataCache']);

    thisModule.service('pipQuotesCache',
        ['pipEnums', 'pipDataCache', 'pipCacheTag', function(pipEnums, pipDataCache, pipCacheTag) {

            return {
                readQuotes: readQuotes,
                onQuoteCreate: onQuoteCreate,
                onQuoteUpdate: onQuoteUpdate,
                onQuoteDelete: onQuoteDelete                
            };
            //-------------

            function readQuotes(params, successCallback, errorCallback) {
                params = params || {};
                params.resource = 'quotes';
                params.item = params.item || {};

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            }
            
            function onQuoteCreate(params, successCallback) {
                
                return pipDataCache.addDecorator(
                    'quotes', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onQuoteUpdate(params, successCallback) {
                return pipDataCache.updateDecorator(
                    'quotes', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onQuoteDelete(params, successCallback) {
                return pipDataCache.removeDecorator('quotes', params, successCallback);
            };

            
        }]
    );

})();

