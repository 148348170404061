/**
 * @file Participants formatting service
 * @copyright Digital Living Software Corp. 2014-2016
 */
 
 /* global _, angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipParticipantsFormat', ['pipCore']);

	thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {

        pipTranslateProvider.translations('en', {
            'DEFAULT_PARTICIPANTS': 'Default participants',
            
            'PARTICIPANT_ROLE_RESPONSIBLE_SHORT': 'R',
            'PARTICIPANT_ROLE_ACCOUNTABLE_SHORT': 'A',
            'PARTICIPANT_ROLE_CONSULTED_SHORT': 'C',
            'PARTICIPANT_ROLE_INFORMED_SHORT': 'I',
            'PARTICIPANT_ROLE_UNDEFINED_SHORT': '?'
        });

        pipTranslateProvider.translations('ru', {
            'DEFAULT_PARTICIPANTS': 'Участников по умолчанию',
            
            'PARTICIPANT_ROLE_RESPONSIBLE_SHORT': 'И',
            'PARTICIPANT_ROLE_ACCOUNTABLE_SHORT': 'О',
            'PARTICIPANT_ROLE_CONSULTED_SHORT': 'К',
            'PARTICIPANT_ROLE_INFORMED_SHORT': 'И',
            'PARTICIPANT_ROLE_UNDEFINED_SHORT': '?'
        });
		
	}]);

    thisModule.factory('pipParticipantsFormat',
        ['pipEnums', 'pipTranslate', function (pipEnums, pipTranslate) {

            return {
                formatParticipants: formatParticipants
            };

            function formatParticipants(parent, count) { 
                if (parent === null || parent === undefined || !parent.contribs || !parent.contribs.length)
                    return '';
                    
                var result = '', 
                    undefParty = '',
                    undefinedName = '<b>' + pipTranslate.translate('PARTICIPANT_ROLE_UNDEFINED_SHORT') + '</b>',
                    partyCount = count ? count : 3,
                    uniqContribs,
                    contribs = [
                        { 
                            role: pipEnums.CONTRIBUTOR_ROLE.ACCOUNTABLE,
                            name : '<b>' + pipTranslate.translate('PARTICIPANT_ROLE_ACCOUNTABLE_SHORT') + '</b>',
                            contribs: ''
                        },
                        { 
                            role: pipEnums.CONTRIBUTOR_ROLE.RESPONSIBLE,
                            name : '<b>' + pipTranslate.translate('PARTICIPANT_ROLE_RESPONSIBLE_SHORT') + '</b>',
                            contribs: ''
                        },
                        { 
                            role: pipEnums.CONTRIBUTOR_ROLE.CONSULTED,
                            name : '<b>' + pipTranslate.translate('PARTICIPANT_ROLE_CONSULTED_SHORT') + '</b>',
                            contribs: ''
                        },
                        { 
                            role: pipEnums.CONTRIBUTOR_ROLE.INFORMED,
                            name : '<b>' + pipTranslate.translate('PARTICIPANT_ROLE_INFORMED_SHORT') + '</b>',
                            contribs: ''
                        }                        
                    ];
                
                
                if (parent && parent.contribs && parent.contribs.length > 0) {
                    uniqContribs = _.uniqBy(parent.contribs, 'party_id');
                    _.each(uniqContribs, function(party) {
                        var contribRole = _.find(contribs, {role: party.role});
                        if (contribRole) {
                            contribRole.contribs = contribRole.contribs  ? contribRole.contribs + ', ' + party.party_name :  contribRole.name + ': ' + party.party_name;
                        } else  {
                            undefParty = undefParty  ? undefParty + ', ' + party.party_name : undefinedName + ': ' + party.party_name;   
                        }
                    });
                }
                
                var k = 0;
                for (var i = 0; i < contribs.length; i++ ) {
                    if (contribs[i].contribs) {
                        if (k > partyCount) return result;
                        
                        result = result ? result + ' • ' + contribs[i].contribs : contribs[i].contribs;
                        k += 1;
                    }     
                }

                if (undefParty) result = result ? result + ' • ' + undefParty : undefParty;
                
                return result;
            };

        }]
    );
    
})();
