/**
 * @file Notes data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipNotesData', ['pipRest', 'pipDataModel', 'pipCacheTag', 'pipNotesCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var notesApi = {
            notes: function (url) {
                return this.createPartyCollection(url, '/api/parties/:party_id/notes/:id');
            },

            sendNote: function (url, pipRest) {
                return this.createPartyCollection(url, '/api/parties/:party_id/notes/:id/send');
            },

            noteComments: function (url) {
                return this.createCollection(url, '/api/parties/:party_id/notes/:parent_id/comments/:id',
                    {
                        party_id: '@party_id',
                        id: '@id',
                        parent_id: '@parent_id'
                    }
                );
            },
        };

        pipRestProvider.addApi(notesApi);
    }]);

    thisModule.provider('pipNotesData', function () {

        // this.readNotesResolver = function (category) {
        //     return /* @ngInject */ function ($stateParams, pipRest, $rootScope) {
        //         return pipRest.notes().query({
        //             party_id: pipDataSession.partyId(),
        //             category: category
        //         }).$promise.then(null,
        //             function (reason) {
        //                 if (reason && reason.code) {
        //                     if (reason.code == -1) $rootScope.$emit('pipRespondingError', {
        //                         error: rejection
        //                     });
        //                     else  $rootScope.$emit('pipUnhandledInternalError', {
        //                         error: rejection
        //                     });
        //                 }
        //             });
        //     };
        // };

        // this.readNoteResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipEnums, pipRest, $rootScope) {
        //         var id;

        //         if ($stateParams.ref_id && $stateParams.ref_type.toLowerCase() == pipEnums.REFERENCE_TYPE.NOTE) {
        //             id = $stateParams.ref_id
        //         } else {
        //             if ($stateParams.id) {
        //                 id = $stateParams.id;
        //             } else {
        //                 return null
        //             }
        //         }

        //         return pipRest.notes().get({
        //             party_id: pipDataSession.partyId(),
        //             id: id
        //         }).$promise.then(null,
        //             function (reason) {
        //                 if (reason && reason.code) {
        //                     if (reason.code == -1) $rootScope.$emit('pipRespondingError', {
        //                         error: rejection
        //                     });
        //                     else  $rootScope.$emit('pipUnhandledInternalError', {
        //                         error: rejection
        //                     });
        //                 }
        //             });
        //     };
        // };

        this.$get = ['$stateParams', 'pipDataModel', 'pipCacheTag', 'pipNotesCache', 'pipDataSession', function ($stateParams, pipDataModel, pipCacheTag, pipNotesCache, pipDataSession) {
            return {
                partyId: pipDataSession.partyId(),

                readNotes: function (params, successCallback, errorCallback) {
                    params.resource = 'notes';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    return pipNotesCache.readNotes(params, successCallback, errorCallback);
                },

                readNote: function (params, successCallback, errorCallback) {
                    params.resource = 'notes';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    if (!params.item.id) {
                        if ($stateParams.ref_id && $stateParams.ref_type.toLowerCase() == 'note') {
                            params.item.id = $stateParams.ref_id;
                        } else {
                            params.item.id = $stateParams.id;
                        }
                    }
                    return pipDataModel.readOne(params, pipNotesCache.onNoteUpdate(params, successCallback), errorCallback);
                },

                getNotesPage: function (params, successCallback, errorCallback) {
                    params.resource = 'notes';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.page(params, successCallback, errorCallback);
                },

                createNote: function (params, successCallback, errorCallback) {
                    params.resource = 'notes';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.create(
                        params,
                        pipNotesCache.onNoteCreate(params, successCallback),
                        errorCallback
                    );
                },

                createNoteWithFiles: function (params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.saveFiles(params, function () {
                        params.resource = 'notes';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;

                        params.item.party_id = pipDataSession.partyId();
                        pipDataModel.create(
                            params,
                            pipNotesCache.onNoteCreate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                updateNote: function (params, successCallback, errorCallback) {
                    params.resource = 'notes';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.update(
                        params,
                        pipNotesCache.onNoteUpdate(params, successCallback),
                        errorCallback
                    );
                },

                updateNoteWithFiles: function (params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.saveFiles(params, function () {
                        params.resource = 'notes';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;

                        pipDataModel.update(
                            params,
                            pipNotesCache.onNoteUpdate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                sendNote: function (params, successCallback, errorCallback) {
                    var note = _.clone(params.item);
                    note.party_id = pipDataSession.partyId();
                    note.recipient_id = params.recipientId;
                    note.remove_after = params.removeAfterSending;
                    params.item = note;
                    params.resource = 'sendNote';
                    params.removeAfterSending
                        ? pipDataModel.remove(params, successCallback, errorCallback)
                        : pipDataModel.update(params, successCallback, errorCallback);
                },

                deleteNote: function (params, successCallback, errorCallback) {
                    params.resource = 'notes';
                    pipDataModel.remove(params, pipNotesCache.onNoteDelete(params, successCallback), errorCallback);
                },

                addComment: function(params, successCallback, errorCallback) {
                    params.resource = 'noteComments';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.create(params, successCallback, errorCallback);
                },

                deleteComment: function(params, successCallback, errorCallback) {
                    params.resource = 'noteComments';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.remove(params, successCallback, errorCallback);
                },

                editComment: function(params, successCallback, errorCallback) {
                    params.resource = 'noteComments';
                    if (!params.item.party_id)
                        params.item.party_id = pipDataSession.partyId();

                    pipDataModel.update(params, successCallback, errorCallback);
                },
            };
        }];
        this.$get.$inject = ['$stateParams', 'pipDataModel', 'pipCacheTag', 'pipNotesCache', 'pipDataSession'];
    });

})();
