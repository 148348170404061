/**
 * @file Convert parent wizard
 * @copyright Digital Living Software Corp. 2016
 */

(function (angular, $) {
    'use strict';

    var thisModule = angular.module('pipParentConvertWizard',
        ['ngMaterial', 'pipUtils', 'pipTranslate', 'pipBasicControls.Templates', 'pipWrapper']);

    thisModule.config(['pipTranslateProvider', function (pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            'CONVERT_PARENT_TITLE': 'Choose type of the parent record',
            'CONVERT_PARENT_SUBTITLE': 'The <b>%s</b> is missing and will be' +
            ' created from scratch. Find and clarify it later',

            'CONVERT_TO_GOAL_SUBTITLE': 'Result that requires significant efforts',
            'CONVERT_TO_TASK_SUBTITLE': 'Simple work that doesn\'t need a plan',
            'CONVERT_TO_AREA_SUBTITLE': 'Area of interests or actions of any kind',
            'CONVERT_TO_VISION_SUBTITLE': 'Situation in specific time period',
            'CONVERT_TO_CANCEL': 'Do not create a parent record',
            'CONVERT_TO_CANCEL_SUBTITLE': 'The <b>%s</b> is deleted',

            'CONVERT_RECORD_TO_GOAL': 'Result that requires significant efforts',
            'CONVERT_RECORD_TO_TASK': 'Simple work that doesn\'t need a plan',
            'CONVERT_RECORD_TO_EVENT': 'Reminder or scheduled block of time',
            'CONVERT_RECORD_TO_POST': 'Any useful information',

            'CONVERT_RECORD_DETAILS_GOAL_TITLE': 'Add a task for the goal?',
            'CONVERT_RECORD_DETAILS_GOAL_BUTTON': 'Add a task for the goal',
            'CONVERT_RECORD_DETAILS_GOAL_PLACEHOLDER': 'Task',
            'CONVERT_RECORD_DETAILS_GOAL_CANCEL_BUTTON': 'The goal without the task',
            'CONVERT_RECORD_DETAILS_GOAL_SUBTITLE': 'The task will be added to the plan of action for the goal <b>[%s]</b>. It will identify what has been done and continue to work in the future.',

            'CONVERT_RECORD_DETAILS_AREA_TITLE': 'Add a task for the area?',
            'CONVERT_RECORD_DETAILS_AREA_BUTTON': 'Add a task for the area',
            'CONVERT_RECORD_DETAILS_AREA_PLACEHOLDER': 'Task',
            'CONVERT_RECORD_DETAILS_AREA_CANCEL_BUTTON': 'The area without the task',
            'CONVERT_RECORD_DETAILS_AREA_SUBTITLE': 'The task will be added to the plan of action for the area <b>[%s]</b>. It will identify what has been done and continue to work in the future.',

            'CONVERT_RECORD_DETAILS_TASK_TITLE': 'The context for the task?',
            'CONVERT_RECORD_DETAILS_TASK_BUTTON': 'The task for the <b>[%s]</b> goal',
            'CONVERT_RECORD_DETAILS_TASK_PLACEHOLDER': '+Area or goal',
            'CONVERT_RECORD_DETAILS_TASK_CANCEL_BUTTON': 'The task without context',
            'CONVERT_RECORD_DETAILS_TASK_SUBTITLE': 'Possible task <b>[%s]</b> part of something bigger. Specify the context, so as not to lose the meaning.',
            'CONVERT_RECORD_DETAILS_TASK_ERROR_HINT': 'Recording with the same name can not be found. Specify the type, if you want to create it.',

            'ADD_DEFAULT_TASK_FOR_EVENT': 'Add default task, some as event'

            // 'CHOOSE_GOAL_AREA': '+Area or goal'
        });

        pipTranslateProvider.translations('ru', {
            'CONVERT_PARENT_TITLE': 'Определите тип родительской записи',
            'CONVERT_PARENT_SUBTITLE': 'Запись <b>%s</b> отсутствует и будет создана ' +
            'с нуля. Найдите и скорректируйте ее позже',

            'CONVERT_TO_GOAL_SUBTITLE': 'Результат требующий значительных усилий',
            'CONVERT_TO_TASK_SUBTITLE': 'Простая работа, для которой не нужен план',
            'CONVERT_TO_AREA_SUBTITLE': 'Область интересов или какой-либо активности',
            'CONVERT_TO_VISION_SUBTITLE': 'Ситуация в определенный промежуток времени',
            'CONVERT_TO_CANCEL': 'Не создавать родительскую запись',
            'CONVERT_TO_CANCEL_SUBTITLE': '<b>%s</b> будет удалена',

            'CONVERT_RECORD_TO_GOAL': 'Результат требующий значительных усилий',
            'CONVERT_RECORD_TO_TASK': 'Простая работа, для которой не нужен план',
            'CONVERT_RECORD_TO_EVENT': 'Напоминание или блок времени в расписании',
            'CONVERT_RECORD_TO_POST': 'Любая полезная информация',

            'CONVERT_RECORD_DETAILS_GOAL_TITLE': 'Добавить задачу для цели?',
            'CONVERT_RECORD_DETAILS_GOAL_BUTTON': 'Добавить задачу к цели',
            'CONVERT_RECORD_DETAILS_GOAL_CANCEL_BUTTON': 'Цель без задачи',
            'CONVERT_RECORD_DETAILS_GOAL_PLACEHOLDER': 'Задача',
            'CONVERT_RECORD_DETAILS_GOAL_SUBTITLE': 'Задача будет добавлена в план действий для цели <b>[%s]</b>. Это позволит определить, что было сделано и продолжить работу в будущем.',

            'CONVERT_RECORD_DETAILS_AREA_TITLE': 'Добавить задачу для области действия?',
            'CONVERT_RECORD_DETAILS_AREA_BUTTON': 'Добавить задачу к области действия',
            'CONVERT_RECORD_DETAILS_AREA_CANCEL_BUTTON': 'Область действия без задачи',
            'CONVERT_RECORD_DETAILS_AREA_PLACEHOLDER': 'Задача',
            'CONVERT_RECORD_DETAILS_AREA_SUBTITLE': 'Задача будет добавлена в план действий для области действия <b>[%s]</b>. Это позволит определить, что было сделано и продолжить работу в будущем.',

            'CONVERT_RECORD_DETAILS_TASK_TITLE': 'Контекст для задачи?',
            'CONVERT_RECORD_DETAILS_TASK_BUTTON': 'Задача к <b>[%s]</b> цели',
            'CONVERT_RECORD_DETAILS_TASK_PLACEHOLDER': '+Область или цель',
            'CONVERT_RECORD_DETAILS_TASK_CANCEL_BUTTON': 'Задача без контекста',
            'CONVERT_RECORD_DETAILS_TASK_SUBTITLE': 'Возможно задача <b>[%s]</b> часть чего-то большего. Укажите контекст, чтобы не потерять общую картину.',
            'CONVERT_RECORD_DETAILS_TASK_ERROR_HINT': 'Запись с таким именем не найдена. Укажите тип, если хотите ее создать.',

            'ADD_DEFAULT_TASK_FOR_EVENT': 'Добавить задачу по умолчанию, такую же как событие'

            // 'CHOOSE_GOAL_AREA': '+Область или цель'
        });
    }]);

    thisModule.factory('pipParentConvertWizard',
        ['$mdDialog', function ($mdDialog) {
            return {
                show: function (params, successCallback, cancelCallback) {
                    if (params.$event) {
                        params.$event.stopPropagation();
                        params.$event.preventDefault();
                    }
                    //  if (!params.options || params.options.length === 0) { return; }

                    function focusToggleControl() {
                        if (params.$event && params.$event.currentTarget) {
                            params.$event.currentTarget.focus();
                        }
                    }

                    $mdDialog.show({
                        targetEvent: params.$event,
                        templateUrl: 'parent_chips/parent_convert_wizard.html',
                        controller: 'pipParentConvertWizardController',
                        locals: { params: params },
                        clickOutsideToClose: false
                    })
                        .then(function (option) {
                            focusToggleControl();

                            if (successCallback) { successCallback(option); }
                        }, function () {
                            focusToggleControl();
                            if (cancelCallback) { cancelCallback(); }
                        });
                }
            };
        }]
    );

    thisModule.controller('pipParentConvertWizardController',
        ['$scope', '$rootScope', '$mdDialog', '$log', '$timeout', 'params', 'pipUtils', 'pipTranslate', 'pipEnums', function ($scope, $rootScope, $mdDialog, $log, $timeout, params, pipUtils, pipTranslate, pipEnums) {

            $scope.step = 'choose_type';
            $scope.task = {};

            var debounceBlur = _.debounce(onBlur, 50);

            $scope.contextNotEmpty = false;
            $scope.recordNotFound = false;

            $scope.convertedRecord = [
                {
                    name: pipEnums.REFERENCE_TYPE.GOAL,
                    title: pipEnums.REFERENCE_TYPE.GOAL.toUpperCase(),
                    subtitle: 'CONVERT_TO_GOAL_SUBTITLE',
                    details: {
                        detailsTitle: 'CONVERT_RECORD_DETAILS_GOAL_TITLE',
                        detailsSubTitle: 'CONVERT_RECORD_DETAILS_GOAL_SUBTITLE',
                        withParents: false,
                        detailsOptions: [], // for choose context type
                        contextType: pipEnums.GOAL_TYPE.TASK, // context type without choose
                        placeholder: 'CONVERT_RECORD_DETAILS_GOAL_PLACEHOLDER',
                        detailsButton: 'CONVERT_RECORD_DETAILS_GOAL_BUTTON',
                        detailsCancelButton: 'CONVERT_RECORD_DETAILS_GOAL_CANCEL_BUTTON'

                    }
                },
                {
                    name: pipEnums.REFERENCE_TYPE.AREA,
                    title: pipEnums.REFERENCE_TYPE.AREA.toUpperCase(),
                    subtitle: 'CONVERT_TO_AREA_SUBTITLE',
                    details: {
                        detailsTitle: 'CONVERT_RECORD_DETAILS_AREA_TITLE',
                        detailsSubTitle: 'CONVERT_RECORD_DETAILS_AREA_SUBTITLE',
                        withParents: false,
                        detailsOptions: [], // for choose context type
                        contextType: pipEnums.GOAL_TYPE.TASK, // context type without choose
                        placeholder: 'CONVERT_RECORD_DETAILS_AREA_PLACEHOLDER',
                        detailsButton: 'CONVERT_RECORD_DETAILS_AREA_BUTTON',
                        detailsCancelButton: 'CONVERT_RECORD_DETAILS_AREA_CANCEL_BUTTON'
                    }
                },
                {
                    name: pipEnums.REFERENCE_TYPE.VISION,
                    title: pipEnums.REFERENCE_TYPE.VISION.toUpperCase(),
                    subtitle: 'CONVERT_TO_VISION_SUBTITLE'
                },
                {
                    name: pipEnums.GOAL_TYPE.TASK,
                    title: pipEnums.GOAL_TYPE.TASK.toUpperCase(),
                    subtitle: 'CONVERT_TO_TASK_SUBTITLE',
                    details: {
                        detailsTitle: 'CONVERT_RECORD_DETAILS_TASK_TITLE',
                        detailsSubTitle: 'CONVERT_RECORD_DETAILS_TASK_SUBTITLE', // [record_name]
                        detailsErrorHint: 'CONVERT_RECORD_DETAILS_TASK_ERROR_HINT',
                        withParents: true,
                        detailsOptions: [
                            {
                                name: pipEnums.REFERENCE_TYPE.GOAL,
                                title: pipEnums.REFERENCE_TYPE.GOAL.toUpperCase(),
                                subtitle: 'CONVERT_TO_GOAL_SUBTITLE'
                            },
                            {
                                name: pipEnums.REFERENCE_TYPE.AREA,
                                title: pipEnums.REFERENCE_TYPE.AREA.toUpperCase(),
                                subtitle: 'CONVERT_TO_AREA_SUBTITLE'
                            }
                        ],
                        placeholder: 'CONVERT_RECORD_DETAILS_TASK_PLACEHOLDER',
                        detailsButton: 'CONVERT_RECORD_DETAILS_TASK_BUTTON', // [имя существующей цели]
                        detailsCancelButton: 'CONVERT_RECORD_DETAILS_TASK_CANCEL_BUTTON'
                    }
                }
            ];

            $scope.optionDetails = [];

            $scope.theme = $rootScope.$theme;
            $scope.title = params.title || 'CONVERT_PARENT_TITLE';
            $scope.recordName = params.recordName || '';
            $scope.subtitle = params.subtitle ||
            pipUtils.sprintf(pipTranslate.translate('CONVERT_PARENT_SUBTITLE'), $scope.recordName);

            $scope.subOptions = []; // params.subOptions || [];
            $scope.parentsCollection = params.subParentsCollection || [];
            $scope.options = params.options;
            $scope.convertedCollection = getConvertedCollection($scope.options, $scope.convertedRecord);
            $scope.eventTitle = params.event.title;

            $scope.selectedIndex = -1;
            $scope.cancelConverting = !!$scope.recordName;
            $scope.selectedOptionName = '';

            if ($scope.cancelConverting) {
                $scope.convertedCollection.push({
                    name: 'cancel',
                    title: 'CONVERT_TO_CANCEL',
                    subtitle: pipUtils.sprintf(pipTranslate.translate('CONVERT_TO_CANCEL_SUBTITLE'), $scope.recordName)
                });
            }

            $scope.onOptionSelect = onOptionSelect;
            $scope.onKeyPress = onKeyPress;
            $scope.onKeyDown = onKeyDown;
            $scope.onCancel = onCancel;
            $scope.onSelect = onSelect;

            $scope.onWizardBackClick = onWizardBackClick;
            $scope.onTaskWithoutContext = onTaskWithoutContext;
            $scope.onTaskWithParent = onTaskWithParent;
            $scope.onTaskByEvent = onTaskByEvent;
            $scope.onOptionDetailsSelect = onOptionDetailsSelect;
            $scope.getMatches = getMatches;
            $scope.onItemChange = onItemChange;
            $scope.onBlur = onBlur;
            $scope.onTaskChange = onTaskChange;

            setTimeout(focusInput, 500);

            // add class to title animation
            setTimeout(function () {
                var element = $('.pip-wrapper');

                element.addClass('pip-dialog-animate');
            }, 300);

            $scope.$watch('task.searchText',
                function (value) {
                    if ($scope.task.searchText === '' ) {
                        debounceBlur();
                    }
                },
                true
            );


            return;

            //+
            function getConvertedCollection(options, convertedRecord) {
                if (!(options && Array.isArray(options) && options.length > 0))
                    return convertedRecord;
                else return _.filter(convertedRecord, function(item) {
                    return options.indexOf(item.name) > -1;
                });
            };

            // check details for
            function optionsDetails(optionName) {
                var obj;

                obj = _.find($scope.convertedCollection, {name: optionName});

                if (obj && obj.details) {
                    return obj.details;
                } else {
                    return null;
                }
            }

            // set detailsbutton
            function setDetailsButton() {
                var recordName = '';

                if ($scope.contentDetails) {
                    recordName = $scope.contentDetails.translateTitle;
                }

                $scope.detailsButton = pipUtils.sprintf(pipTranslate.translate($scope.optionDetails.detailsButton), recordName);
            }

            function getParents(collection, optionDetails) {
                var result = [];

                if (!collection) {
                    return result;
                }

                if (!optionDetails || !optionDetails.detailsOptions || optionDetails.detailsOptions.length === 0) {
                    return result;
                }
                result = _.filter(collection, function(item) {
                    var i;

                    for (i = 0; i < optionDetails.detailsOptions.length; i++) {
                        if (item.ref_type === optionDetails.detailsOptions[i].name) { return true; }
                    }

                    return false;
                });

                return result;
            }

            function startWizard(optionName) {
                var optionDetails;

                optionDetails = optionsDetails(optionName);

                if (!optionDetails) { return; }

                $scope.contextNotEmpty = false;
                $scope.recordNotFound = true;

                // filter parent if this need
                $scope.subParentsCollection = getParents($scope.parentsCollection, optionDetails);

                // set subdetailstitle
                $scope.subDetailsTitle = pipUtils.sprintf(pipTranslate.translate(optionDetails.detailsSubTitle), $scope.recordName);

                $scope.optionDetails = optionDetails;
                setDetailsButton();
                $scope.step = 'task_content';
                $scope.task.searchText = '';
                $scope.task.name = '';

            }

            function onOptionDetailsSelect(event, option) {
                event.stopPropagation();

                // add information about new parent to returned (selected) object
                $scope.contentDetails.type = option.name;
                $scope.onSelect();
            }

            function onTaskWithParent(event) {
                event.stopPropagation();

                $scope.onSelect();
            }

            function onTaskByEvent(event) {
                event.stopPropagation();
                $scope.contentDetails = {
                    title: params.event.title,
                    type: $scope.optionDetails.contextType || 'task'
                };

                $scope.onSelect();
            }

            function onTaskWithoutContext(event) {
                event.stopPropagation();

                $scope.contentDetails = null;
                $scope.onSelect();
            }

            function filterSearch(collection, selectedParent, searchText) {
                var queryLowercase = searchText.toLowerCase(),
                    title, goalArray;

                // delete selected parent
                if (selectedParent && selectedParent.id) {
                    _.remove(collection, {id: selectedParent.id});
                }

                goalArray = _.filter(collection, function (item) {
                    if (item.title) {
                        title = item.type === pipEnums.AREA_TYPE.CATEGORY
                            ? pipTranslate.translate(item.title) : item.title;

                        return title.toLowerCase().indexOf(queryLowercase) >= 0;
                    } else { return false; }
                }) || [];

                // формируем спискок для вывода
                _.each(goalArray, function (item) {
                    item.translateTitle = item.type === pipEnums.AREA_TYPE.CATEGORY
                        ? pipTranslate.translate(item.title) : item.title;
                });

                return goalArray;
            }

            function searchByTitle(query, collection) {
                var result = null,
                    queryLowercase,
                    resultArray;

                if (!query || !collection || collection.length === 0) {
                    return result;
                }

                queryLowercase = query.toLowerCase();
                resultArray = _.filter(collection,
                    function (item) {
                        return item.title && item.title.toLowerCase() === queryLowercase;
                    }) || [];

                if (resultArray.length > 0) {
                    result = _.cloneDeep(resultArray[0]);
                }

                return result;
            }

            function onItemChange() {
                onBlur();
            }

            function onTaskChange() {

                if ($scope.task.name) {
                    $scope.recordNotFound = false;
                    $scope.contextNotEmpty = true;
                    $scope.contentDetails = {
                        title: $scope.task.name,
                        // withParents = !!$scope.optionDetails.withParents,
                        type: $scope.optionDetails.contextType || 'task'
                    };
                } else {
                    $scope.recordNotFound = true;
                    $scope.contextNotEmpty = false;
                }

            }
            function onBlur() {
                var parent;

                if ($scope.task.parent && $scope.task.parent.id) {
                    $timeout(function () {
                        $scope.contextNotEmpty = true;
                        $scope.recordNotFound = false;
                    }, 0);

                    $scope.contentDetails = $scope.task.parent;
                    // $scope.contentDetails.withParents = !!$scope.optionDetails.withParents
                } else if ($scope.task.searchText === '') {
                    $timeout(function () {
                        $scope.contextNotEmpty = false;
                        $scope.recordNotFound = true;
                    }, 0);

                    $scope.contentDetails = null;
                } else {
                    parent = searchByTitle($scope.task.searchText, $scope.subParentsCollection);
                    if (parent) {
                        $scope.contentDetails = parent;
                        $timeout(function () {
                            $scope.contextNotEmpty = true;
                            $scope.recordNotFound = false;
                        }, 0);

                    } else {
                        $scope.contentDetails = {
                            title: $scope.task.searchText,
                            translateTitle: $scope.task.searchText
                        };
                        $timeout(function () {
                            $scope.contextNotEmpty = true;
                            $scope.recordNotFound = true;
                        }, 0);

                    }
                }

                setDetailsButton();
                // $scope.$apply();
            }

            function sortDefault(item) {
                return item.title;
            }

            function getMatches() {
                var result = _.cloneDeep($scope.subParentsCollection);

                if ($scope.task.searchText === '') {
                    debounceBlur();

                    return result;
                }

                result = filterSearch(result, $scope.task.parent, $scope.task.searchText);

                result = _.sortBy(result, sortDefault);
                debounceBlur();

                return result;
            }

            function onOptionSelect(event, option) {
                var optionDetails;

                event.stopPropagation();
                $scope.selectedOptionName = option.name;

                optionDetails = optionsDetails($scope.selectedOptionName);

                if (optionDetails) {
                    // show wizard
                    startWizard($scope.selectedOptionName);
                } else {
                    $scope.onSelect();
                }
            }

            function onWizardBackClick() {
                $scope.step = 'choose_type';
                $scope.contentDetails = null;
            }

            function onKeyPress(event) {
                if (event.keyCode === 32 || event.keyCode === 13) {
                    event.stopPropagation();
                    event.preventDefault();
                    var option = $scope.selectedIndex === -1
                        ? $scope.convertedCollection[0] : $scope.convertedCollection[$scope.selectedIndex];
                    //$scope.onSelect(  );
                    onOptionSelect(null, option);
                    return;
                }
            }

            function onKeyDown(event) {
                if (event.keyCode === 40) {
                    event.stopPropagation();
                    event.preventDefault();
                    $scope.selectedIndex += 1;
                    $scope.selectedIndex = $scope.selectedIndex % $scope.convertedCollection.length;
                    $scope.selectedOptionName = $scope.convertedCollection[$scope.selectedIndex].name;

                    return;
                }
                if (event.keyCode === 38) {
                    event.stopPropagation();
                    event.preventDefault();
                    $scope.selectedIndex = $scope.selectedIndex <= 0
                        ? $scope.selectedIndex = $scope.convertedCollection.length - 1 : $scope.selectedIndex - 1;
                    $scope.selectedOptionName = $scope.convertedCollection[$scope.selectedIndex].name;

                    return;
                }
            }

            function onCancel() {
                $mdDialog.cancel();
            }

            function onSelect() {
                var option = _.find($scope.convertedCollection, {name: $scope.selectedOptionName});

                if (option && option.name !== 'cancel') {
                    if ($scope.contentDetails) {
                        $scope.contentDetails.withParents = !!$scope.optionDetails.withParents;
                    }
                    $mdDialog.hide({option: option, contentDetails: $scope.contentDetails});
                } else {
                    $mdDialog.hide(null);
                }
            }

            // Setting focus to input control
            function focusInput() {
                var list = $('.pip-parent-convert-wizard .pip-list');

                list.focus();
            }

        }]
    );

})(window.angular, window.jQuery);
