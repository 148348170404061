/**
 * @file Sharing details control
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global _, angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipComments",
        []);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            'BOTTOM_UP_PLACEHOLDER': '+Area or goal'
        });
        pipTranslateProvider.translations('ru', {
            'BOTTOM_UP_PLACEHOLDER': '+Область или цель'
        });
    }]);

    thisModule.directive('pipComments',
        function () {
            return {
                restrict: 'E',
                scope:  {
                    item: '=pipItem',
                    addCommentInNote: '=pipAddComment',
                    user:'=',
                    deleteComment:'=pipDeleteComment',
                    updateComment:'=pipUpdateComment'
            },
                templateUrl: 'comments/comments.html',
                controller: 'pipCommentsController',
                
            };
        }
    );

    thisModule.controller('pipCommentsController',
        ['$scope', 'pipToasts', 'pipStrings', 'pipTranslate', '$rootScope', 'pipUtils', function ($scope,  pipToasts, pipStrings, pipTranslate, $rootScope, pipUtils) {

            $scope.pictureIds = [];
            $scope.documentsIds= [];

            $scope.commetsPlaceholder = 'Type something';
            $scope.onAddComment = onAddComment;
            $scope.onDocumentListCreated = onDocumentListCreated;
            $scope.onPictureListCreated = onPictureListCreated;
            $scope.openCamera = openCamera;
            $scope.openDocument = openDocument;
            $scope.onReplyClick = onReplyClick;
            $scope.onUpdate = onUpdate;
            $scope.onUpdateComment = onUpdateComment;
            $scope.addCallback = addCallback;


            function setFocusToComment(duration) {
                setTimeout(function () {
                    pipUtils.scrollTo('.pip-comment-content', '#input-comment');
                    var input = $('#input-comment').focus();
                }, duration || 1000);
            };
            
            function onReplyClick(comment) {
                $scope.$parent.commentInput = (comment && comment.creator_name) ? comment.creator_name + ', ' : '';
                setFocusToComment(200);
            };

            function onUpdate(comment) {
                $scope.oldComment = comment;
                $scope.$parent.commentInput = comment.content[0].text;
                $scope.pictureIds = comment.content[1].pic_ids || [];
                $scope.documentsIds = comment.content[2].docs || [];
                if(comment.content[1].pic_ids.length > 0){
                   // $scope.pictureList = [];
                    $scope.camera = true;
                    $scope.document = false;
                }
                if($scope.documentsIds.length > 0){
                    //$scope.documentList = [];
                    $scope.document = true;
                    $scope.camera = false;
                }
                $scope.update = true;
                setFocusToComment(200);
            };
            
            function disButtonsend() {
                return (!$scope.pictureList || $scope.pictureList.items.length == 0) && (!$scope.documentList || $scope.documentList.items.length == 0) && ($scope.$parent.commentInput == '' || !$scope.$parent.commentInput);
            }


            function onPictureListCreated($event) {
                $scope.pictureList = $event.sender;
            };
            function onDocumentListCreated($event) {
                $scope.documentList = $event.sender;
            }
            function openCamera() {
                $scope.camera = true;
            }

            function openDocument() {
                $scope.document = true;
                $scope.documentsIds = [];
            }

            function addCallback() {
                $scope.pictureIds = [];
                $scope.documentsIds = [];
                $scope.pictureIds = angular.extend($scope.pictureIds, []);
                $scope.$parent.commentInput = '';
                $scope.camera = false;
                $scope.document = false;
                $scope.update = false;
            }
            function onUpdateComment() {
                
                if (disButtonsend()) {
                    pipToasts.showError(pipTranslate.translate("NOTES_ERROR"));
                } else {
                    $scope.oldComment.content[0].text = $scope.$parent.commentInput;
                    if ($scope.pictureList) {
                        $scope.pictureList.save(
                            function (r) {
                                $scope.oldComment.content[1].pic_ids = $scope.pictureIds;
                                $scope.updateComment($scope.oldComment,addCallback);
                                //$scope.addCommentInNote(c,addCallback);
                            },
                            function (error) {
                                console.error(error); // eslint-disable-line
                            }
                        );

                    } else {
                        if ($scope.documentList) {
                            $scope.documentList.save(
                                function (r) {
                                    $scope.oldComment.content[2].docs = $scope.documentsIds;
                                    //$scope.addCommentInNote(c, addCallback);

                                    $scope.updateComment($scope.oldComment,addCallback);
                                }
                            )
                        } else {
                            $scope.updateComment($scope.oldComment,addCallback);
                        }

                    }
                }
                
            }
            
            function onAddComment(item) {
                var c = {
                    creator_id: $scope.user.id,
                    creator_name: $scope.user.name,
                    content: [{
                        type: 'text',
                        text:  $scope.$parent.commentInput,
                        pic_ids: [],
                        docs: []
                    },
                        {type: 'pictures', pic_ids: []},
                        {type: 'documents', docs: []}],
                    parent_id: item.id,
                    created: new Date()

                }, localComments = item.comments, index = 0;

                if (disButtonsend()) {
                    pipToasts.showError(pipTranslate.translate("NOTES_ERROR"));
                } else {
                    if ($scope.pictureList) {
                        $scope.pictureList.save(
                            function (r) {
                                c.content[1].pic_ids = $scope.pictureIds;
                                if ($scope.documentList) {
                                    $scope.documentList.save(
                                        function (r) {
                                            c.content[2].docs = $scope.documentsIds;
                                            $scope.addCommentInNote(c,addCallback);
                                        }
                                    )
                                } else {
                                    $scope.addCommentInNote(c, addCallback);
                                }
                            },
                            function (error) {
                                console.error(error); // eslint-disable-line
                            }
                        );

                    } else {
                        if ($scope.documentList) {
                            $scope.documentList.save(
                                function (r) {
                                    c.content[2].docs = $scope.documentsIds;
                                    $scope.addCommentInNote(c, addCallback);
                                }
                            )
                        } else {
                            $scope.addCommentInNote(c, addCallback);
                        }

                    }
                }

            }


        }]
    );
})();

