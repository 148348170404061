<!--
@file Visibility details control content
@copyright Digital Living Software Corp. 2014-2016
-->

<md-list class="pip-menu layout-column">
    <div class="pip-body layout-column">
        <md-progress-linear ng-show="transaction.busy()" md-mode="indeterminate" class="pip-progress-top">
        </md-progress-linear>
        <!-- confident options -->
        <div class="bm24 flex-fixed layout-row layout-align-start-center"
             ng-if="showConfident">
            <div class="text-subhead1 opacity-primary rm16 flex" ng-bind-html="selected.confidDiscription">
            </div>
            <md-button class="m0 md-raised md-accent flat flex-fixed"
                       ng-click="onConfidentChange($event)"
                       ng-disabled="selected.shareDef && showConfident || transaction.busy() || ngDisabled()"
                       aria-label="CHANGE_TYPE">
                {{::'VISIBILITY_CHANGE' | translate}}
            </md-button>
        </div>
        <!-- default switch -->
        <div class="h48 flex-fixed layout-row layout-align-start-center"
             ng-if="!selected.hideDefault">
            <md-switch ng-model="selected.shareDef"
                       ng-disabled="transaction.busy() || ngDisabled()"
                       class="flex-fixed"
                       ng-change="onDefault()" aria-label="DEFAULT_VISIBILITY">
            </md-switch>
            <label class="text-body2 color-secondary-text flex">{{::'VISIBILITY_DETAILS_DEFAULT_VISIBILITY' | translate}}</label>
        </div>
        <div class="pip-divider text-subhead1 flex-fixed"></div>
        <!-- Share level options -->
        <div class="tm16 bm8 flex-fixed"
             ng-if="(selected.confid != confidentClassified) && (selected.confid != confidentSecret)">
            <p class="text-caption opacity-disabled m0">{{::'VISIBILITY_DETAILS_SHARE_LEVEL' | translate}}</p>
            <md-select  class="w-stretch tm0 bm16 text-subhead1"
                        ng-model="selected.shareLevel"
                        ng-disabled="selected.shareDef || transaction.busy() || ngDisabled()"
                        ng-class="{'cursor-auto color-secondary-text': selected.shareDef, 'ie': isIe()}"
                        ng-change="onChangeShareLevel()">
                <md-option ng-value="opt.id" ng-repeat="opt in shareLevelCollection | filter : {visible: true}" >
                    <md-icon class="share-level-icon rm8" md-svg-icon="icons:{{opt.icon}}"></md-icon>
                    <span class="flex">{{ ::opt.nameLocal }}</span>
                </md-option>
            </md-select>
        </div>

<div ng-switch on="{{!selected.shareDef}}">
    <div ng-switch-when="true">
            <div class="flex=fixed" xxxxng-show="selected.confid != confidentSecret && !transaction.busy() && !selected.shareDef && !ngDisabled()" ng-cloak>
                <p class="text-caption opacity-disabled m0">{{::'VISIBILITY_DETAILS_CONTACTS' | translate}}</p>
                <md-contact-chips ng-model="selected.share_connections"
                                ng-disabled="selected.shareDef || transaction.busy()"
                                md-contacts="getConnections($query)"
                                md-contact-name="party_name"
                                md-contact-image="image"
                                placeholder="{{::'VISIBILITY_DETAILS_CONTACT_PLACEHOLDER' | translate}}"
                                class="w-stretch visibility-contact">
                </md-contact-chips>
            </div>
    </div>
    <div ng-switch-when="false">
            <div style="padding-top:9px; padding-left: 3px;" class="flex=fixed" ng-cloak
                xxxxng-hide="selected.confid != confidentSecret && !transaction.busy() && !selected.shareDef && !ngDisabled()" >
                <pip-party-list
                        pip-parties="selected.share_connections" pip-rebind="true">
                </pip-party-list>
            </div>    
    </div>
</div>
        <!--<div class="flex=fixed" ng-show="selected.confid != confidentSecret && !transaction.busy() && !selected.shareDef && !ngDisabled()" ng-cloak>
            <p class="text-caption opacity-disabled m0">{{::'VISIBILITY_DETAILS_CONTACTS' | translate}}</p>
            <md-contact-chips ng-model="selected.share_connections"
                              ng-disabled="selected.shareDef || transaction.busy()"
                              md-contacts="getConnections($query)"
                              md-contact-name="party_name"
                              md-contact-image="image"
                              placeholder="{{::'VISIBILITY_DETAILS_CONTACT_PLACEHOLDER' | translate}}"
                              class="w-stretch visibility-contact">
            </md-contact-chips>
        </div>
        <div style="padding-top:9px; padding-left: 3px;" class="flex=fixed" ng-cloak
             ng-hide="selected.confid != confidentSecret && !transaction.busy() && !selected.shareDef && !ngDisabled()" >
            <pip-party-list
                    pip-parties="selected.share_connections" pip-rebind="true">
            </pip-party-list>
        </div>-->

        <div class="flex layout-column layout-align-center-center pip-visibility-empty">
            <div ng-if="selected.confid == confidentSecret"
                 class="pip-empty layout-column layout-align-center-center">
                <img src="images/ES_VisibilitySecret.svg" class="pip-pic block">
            </div>
        </div>

    </div>
</md-list>
