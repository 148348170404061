/**
 * @file Visibility details control
 * @copyright Digital Living Software Corp. 2014-2016
 */
 
/* global _, angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipVisibilityDetails",
        ['pipUtils', 'pipRest.Enums', 'pipTranslate', 'pipImageUtils', 'pipLifeCommons.Templates']);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            'VISIBILITY_DETAILS_DEFAULT_VISIBILITY': 'Default visibility (as parent records have)',
            'VISIBILITY_CONFIDENT_DIALOG_TITLE': 'Change visibility constraints',
            'VISIBILITY_DETAILS_SHARE_LEVEL': 'Who can see this record',
            'DESCRIPTION_CONFIDENTIALITY_0': 'Participants can share this information with anybody',
            'DESCRIPTION_CONFIDENTIALITY_1': 'Participants are not allowed to share this information publically',
            'DESCRIPTION_CONFIDENTIALITY_2': 'Participants are allowed to open this information only to selected invividuals',
            'DESCRIPTION_CONFIDENTIALITY_3': 'This is a secret. Only active participants can see this',
            'VISIBILITY_CHANGE': 'Change',
            'VISIBILITY_DETAILS_CONTACTS': 'Anyone tagged here will be able to see this record',
            'VISIBILITY_DETAILS_CONTACT_PLACEHOLDER': '+Add parties and groups'
        });
        pipTranslateProvider.translations('ru', {
            'VISIBILITY_DETAILS_DEFAULT_VISIBILITY': 'Видимость по умолчанию (такие же, как у родительских записей)',
            'VISIBILITY_CONFIDENT_DIALOG_TITLE': 'Изменить ограничения видимости',
            'VISIBILITY_DETAILS_SHARE_LEVEL': 'Кто может видеть эту запись',
            'DESCRIPTION_CONFIDENTIALITY_0': 'Участники могут открыть эту информацию кому угодно',
            'DESCRIPTION_CONFIDENTIALITY_1': 'Участники не могут открыть эту информацию широкой публике',
            'DESCRIPTION_CONFIDENTIALITY_2': 'Участники могут открыти эту информацию только определенным людям',
            'DESCRIPTION_CONFIDENTIALITY_3': 'Это секрет. Только активные участники могут это видеть',
            'VISIBILITY_CHANGE': 'Изменить',
            'VISIBILITY_DETAILS_CONTACTS': 'Каждый выбранный сможет видеть эту запись',
            'VISIBILITY_DETAILS_CONTACT_PLACEHOLDER': '+Добавить контакты и группы'
        });
    }]);

    thisModule.directive('pipVisibilityDetails',
        function () {
            return {
                restrict: 'EA',
                scope: {
                    entity: '=pipEntity',
                    parties: '=pipParties',
                    groups: '=pipGroups',
                    defaultEntityOptions: '=pipDefaultEntityOptions',
                    saveEntity: '=pipSaveEntity',
                    hideDefault: '=pipHideDefault',
                    transaction: '=pipVisibilityDetailsTransaction'
                },
                templateUrl: 'visibility/visibility_details.html',
                controller: 'pipVisibilityDetailsController'             
            };
        }
    );

    thisModule.controller('pipVisibilityDetailsController',
        ['$scope', '$element', '$attrs', '$parse', '$rootScope', '$state', 'pipCollections', 'pipEnums', 'pipTranslate', 'AccessoryService', 'pipUtils', 'pipImageUtils', '$window', 'pipOptionsBigDialog', 'pipDataAvatar', function ($scope, $element, $attrs, $parse, $rootScope, $state, pipCollections, pipEnums, pipTranslate,
                  AccessoryService, pipUtils, pipImageUtils, $window, pipOptionsBigDialog, pipDataAvatar) {

            checkParameter();
            setTimeout(function () {
                var newVisibilityElement = $('.md-autocomplete-suggestions-container');
                newVisibilityElement.addClass('pip-visibility-details');
            }, 1000);

            $scope.shareLevel = [
                {id: pipEnums.SHARE_LEVEL.WORLD, name: 'SHARE_LEVEL_0', icon: 'world', visible: true},
                {id: pipEnums.SHARE_LEVEL.OUTER, name: 'SHARE_LEVEL_1', icon: 'people-white', visible: true},
                {id: pipEnums.SHARE_LEVEL.INNER, name: 'SHARE_LEVEL_2', icon: 'people', visible: true},
                {id: pipEnums.SHARE_LEVEL.PRIVATE, name: 'SHARE_LEVEL_3', icon: 'person', visible: true}
            ];
            $scope.shareLevelCollection = _.cloneDeep($scope.shareLevel);

            $scope.confidentSecret = pipEnums.CONFIDENTIALITY.SECRET;
            $scope.confidentClassified = pipEnums.CONFIDENTIALITY.CLASSIFIED;
            $scope.selected = {};
            initData();

            $scope.onConfidentChange = onConfidentChange;
            $scope.getConnections = getConnections;
            $scope.onChangeShareLevel = onChangeShareLevel;
            $scope.onDefault = onDefault;
            $scope.onPartyClick = onPartyClick;
            $scope.onGroupClick = onGroupClick;
            $scope.isIe = isIe;

            var update = _.debounce(updateShareInf, 1000);

            $scope.$watchCollection('selected.share_connections', function () {
                onChangeShare(true);
            });

            $scope.$watch('entity', entityChanged);
            
            // Add class
            $element.addClass('pip-visibility-details layout-column');

            isIe();

            return;

            function isIe() {
                var sAgent = $window.navigator.userAgent;
                var Idx = sAgent.indexOf("MSIE");

                return (Idx > 0) || ($window.navigator.msManipulationViewsEnabled === true);
            }

            function checkParameter() {
                var ERROR_TITLE = 'pipContributorDetailsController error: ';
                AccessoryService.checkParameter($scope.entity, ERROR_TITLE, 'pip-entity');
                AccessoryService.checkOneCollection($scope.parties, 'pip-parties', ERROR_TITLE);
                AccessoryService.checkOneCollection($scope.groups, 'pip-groups', ERROR_TITLE);
                AccessoryService.checkParameter($scope.defaultEntityOptions, ERROR_TITLE, 'pip-default-entity-options');
                AccessoryService.checkOneFunction($scope.saveEntity, 'pip-save-entity', ERROR_TITLE);
            };

            function entityChanged(newValue) {
                $scope.entity = newValue;
                initData();
            };

            function initData() {
                if (!$scope.entity) return;

                $scope.selected.shareLevel = $scope.entity.share_level;
                $scope.selected.shareDef = $scope.entity.share_def;
                $scope.selected.hideDefault = pipUtils.toBoolean($scope.hideDefault) != false;
                $scope.selected.confid = $scope.entity.confid;
                $scope.selected.minConfid = $scope.entity.confid_min;
                $scope.selected.parent = $scope.entity.parent_source;
                $scope.selected.settings = $scope.defaultEntityOptions;

                setDefaultOption();
                initSet();

                $scope.selected.share_parties = $scope.entity.share_parties ? _.cloneDeep($scope.entity.share_parties) : [];
                $scope.selected.share_groups = $scope.entity.share_groups ? _.cloneDeep($scope.entity.share_groups) : [];

                $scope.selected.share_connections = getShareCollection($scope.selected.share_parties, $scope.selected.share_groups);
                $scope.selected.groups = $scope.groups || [];
                $scope.parties = $scope.parties ? $scope.parties : [];
                $scope.selected.connections = _.map(
                    _.uniq(
                        _.filter(
                            $scope.parties,
                            function(item){
                                return (item.type != 'follow' && item.accept == 'accepted')
                            }
                        ),
                        'to_party_id'),
                    function (item) {
                        return {
                            party_id: item.to_party_id,
                            party_name: item.to_party_name
                        }
                    }
                );

                $scope.selected.cons = getShareCollection($scope.selected.connections, $scope.selected.groups);

                shareSet($scope.selected.confid);
                $scope.showConfident = true;
            };

            function updateShareInf() {
                if (!$scope.isUpdate) {
                    $scope.isUpdate = true;
                    return;
                }

                $scope.entity.share_def = $scope.selected.shareDef;
                $scope.entity.share_level = $scope.selected.shareLevel;
                $scope.entity.confid = $scope.selected.confid;
                $scope.entity.confid_min = $scope.selected.minConfid;
                $scope.entity.share_parties = $scope.selected.share_parties;
                $scope.entity.share_groups = $scope.selected.share_groups;

                if ($scope.saveEntity) {
                    $scope.saveEntity();
                }
            };

            function initSet() {
                $scope.confidentCollection = [];
                _.each(pipEnums.CONFIDENTIALITY, function (value) {
                    if ($scope.selected.minConfid && value > $scope.selected.minConfid) return;
                    $scope.confidentCollection.push({
                        id: value,
                        name: value,
                        title: 'CONFIDENTIALITY_' + value,
                        subtitle: 'DESCRIPTION_CONFIDENTIALITY_' + value,
                        active: $scope.selected.confid == value
                    });
                });
                $scope.selected.confidDiscription = pipTranslate.translate($scope.confidentCollection[$scope.selected.confid].subtitle);
            };

            function shareSet(confident) {
                $scope.shareLevelCollection = [];
                $scope.shareLevelCollection = _.cloneDeep($scope.shareLevel);
                if (confident != 0) {
                    $scope.shareLevelCollection[0].visible = false;
                    $scope.selected.shareLevel = $scope.selected.shareLevel == 0 ? 1 : $scope.selected.shareLevel;
                }
                $scope.selected.shareIndex = _.findIndex($scope.shareLevelCollection, {id: $scope.selected.shareLevel});
                pipTranslate.translateObjects($scope.shareLevelCollection, 'name', 'nameLocal');
            };

            function setDefaultOption() {
                if (!$scope.selected.settings) $scope.selected.settings = {};
                if (!$scope.selected.settings.shareLevel)  $scope.selected.settings.shareLevel = pipEnums.SHARE_LEVEL.PRIVATE;
                if (!$scope.selected.settings.confid)  $scope.selected.settings.shareLevel = pipEnums.CONFIDENTIALITY.PUBLIC;
                if (!$scope.selected.settings.confidMin)  $scope.selected.settings.shareLevel = pipEnums.CONFIDENTIALITY.PUBLIC;
            };

            function getShareCollection(parties, groups) {
                var result = [];

                _.each(groups, function (group){
                    group.entity_type = 'group';
                    group.group_id = group.group_id ? group.group_id : group.id;
                    group.group_name = group.group_name ? group.group_name : group.name;
                    group.party_name = group.group_name;
                    result.push(group);
                });

                _.each(parties, function (party){
                    party.entity_type = 'party';
                    party.image = pipDataAvatar.getAvatarUrl(party.party_id, party.party_name, $rootScope.$party.id);
                    result.push(party);
                });

                return result;
            };

            function getConnections($query) {
                var result = _.filter($scope.selected.cons, function (item) {
                    var name = item.party_name;

                    return name.toLowerCase().indexOf($query.toLowerCase()) > -1;
                });

                var index;
                for(var i = 0; i < $scope.selected.share_connections.length; i++) {
                    var con_item = $scope.selected.share_connections[i];

                    if (con_item.enity_type == 'group')
                        index = _.findIndex(result, {group_id: con_item.group_id});
                    else
                        index = _.findIndex(result, {party_id: con_item.party_id});
                    if (index > -1) {
                        result.splice(index, 1);
                        i --; // !!
                    }
                }
                return result;
            };

            function onChangeShareLevel() {
                $scope.selected.shareIndex = _.findIndex($scope.shareLevelCollection, {id: $scope.selected.shareLevel});
                $scope.isUpdate = true;
                update();
            };

            function onConfidentChange($event) {
                pipOptionsBigDialog.show(
                    {
                        event: event,
                        title: 'VISIBILITY_CONFIDENT_DIALOG_TITLE',
                        options: $scope.confidentCollection,
                        appleButtonTitle: 'VISIBILITY_CHANGE'
                    },
                    function (result) {
                        $scope.selected.confid = result.option.id;
                        $scope.entity.confid = result.option.id;
                        $scope.selected.confidDiscription = pipTranslate.translate($scope.confidentCollection[$scope.selected.confid].subtitle);
                        shareSet($scope.selected.confid);
                        $scope.isUpdate = true;
                        update();
                    }
                );
            };

            function onDefault() {
                $scope.isUpdate = true;
                update();
            };

            function onPartyClick(event, party) {
                $state.go('about_party', {party_id: party.party_id});
            };

            function onGroupClick(event, group) {
                $state.go('about_party', {id: group.id});
            };

            function compareParties(arr1, arr2) {
                if (arr1.length != arr2.length) return false;

                for(var i = 0; i < arr1.length; i++) {
                    if(arr1[i].party_id != arr1[i].party_id)
                        return false;
                }

                return true;
            };

            function compareGroups(arr1, arr2) {
                if (arr1.length != arr2.length) return false;

                for(var i = 0; i < arr1.length; i++) {
                    var id1 = arr1[i].id || arr1[i].group_id;
                    var id2 = arr2[i].id || arr2[i].group_id;
                    if(id1 != id2)
                        return false;
                }

                return true;
            };

            function onChangeShare(timeout) {
                var groups = [];
                var parties = [];
                
                var i = 0;    
                _.each($scope.selected.share_connections, function(item) {
                    if (!angular.isObject(item) || (!item.party_id && !item.group_id)) $scope.selected.share_connections.splice(i, 1)
                    i += 1;
                });
                
                $scope.selected.share_connections.forEach(function (con) {
                    if (con.entity_type == 'group')
                        groups.push(con);
                    else parties.push(con);
                });
                if (compareParties(parties, $scope.selected.share_parties)
                    && compareGroups(groups, $scope.selected.share_groups))  return;
                $scope.selected.share_parties = parties;
                $scope.selected.share_groups = groups;
                $scope.isUpdate = true;
                update();
            };

        }]
    );
})();

