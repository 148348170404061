/**
 * @file Priority edit dialog
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo
 * - Improve sample in sampler app
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipPriorityEditDialog', 
        ['ngMaterial', 'pipRest', 'pipCore', 'pipLifeCommons.Templates', 'pipControls']);

    thisModule.factory('pipPriorityEditDialog',
        ['$mdDialog', function ($mdDialog) {
            return {
                show: function (params, successCallback, cancelCallback) {
                    $mdDialog.show({
                        targetEvent: params.event,
                        controller: 'pipPriorityEditDialogController',
                        templateUrl: 'priority_edit_dialog/priority_edit_dialog.html',
                        locals: {
                            urgency: params.urgency,
                            showUrgency: params.showUrgency,
                            importance: params.importance,
                            showImportance: params.showImportance,
                            importanceCollection: params.importanceCollection,
                            urgencyCollection: params.urgencyCollection
                        },
                        clickOutsideToClose: true
                    })
                    .then(function (result) {
                        if (successCallback) {
                            successCallback(result);
                        }
                    }, function () {
                        if (cancelCallback) {
                            cancelCallback();
                        }
                    });
                }
            };
        }]
    );

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            'COMPOSE_PRIORITY' : 'Set priority',
            'COMPOSE_IMPORTANCE' : 'Set importance',
            'COMPOSE_URGENCY' : 'Set urgency'
        });
        pipTranslateProvider.translations('ru', {
            'COMPOSE_PRIORITY' : 'Задать приоритет',
            'COMPOSE_IMPORTANCE' : 'Задать важность',
            'COMPOSE_URGENCY' : 'Задать срочность'

        });
    }]);

    thisModule.controller('pipPriorityEditDialogController', 
        ['$scope', '$rootScope', '$mdDialog', 'pipEnums', 'urgency', 'importance', 'urgencyCollection', 'importanceCollection', 'showUrgency', 'showImportance', function ($scope, $rootScope, $mdDialog, pipEnums, urgency, importance, urgencyCollection, importanceCollection,
                  showUrgency, showImportance) {

            $scope.theme = $rootScope.$theme;
            $scope.urgency = urgency;
            $scope.showUrgency = showUrgency;
            $scope.importance = importance;
            $scope.showImportance = showImportance;
            $scope.importanceCollection = importanceCollection;
            $scope.urgencyCollection = urgencyCollection;

            if (showUrgency && showImportance)
                $scope.priorityTitle = 'COMPOSE_PRIORITY';
            else $scope.priorityTitle = showImportance ? 'COMPOSE_IMPORTANCE' : 'COMPOSE_URGENCY';

            $scope.onApplyClick = onApplyClick;
            $scope.onCancelClick = onCancelClick;

            return;

            function onApplyClick() {
                $mdDialog.hide({ 
                    urgency: $scope.urgency, 
                    importance: $scope.importance 
                });
            };

            function onCancelClick() {
                $mdDialog.cancel();
            };
        }]
    );

})();
