/**
 * @file PipLife Parent service, function for auto create parents entities
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo Complete refactoring +
 */

/* global angular, async, _ */

(function () {
    'use strict';

    var thisModule = angular.module('pipConvertService', []);

    thisModule.config(['pipTranslateProvider', function (pipTranslateProvider) {

        pipTranslateProvider.translations('en', {
            'TASK_TO_GOAL': 'Task converted to goal successfuly',
            'TASK_TO_POST': 'Task converted to post successfuly',
            'TASK_TO_AREA': 'Task converted to area successfuly',
            'TASK_TO_EVENT': 'Task converted to event successfuly'
        });

        pipTranslateProvider.translations('ru', {
            'TASK_TO_GOAL': 'Задача успешно сконвертирована в цель',
            'TASK_TO_POST': 'Задача успешно сконвертирована в пост',
            'TASK_TO_AREA': 'Задача успешно сконвертирована в область',
            'TASK_TO_EVENT': 'Задача успешно сконвертирована в событие'
        });

    }]);

    thisModule.factory('ConvertService',
        ['$log', 'pipEnums', '$rootScope', '$state', '$window', 'pipGoalsData', 'pipAreasData', 'pipPostsData', 'pipEventsData', 'pipToasts', 'pipTranslate', function ($log, pipEnums,
                //   pipCollections, pipRest, pipStrings,   

                  $rootScope, $state, $window, pipGoalsData, pipAreasData, pipPostsData, pipEventsData, pipToasts, pipTranslate) {

            //       create: 'auto', // value = {auto, new_tool, new_app, new_piplife}
            //        view: 'piplife', // value = {none, tool, app, piplife, magazine}

// goal

            function getGoal(template, templateType, subType) {
                var  newGoal = {
                    type: subType ? subType : pipEnums.GOAL_TYPE.GOAL,
                    title: template.title,
                    status: pipEnums.EXECUTION_STATUS.NEW,
                    content: _.cloneDeep(template.content), // todo ???
                    progress: 0,
                    importance: template.importance != undefined ? template.importance : pipEnums.IMPORTANCE.NORMAL,
                    urgency: template.urgency != undefined ? template.urgency : pipEnums.URGENCY.NORMAL,
                    contribs: _.cloneDeep(template.contribs), // todo ??
                    contrib_def: true,
                    share_def: true
                };

                return newGoal;
            }

            function createGoal(params, viewOption, showToast, recordType) {
                if (!angular.isObject(params)) {
                    throw new Error('ConvertService: Error create Goal parametrs');
                }

                var goal = getGoal(params.item, params.ref_type, params.subType),
                    transaction = params.transaction,
                    successCallback = params.callback;

                if (goal) {
                    saveGoal(goal, viewOption, showToast, recordType, transaction, successCallback)
                }
            };

            function saveGoal(goal, viewOption, showToast, recordType, transaction, successCallback) {
                var messageCreate = 'TASK_TO_GOAL', // todo string constant
                    createNotificationAction = !!viewOption && viewOption != 'none' ? ['VIEW'] : null;

                pipGoalsData.createGoal(
                    {
                        transaction: transaction,
                        item: goal
                    },
                    function (result) {
                        if (angular.isFunction(successCallback)) {
                            successCallback(result);
                        }
                        if (showToast) { // {none, tool, app, piplife, magazine
                            pipToasts.showNotification(
                                pipTranslate.translate(messageCreate),
                                createNotificationAction,
                                function () {
                                    openView(viewOption, 'goal', result.id);
                                }
                            );
                        }
                    },
                    function (err) {

                    }
                );
            }

            // area

            function getArea(template, templateType, subType) {
                var  newArea = {
                    type: subType ? subType : pipEnums.GOAL_TYPE.AREA,
                    title: template.title,
                    status: pipEnums.EXECUTION_STATUS.NEW,
                    content: _.cloneDeep(template.content), // todo ???
                    progress: 0,
                    importance: template.importance != undefined ? template.importance : pipEnums.IMPORTANCE.NORMAL,
                    urgency: template.urgency != undefined ? template.urgency : pipEnums.URGENCY.NORMAL,
                    contribs: _.cloneDeep(template.contribs), // todo ??
                    contrib_def: true,
                    share_def: true
                };

                return newArea;
            }

            function createArea(params, viewOption, showToast, recordType) {
                if (!angular.isObject(params)) {
                    throw new Error('ConvertService: Error create Area parametrs');
                }

                var area = getArea(params.item, params.ref_type, params.subType),
                    transaction = params.transaction,
                    successCallback = params.callback;

                if (area) {
                    saveArea(area, viewOption, showToast, recordType, transaction, successCallback)
                }
            };

            function saveArea(area, viewOption, showToast, recordType, transaction, successCallback) {
                var messageCreate = 'TASK_TO_AREA', // todo string constant
                    createNotificationAction = !!viewOption && viewOption != 'none' ? ['VIEW'] : null;

                pipAreasData.createArea(
                    {
                        transaction: transaction,
                        item: area
                    },
                    function (result) {
                        if (angular.isFunction(successCallback)) {
                            successCallback(result);
                        }
                        if (showToast) { // {none, tool, app, piplife, magazine
                            pipToasts.showNotification(
                                pipTranslate.translate(messageCreate),
                                createNotificationAction,
                                function () {
                                    openView(viewOption, 'area', result.id);
                                }
                            );
                        }
                    },
                    function (err) {

                    }
                );
            }

            // post

            function getPost(template, templateType, subType) {
                var newPost = {
                    type: subType ? subType : pipEnums.GOAL_TYPE.POST,
                    title: template.title,
                    content: _.cloneDeep(template.content), // todo ???
                    contribs: _.cloneDeep(template.contribs), // todo ??
                    contrib_def: true,
                    share_def: true
                };

                return newPost;
            }

            function createPost(params, viewOption, showToast, recordType) {
                if (!angular.isObject(params)) {
                    throw new Error('ConvertService: Error create Post parametrs');
                }

                var post = getPost(params.item, params.ref_type, params.subType),
                    transaction = params.transaction,
                    successCallback = params.callback;

                if (post) {
                    savePost(post, viewOption, showToast, recordType, transaction, successCallback)
                }
            };

            function savePost(post, viewOption, showToast, recordType, transaction, successCallback) {
                var messageCreate = 'TASK_TO_POST', // todo string constant
                    createNotificationAction = !!viewOption && viewOption != 'none' ? ['VIEW'] : null;

                pipPostsData.createPost(
                    {
                        transaction: transaction,
                        item: post
                    },
                    function (result) {
                        if (angular.isFunction(successCallback)) {
                            successCallback(result);
                        }
                        if (showToast) { // {none, tool, app, piplife, magazine
                            pipToasts.showNotification(
                                pipTranslate.translate(messageCreate),
                                createNotificationAction,
                                function () {
                                    openView(viewOption, 'post', result.id);
                                }
                            );
                        }
                    },
                    function (err) {

                    }
                );
            }

            // event
            function getEvent(template, templateType, subType) {
                var category = subType ? subType : 'daily',
                    startDate, endDate,
                    newEvent = {
                        category: category,
                        type: pipEnums.EVENT_TYPE.INSTANCE,
                        title: template.title,
                        status: pipEnums.EXECUTION_STATUS.NEW,
                        content: _.cloneDeep(template.content), // todo ???
                        end: endDate,
                        start: startDate,
                        progress: 0,
                        all_day: true,
                        importance: template.importance != undefined ? template.importance : pipEnums.IMPORTANCE.NORMAL,
                        urgency: template.urgency != undefined ? template.urgency : pipEnums.URGENCY.NORMAL,
                        contribs: _.cloneDeep(template.contribs), // todo ??
                        contrib_def: false
                    };

                return newEvent;
            }

            function createEvent(params, viewOption, showToast, recordType) {
                if (!angular.isObject(params)) {
                    throw new Error('ConvertService: Error create Event parametrs');
                }

                var event = getEvent(params.item, params.ref_type, params.subType),
                    transaction = params.transaction,
                    successCallback = params.callback;

                if (event) {
                    saveEvent(event, viewOption, showToast, recordType, transaction, successCallback)
                }
            };

            function saveEvent(event, viewOption, showToast, recordType, transaction, successCallback) {
                var messageCreate = 'TASK_TO_EVENT', // todo string constant
                    createNotificationAction = !!viewOption && viewOption != 'none' ? ['VIEW'] : null;

                pipEventsData.createEvent(
                    {
                        transaction: transaction,
                        item: event
                    },
                    function (result) {
                        if (angular.isFunction(successCallback)) {
                            successCallback(result);
                        }
                        if (showToast) { // {none, tool, app, piplife, magazine
                            pipToasts.showNotification(
                                pipTranslate.translate(messageCreate),
                                createNotificationAction,
                                function () {
                                    openView(viewOption, 'event', result.id);
                                }
                            );
                        }
                    },
                    function (err) {

                    }
                );
            }

            // tasks

            function getTask(template, templateType, subType) {
                var  newTask = {
                    type: subType ? subType : 'task',
                    title: template.title,
                    status: pipEnums.EXECUTION_STATUS.NEW,
                    content: _.cloneDeep(template.content), // todo ???
                    progress: 0,
                    importance: template.importance != undefined ? template.importance : pipEnums.IMPORTANCE.NORMAL,
                    urgency: template.urgency != undefined ? template.urgency : pipEnums.URGENCY.NORMAL,
                    contribs: _.cloneDeep(template.contribs), // todo ??
                    contrib_def: true,
                    share_def: true
                };

                return newTask;
            }

            function createTask(params, viewOption, showToast, recordType) {
                if (!angular.isObject(params)) {
                    throw new Error('ConvertService: Error create Task parametrs');
                }

                var task = getTask(params.item, params.ref_type, params.subType),
                    transaction = params.transaction,
                    successCallback = params.callback;

                if (task) {
                    saveTask(task, viewOption, showToast, recordType, transaction, successCallback)
                }
            };

            function saveTask(task, viewOption, showToast, recordType, transaction, successCallback) {
                var messageCreate = 'Task created', // todo string constant
                    createNotificationAction = !!viewOption && viewOption != 'none' ? ['VIEW'] : null;

                pipGoalsData.createGoal(
                    {
                        transaction: transaction,
                        item: task
                    },
                    function (result) {
                        if (angular.isFunction(successCallback)) {
                            successCallback(result);
                        }
                        if (showToast) { // {none, tool, app, piplife, magazine
                            pipToasts.showNotification(
                                pipTranslate.translate(messageCreate),
                                createNotificationAction,
                                function () {
                                    openView(viewOption, 'task', result.id);
                                }
                            );
                        }
                    },
                    function (err) {

                    }
                );
            }

            function getPipLifeUrl(refId, refType, partyId) {
                var url = 'https://piplife.com/#/', tool;
                switch (refType){
                    case 'goal':
                        tool = 'goals'
                        break;
                    case 'area':
                        tool = 'areas'
                        break;
                    case 'post':
                        tool = 'news/split'
                        break;
                    case 'task':
                        tool = 'actions'
                        break;
                    case 'event':
                        tool = 'plans/split'
                        break;
                }

                if (tool && refId && partyId) {
                    url = url + tool + '/' + refId + '?party_id=' + partyId;
                    return url;
                } else {
                    return null;
                }
            }

            // viewOption = {none, tool, app, piplife, magazine
            function openView(viewOption, refType, refId) {
                var piplifeUrl = getPipLifeUrl(refId, refType, $rootScope.$party.id);
                switch (viewOption){
                    case 'tool':

                        break;
                    case 'app':

                        break;
                    case 'magazine':

                        break;
                    case 'piplife':

                        if (piplifeUrl) {
                            $window.open(piplifeUrl , '_blank');
                        }

                        break;
                }
            }

            function openTool(tool, params) {
                switch (tool){
                    case 'pipGoal':
                        $state.go('goals.new', {
                            ref_id: params.ref_id,
                            ref_type: params.ref_type,
                            ref_deleted: result.deleted ? result.deleted  :false,
                            party_id: $rootScope.$party.id
                        });
                        break;
                    case 'pipSchedule':
                        $state.go('plans.new', {
                            ref_id: params.ref_id,
                            ref_type: params.ref_type,
                            ref_deleted: result.deleted ? result.deleted  :false,
                            party_id: $rootScope.$party.id
                        });
                        break;
                    case 'pipNews':
                        $state.go('news.new',{
                            ref_id: params.ref_id,
                            ref_type: params.ref_type,
                            ref_deleted: result.deleted ? result.deleted  :false,
                            party_id: $rootScope.$party.id
                        });
                        break;
                    case 'pipArea':
                        $state.go('areas.new', {
                            ref_id: params.ref_id,
                            ref_type: params.ref_type,
                            ref_deleted: result.deleted ? result.deleted  :false,
                            party_id: $rootScope.$party.id
                        });
                        break;
                    case 'pipTask':
                        $state.go('tasks.new', {
                            ref_id: params.ref_id,
                            ref_type: params.ref_type,
                            ref_deleted: result.deleted ? result.deleted  :false,
                            party_id: $rootScope.$party.id
                        });
                        break;
                }
            }

            // ---------------------

            function convertToProto(params, option) {
                switch (option.name){
                    case pipEnums.REFERENCE_TYPE.GOAL :
                        convertToRecord(params, option, 'pipGoal', createGoal);
                        break;
                    case 'task' :
                        convertToRecord(params, option, 'pipTask', createTask);
                        break;
                    case pipEnums.REFERENCE_TYPE.EVENT :
                        params.subType = option.subType;
                        convertToRecord(params, option, 'pipEvent', createEvent);
                        break;
                    case pipEnums.REFERENCE_TYPE.POST :
                        convertToRecord(params, option, 'pipPost', createPost);
                        break;
                }
            }

            function convertToRecord(params, option, recordType, createFunction) {
                if (!angular.isObject(option) || !angular.isObject(params)) {
                    throw new Error('ConvertService: Error converting parametrs');
                }

                var createOption = option.create || 'auto',
                    viewOption = option.view || 'piplife',
                    showToast = option.showToast != undefined ? !!option.showToast : true;

                switch (createOption){
                    case 'new_tool':
                        openTool(recordType, params);
                        break;
                    case 'new_app':
                        openApp(recordType, params);
                        break;
                    case 'new_piplife':
                        openPipLife(recordType, params);
                        break;
                    default: // auto
                        createFunction(params, viewOption, showToast, option.name);
                        break;
                }
            }

            return {

                convertToProto: convertToProto,
                convertToRecord: convertToRecord,

                createGoal: createGoal,
                createArea: createArea,
                createEvent: createEvent,
                createPost: createPost,
                createTask: createTask

            }
        }]
    );

})();
