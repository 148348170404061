/**
 * @file Quotes service
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipQuotes.Service', ['pipLifeCommons.Templates', 'pipGuidance']);

    thisModule.factory('pipQuotes', ['$pipPopover', '$timeout', '$rootScope', 'pipTips', function ($pipPopover, $timeout, $rootScope, pipTips) {
            var quotes;
            
            return {
                filterQuotes: filterQuotes,
                showQuotes: showQuotes,
                waitUserTipsQuotes: waitUserTipsQuotes
            }

            function checkStatus(item) {
                return item.status == 'completed' ? true : false;
            }

            function compareRandom(a, b) {
                return Math.random() - 0.5;
            }

            function filterQuotes(data, topic) {
                var quotes, quotesCollection = _.filter(data, checkStatus);
                if (topic) {
                    quotes = [];
                    for (var index = 0; index < quotesCollection.length; index++) {
                        var topic = _.find(quotesCollection[index].tags, function (t) { return t == topic });
                        if (topic) {
                            quotes.push(quotesCollection[index]);
                        }
                    }
                } else {
                    quotes = quotesCollection;
                }

                quotes.sort(compareRandom);
                return quotes;
            }

            function quoteController($scope, $mdMedia) {

                $scope.index = 0;
                $scope.$mdMedia = $mdMedia;

                init();

                $scope.onNextClick = function () {
                    $scope.index++;
                    if ($scope.index == $scope.locals.quotes.length)
                        $pipPopover.hide();
                    else {
                        init();
                        $pipPopover.resize();
                        //$rootScope.$broadcast('pipWindowResized');
                    }

                };

                $scope.$on('pipWindowResized', init);

                function init() {

                    if ($scope.locals.quotes[$scope.index].author)
                        $scope.author = $scope.locals.quotes[$scope.index].author[$scope.locals.ln] ?
                            $scope.locals.quotes[$scope.index].author[$scope.locals.ln] : $scope.locals.quotes[$scope.index].author['en'];
                    if ($scope.locals.quotes[$scope.index].text)
                        $scope.content = $scope.locals.quotes[$scope.index].text[$scope.locals.ln] ? $scope.locals.quotes[$scope.index].text[$scope.locals.ln] :
                            $scope.locals.quotes[$scope.index].text['en'];

                    $scope.link = $scope.locals.quotes[$scope.index].more_url;
                }
            }

            function showQuotes(quotes, ln, $event) {

                if (quotes && quotes.length > 0) {
                    $pipPopover.hide();

                    $pipPopover.show({
                        element: $event ? $event.currentTarget : null,
                        class: 'pip-quote',
                        cancelCallback: function () {
                            return false;
                        },
                        locals: {
                            quotes: quotes,
                            ln: ln || 'en'
                        },
                        controller: ['$scope', '$mdMedia', quoteController],
                        templateUrl: 'quotes/quote.template.html'
                    });
                }
            }

            function waitUserTipsQuotes(tips, quotes, ln) {
                var idleTimer = null;
                var idleState = false; // состояние отсутствия
                var idleWait = 180000; // время ожидания в мс. (1/1000 секунды)

                $(document).ready(function () {
                    $(document).bind('click keydown scroll', function () {
                        clearTimeout(idleTimer); // отменяем прежний временной отрезок
                        if (idleState == true) {
                            // Действия на возвращение пользователя
                        }

                        idleState = false;
                        idleTimer = setTimeout(function () {
                            // Действия на отсутствие пользователя
                            $pipPopover.hide();
                            if (!quotes)
                                pipTips.showTips(tips, ln);
                            else if (!tips)
                                showQuotes(quotes, ln);
                            else {
                                if (Math.random() < 0.5)
                                 pipTips.showTips(tips, ln);
                                else
                                    showQuotes(quotes, ln);
                            }
                            idleState = true;
                        }, idleWait);
                    });

                    $("body").trigger("click"); // сгенерируем ложное событие, для запуска скрипта
                });
            }
        }]
    );

})();