/**
 * @file Visions data cache
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipVisionsCache', ['pipDataCache', 'pipCacheTag']);

    thisModule.service('pipVisionsCache',
        ['pipEnums', 'pipDataCache', 'pipCacheTag', function(pipEnums, pipDataCache, pipCacheTag) {

            return {
                readVisions:    readVisions,
                onVisionCreate: onVisionCreate,
                onVisionUpdate: onVisionUpdate,
                onVisionDelete: onVisionDelete
            };
            //-------------

            function readVisions(params, successCallback, errorCallback) {
                params.resource = 'visions';
                params.item = params.item || {};

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function onVisionCreate(params, successCallback) {
                
                return pipDataCache.addDecorator(
                    'visions', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onVisionUpdate(params, successCallback) {
                return pipDataCache.updateDecorator(
                    'visions', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onVisionDelete(params, successCallback) {
                return pipDataCache.removeDecorator('visions', params, successCallback);
            };

        }]
    );

})();

