<!--
@file Email verification success page
@copyright Digital Living Software Corp. 2014-2016
-->

<div class="pip-card-container pip-outer-scroll pip-entry">
    <pip-card width="400">
        <div class="pip-footer">
            <md-button ng-click="onContinue()" class="md-accent">
                {{'CONTINUE' | translate}} 
            </md-button>
        </div>
        <div class="pip-body">
            <div class="pip-content">
                <h2>{{'VERIFY_EMAIL_TITLE' | translate}}</h2>

                <p class="title-padding">
                    {{'VERIFY_EMAIL_SUCCESS_TEXT' | translate}} 
                </p>
            </div>
        </div>
    </pip-card>
</div>