/**
 * @file Connections data cache
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipConnectionsCache', ['pipDataCache']);

    thisModule.service('pipConnectionsCache',
        ['pipEnums', 'pipDataCache', function(pipEnums, pipDataCache) {

            return {
                readConnections: readConnections,
                readConnectionBlocks: readConnectionBlocks,
                readPartners: readPartners,
                readMembers: readMembers,
                readCollaborators: readCollaborators,
                readFollowings: readFollowings,
                readFollowers: readFollowers,
                onConnectionCreate: onConnectionCreate,
                onConnectionUpdate: onConnectionUpdate,
                onConnectionDelete: onConnectionDelete
            };
            //-------------

            function readConnections(params, successCallback, errorCallback) {
                params.resource = 'connections';

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function readConnectionBlocks(params, successCallback, errorCallback) {
                params.resource = 'connectionBlocks';

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function readPartners(params, successCallback, errorCallback) {
                params.resource = 'connections';
                params.filter = function(data) {
                    return _.filter(data, function(item) {
                        return item.type == pipEnums.CONNECTION_TYPE.PARTNER;
                    });
                };

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function readMembers(params, successCallback, errorCallback) {
                params.resource = 'connections';
                params.filter = function(data) {
                    return _.filter(data, function(item) {
                        return item.type == pipEnums.CONNECTION_TYPE.MEMBER;
                    });
                };

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function readCollaborators(params, successCallback, errorCallback) {
                params.resource = 'connections';
                params.filter = function(data) {
                    return _.filter(data, function(item) {
                        return item.type == pipEnums.CONNECTION_TYPE.PARTNER
                            || item.type == pipEnums.CONNECTION_TYPE.MEMBER;
                    });
                };

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function readFollowings(params, successCallback, errorCallback) {
                params.resource = 'connections';
                params.filter = function(data) {
                    return _.filter(data, function(item) {
                        return item.type == pipEnums.CONNECTION_TYPE.FOLLOW;
                    });
                };

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function readFollowers(params, successCallback, errorCallback) {
                params.resource = 'followers';

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function onConnectionCreate(params, successCallback) {
                return pipDataCache.addDecorator('connections', params, successCallback);
            };

            function onConnectionUpdate(params, successCallback) {
                return pipDataCache.updateDecorator('connections', params, successCallback);
            };

            function onConnectionDelete(params, successCallback) {
                return pipDataCache.removeDecorator('connections', params, successCallback);
            };

        }]
    );

})();

