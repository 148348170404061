/**
 * @file Confirmation dialog
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipDeleteConfirmationDialog',
        ['ngMaterial', 'pipUtils', 'pipTranslate', 'pipLifeCommons.Templates']);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            'DELETE_CONFIRM_TITLE': 'Delete',
            'CONFIRM_ENTITY': 'Entity',
            'CONFIRM_TASK': 'Task',
            'REFUSE': 'Refuse',
            'DELETE_CONFIRM_TEXT': 'Delete or refuse to participate?'
        });
        pipTranslateProvider.translations('ru', {
            'DELETE_CONFIRM_TITLE': 'Удалить',
            'CONFIRM_ENTITY': 'Сущность',
            'CONFIRM_TASK': 'Задачу',
            'REFUSE': 'Отказаться',
            'DELETE_CONFIRM_TEXT': 'Удалить или отказаться от участия?'
        });
    }]);

    thisModule.factory('deleteConfirmationDialog',
        ['$mdDialog', function ($mdDialog) {
            return {
                show: function (params, successCallback, cancelCallback) {
                    $mdDialog.show({
                        targetEvent: params.event,
                        templateUrl: 'delete_confirmation_dialog/delete_confirmation_dialog.html',
                        controller: 'pipDeleteConfirmationDialogController',
                        locals: { params: params },
                        clickOutsideToClose: true
                    })
                        .then(function (result) {
                            if (successCallback) {
                                successCallback(result);
                            }
                        }, function () {
                            if (cancelCallback) {
                                cancelCallback();
                            }
                        });
                }
            };
        }]
    );

    thisModule.controller('pipDeleteConfirmationDialogController',
        ['$scope', '$rootScope', '$mdDialog', 'pipTranslate', 'pipUtils', 'params', function ($scope, $rootScope, $mdDialog, pipTranslate, pipUtils, params) {
            $scope.theme = $rootScope.$theme;

            // check params
            if (!params.entity || !params.entity.contribs || params.entity.contribs.length == 0) {
                $mdDialog.cancel();
                return;
            }

            $scope.delete = params.delete || 'DELETE';
            $scope.reject = params.reject || 'REFUSE';
            $scope.cancel = params.cancel || 'CANCEL';

            $scope.partyId = params.party_id ? params.party_id : $rootScope.$party.id;
            $scope.type = params.type ? params.type : 'CONFIRM_ENTITY';

            prepareDialog ();

            $scope.onCancel = function () {
                $mdDialog.cancel();
            };

            $scope.onDelete = function () {
                $mdDialog.hide({action:'delete'});
            };

            $scope.onReject = function () {
                $mdDialog.hide({action:'reject'});
            };

            return ;

            function prepareDialog() {
                var contribs = _.uniqBy(params.entity.contribs, 'party_id');

                $scope.hasFewContribs = contribs.length > 1;

                if (contribs.length > 1) {
                    $scope.title = params.confirmation ? params.confirmation : pipTranslate.translate('DELETE_CONFIRM_TEXT');
                } else {
                    var confirmationTitleEntity = $scope.type == 'task' ?
                        pipTranslate.translate('CONFIRM_TASK') : pipTranslate.translate($scope.type.toUpperCase());
                    var title = pipTranslate.translate('DELETE_CONFIRM_TITLE') + ' ' + confirmationTitleEntity.toLowerCase() + '?';
                    $scope.title = params.title || title;
                }
            };
        }]
    );

})();
