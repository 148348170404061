/**
 * @file Global string resources
 * @copyright Digital Living Software Corp. 2014-2015
 * @todo:
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipStringsEnums', ['pipTranslate']);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            // Genders
            'male': 'Male',
            'female': 'Female',
            'n/s': 'Not specified',

            'DAY': 'Day',
            'MONTH': 'Month',
            'YEAR': 'Year',
            'WEEK': 'Week',

            //ACCEPTANCE type
            'ACCEPTANCE_JOINED': 'Joined',
            'ACCEPTANCE_INVITED': 'Invited',
            'ACCEPTANCE_ACCEPTED': 'Accepted',

            //Join Method
            'JOIN_METHOD_INVITE': 'By invitation',
            'JOIN_METHOD_APPROVE': 'By approval',
            'JOIN_METHOD_OPEN': 'Open',

            //CONFIDENTIALITY
            'CONFIDENTIALITY_0': 'Unrestricted',
            'CONFIDENTIALITY_1': 'Sensitive',
            'CONFIDENTIALITY_2': 'Classified',
            'CONFIDENTIALITY_3': 'Secret',

            'POST_TYPE_INFO': 'Info',
            'POST_TYPE_ESCALATION': 'Escalation',
            'POST_TYPE_QUESTION': 'Question',
            'POST_TYPE_ISSUE': 'Issue',
            'POST_TYPE_STATUS': 'Status',
            'POST_TYPE_FORECAST': 'Forecast',
            'POST_TYPE_WARNING': 'Warning',
            'POST_TYPE_REPORT': 'Report',

            // importance type  низкая нормальная высокая
            'IMPORTANCE_1': 'Low',
            'IMPORTANCE_2': 'Normal',
            'IMPORTANCE_3': 'High',

            // urgency type низкая нормальная высокая
            'URGENCY_1': 'Low',
            'URGENCY_2': 'Normal',
            'URGENCY_3': 'High',

            // status type
            'STATUS_NEW': 'Not started',
            'STATUS_ASSIGNED': 'Assigned',
            'STATUS_IN_PROGRESS': 'In progress',
            'STATUS_ON_HOLD': 'On hold',
            'STATUS_VERIFYING': 'Verifying',
            'STATUS_COMPLETED': 'Completed',
            'STATUS_CANCELED': 'Canceled',

            // Sharing levels
            'SHARING_LEVEL': 'Visibility level',
            'SHARE_LEVEL_0': 'Everyone',
            'SHARE_LEVEL_1': 'Known parties',
            'SHARE_LEVEL_2': 'Trusted parties',
            'SHARE_LEVEL_3': 'Only privately'
        });

        pipTranslateProvider.translations('ru', {
            // Genders
            'male': 'Муж',
            'female': 'Жен',
            'n/s': 'Не указан',

            'DAY': 'День',
            'MONTH': 'Месяц',
            'YEAR': 'Год',
            'WEEK': 'Неделя',

            'ACTIVITY_SHOW_MORE' : 'Показать ещё',
            'ACTIVITIES_NONE' : 'Нет никакой активности',

            //ACCEPTANCE type
            'ACCEPTANCE_JOINED': 'Присоединение',
            'ACCEPTANCE_INVITED': 'Приглашение',
            'ACCEPTANCE_ACCEPTED': 'одобренный',

            // Join Method
            'JOIN_METHOD_INVITE': 'По приглашению',
            'JOIN_METHOD_APPROVE': 'С подтверждением',
            'JOIN_METHOD_OPEN': 'Открыто',

            //CONFIDENTIALITY
            'CONFIDENTIALITY_0': 'Не ограниченно',
            'CONFIDENTIALITY_1': 'Ограничено',
            'CONFIDENTIALITY_2': 'Закрыто',
            'CONFIDENTIALITY_3': 'Секретно',

            'POST_TYPE_INFO': 'Информация',
            'POST_TYPE_ESCALATION': 'Экскалация',
            'POST_TYPE_QUESTION': 'Вопрос',
            'POST_TYPE_ISSUE': 'Проблема',
            'POST_TYPE_STATUS': 'Статус',
            'POST_TYPE_FORECAST': 'Прогноз',
            'POST_TYPE_WARNING': 'Предупреждение',
            'POST_TYPE_REPORT': 'Отчет',

            // importance type  низкая нормальная высокая
            'IMPORTANCE_1': 'Неважно',
            'IMPORTANCE_2': 'Нормально',
            'IMPORTANCE_3': 'Важно',

            // urgency type низкая нормальная высокая
            'URGENCY_1': 'Не срочно',
            'URGENCY_2': 'Нормально',
            'URGENCY_3': 'Срочно',

            // status type
            'STATUS_NEW': 'Не начато',
            'STATUS_ASSIGNED': 'Назначено',
            'STATUS_IN_PROGRESS': 'В процессе',
            'STATUS_ON_HOLD': 'Отложено',
            'STATUS_VERIFYING': 'Подтверждено',
            'STATUS_COMPLETED': 'Завершено',
            'STATUS_CANCELED': 'Отменено',

            // Sharing levels
            'SHARING_LEVEL': 'Уровень видимости',
            'SHARE_LEVEL_0': 'Кто угодно',
            'SHARE_LEVEL_1': 'Только знакомые',
            'SHARE_LEVEL_2': 'Только доверенные лица',
            'SHARE_LEVEL_3': 'Только лично'

        });

    }]);

})();