/**
 * @file focus event for md-autocomplete
 * @copyright Digital Living Software Corp. 2015-2016
 */

/* global _, angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipInputFocus', []);

    thisModule.directive('pipInputFocus',
        ['$timeout', function ($timeout) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {

                    $timeout(function () {
                        angular.element(element[0].querySelector('input.md-input')).bind('focus', function () {
                            $timeout(function () {
                                scope.$eval(attrs.pipInputFocus);
                            }, 100);
                        });
                    }, 0);
                }
            };
        }]
    );

})();


