/**
 * @file extend md-autocomplete control (add onBlur )
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("mdAutocompleteExtend", []);

    thisModule.directive('mdAutocompleteBlur',
        ['$timeout', '$mdUtil', function ($timeout, $mdUtil) {
            return {
                require: "^mdAutocomplete",
                link: function($scope, $element, $attributes, $mdAutocompleteCtrl) {
                    $timeout(function() {
                        var input = $element.find("input");
                        var element =  $element[0];
                        var nativeBlur = $mdAutocompleteCtrl.blur;
                        $mdAutocompleteCtrl.blur = function() {
                                    nativeBlur.call($mdAutocompleteCtrl);
                                    $mdUtil.nextTick(function () {
                                        $scope.$eval($attributes.mdAutocompleteBlur, {"$mdAutocomplete": $mdAutocompleteCtrl });
                                    });
                        };
                    });
                }
            }
        }]
    );

})();
