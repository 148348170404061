/**
 * @file Visions data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipVisionsData', ['pipRest', 'pipDataModel', 'pipCacheTag',  'pipVisionsCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var visionsApi = {
                visions: function (url) {
                   return this.createPartyCollection(url, '/api/parties/:party_id/visions/:id');
                },
                acceptVision: function (url) {
                   return this.createPartyCollection(url, '/api/parties/:party_id/visions/:id/accept');
                },
                
                rejectVision: function (url) {
                   return this.createPartyCollection(url, '/api/parties/:party_id/visions/:id/reject');
                }
         };
        
         pipRestProvider.addApi(visionsApi);
    }]);
    
    thisModule.provider('pipVisionsData', function () {
        
        // this.readVisionsResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipRest) {
        //         return pipRest.visions().query({
        //             party_id: pipDataSession.partyId()
        //         }).$promise;
        //     };
        // };

        this.$get = ['$stateParams', '$state', 'pipDataModel', 'pipCacheTag', 'pipVisionsCache', 'pipDataSession', function ($stateParams, $state, pipDataModel, pipCacheTag, pipVisionsCache, pipDataSession) {
            return {
                partyId: pipDataSession.partyId(),

                readVisions: function (params, successCallback, errorCallback) {
                    params.resource = 'visions';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    return pipVisionsCache.readVisions(params, successCallback, errorCallback);
                },

                readVision: function (params, successCallback, errorCallback) {
                    params.resource = 'visions';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    //if (!params.item.id) { params.item.id = $stateParams.id; }
                    return pipDataModel.readOne(params, pipVisionsCache.onVisionUpdate(params, successCallback), errorCallback);
                },
                
                getVisionsPage: function (params, successCallback, errorCallback) {
                    params.resource = 'visions';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.page(params, successCallback, errorCallback);
                },

                createVisionWithFiles: function (params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(
                        params,
                        function () {
                            params.resource = 'visions';
                            params.skipTransactionBegin = true;
                            params.skipTransactionEnd = false;

                            params.item.party_id = pipDataSession.partyId();
                            pipDataModel.create(
                                params,
                                pipCacheTag.onTagsUpdate(params, successCallback, errorCallback),
                                errorCallback
                            );
                        },
                        errorCallback);
                },

                createVision: function (params, successCallback, errorCallback) {
                    params.resource = 'visions';
                    params.item.party_id = pipDataSession.partyId();
                    params.item.creator_id = pipDataSession.partyId();
                    pipDataModel.create(
                        params,
                        pipCacheTag.onTagsUpdate(params, successCallback),
                        errorCallback
                    );
                },

                updateVision: function (params, successCallback, errorCallback) {
                    params.resource = 'visions';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.update(
                        params,
                        pipCacheTag.onTagsUpdate(params, successCallback),
                        errorCallback
                    );
                },

                updateVisionWithFiles: function (params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(params, function () {
                        params.resource = 'visions';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;

                        params.item.party_id = pipDataSession.partyId();
                        pipDataModel.update(
                            params,
                            pipCacheTag.onTagsUpdate(params, successCallback),
                            errorCallback
                        );
                    });
                },

                deleteVision: function (params, successCallback, errorCallback) {
                    params.resource = 'visions';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.remove(params, successCallback, errorCallback);
                },

                acceptVision: function (params, successCallback, errorCallback) {
                    params.resource = 'acceptVision';
                    pipDataModel.create(params, successCallback, errorCallback);
                },

                rejectVision: function (params, successCallback, errorCallback) {
                    params.resource = 'rejectVision';
                    pipDataModel.create(params, successCallback, errorCallback);
                }

            }
        }];
        this.$get.$inject = ['$stateParams', '$state', 'pipDataModel', 'pipCacheTag', 'pipVisionsCache', 'pipDataSession'];
    });

})();
