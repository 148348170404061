/*
 * convert_to.js
 * Convert To dialog
 * (с) Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipConvertToDialog', [
        'ngMaterial', 'pipRest.Enums', 'pipAppBar', 'pipRest.State',
        'pipTranslate', 'pipDataModel', 'pipControls'
        // 'pipPlanDetailsDialog.strings'  // This shall not be here!
    ]);

    thisModule.factory('ConvertToDialog',
        ['$state', '$http', '$mdDialog', 'pipEnums', 'pipConversionDialog', '$rootScope', function ($state, $http, $mdDialog, pipEnums, pipConversionDialog, $rootScope) {
            return {
                show: function (params) {
                    var convertCollection = [
                            {
                                name: 'goal',
                                title: 'GOAL',
                                subtitle: 'CONVERT_RECORD_TO_GOAL'
                            },
                            {
                                name: 'task',
                                title: 'TASK',
                                subtitle: 'CONVERT_RECORD_TO_TASK'
                            },
                            {
                                name: 'event',
                                title: 'EVENT',
                                subtitle: 'CONVERT_RECORD_TO_EVENT'
                            },
                            {
                                name: 'post',
                                title: 'POST',
                                subtitle: 'CONVERT_RECORD_TO_POST'
                            }
                        ],
                        options;

                    if (params.options) {
                        options = _.filter(convertCollection, function(item) {
                            return _.indexOf(params.options, item.name) != -1;
                        });
                    } else {
                        options = convertCollection;
                    }

                    pipConversionDialog.show(
                        {
                            event: params.event,
                            options: options,
                            deleted: params.deleted ? params.deleted : false,
                            deleteTitle: params.deletedTitle ? params.deletedTitle : null
                        },
                        function (result) {
                            switch (result.option.name){
                                case pipEnums.REFERENCE_TYPE.GOAL :
                                    $state.go('goals.new', {
                                        ref_id: params.ref_id,
                                        ref_type: params.ref_type,
                                        ref_deleted: result.deleted ? result.deleted  :false,
                                        party_id: $rootScope.$party.id,
                                        managed_party_id: params.managed_party_id || null
                                    });
                                    break;
                                case 'task' :
                                    $state.go('actions.new', {
                                        ref_id: params.ref_id,
                                        ref_type: params.ref_type,
                                        ref_deleted: result.deleted ? result.deleted  :false,
                                        party_id: $rootScope.$party.id,
                                        managed_party_id: params.managed_party_id || null
                                    });
                                    break;
                                case pipEnums.REFERENCE_TYPE.EVENT :
                                    $state.go('plans.new', {
                                        ref_id: params.ref_id,
                                        ref_type: params.ref_type,
                                        ref_deleted: result.deleted ? result.deleted  :false,
                                        party_id: $rootScope.$party.id,
                                        managed_party_id: params.managed_party_id || null
                                    });
                                    break;
                                case pipEnums.REFERENCE_TYPE.POST :
                                    $state.go('news.new',{
                                        ref_id: params.ref_id,
                                        ref_type: params.ref_type,
                                        ref_deleted: result.deleted ? result.deleted  :false,
                                        party_id: $rootScope.$party.id,
                                        managed_party_id: params.managed_party_id || null
                                    });
                                    break;
                            }
                        }
                    );
                }
            };
        }]
    );

    thisModule.controller('PipConvertToDialogController',
        ['$scope', '$rootScope', '$state', '$http', '$mdDialog', 'pipTransaction', 'pipTranslate', 'item', 'importanceCollection', 'urgencyCollection', 'statusCollection', function ($scope, $rootScope, $state, $http, $mdDialog, pipTransaction, pipTranslate,
                  item,  importanceCollection, urgencyCollection, statusCollection) {
        }]
    );

})();
