<!--
@file Task Signin page
@copyright Digital Living Software Corp. 2014-2016
-->

<div class="pip-card-container pip-entry pip-mobile-entry pip-scroll scroll-y"
        ng-class="styleClass">
        <div class="layout-column">
                <div class="task-entry-header layout-column layout-align-center-center">
                        <img ng-src="{{appLogo}}" class="pip-pic tp24-flex bm8 block" ng-if="!isIe">
                        <div class="ie-logo tp24-flex bm8" ng-if="isIe"></div>
                        <div class="text-subhead1 tp16">{{::'SIGNIN_MOBILE_TITLE' | translate}}</div>
                </div>        
                <div class="befor-signin-panel">
                </div>
                <pip-signin-panel class="text-subhead1" 
                                pipfixedServerUrl="fixedServerUrl"
                                pip-remember="'true'"
                                pip-hide-elements="{remember: true, title: true, server: true, forgotPassword: true, signup: true, hint: true, progress: true}">
                </pip-signin-panel>

                <div class="task-entry-footer">
                        <div class="text-body1 h48  layout-row layout-align-center-center">
                                <div class="rp4">
                                        {{::'SIGNIN_MOBILE_SUBTITLE1' | translate}}
                                </div>
                                <div>
                                        <a ng-click="gotoSignup()" href="">
                                                {{::'SIGNIN_SIGNUP_HERE' | translate}}
                                        </a>
                                </div> 
                        </div> 
                        <div class="text-body1 h48 layout-row layout-align-center-center">
                                <div class="rp16">
                                        <a ng-click="gotoForgotPassword()" href="" >
                                                {{::'SIGNIN_MOBILE_FORGOT' | translate}}
                                        </a> 
                                </div>
                                <div>
                                        <md-menu md-position-mode="target-right target">
                                                <md-button aria-label="DROPDOWN_MENU"
                                                        ng-click="$mdOpenMenu()" class="m0 tp0">
                                                {{::ln}}
                                                <md-icon md-svg-icon="icons:triangle-down" class="fg-white"></md-icon>
                                                </md-button>
                                                <md-menu-content width="3" class="bg-content ">
                                                <md-menu-item ng-repeat="lnL in lnLocal">
                                                        <md-button ng-click="setLanguage(lnL)" class="pip-capitalize">{{::lnL.name| translate}}
                                                        </md-button>
                                                </md-menu-item>

                                                </md-menu-content>
                                        </md-menu>
                                </div>
                        </div> 
                </div> 
        </div>
</div>
