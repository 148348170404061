/**
 * @file Settings blacklist controller
 * @copyright Digital Living Software Corp. 2014-2015
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipSettingsBlacklist', []);

    thisModule.config(['pipSettingsProvider', function (pipSettingsProvider) {
        pipSettingsProvider.addTab({
            state: 'blacklist',
            index: 2,
            title: 'SETTINGS_BLACKLIST_TITLE',
            stateConfig: {
                url: '/blacklist',
                controller: 'pipSettingsBlacklistController',
                templateUrl: 'settings/settings_blacklist.html',
                auth: true
            }
        });
    }]);

    thisModule.controller('pipSettingsBlacklistController',
        ['$scope', '$rootScope', '$q', 'pipTransaction', 'pipConnectionsData', 'pipToasts', '$sce', function ($scope, $rootScope, $q, pipTransaction, pipConnectionsData, pipToasts, $sce) { 

            $rootScope.settingsBlacklistInitialized = false;
            $rootScope.$routing = true;

            $scope.transaction = pipTransaction('settings.blocks', $scope);

            $scope.onRemove = onRemoveBlock;
            $scope.onAdd = onAddBlock;
            Initialize();

            return;


            function Initialize() {
                pipConnectionsData.readConnectionBlocks(
                    {
                        item: {
                            party_id: $rootScope.$party.id
                        },
                        force: true
                    },
                    function (data) {
                        $scope.blocks = data;
                        $rootScope.settingsBlacklistInitialized = true;
                        $rootScope.$routing = false;
                    }, function (error) {
                        $rootScope.settingsBlacklistInitialized = true;
                        $rootScope.$routing = false;
                        // no connection error
                        if (error && (error.status === -1 || error.code === -1)) {
                            $state.go('errors_no_connection', {
                                error: error
                            });
                        } else { // unknown error
                            $state.go('errors_unknown', {
                                error: error
                            });
                        }
                    }
                );
            }

            //-----------------------------

            // Hack for testing
            function onAddBlock() {
            //    pipRest.connectionBlocks().save(
            //        {
            //            party_id: $scope.party.id,
            //            to_party_id: '5501d3ffa9d7ec7e74d47a72',
            //            to_party_name: 'Name Surname'
            //        }, function (data) {
            //            $scope.blocks.push(data);
            //        }, function (error) {
            //        });

                pipConnectionsData.connectionBlock(
                    {
                        party_id: $scope.party.id,
                        to_party_id: '5501d3ffa9d7ec7e74d47a72',
                        to_party_name: 'Name Surname'
                    }, 
                   function (data) {
                        $scope.blocks.push(data);
                   }, 
                   function (error) {
                   }
                );
            };

            function onRemoveBlock(block) {
                // var tid = $scope.transaction.begin('REMOVING');

                pipConnectionsData.deleteConnectionsBlocks(
                    {
                        transaction: $scope.transaction,
                        item: block
                    },
                    function () {
                            // if (transaction.aborted(tid)) {
                            //     return;
                            // }
                            // transaction.end();

                        $scope.blocks = _.reject($scope.blocks,
                            function (value) {
                                return value.id == block.id;
                            }
                        );
                    }, 
                    function (error) {
        //                    transaction.end(error);

                        $scope.message = 'ERROR_' + error.status || error.data.status_code;
                        //pipToasts.showError(message);
                    }
                );
            };
        }]
    );

	
})();
