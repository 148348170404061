/**
 * @file Parties data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipPartiesData', ['pipRest', 'pipDataModel', 'pipConnectionsCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var partiesApi = {
                parties: function (url) {
                    return this.createPagedCollection(url, '/api/parties/:id');
                },

                inviteParty: function (url) {
                    return this.createPagedCollection(url, '/api/parties/:id/invite');
                },

                connectionSuggestions: function (url) {
                    return this.createCollection(url, '/api/parties/:party_id/connections/suggestions',
                        {
                            party_id: '@party_id'
                        }
                    );
                },

                partyActivities: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/activities');
                },

                partySettings: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/settings');
                },

                partyTags: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/tags');
                },

                partySupportCases: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/support_cases/:id');
                },

                supportCases: function (url) {
                    return this.createPagedCollection(url, '/api/support_cases/:id');
                },

                eventContribManage: function (url) {
                    return this.createPagedCollection(url, '/api/parties/:party_id/events/:id/manage_contrib',
                        {
                            id: '@id',
                            party_id: '@party_id'
                        }
                    );
                },

                areaContribManage: function (url) {
                    return this.createPagedCollection(url, '/api/parties/:party_id/areas/:id/manage_contrib',
                        {
                            id: '@id',
                            party_id: '@party_id'
                        }
                    );
                },

                goalContribManage: function (url) {
                    return this.createPagedCollection(url, '/api/parties/:party_id/goals/:id/manage_contrib',
                        {
                            id: '@id',
                            party_id: '@party_id'
                        }
                    );
                },

                visionContribManage: function (url) {
                    return this.createPagedCollection(url, '/api/parties/:party_id/visions/:id/manage_contrib',
                        {
                            id: '@id',
                            party_id: '@party_id'
                        }
                    );
                },

                noteContribManage: function (url) {
                    return this.createPagedCollection(url, '/api/parties/:party_id/notes/:id/manage_contrib',
                        {
                            id: '@id',
                            party_id: '@party_id'
                        }
                    );
                }
         };
        
         pipRestProvider.addApi(partiesApi);
    }]);
    
    thisModule.provider('pipPartiesData', function () {


        this.$get = ['$stateParams', '$state', 'pipDataModel', 'pipConnectionsCache', 'pipDataSession', function($stateParams, $state, pipDataModel, pipConnectionsCache, pipDataSession) {
            return {
                partyId: pipDataSession.partyId(),

                getParties : function (params, transaction, successCallback, errorCallback) {
                    var params = {
                        item : { skip: 0, take: 25, search: params.search, type: params.type},
                        resource : 'parties',
                        transaction : transaction
                    };
                    pipDataModel.query(params, successCallback, errorCallback);
                },

                getParty : function (params, successCallback, errorCallback) {
                    params.resource = 'parties';
                    pipDataModel.readOne(params, successCallback, errorCallback);
                },

                createTeam : function (params, successCallback, errorCallback) {
                    params.resource = 'parties';
                    params.item.creator_id = pipDataSession.partyId();
                    pipDataModel.create(params, function (team) {
                        params = { };
                        params.resource = 'members';
                        params.item = { };
                        params.item.party_id = pipDataSession.partyId();
                        params.item.to_party_id = team.id;
                        pipDataModel.query(params, function (connections) {
                            pipConnectionsCache.onConnectionCreate(params)(connections[0]);
                            if (successCallback) successCallback(team, connections);
                        });

                    }, errorCallback);
                },

                updateTeam : function (params, successCallback, errorCallback) {
                    params.resource = 'parties';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.update(params, successCallback, errorCallback);
                },

                inviteParty : function (params, successCallback, errorCallback) {
                    params.resource = 'inviteParty';
                    params.item.id = pipDataSession.partyId();
                    pipDataModel.update(
                        params,
                        pipConnectionsCache.onConnectionCreate(params, successCallback), 
                        errorCallback
                    );
                },

                getSuggestions : function (params, successCallback, errorCallback) {
                    var params = params;
                    params.item = {};
                    params.item.type = params.type;
                    params.resource = 'connectionSuggestions';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.query(params,successCallback,errorCallback);
                },

                manageContribs: function(params, successCallback, errorCallback) {
                    if (!params.resource) {
                        throw new Error('Set "resource" params for manageContribs operation.');
                    }
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.create(params, successCallback, errorCallback);
                }
            }
        }];
    });

})();
