<!--
@file Participant details control content
@copyright Digital Living Software Corp. 2014-2016
-->

<md-list class="pip-menu" ng-if="!selected.onlyOwner">
    <div class="pip-body tp24-flex">
        <md-progress-linear ng-show="transaction.busy()" md-mode="indeterminate" class="pip-progress-top">
        </md-progress-linear>
        <!-- join options -->
        <div class="bm24 layout-row layout-align-start-center"
             ng-if="selected.showJoinMethod">
            <div class="flex text-subhead1 opacity-primary rm16" ng-bind-html="selected.joinMethodDiscription">
            </div>
            <md-button class="m0 md-raised md-accent flat  flex-fixed"
                       ng-click="onJoinMethodChange($event)"
                       ng-disabled="selected.contrib_def || transaction.busy() || ngDisabled()"
                       aria-label="CHANGE_TYPE">
                {{::'CHANGE' | translate}}
            </md-button>
        </div>
        <!-- default switch -->
        <div class="h48 layout-row layout-align-start-center"
             ng-if="selected.showDefault">
            <md-switch ng-model="selected.contrib_def"
                       ng-disabled="transaction.busy() || ngDisabled()"
                       ng-change="onOptionChange()" aria-label="CONTRIB_DEF">
            </md-switch>
            <label class="text-body2 color-secondary-text">
                {{::'PARTICIPANT_CONFIDENT_DEFAULT' | translate}}
            </label>
        </div>
        <!-- pending party -->
        <div ng-if="selected.pending.length > 0">
            <p class="m0 text-body2 h48 color-secondary-text participant-title" >
                {{::'PARTICIPANT_PENDING' | translate}}
            </p>
            <div class="" ng-repeat="pendingParty in selected.pending track by pendingParty.party_id">
                <div class="pip-ref-item layout-row layout-align-start-center">
                    <pip-avatar class="pip-face rm16"
                                ng-click="onPartyClick($event, pendingParty)"
                                pip-party-id="pendingParty.party_id"
                                pip-party-name="pendingParty.party_name">
                    </pip-avatar>
                    <div class="pip-content color-secondary-text flex">
                        <p  class="pip-title">{{::pendingParty.party_name}}</p>
                        <p class="pip-subtitle"
                           ng-if="selected.showRole"
                           ng-click="onRoleChange($event, pendingParty)">
                            <a class="text-uppercase ">
                                {{getRoleString(pendingParty) }}
                            </a>
                        </p>
                    </div>
                    <div class="button-cross" style="margin-right: -16px;">
                        <md-button class="md-icon-button"
                                   ng-click="onRemovePending($event, pendingParty)"
                                   ng-if="!selected.contrib_def"
                                   ng-disabled="transaction.busy() || ngDisabled()"
                                   aria-label="REMOVE">
                            <md-icon md-svg-icon="icons:cross"></md-icon>
                        </md-button>
                    </div>
                </div>
                <div ng-class="{'pip-participant-divider': $index != selected.pending.length - 1 }" class="flex"></div>
            </div>
            <div class="h72" ng-if="selected.accepted.length == 0"></div>
        </div>
        <!-- active party -->
        <div ng-if="selected.accepted.length > 0">
            <p class="m0 text-body2 h48 color-secondary-text participant-title" >
                {{::'PARTICIPANT_ACTIVE' | translate}}
            </p>
            <div ng-repeat="acceptedParty in selected.accepted track by acceptedParty.party_id" >
                <div class="pip-ref-item layout-row layout-align-start-center">
                    <pip-avatar class="pip-face rm16"
                                ng-click="onPartyClick($event, acceptedParty)"
                                pip-party-id="acceptedParty.party_id"
                                pip-party-name="acceptedParty.party_name" >
                    </pip-avatar>
                    <div class="pip-content flex">
                        <p class="pip-title">{{::acceptedParty.party_name}}</p>
                        <p class="pip-subtitle"
                           ng-if="selected.showRole"
                           ng-click="onRoleChange($event, acceptedParty)">
                            <a class="text-uppercase ">
                                {{ getRoleString(acceptedParty) }}
                            </a>
                        </p>
                    </div>
                    <div class="button-cross" style="margin-right: -16px;">
                        <md-button class="md-icon-button"
                                   ng-click="onRemoveAccepted($event, acceptedParty)"
                                   ng-if="!selected.contrib_def"
                                   ng-disabled="transaction.busy() || ngDisabled()"
                                   aria-label="REMOVE">
                            <md-icon md-svg-icon="icons:cross"></md-icon>
                        </md-button>
                    </div>
                </div>
                <div ng-class="{'pip-participant-divider': $index != selected.accepted.length - 1 }" class="flex"></div>
            </div>
            <div class="h72"></div>
        </div>
    </div>
</md-list>

<!-- added participants  -->
<md-button class="md-fab md-accent pip-participants-fab"
           ng-click="onParticipantEdit()"
           ng-hide="selected.contrib_def ||  transaction.busy() || ngDisabled() || selected.onlyOwner"
           aria-label="ADD">
    <md-icon md-svg-icon="icons:person-square"></md-icon>
</md-button>

<md-list class="layout-column layout-align-center-center flex h-stretch"
         ng-if="!$routing && selected.onlyOwner">

    <div class="pip-empty layout-column layout-align-center-center flex">


        <img src="images/ES_Connections_Teams.svg" class="pip-pic block">

        <div class="pip-text">
            {{::'PARTICIPANT_EMPTY' | translate}}
        </div>

            <div class="tm16">
                <md-button class="md-accent md-raised flat" 
                            ng-hide="transaction.busy() || ngDisabled()"
                            ng-click="onParticipantEdit()">
                    {{::'PARTICIPANT_ADD' | translate}}
                </md-button>
            </div>
    </div>
</md-list>