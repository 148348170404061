/**
 * @file removing service, removing entities after converting
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo Complete refactoring
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipRemoveAfterConvertService', ['pipData']);

    thisModule.factory('RemoveAfterConvertService',
        ['pipMessagesData', 'pipNotesData', 'pipPostsData', 'pipUtils', function(pipMessagesData, pipNotesData, pipPostsData, pipUtils) {

            function removeReferenceEntity(params, successCallback, errorCallback) {
                if (pipUtils.toBoolean(params.ref_id && params.ref_type && params.ref_deleted)) {
                    var deleteParams = { item: { id: params.ref_id, party_id: params.partyId } };

                    switch (params.ref_type){
                        case 'note' :
                            pipNotesData.deleteNote(
                                deleteParams,
                                function (result) {
                                    if (successCallback)
                                        successCallback(result);
                                },
                                function (error) {
                                    if (errorCallback) {
                                        errorCallback(error);
                                    }
                                }
                            );
                            break;
                        case 'message' :
                            pipMessagesData.deleteMessage(
                                deleteParams,
                                function (result) {
                                    if (successCallback)
                                        successCallback(result);
                                },
                                function (error) {
                                    if (errorCallback) {
                                        errorCallback(error);
                                    }
                                }
                            );
                            break;
                        case 'post' :
                            pipPostsData.deletePost(
                                deleteParams,
                                function (result) {
                                    if (successCallback)
                                        successCallback(result);
                                },
                                function (error) {
                                    if (errorCallback) {
                                        errorCallback(error);
                                    }
                                }
                            );
                            break;
                        default:
                            break;
                    }

                }
            };

            return {
                removeReferenceEntity: removeReferenceEntity
            }

        }]
    );

})();
