/**
 * @file Events data cache
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipEventsCache', ['pipDataCache', 'pipCacheTag']);

    thisModule.service('pipEventsCache',
        ['pipEnums', 'pipDataCache', 'pipCacheTag', function(pipEnums, pipDataCache, pipCacheTag) {

            return {
                readEvents:    readEvents,
                onEventCreate: onEventCreate,
                onEventUpdate: onEventUpdate,
                onEventDelete: onEventDelete
            };
            //-------------

            function readEvents(params, successCallback, errorCallback) {
                params.resource = 'events';
                params.item = params.item || {};

                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function onEventCreate(params, successCallback) {
                
                return pipDataCache.addDecorator(
                    'events', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onEventUpdate(params, successCallback) {
                return pipDataCache.updateDecorator(
                    'events', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onEventDelete(params, successCallback) {
                return pipDataCache.removeDecorator('events', params, successCallback);
            };

        }]
    );

})();

