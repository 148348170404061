/**
 * @file Goals utils
 * @copyright Digital Living Software Corp. 2014-2016
 */

/*  global jQuery,  _, async, angular  */

(function () {
    'use strict';

    var thisModule = angular.module('pipGoalsUtils', ['pipUtils', 'pipNewsUAService']);

    thisModule.factory('GoalsUtilsService',
        ['pipGoalsData', '$rootScope', 'pipCollections', 'NewsUAService', 'pipEnums', 'pipToasts', 'pipTranslate', function(pipGoalsData, $rootScope, pipCollections, NewsUAService, pipEnums, pipToasts, pipTranslate) {

            function changeShowItems (itemIndex, itemCollection, onlyOpen) {
                if (itemCollection[itemIndex].sub_goals_count < 1) return;
                itemCollection[itemIndex].showGoals = itemCollection[itemIndex].showGoals ? onlyOpen ? true : false : true;
                var currentLevel = itemCollection[itemIndex].level;
                var i = itemIndex + 1;

                while (itemCollection[i].level > currentLevel) {
                    if (itemCollection[itemIndex].showGoals == true) {
                        if (currentLevel + 1 == itemCollection[i].level)
                            itemCollection[i].showCurrent = true;
                    } else {
                        itemCollection[i].showCurrent = false;
                        itemCollection[i].showGoals = false;
                    }
                    i++;
                    if (i == itemCollection.length) break;
                }
            };

            function createGoalTree (goals) {
                goals.sort(function (a, b) {
                    if (a.type == 'dream' || a.type == 'accomplishment' || a.status == 'completed' || a.status == "canceled") {
                        return 1;
                    }

                    if (b.type == 'dream' || b.type == 'accomplishment' || b.status == 'completed' || b.status == "canceled") {
                        return -1;
                    }

                    var important  = a.importance + a.urgency;
                    var importantB = b.importance + b.urgency;

                    if(important < importantB) return 1;
                    if(important > importantB) return -1;


                    if(important == importantB) {
                        if (a.title < b.title) return 1;
                        if (b.title < a.title) return -1;
                    }

                    return 0;
                });

                var goalTree = [ ];

                // Initialize links
                _.each(goals, function (goal) {
                    goal.super_goals = [];
                    goal.sub_goals = [];
                    goal.showGoals = false;
                });

                _.each(goals, function (goal) {
                    if (goal.type != 'category') {
                        _.each(goal.refs, function (item) {
                            var superGoal = _.find(goals, function (goal) {
                                return (goal.id == item.ref_id);// && (item.parent); //item.ref_type ???
                            });
                            if (superGoal) {
                                goal.super_goals.push(superGoal);
                                superGoal.sub_goals = superGoal.sub_goals || [];
                                superGoal.sub_goals.push(goal);
                            }
                        });
                    }

                    if (goal.super_goals.length == 0) {
                        goalTree.push(goal);
                    }
                });

                return goalTree;
            };

            function createGoalList (goalTree, excludeGoal) {
                var result = [],
                    index = 0;

                function processGoalList (goalSubtree, excludeGoal, level, result) {
                    _.each(goalSubtree, function (goal) {
                        if (excludeGoal && goal.id == excludeGoal.id) {
                            return;
                        }
                        var object = _.defaults(_.clone(goal), {
                            sub_goals_count: goal.sub_goals.length,
                            listIndex: index,
                            level: level,
                            showCurrent: level == 0 ? true : false
                        });
                        result.push(object);
                        index++;

                        if (goal.sub_goals.length > 0) {
                            processGoalList(goal.sub_goals, excludeGoal, level + 1, result);
                        }
                    });
                };

                processGoalList(goalTree, excludeGoal, 0, result);

                return result;
            };

            function createGoalShiftList (goalList) {
                var result = [];
                _.each(goalList, function (goal){
                    var item = _.cloneDeep(goal);
                    if (item.id == 1) return; // если это добавленная нами в дерево глобальная  "UNDEFINED"
                    item.shiftName = item.title;
                    for (var i = 0; i < item.level; i++)
                        item.shiftName = '...' + item.shiftName;
                    result.push(item);
                });
                return result;
            };

            function isOneParent(child) {
                return child.super_goals.length == 1;
            };

            function getTasks(item, successCallback, errorCallback) {
                pipGoalsData.readTasks({
                     item: {
                         type: 'task',
                         ref_id: item.id
                    }
                }, function (tasks) {
                    if (successCallback) successCallback(tasks);
                    return tasks;
                }, function (error){
                    if (errorCallback) {
                        errorCallback(error)
                    } else {
                        if (error.data.code) {
                            var errString = 'GOALS_ERROR_' + error.data.code;
                        } else {
                            var errString = 'GOALS_ERROR_' + error.status;
                        }
                        pipToasts.showError(pipTranslate.translate(errString), null, null, null, error);
                    }
                });
            };

            function getSubGoals(goals, superGoal) {
                var result = [];

                if (!superGoal || !superGoal.id) { return result; }
                _.each(goals, function (goal) {
                    if (goal.type != 'category') {
                        _.each(goal.refs, function (ref) {
                            if (superGoal.id == ref.ref_id)
                                result.push(goal);
                        });
                    }
                });

                return result;
            }
            
            function isComplited(collection) {
                if (!collection || !collection.length) return true;
                for (var j = 0; j < collection.length; j ++) {
                    // if not close or not close one of child
                    if (collection[j].status != 'completed' && collection[j].status != 'canceled' || !isComplited(collection[j].sub_goals)) return false;
                }
                return true;
            }            

            return {
                
                setComplited : function (collection) {
                    for (var i = 0; i< collection.length; i ++) {
                        collection[i].sub_goals_complited = isComplited(collection[i].sub_goals);
                    }
                },
                
                filterComplited: function (collection) {
                    var result = _.filter(collection, function(item){
                        return !((item.status == 'completed' || item.status == 'canceled') && item.sub_goals_complited)
                    });
                    return result;                     
                },
                
                prepareGoalListAcceptance: function (itemList) {
                    _.each(itemList, function (item) {
                        var i = _.findIndex(item.contribs || item.contribs, {party_id: $rootScope.$party.id});
                        if (i > -1) item.accept = item.contribs[i].accept == 'accepted' ? true : false;
                    });
                },

                getParentGoalsMatches: function (searchText, collection, filter) {
                    if (!searchText || searchText == '') return collection;
                    else if (filter)
                        return _.filter(collection, filter);
                },

                changeShowItems: function (itemIndex, itemCollection, onlyOpen) {
                    changeShowItems(itemIndex, itemCollection, onlyOpen);
                },

                openItem: function (item, itemCollection) {
                    var element = _.find(itemCollection, function(i) { return i.id == item.id; });
                    //_.find(_.filter(itemCollection, function(i) { return id == item.id; }));

                    if (element.level < 1) return;
                    else {
                        var currentLevel = element.level;
                        var i = itemCollection.indexOf(element);
                        while (itemCollection[i].level != 0) {
                            i--;
                            if (i < 0) return;
                            if (itemCollection[i].level < currentLevel) {
                                currentLevel--;
                                changeShowItems(i, itemCollection, true);
                            }
                        }
                    }
                },

                createGoalTree: function (goals) {
                    return createGoalTree(goals);
                },

                createGoalList: function (goalTree, excludeGoal) {
                    return createGoalList(goalTree, excludeGoal);
                },

                createGoalShiftList: function (goalList) {
                    return createGoalShiftList(goalList);
                },

                filterGoalTree: function (col, filterItem) {
                    var result = [];
                    var resultIndexes = [];

                    function addItem(item) {
                        if (resultIndexes.indexOf(item.listIndex) > -1) return;

                        result.push(item);
                        resultIndexes.push(item.listIndex);
                    }

                    col.forEach(function (item) {
                        if (filterItem(item)) {
                            addItem(item);

                            return;
                        }

                        if (item.sub_goals && item.sub_goals.length > 0) {
                            for(var i = 0; i < item.sub_goals.length; i++) {
                                var child = _.find(col, {id:item.sub_goals[i].id});

                                if (child)
                                    if (filterItem(child)) {
                                        addItem(item);
                                        addItem(child);

                                        return;
                                    }
                            }
                        }
                    });

                    return result;
                },

                createBreakdownCollection: function (goalId, itemCollection, includes) {
                    if (!goalId) return;

                    var includeSuperGoal = includes === undefined ? 'true' : includes;

                    var itemTree = createGoalTree(itemCollection);
                    var itemList = createGoalList(itemTree, null);

                    var result = [];

                    var getSubgoals = function (goal, result) {
                        _.each(goal.sub_goals, function (item) {
                            result.push(_.find(itemList, function(i) { return i.id == item.id; }));
                            getSubgoals(result[result.length - 1], result);
                        })
                    };

                    if (includeSuperGoal) result.push(_.find(itemList, function(i) { return i.id == goalId; }));
                    getSubgoals(_.find(itemList, function (i) { return i.id == goalId; }), result);

                    return result;
                },

                prepareGoalShiftList: function (itemCollection) {
                    var goalTree = createGoalTree(itemCollection);
                    var goalList = createGoalList(goalTree, null);
                    return createGoalShiftList(goalList);
                },

                getSubGoals: function (goals, superGoal) {
                    return getSubGoals(goals, superGoal);
                },

                getGoalIcons: function () {
                    return {
                        goal : "icons:goal",
                        accomplishment : "icons:check-all",
                        area : "icons:area",
                        objective : "icons:goal",
                        dream : "icons:lamp",
                        category : "icons:category"
                    };
                },

                getTasks:function (item, successCallback, errorCallback) {
                    return getTasks(item, successCallback, errorCallback);
                },

                convertToGoal: function (item, entity, ref_type) {
                    var exclusions = ['id', 'creator_id', 'creator_name', 'created', 'updated', 'closed', 'type', 'party_id',
                        'category', 'subcategory', 'sender_id', 'sender_name', 'sender_email', 'sent', 'deleted', 'rec_parties',
                        'rec_extern', '$promise', '$resolved'];

                    for (var prop in entity) {
                        if (typeof(entity[prop]) != 'function')
                            if ( exclusions.indexOf(prop) < 0 ) {
                               switch ( prop ) {
                                   case 'title' : item.title = _.clone(entity[prop]); break;
                                   case 'content' : item.content = _.cloneDeep(entity[prop]); break;
                                   default: item[prop] = _.cloneDeep(entity[prop]); break;
                               }
                            }
                    }

                    return item;
                },

                resetSubGoalsCounts: function (itemList) {
                    _.each(itemList, function (item) {
                        item.sub_goals_count = 0;
                        _.each(item.sub_goals, function (sub_goal) {
                            var patency = _.findIndex(itemList, {id: sub_goal.id});
                            if (patency > -1) item.sub_goals_count++;
                        });
                    });
                },

                getParentCategoryId: function (itemList, itemIndex) {
                    if (!itemList.length || itemList.length < 1) return null;
                    if (!itemIndex || itemIndex < 0 || itemIndex >= (itemList.length - 1)) return itemList[0].id;

                    while (itemList[itemIndex] && itemList[itemIndex].level && itemList[itemIndex].level != 0)
                        itemIndex--;

                    return itemList[itemIndex] ? itemList[itemIndex].id : null;
                },

                selectItemAfterRemoving: function (list, itemIndex) {
                    var removedItemLevel = list[itemIndex].level;
                    var removedItemId = list[itemIndex].id;
                    var index = _.clone(itemIndex);
                    index++;
                    while (index != list.length && list[index].level > removedItemLevel) {
                        if(!isOneParent(list[index])) {
                            list[index].showCurrent = false;
                        }
                        _.remove(list[index].parent_sources, {id: removedItemId});
                        list[index].level = 0;
                        index++;
                    }

                    if (list.length == 1)
                    return itemIndex;

                    if (itemIndex > 0 && (itemIndex + 1 >= list.length ||
                        list[itemIndex + 1].level != removedItemLevel))
                        itemIndex--;
                    else
                        itemIndex++;

                    return itemIndex;
                },

                setItemCounts: function (item, successCallback, f, goals) {
                    var force = f || false;

                    function calcTasks() {
                        if (!item.sub_goals) item.sub_goals = getSubGoals(goals, item);
                        item.subGoalsCount = (item.tasks ? item.tasks.length : 0) + (item.sub_goals ? item.sub_goals.length : 0);
                        item.subGoalsCompleteCount = (_.filter(item.sub_goals, function(g) { return g.status == 'completed'; }).length) +
                            (_.filter(item.tasks, function(t) { return t.status == 'completed'; }).length);
                    }

                    if (item.tasks && item.posts && !force) {
                        calcTasks();
                        item.postsCount = item.posts.length;
                        if (successCallback) successCallback(item);

                        return;
                    }

                    async.parallel([function (callback) {
                        if(!item.tasks || !!force) {
                            getTasks(item, function (tasks) {
                                item.tasks = tasks;
                                calcTasks();
                                callback();
                            });
                        } else {
                            calcTasks();
                            callback();
                        }

                    }, function (callback) {
                        if(!item.posts || !!force) {
                            NewsUAService.getPosts(item, pipEnums.REFERENCE_TYPE.AREA, $rootScope.$party.id, function (posts) {
                                item.posts = posts;
                                item.postsCount = item.posts.length;
                                callback();
                            });
                        } else {
                            item.postsCount = item.posts.length;
                            callback();
                        }
                    }], function () {
                        if (successCallback) successCallback(item);
                    });
                },

                setParentResource: function (item, parentList) {
                    item.parent_sources = [];

                    if (item.super_goals && item.super_goals.length > 0) {
                        item.super_goals.forEach(function (area) {
                            item.parent_sources.push(area);
                        });
                    }

                    if (item.refs) {
                        for (var i = 0; i < item.refs.length; i++) {
                            if (item.refs[i].parent) {
                                var parent = _.find(parentList, {id: item.refs[i].ref_id});
                                if (parent != null) {
                                    item.parent_sources.push(parent);
                                }
                            }
                        }
                        item.parent_sources = _.uniqBy(item.parent_sources, 'id');
                    }
                }

            }
        }]
    );

})();