/**
 * @file PipLife generic translation strings
 * @copyright Digital Living Software Corp. 2014-2015
 * @todo Move specific strings to their modules (pages)
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipStringsCommon', ['pipTranslate']);

    thisModule.config(['pipTranslateProvider', function (pipTranslateProvider) {

        pipTranslateProvider.translations('en', {
            'PRODUCT_NAME': 'Pip.Life',
            'COPYRIGHTS': 'Digital Living Software Corp. 2014-2016',

            // Common menu items
            'ABOUT_US': 'About us',
            'TERMS': 'Terms',
            'SUPPORT': 'Support',
            'MARKET': 'Market',

            // Common labels
            'FULLNAME': 'First and last name',
            'EMAIL': 'Email',
            'PASSWORD': 'Password',
            'LANGUAGE': 'Language',
            'GENDER': 'Gender',
            'BIRTHDAY': 'Birthday',
            'LOCATION': 'Location',
            'RELATED_TO': 'Related to',
            'SUBJECT': 'Subject',
            'TEXT': 'Text',
            'EVENT': 'Event',
            'WEB_LINK': 'Web link',
            'START_TIME': 'Start time',
            'END_TIME': 'End time',
            'GOAL': 'Goal',
            'TASK': 'Task',
            'AREA': 'Area',
            'POST': 'Post',
            'VISION': 'Vision',
            'DESCRIPTION': 'Description',
            'TITLE': 'Short name',
            'CONTENT': 'Content',
            'DAY': 'Day',
            'WEEK': 'Week',
            'IMPORTANCE': 'Importance',
            'URGENCY': 'Urgency',
            'PROGRESS': 'Progress',
            'STATUS': 'Status',
            'RECENT_ACTIVITIES': 'History',
            'COUNT': 'Count',
            'REJECT': 'Reject',
            'ACCEPT': 'Accept',
            'COPY': 'Copy',
            'CONVERT_TO': 'Convert to',
            'CONVERT_DIALOG_SELECT': 'Start',
            'PLANS_MOVE': 'Move',
            'PLANS_COPY': 'Copy',
            'PLANS_ALL_DAY': 'All day',
            'PLANS_REPEAT': 'Repeat',
            'SHARE_TITLE': 'Visible to',
            'INCLUDES': 'Includes',
            'PLANS_RELATED_VISION': 'Related vision',
            'CHOOSE_VISION_GOAL_AREA': '+Vision, area or goal',
            'CHOOSE_GOAL_AREA': '+Area or goal',
            'CHOOSE_GOAL': '+Goal',

            
            // User action
            'BLOCK_USER': 'Block user',
            'NOTIFICATION_BLOCK_USER': 'User %s is blocked',
            'NOTIFICATION_BLOCK_USER_ERROR': 'User blocking error: s%',

            // Special values
            'ALL': 'All',
            'UNDEFINED': 'Undefined',
            'Personal': 'Personal',
            'Health': 'Health',
            'Friends': 'Friends',
            'Recreation': 'Recreation',
            'Family': 'Family',
            'Community': 'Community',
            'Work': 'Work',
            'Money': 'Money',
            'Home': 'Home',
            'Education': 'Education',
            'Fun': 'Fun',
            'Finances': 'Finances',
            'Career': 'Career',
            'LOW': 'Low',
            'NORMAL': 'Normal',
            'HIGH': 'High',
            'DOCUMENT': 'document',
            'DOCUMENTS_FIVE_AND_MORE': 'documents',
            'DOCUMENTS_FIVE_LESS': 'documents',
            'FEEDBACK': 'Feedback',
            'COPYRIGHT': 'Copyright',
            'BUSINESS': 'Business',
            'ADVERTISING': 'Advertising',
            'COMPLETE': 'Complete',
            'PLANNED_EFFORT': 'Planned',
            'ACTUAL_EFFORT': 'Actual',
            'HOURS_SHORT': 'hrs',

            'ACTION_PLAN': 'Action plan',
            'POSTS': 'Posts',
            'TAGS': '+Keyword',

            // Common hints
            'HINT_FULLNAME': "Use your real name, so others know who you are",
            'MINLENGTH_PASSWORD': 'Minimum 6 characters',
            'HINT_ABOUT': 'A few words about yourself',

            //'VERIFY_EMAIL': 'Please, verify your email address. ', //??
            //'NOT_SHOW':'Don`t show it again', //??

            'DEFAULT_SHARING': 'Visibility by default',

            // Dialogs
            'ADD_RECIPIENTS': 'Add recipients',
            'CHOOSE_RECIPIENTS': '+Recipient',
            'TOTAL_CONTRIBUTORS': 'Total %s contributors',
            'NO_CONTRIBUTORS': 'There are no contributors',

            // View types
            'VIEW_LIST': 'List',
            'VIEW_TILES': 'Tiles',
            'VIEW_KANBAN': 'Kanban',
            'VIEW_MIND_MAP': 'Mind map',

            // Common errors
            'ERROR_EMAIL_INVALID': 'Please enter a valid email',
            'ERROR_PASSWORD_INVALID': 'Please enter a valid password',
            'ERROR_FULLNAME_INVALID': 'Please enter your full name',
            'ERROR_REQUIRED': 'Please fill in the field',
            'ERROR_UPLOAD': "Sorry some files don't upload",

            //success messages
            'AREA_SUCCESS_ADDED': 'The %s "%s" successfully created',
            'GOAL_SUCCESS_ADDED': 'The %s "%s" successfully created',
            'EVENT_SUCCESS_ADDED': 'The event "%s" successfully created',
            'EVENT_SUCCESS_EDIT': 'The event "%s" successfully saved',
            'ACTION_SUCCESS_ADDED': 'The action "%s" successfully created',
            'ACTION_SUCCESS_EDIT': 'The action "%s" successfully saved',
            'EVENT_COPY_FROM_GOAL': 'Event "%s" successfully created from the action',
            'EVENT_COPY_FROM_PERIOD': 'Event "%s" successfully created from %s event "%s"',
            'EVENT_SUCCESS_ACCEPT': 'Event "%s" successfully accepted',
            'EVENT_SUCCESS_REJECT': 'Event "%s" successfully rejected',

            //Relations
            'RELATION_WIFE': 'Wife',
            'RELATION_HUSBAND': 'Husband',
            'RELATION_EXWIFE': 'Ex-wife',
            'RELATION_EXHUSBAND': 'Ex-husband',
            'RELATION_SON': 'Son',
            'RELATION_DAUGHTER': 'Daughter',
            'RELATION_MOTHER': 'Mother',
            'RELATION_FATHER': 'Father',
            'RELATION_MOTHERINLOW': 'Mother-in-law',
            'RELATION_FATHERINLOW': 'Father-in-law',
            'RELATION_GRANDMOTHER': 'Grandmother',
            'RELATION_GRANDFATHER': 'Grandfather',
            'RELATION_BROTHER': 'Brother',
            'RELATION_SISTER': 'Sister',
            'RELATION_COUSIN': 'Cousin',
            'RELATION_FRIEND': 'Friend',
            'RELATION_COWORKER': 'Coworker',
            'RELATION_OTHER': 'Other',
            'Wife': 'Wife',
            'Husband': 'Husband',
            'Ex-wife': 'Ex-wife',
            'Ex-husband': 'Ex-husband',
            'Son': 'Son',
            'Daughter': 'Daughter',
            'Mother': 'Mother',
            'Father': 'Father',
            'Mother-in-law': 'Mother-in-law',
            'Father-in-law': 'Father-in-law',
            'Grandmother': 'Grandmother',
            'Grandfather': 'Grandfather',
            'Brother': 'Brother',
            'Sister': 'Sister',
            'Cousin': 'Cousin',
            'Friend': 'Friend',
            'Coworker': 'Coworker',
            'Other': 'Other',

            // Area type
            'AREA_TYPE_AREA': 'Area',
            'AREA_TYPE_GOAL': 'Goal',
            'AREA_TYPE_OBJECTIVE': 'Objective',
            'AREA_TYPE_DREAM': 'Dream',
            'AREA_TYPE_ASPIRATION': 'Aspiration',
            'AREA_TYPE_ACCOMPLISHMENT': 'Deed',
            'AREA_TYPE_CHORE': 'Chore',
            'AREA_TYPE_HABIT': 'Habit',
            'AREA_TYPE_TASK': 'Task',
            
            'OBJECTIVE': 'Objective',
            'DREAM': 'Dream',
            'ASPIRATION': 'Aspiration',
            'ACCOMPLISHMENT': 'Deed',
            'CHORE': 'Chore',
            'HABIT': 'Habit',
           

            // Contributor roles
            'RESPONSIBLE_ROLE': 'Responsible',
            'ACCOUNTABLE_ROLE': 'Accountable',
            'CONSULTED_ROLE': 'Consulted',
            'INFORMED_ROLE': 'Informed',

            // Common actions
            'CATEGORIES': 'Categories',
            'GUIDANCE': 'Guidance',
            'CONTRIBUTORS': 'Participants',
            'VISIBILITY': 'Visibility',
            'COMMENTS': 'Comments',
            'REQUSTED': 'Requested',
            'SHOW_MORE': 'Show more',
            'MORE_URL': 'See more...',
            'NEXT': 'Next',
            'SHOW_LESS': 'Show less',
            'ADD_ANOTHER': 'Add another',

            'DO_NOT_SHOW': "Don't show it again",
            'DOCUMENTS_ATTACHED': 'document(s) attached',
            'UNDER_CONSTRUCTION': 'This page is under construction. <br/> Please, come back later!',

            // event period
            'DAILY': 'Daily',
            'WEEKLY': 'Weekly',
            'MONTHLY': 'Monthly',
            'YEARLY': 'Yearly',
            'ACTION': 'Anytime',

            'CREATE': 'Create',
            'ADD': 'Add',
            'SAVE': 'Save',
            'DELETE': 'Delete',
            'EDIT': 'Edit',
            'CONVERT': 'Convert',
            'SEND': 'Send',
            'REPLY_ALL': 'Reply all',
            'FORWARD': 'Forward',
            'SIGNOUT': 'Sign out',
            'CONTINUE': 'Continue',

            'ENGLISH': 'English',
            'RUSSIAN': 'Russian',

            'INTRO': 'Intro',

            'DETAILS': 'Details'

        });

        pipTranslateProvider.translations('ru', {
            'PRODUCT_NAME': 'Pip.Life',
            'COPYRIGHTS': 'Digital Living Software Corp. 2014-2016',

            // Common menu items
            'ABOUT_US': 'О нас',
            'TERMS': 'Соглашение',
            'SUPPORT': 'Техподдержка',
            'MARKET': 'Продукты',
            'TAGS': '+Ключевое слово',

            // Common labels
            'FULLNAME': 'Имя и фамилия',
            'EMAIL': 'Адрес эл.почты',
            'PASSWORD': 'Пароль',
            'LANGUAGE': 'Язык',
            'GENDER': 'Пол',
            'BIRTHDAY': 'Дата рождения',
            'LOCATION': 'Местонахождение',
            'RELATED_TO': 'С чем это связано?',
            'SUBJECT': 'Заголовок',
            'TEXT': 'Текст',
            'EVENT': 'Событие',
            'WEB_LINK': 'Веб ссылка',
            'START_TIME': 'Начало',
            'END_TIME': 'Конец',
            'TITLE': 'Краткое название',
            'GOAL': 'Цель',
            'TASK': 'Задача',
            'AREA': 'Область',
            'POST': 'Пост',
            'VISION': 'Видение',
            'DESCRIPTION': 'Описание',
            'CONTENT': 'Содержимое',
            'IMPORTANCE': 'Важность',
            'URGENCY': 'Срочность',
            'PROGRESS': 'Прогресс',
            'STATUS': 'Статус',
            'RECENT_ACTIVITIES': 'История',
            'COUNT': 'Количество',
            'ACCEPT': 'Принять',
            'REJECT': 'Отказаться',
            'COPY': 'Копировать',
            'CONVERT_TO': 'Конвертировать в',
            'CONVERT_DIALOG_SELECT': 'Начать',
            'PLANS_ALL_DAY': 'Весь день',
            'PLANS_REPEAT': 'Повторять',
            'SHARE_TITLE': 'Поделитесь с',
            'PLANS_MOVE': 'Перенести',
            'PLANS_COPY': 'Скопировать',
            'ACTION_PLAN': 'План действий',
            'POSTS': 'Посты',
            'INCLUDES': 'Включает',
            'PLANS_RELATED_VISION': 'Связанное видение/мечта',
            'CHOOSE_VISION_GOAL_AREA': '+Видение, область или цель',
            'CHOOSE_GOAL_AREA': '+Область или цель',
            'CHOOSE_GOAL': '+Цель',

            // User action
            'BLOCK_USER': 'Блокировать пользователя',
            'NOTIFICATION_BLOCK_USER': 'Пользователь %s заблокирован',
            'NOTIFICATION_BLOCK_USER_ERROR': 'При блокировке пользователя %s возникла ошибка: s%',


            // Special values
            'ALL': 'Все',
            'UNDEFINED': 'Неопределенные',
            'Personal': 'Личное',
            'Health': 'Здоровье',
            'Recreation': 'Отдых',
            'Friends': 'Друзья',
            'Family': 'Семья',
            'Community': 'Общество',
            'Work': 'Работа',
            'Money': 'Деньги',
            'Home': 'Дом',
            'Education': 'Образование',
            'Fun': 'Развлечения',
            'Finances': 'Финансы',
            'Career': 'Карьера',
            'LOW': 'Низкая',
            'NORMAL': 'Нормальная',
            'HIGH': 'Высокая',
            'DOCUMENT': 'документ',
            'DOCUMENTS_FIVE_AND_MORE': 'документов',
            'DOCUMENTS_FIVE_LESS': 'документа',
            'FEEDBACK': 'Обратная связь',
            'COPYRIGHT': 'Авторское право',
            'BUSINESS': 'Бизнес',
            'ADVERTISING': 'Реклама',
            'COMPLETE': 'Завершить',
            'PLANNED_EFFORT': 'Запланировано',
            'ACTUAL_EFFORT': 'Потрачено',
            'HOURS_SHORT': 'час.',

            // Common hints
            'HINT_FULLNAME': "Используйте своё настоящее имя, чтобы другие знали с кем имеют дело",
            'MINLENGTH_PASSWORD': 'Минимум 6 символов',
            'HINT_ABOUT': 'Несколько слов о себе',
            'VERIFY_EMAIL': 'Подтвердите адрес своей эл.почты ',

            'DEFAULT_SHARING': 'Видимость по умолчанию',
            // Dialog
            'ADD_RECIPIENTS': 'Добавить получателей',
            'CHOOSE_RECIPIENTS': '+Получатель',
            'TOTAL_CONTRIBUTORS': 'Всего %s участников',
            'NO_CONTRIBUTORS': 'Нет участников',

            // View types
            'VIEW_LIST': 'Список',
            'VIEW_TILES': 'Плитки',
            'VIEW_KANBAN': 'Kanban',
            'VIEW_MIND_MAP': 'Mind Map',

            // Common errors
            'ERROR_EMAIL_INVALID': 'Введите правильный адрес эл.почты',
            'ERROR_PASSWORD_INVALID': 'Введите правильный пароль',
            'ERROR_FULLNAME_INVALID': 'Введите фамилию и имя',
            'ERROR_REQUIRED': 'Заполните поле',
            'ERROR_UPLOAD': "Ошибка при загрузки файлов",

            //success messages
            'AREA_SUCCESS_ADDED': '%s "%s" создана',
            'GOAL_SUCCESS_ADDED': '%s %s создана',
            'ACTION_SUCCESS_ADDED': 'Цель/задача %s создана',
            'ACTION_SUCCESS_EDIT': 'Цель/задача %s сохранена',
            'EVENT_SUCCESS_ADDED': 'Событие %s создано',
            'EVENT_SUCCESS_EDIT': 'Событие %s сохранено',
            'EVENT_COPY_FROM_GOAL': 'Событие %s создано из цели',
            'EVENT_COPY_FROM_PERIOD': 'Событие %s создано из события %s %s',
            'EVENT_SUCCESS_ACCEPT': 'Событие %s принято',
            'EVENT_SUCCESS_REJECT': 'Событие %s отклонено',

            //Relations
            'RELATION_WIFE': 'Жена',
            'RELATION_HUSBAND': 'Муж',
            'RELATION_EXWIFE': 'Бывшая жена',
            'RELATION_EXHUSBAND': 'Бывший муж',
            'RELATION_SON': 'Сын',
            'RELATION_DAUGHTER': 'Дочь',
            'RELATION_MOTHER': 'Мама',
            'RELATION_FATHER': 'Папа',
            'RELATION_MOTHERINLOW': 'Свекровь',
            'RELATION_FATHERINLOW': 'Тесть',
            'RELATION_GRANDMOTHER': 'Бабушка',
            'RELATION_GRANDFATHER': 'Дедушка',
            'RELATION_BROTHER': 'Брат',
            'RELATION_SISTER': 'Сестра',
            'RELATION_COUSIN': 'Кузен или кузина',
            'RELATION_FRIEND': 'Друг',
            'RELATION_COWORKER': 'Сотрудник',
            'RELATION_OTHER': 'Другой',
            'Wife': 'Жена',
            'Husband': 'Муж',
            'Ex-wife': 'Бывшая жена',
            'Ex-husband': 'Бывший муж',
            'Son': 'Сын',
            'Daughter': 'Дочь',
            'Mother': 'Мама',
            'Father': 'Папа',
            'Mother-in-law': 'Свекровь',
            'Father-in-law': 'Тесть',
            'Grandmother': 'Бабушка',
            'Grandfather': 'Дедушка',
            'Brother': 'Брат',
            'Sister': 'Сестра',
            'Cousin': 'Кузен или кузина',
            'Friend': 'Друг',
            'Coworker': 'Сотрудник',
            'Other': 'Другой',

            // Area type
            'AREA_TYPE_AREA': 'Область',
            'AREA_TYPE_GOAL': 'Цель',
            'AREA_TYPE_OBJECTIVE': 'Подцель',
            'AREA_TYPE_DREAM': 'Мечта',
            'AREA_TYPE_ASPIRATION': 'Стремление',
            'AREA_TYPE_ACCOMPLISHMENT': 'Достижение',
            'AREA_TYPE_CHORE': 'Рутина',
            'AREA_TYPE_HABIT': 'Привычка',
            'AREA_TYPE_TASK': 'Задача',
            
            // Area type
            'OBJECTIVE': 'Подцель',
            'DREAM': 'Мечта',
            'ASPIRATION': 'Стремление',
            'ACCOMPLISHMENT': 'Достижение',
            'CHORE': 'Рутина',
            'HABIT': 'Привычка',

            // Contributor roles
            'RESPONSIBLE_ROLE': 'Исполнитель',
            'ACCOUNTABLE_ROLE': 'Ответственный',
            'CONSULTED_ROLE': 'Консультант',
            'INFORMED_ROLE': 'Информируемый',

            // Common actions
            'CATEGORIES': 'Категории',
            'GUIDANCE': 'Подсказка',
            'CONTRIBUTORS': 'Участники',
            'VISIBILITY': 'Видимость',
            'REQUSTED': 'Приглашенный',
            'SHOW_MORE': 'Показать больше',
            'SHOW_LESS': 'Скрыть',
            'ADD_ANOTHER': 'Добавить еще',
            'MORE_URL': 'Больше по ссылке...',
            'NEXT': 'Следующая',

            'DO_NOT_SHOW': 'Не показывать это снова',
            'DOCUMENTS_ATTACHED': 'документ(ов) приклеплено',
            'UNDER_CONSTRUCTION': 'Эта страница находится в разработке. <br/> Пожалуйста, приходите позже!',

            // периоды для задач
            'DAILY': 'На день', //'Дневные',
            'WEEKLY': 'На неделю', //'Недельные',
            'MONTHLY': 'На месяц', //'Месячные',
            'YEARLY': 'На год', //'Годовые',
            'ACTION': 'Когда-нибудь', //'Нужно сделать',

            'CREATE': 'Создать',
            'ADD': 'Добавить',
            'SAVE': 'Сохранить',
            'DELETE': 'Удалить',
            'EDIT': 'Изменить',
            'CONVERT': 'Конвертировать',
            'SEND': 'Послать',
            'REPLY_ALL': 'Ответить всем',
            'FORWARD': 'Переслать',
            'SIGNOUT': 'Выйти',
            'INTRO': 'Введение',
            'CONTINUE': 'Продолжить',

            'ENGLISH': 'Английский',
            'RUSSIAN': 'Русский',

            'DETAILS': 'Детали'
        });

    }]);

})();