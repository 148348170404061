<!--
@file Party select control content
@copyright Digital Living Software Corp. 2014-2016
-->

<md-input-container>
	<md-select ng-model="selectedOption"
			   ng-disabled="hideSelectIcon"
			   ng-class="{'pip-md-select-icon-hide': hideSelectIcon}"
			   aria-label="SELECT"
			   md-ink-ripple
			   md-on-close="onOptionSelect($event)">

		<md-option ng-repeat="option in partyOptions track by option.party_id" value="{{ $index }}" >
			<div class="pip-party-select-option">
				<pip-avatar pip-party-id="option.party_id"
							pip-party-name="option.party_name"
							class="pip-face">
				</pip-avatar>
				<span class="pip-option-party-name flex">{{option.party_name}}</span>
			</div>
		</md-option>
	</md-select>
</md-input-container>