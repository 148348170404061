/**
 * @file Ripple Circle control
 * @copyright Digital Living Software Corp. 2014-2016
 * - add id for ripple element. this id can be used in the event for identifical rippled element
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipRippleCircle", []);

    thisModule.directive('pipRippleCircle',
        ['$timeout', '$interval', 'pipUtils', '$rootScope', function($timeout, $interval, pipUtils, $rootScope) {
            return {
                restrict: 'C',
                link: function(scope, element, attrs) {
                    var sizeCircle = 25,
                        deltaX = 0, deltaY = 0,
                        periodDuration = attrs.pipPeriodDuration || 0,
                        rippleStop,
                        delteOffset = attrs.pipRippleOffset || null,
                        colorClass = attrs.pipRippleColorClass || 'color-accent',
                        dellay = attrs.pipRippleDellay || 5000,
                        rippleByEvent = attrs.pipRippleEvent || null,
                        intervalStop,
                        parentElement = $('body'),
                        docWidth,
                        docHeight,
                        parentOffset;

                    if (rippleByEvent) {
                        $rootScope.$on(rippleByEvent, showRipple);
                    } else if (periodDuration ) {
                        intervalStop = $interval(showRipple, periodDuration);
                    } else{
                        $timeout(showRipple, dellay);
                    }

                    if (rippleByEvent) {
                        $rootScope.$on(rippleByEvent, showRipple);
                    }

                    return ;

                    function showRipple() {

                        if (!element[0]) return;

                        parentOffset = element.offset();
                        docWidth = $(document).width();
                        docHeight = $(document).height();

                        // stop periodical ripple
                        if (periodDuration) {
                            rippleStop = pipUtils.toBoolean(attrs.pipRippleStop);

                            if (rippleStop === true && intervalStop) {
                                $interval.cancel(intervalStop);

                                return;
                            }
                        }

                        var $div = angular.element('<div></div>'),
                            yPos = parentOffset.top + deltaY - sizeCircle,
                            xPos = parentOffset.left + deltaX - sizeCircle

                        $div.addClass('ripple-effect');

                        $div.addClass(colorClass);
                        $div.css({
                            position: 'absolute',
                            top: '' + yPos  + 'px',
                            left: '' + xPos + 'px'
                        })
                            .appendTo(parentElement);

                        $timeout(function() {
                            $div.remove();
                        }, 2800);
                    }
                }
            }
        }]
    );

})();