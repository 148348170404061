/**
 * @file Registration of commons
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    angular.module('pipLifeCommons', [
        // Common panels and extensions
        'pipParticipantsDetails',
        'pipVisibilityDetails',
        'mdChipsExtend',
        'mdAutocompleteExtend',
        // Dialogs
        'pipChooseTilesDialog',
        'pipConvertToDialog',
        'pipDeleteConfirmationDialog',
        'pipEditTitlesDialog',
        // Priority status dialogs
        'pipPriorityEditDialog',
        'pipStatusEditDialog',
        'pipConversionDialog',
        // Services
        'pipAccessoryService',
        'pipAreasUtils',
        'pipCommonCollectionService',
        'pipCommonServices',
        'pipEventsUAService',
        'pipGoalsUtils',
        'pipNewsUAService',
        'pipParentsService',
        'pipRemoveAfterConvertService',
        
        // quote services
        'pipQuotes.Service',
        // hande errors 
        'pipErrorHandleService',
        // ripple effect
         'pipRippleButton',
         'pipRippleCircle',

        // Recent activities services
        'pipActivityFormat',
        'pipActivityFilters',
        'pipRecentActivities',

        // Format services
        'pipPriorityFormat',
        'pipPriorityFilters',
        'pipVisibilityFormat',
        'pipVisibilityFilters',
        'pipParticipantsFormat',
        'pipParticipantsFilters',
        // Priority status services
        'pipPriorityStatusEdit',
        // Parties services
        'pipPartySelect',
        'pipPartyList',
        'pipPartyEditDialog',
        // Participants combined list
        'pipParticipantsListCombined',
        // SideNav
        'pipLifeSideNav',
        // Parent chips
        'pipParentChipsComplex',

        // Rest API
        // 'pipLifeRest',
        'pipAreasCache',
        'pipAreasData',
        'pipConnectionsCache',
        'pipConnectionsData',
        'pipEventsData',
        'pipFollowingsData',
        'pipGoalsCache',
        'pipGoalsData',
        'pipGroupsCache',
        'pipGroupsData',
        'pipMembersData',
        'pipNotesData',
        'pipContactsData',
        'pipNotesCache',
        'pipNotificationsData',
        'pipPartiesData',
        'pipPartnersData',
        'pipPostsData',
        'pipQuotesData',
        'pipQuotesCache',
        'pipVisionsData',
        'pipMessagesData',
        'pipMessagesCache',
        'pipNotificationsCache',

        'pipStringsEnums',
        'pipStringsCommon',
        'pipStringsErrors',

        // Configurations services
        'pipLifeParticipantsConfig',

        // new record wizard
        'pipRecordWizardHeaderPanel',
        'pipRecordWizardParticipantsPanel',
        'pipRecordWizardVisabilityPanel',
        'pipTaskList',

        // parent convert wizard
        'pipParentConvertWizard',
        'pipWrapper',
        // blur for md-autocomplite or other control with md-input
        'pipInputBlur',
        // focus for md-autocomplite or other control with md-input
        'pipInputFocus',
        'pipParentChips',

        // entry for mobile applications
        // 'pipMobileEntry'
        'pipConvertToDialogNew',
        'pipConvertService',

        'pipComments'
    ]);

})();



