/**
 * @file Participants common logic (local config)
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipLifeParticipantsConfig', []);

    thisModule.provider('pipLifeParticipantsConfig', function() {
        var openAbout = true;

        this.setOpenAbout = function(open) {
            openAbout = open;
        };

        this.$get = function () {
            return {
                isOpenAbout: function() {
                    return openAbout;
                }
            };
        }
    });

})();
