/**
 * @file Areas data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipAreasData',
        ['pipRest', 'pipRest', 'pipDataModel', 'pipAreasCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var areasApi = {
               areas: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/areas/:id');
                },

                acceptArea: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/areas/:id/accept');
                },

                rejectArea: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/areas/:id/reject');
                },

                goals: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/goals/:id');
                },

                notes: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/notes/:id');
                },

                posts: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/posts/:id');
                },

                events: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/events/:id');
                },

                messages: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/messages/:id');
                },

                visions: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/visions/:id');
                }
         };
        
         pipRestProvider.addApi(areasApi);
    }]);
    
    thisModule.provider('pipAreasData', function () {

        // // Read all categories
        // this.readCategoriesResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, pipAreasCache) {
        //         return pipAreasCache.readCategories({
        //             item: { party_id: pipDataSession.partyId() }
        //         });
        //     };
        // };

        // this.readAreasResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, pipAreasCache) {
        //         return pipAreasCache.readAreas({
        //             item: { party_id: pipDataSession.partyId() }
        //         });
        //     };
        // };

        // this.readEntityResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipEnums, pipDataModel, pipRest) {
        //         if ( !$stateParams.ref_id || !$stateParams.ref_type ) return null;
        //         // huk for actions
        //         var resourceName = $stateParams.ref_type == pipEnums.GOAL_TYPE.TASK
        //             ? pipEnums.REFERENCE_TYPE.GOAL : $stateParams.ref_type;

        //         var params = { };
        //         params.resource = resourceName + 's';
        //         params.item = { };
        //         // for messages: $stateParams.managed_party_id
        //         params.item.party_id = $stateParams.managed_party_id ||  pipDataSession.partyId();
        //         params.item.id = $stateParams.ref_id;

        //         return pipDataModel.readOne(params).$promise;
        //     };
        // };

        // this.readAreaResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipEnums, pipRest, pipAreasCache) {
        //         if (!$stateParams.id) return null;

        //         var params = {
        //             party_id: pipDataSession.partyId(),
        //             id: $stateParams.id
        //         };

        //         return pipRest.areas().get(
        //             params,
        //             pipAreasCache.onAreaUpdate(params)
        //         ).$promise;
        //     };
        // };

        this.$get = ['$stateParams', 'pipDataModel', 'pipAreasCache', 'pipDataSession', function($stateParams, pipDataModel, pipAreasCache, pipDataSession) {
            return {
                partyId: pipDataSession.partyId(),

                readAreas: function(params, successCallback, errorCallback) {
                    params.resource = 'areas';
                    params.party_id = pipDataSession.partyId();
                    params.type = $stateParams.type;
                    params.parent_id = $stateParams.parent_id;
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    return  pipAreasCache.readAreas(params, successCallback, errorCallback);
                },

                getAreasPage: function(params, successCallback, errorCallback) {
                    params.resource = 'areas';
                    params.item = params.item || { };
                    params.item.party_id = pipDataSession.partyId();
                    return pipDataModel.page(params, successCallback, errorCallback);
                },

                readArea: function(params, successCallback, errorCallback) {
                    params.resource = 'areas';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.id = params.item.id || $stateParams.id;
                    return pipDataModel.readOne(
                        params,
                        pipAreasCache.onAreaUpdate(params, successCallback),
                        errorCallback
                    );
                },

                createArea: function(params, successCallback, errorCallback) {
                    params.resource = 'areas';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.create(
                        params,
                        pipAreasCache.onAreaCreate(params, successCallback),
                        errorCallback
                    );
                },

                createAreaWithFiles: function(params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(params, function() {
                        params.resource = 'areas';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;
                        params.item.party_id = pipDataSession.partyId();
                        pipDataModel.create(
                            params,
                            pipAreasCache.onAreaCreate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                updateArea: function(params, successCallback, errorCallback) {
                    params.resource = 'areas';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.update(
                        params,
                        pipAreasCache.onAreaUpdate(params, successCallback),
                        errorCallback
                    );
                },

                updateAreaWithFiles: function(params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(params, function() {
                        params.resource = 'areas';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;
                        params.item.party_id = pipDataSession.partyId();
                        pipDataModel.update(
                            params,
                            pipAreasCache.onAreaUpdate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                deleteArea: function(params, successCallback, errorCallback) {
                    params.resource = 'areas';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.remove(
                        params,
                        pipAreasCache.onAreaDelete(params, successCallback),
                        errorCallback
                    );
                },

                acceptArea: function(params, successCallback, errorCallback) {
                    params.resource = 'acceptArea';
                    pipDataModel.create(
                        params,
                        pipAreasCache.onAreaUpdate(params, successCallback),
                        errorCallback
                    );
                },

                rejectArea: function(params, successCallback, errorCallback) {
                    params.resource = 'rejectArea';
                    pipDataModel.create(
                        params,
                        pipAreasCache.onAreaDelete(params, successCallback),
                        errorCallback
                    );
                }
            };
        }];
        this.$get.$inject = ['$stateParams', 'pipDataModel', 'pipAreasCache', 'pipDataSession'];
    });

})();