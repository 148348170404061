/**
 * @file Participant details control
 * @copyright Digital Living Software Corp. 2014-2016
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipParticipantsDetails",
        ['pipUtils', 'pipRest.Enums', 'pipTranslate', 'pipPartyEditDialog', 'pipOptionsBigDialog', 'pipLifeCommons.Templates']);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            'PARTICIPANT_DIALOG_TITLE': 'Choose participants',
            'CHOOSE_JOIN_METHOD': 'Change join method',
            'PARTICIPANT_CONFIDENT_DEFAULT': 'Default participants (same as parent records have)',
            'PARTICIPANT_ACTIVE': 'Active',
            'PARTICIPANT_PENDING': 'Pending',
            'DESCRIPTION_JOIN_METHOD_INVITE': 'Only active participants can invite other parties',
            'DESCRIPTION_JOIN_METHOD_APPROVE': 'Other parties can join after approval from an active participant',
            'DESCRIPTION_JOIN_METHOD_OPEN': 'Anyone can become a participant. No permissions needed',

            'PARTICIPANT_ROLE_RESPONSIBLE': 'Responsible',
            'PARTICIPANT_ROLE_ACCOUNTABLE': 'Accountable',
            'PARTICIPANT_ROLE_CONSULTED': 'Consulted',
            'PARTICIPANT_ROLE_INFORMED': 'Informed',
            'PARTICIPANT_ROLE_UNDEFINED_IN_LIST': 'Undefined',
            'PARTICIPANT_ROLE_DEFAULT': 'Default',

            'PARTICIPANT_ROLE_RESPONSIBLE_DESCRIPTION': 'Does the work',
            'PARTICIPANT_ROLE_ACCOUNTABLE_DESCRIPTION': 'Makes decisions and is accountable for results',
            'PARTICIPANT_ROLE_CONSULTED_DESCRIPTION': 'Provides information or checks results',
            'PARTICIPANT_ROLE_INFORMED_DESCRIPTION': 'Tracks the progress',
            'PARTICIPANT_ROLE_UNDEFINED_IN_LIST_DESCRIPTION': 'To be defined later',
            'PARTICIPANT_ROLE_DEFAULT_DESCRIPTION': 'The same as in parent records',

            'PARTICIPANT_ROLE_HINT': 'Roles allow to separate personal work from work of other partners.',

            'PARTICIPANT_ROLE_UNDEFINED': 'Set Role',
            'DELETE_PARTICIPANT_TITLE': 'Delete participant',
            'DELETE_LAST_PARTICIPANT_TEXT': 'When you remove the last participant the record will be deleted.',
            'DELETE_CURRENT_PARTICIPANT_TEXT': 'You\'ll be removed from participants and may no longer access to this record',
            'PARTICIPANT_EMPTY': 'You can invite your family members, friends and coworkers to participate. Work together to get tasks and goals done.',
            'PARTICIPANT_ADD': 'Add Participants'
        });
        pipTranslateProvider.translations('ru', {
            'PARTICIPANT_DIALOG_TITLE': 'Выберите участников',
            'CHOOSE_JOIN_METHOD': 'Измените способ присоединения',
            'PARTICIPANT_CONFIDENT_DEFAULT': 'Участники по умолчанию (такие же, как у родительских записей)',
            'PARTICIPANT_ACTIVE': 'Активные',
            'PARTICIPANT_PENDING': 'Ожидаются',
            'DESCRIPTION_JOIN_METHOD_INVITE': 'Только активные участники могут пригласить других',
            'DESCRIPTION_JOIN_METHOD_APPROVE': 'Участником можно стать после подтверждения от активного участника',
            'DESCRIPTION_JOIN_METHOD_OPEN': 'Кто угодно может стать участником. Разрешения не требуется.',

            'PARTICIPANT_ROLE_RESPONSIBLE': 'Исполнитель',
            'PARTICIPANT_ROLE_ACCOUNTABLE': 'Ответственный',
            'PARTICIPANT_ROLE_CONSULTED': 'Консультант',
            'PARTICIPANT_ROLE_INFORMED': 'Информируемый',
            'PARTICIPANT_ROLE_UNDEFINED_IN_LIST': 'Не определенная',
            'PARTICIPANT_ROLE_DEFAULT': 'По умолчанию',

            'PARTICIPANT_ROLE_RESPONSIBLE_DESCRIPTION': 'Делает работу',
            'PARTICIPANT_ROLE_ACCOUNTABLE_DESCRIPTION': 'Принимает решения и отвечает за результат',
            'PARTICIPANT_ROLE_CONSULTED_DESCRIPTION': 'Предоставляет информацию и проверяет',
            'PARTICIPANT_ROLE_INFORMED_DESCRIPTION': 'Отслеживает прогресс',
            'PARTICIPANT_ROLE_UNDEFINED_IN_LIST_DESCRIPTION': 'Роль будет определена позже',
            'PARTICIPANT_ROLE_DEFAULT_DESCRIPTION': 'Та же, что и у родительских записей',

            'PARTICIPANT_ROLE_HINT': 'Роли позволяют отделить свою работу от работы других партнеров.',

            'PARTICIPANT_ROLE_UNDEFINED': 'Определить роль',
            'DELETE_PARTICIPANT_TITLE': 'Удалить участника',
            'DELETE_LAST_PARTICIPANT_TEXT': 'Когда вы удалите последнего участника запись будет удалена.',
            'DELETE_CURRENT_PARTICIPANT_TEXT': 'Когда вы будете удалены из участников, то можете потерять доступ к этой записи.',
            'PARTICIPANT_EMPTY': 'Вы можете пригласить своих членов семьи, друзей и коллег, чтобы работать вместе над выполнением задач и целей.',
            'PARTICIPANT_ADD': 'Добавить участников'
        });
    }]);

    thisModule.directive('pipParticipantDetails',
        function () {
            return {
                restrict: 'EA',
                scope: {
                    entity: '=pipEntity',
                    parties: '=pipParties',
                    defaultEntityOptions: '=pipDefaultEntityOptions',
                    saveEntity: '=pipSaveEntity',
                    showJoinMethod: '=pipShowJoinMethod',
                    addAcceptedParticipants: '=pipAddAcceptedParticipants',
                    showDefault: '=pipShowDefault',
                    showRole: '=pipShowRole',
                    entityType: '=pipEntityType',
                    deleteOwnerParticipantCallback: '=pipDeleteOwnerParticipant',
                    updateCallback: '=pipUpdateParticipant',
                    ngDisabled: '&',
                    transaction: '=pipParticipantDetailsTransaction'
                },
                templateUrl: 'participants/participants_details.html',
                controller: 'pipParticipantDetailsController'
            }
        }
    );

    thisModule.controller('pipParticipantDetailsController', 
        ['$scope', '$element', '$attrs', '$parse', '$state', '$rootScope', 'pipEnums', 'pipTranslate', 'pipCollections', 'pipPartyEditDialog', 'pipOptionsBigDialog', 'pipConfirmationDialog', 'AccessoryService', 'pipLifeParticipantsConfig', 'CommonCollectionService', function ($scope, $element, $attrs, $parse, $state, $rootScope, pipEnums, pipTranslate, pipCollections,
                  pipPartyEditDialog, pipOptionsBigDialog, pipConfirmationDialog, AccessoryService,
                  pipLifeParticipantsConfig, CommonCollectionService) {

            checkParameter();

            $scope.responsibilityCollection = CommonCollectionService.getResponsibilityCollections() 

            $scope.joinMethodCollection = [];
            _.each(pipEnums.JOIN_METHOD, function (value) {
                $scope.joinMethodCollection.push({
                    id: value,
                    name: value,
                    title: 'JOIN_METHOD_' + value.toUpperCase(),
                    subtitle: 'DESCRIPTION_JOIN_METHOD_' + value.toUpperCase()
                });
            });
            $scope.buttonTitle = 'APPLY';
            $scope.dialogTitle = 'PARTICIPANT_DIALOG_TITLE';
            $scope.selected = {
                currentUser: false
            };

            initData();

            var update = _.debounce(updateParticipants, 3000),
                DEFAULT_ROLE = 'default';

            $scope.DEFAULT_ROLE = DEFAULT_ROLE;
            $scope.$watch('entity', entityChanged);
            $rootScope.$on('cacheUpdated', updateConnection);

            // Add class
            $element.addClass('pip-participant-details');

            $scope.onOptionChange = onOptionChange;
            $scope.onJoinMethodChange = onJoinMethodChange;
            $scope.onPartyClick = onPartyClick;
            $scope.onParticipantEdit = onParticipantEdit;
            $scope.onRemovePending = onRemovePending;
            $scope.onRemoveAccepted = onRemoveAccepted;
            $scope.onRespondibility = onRespondibility;
            $scope.onMenuClick = onMenuClick;
            $scope.onRoleChange = onRoleChange;

            $scope.getRoleString = getRoleString;

            return;

            // ----------------------------------------------------------------------------------------

            function onRoleChange($event, party) {
                if ($scope.transaction.busy() || $scope.ngDisabled()) return;
                var collection = _.cloneDeep($scope.responsibilityCollection);

                var currentIndex = _.findIndex(collection, {name: party.role});
                if (currentIndex > -1) collection[currentIndex].active = true;
                pipOptionsBigDialog.show(
                    {
                        event: $event,
                        noActions: true,
                        noTitle: true,
                        hint: 'PARTICIPANT_ROLE_HINT',
                        options: collection
                    },
                    function(option) {
                        var optionName = option ? option.option.name : null;
                        if (optionName) onRespondibility(party, optionName, $event);
                    }
                );
            }

            function getRoleString(party) {
                if (party.default_role) {
                    return party.role ? (pipTranslate.translate('PARTICIPANT_ROLE_DEFAULT') +
                        ' (' + pipTranslate.translate('PARTICIPANT_ROLE_' + party.role.toUpperCase()) + ')') :
                        (pipTranslate.translate('PARTICIPANT_ROLE_DEFAULT') +
                        ' (' +  pipTranslate.translate('PARTICIPANT_ROLE_UNDEFINED_IN_LIST') + ')');
                }

                return party.role ? pipTranslate.translate('PARTICIPANT_ROLE_' + party.role.toUpperCase()) :
                    pipTranslate.translate('PARTICIPANT_ROLE_UNDEFINED');
            }

            function updateConnection(event, args) {
                if ($rootScope.$user.id != $rootScope.$party.id) return;
                if (args.name == 'connections' && args.result) {
                    var connections = _.filter(args.result, {accept: 'accepted'});
                    prepareParticipant(connections);
                }
            }

            function checkParameter() {
                var ERROR_TITLE = 'pipParticipantDetailsController error: ';

                AccessoryService.checkParameter($scope.entity, ERROR_TITLE, 'pip-entity');
                AccessoryService.pipValidateExist($scope.entityType, ERROR_TITLE, ' attribute pip-entity-type missed.');

                // check entity type value
                if ($scope.entityType != pipEnums.REFERENCE_TYPE.GOAL
                    && $scope.entityType != pipEnums.REFERENCE_TYPE.AREA
                    && $scope.entityType != pipEnums.REFERENCE_TYPE.VISION
                    && $scope.entityType != pipEnums.REFERENCE_TYPE.NOTE
                    && $scope.entityType != pipEnums.REFERENCE_TYPE.EVENT)
                    throw new Error(ERROR_TITLE + ' pip-entity-type can take the values:  [goal, area, vision, event, note]');

                AccessoryService.checkOneCollection($scope.parties, 'pip-parties', ERROR_TITLE);
                AccessoryService.checkParameter($scope.defaultEntityOptions, ERROR_TITLE, 'pip-default-entity-options');
                AccessoryService.checkOneFunction($scope.saveEntity, 'pip-save-entity', ERROR_TITLE);
            }

            function entityChanged(newValue) {
                AccessoryService.checkParameter($scope.entity, 'pip-entity');
                $scope.entity = newValue;
                initData();
            }

            function onOptionChange() {
                $scope.entity.contrib_def = $scope.selected.contrib_def;
                $scope.isUpdate = true;
                updateParticipants();
            }

            function onJoinMethodChange(event) {
                _.each($scope.joinMethodCollection, function(item) {
                    item.active = $scope.selected.joinMethod == item.id;
                });
                pipOptionsBigDialog.show(
                    {
                        event: event,
                        title: 'CHOOSE_JOIN_METHOD',
                        options: $scope.joinMethodCollection,
                        appleButtonTitle: 'SHARING_CHANGE'
                    },
                    function (result) {
                        $scope.selected.joinMethod = result.option.id;
                        $scope.entity.join = result.option.id;
                        setJoinMethodDescription();
                        $scope.isUpdate = true;
                        update();
                    }
                );
            }

            function onPartyClick(event, party) {
                if (pipLifeParticipantsConfig.isOpenAbout())
                    $state.go('about_party', {party_id: party.party_id});
            }

            function onParticipantEdit(event) {
                var contrib_def = $scope.selected.contrib_def;
                
                // save contrib_def value
                $scope.selected.contrib_def = false;
                $scope.entity.contrib_def = false;

                pipPartyEditDialog.show(
                    {
                        title: $scope.dialogTitle,
                        parties: $scope.selected.contribs,
                        partyOptions: _.uniq(_.union($scope.selected.parties, [
                            {party_id: $scope.selected.party.id, party_name: $scope.selected.party.name}
                        ]), 'party_id'),
                        optionTitle: null,
                        option: null,
                        event: event
                    },
                    function (result) {
                        if (result.parties.length == 0) {
                            pipConfirmationDialog.show(
                                {
                                    event: event,
                                    title: 'DELETE_PARTICIPANT_TITLE',
                                    message: 'DELETE_LAST_PARTICIPANT_TEXT',
                                    item: '',
                                    ok: 'DELETE',
                                    cancel: 'CANCEL'
                                },
                                function () {
                                    $scope.selected.contribs = [];
                                    $scope.selected.accepted = [];
                                    $scope.selected.pending = [];
                                    $scope.entity.contribs = [];
                                    if ($scope.saveEntity) $scope.saveEntity();
                                },
                                function () {
                                    return ;
                                }
                            );
                        } else {
                            var currentParty = _.findIndex(result.parties, {party_id: $rootScope.$party.id});

                            if (currentParty < 0) {
                                pipConfirmationDialog.show(
                                    {
                                        event: event,
                                        title: 'DELETE_PARTICIPANT_TITLE',
                                        message: 'DELETE_CURRENT_PARTICIPANT_TEXT',
                                        item: '',
                                        ok: 'DELETE',
                                        cancel: 'CANCEL'
                                    },
                                    function () {
                                        saveParticipants(result.parties)
                                    },
                                    function () {
                                        return false;
                                    }
                                );

                            } else {
                                saveParticipants(result.parties)
                            }
                        }
                    },
                    function () {
                        $scope.entity.contrib_def = contrib_def;
                        $scope.selected.contrib_def = contrib_def;
                    }
                );
            }

            function saveParticipants(participants, withoutCurrent) {
                $scope.selected.contribs = replaceParticipants(participants);
                fillParticipantCollections($scope.selected.contribs);
                $scope.entity.contribs = $scope.selected.contribs;
                if ($scope.saveEntity) $scope.saveEntity();
                if (withoutCurrent && $scope.deleteOwnerParticipantCallback)
                    $scope.deleteOwnerParticipantCallback($scope.entity.id);
            }

            function onRemovePending(event, party) {
                if (party.party_id != $rootScope.$party.id) removeParticipants($scope.selected.pending, party);
                else removeCurrentParty($scope.selected.pending, party, removeParticipants);
            }

            function onRemoveAccepted(event, party) {
                if (party.party_id != $rootScope.$party.id) removeParticipants($scope.selected.accepted, party);
                else removeCurrentParty($scope.selected.accepted, party, removeParticipants);
            }

            function onRespondibility(item, responsible, $event) {
                if ($event) $event.stopPropagation();

                if (item.role == responsible && item.default_role != true) return;

                item.role = responsible;
                var contribIndex = _.findIndex($scope.selected.contribs, {party_id: item.party_id});

                if (contribIndex != null) {
                    if (item.role === String(pipEnums.CONSTRIBUTOR_ROLES.UNDEFINED)) {
                        delete $scope.selected.contribs[contribIndex].role;
                        delete $scope.selected.contribs[contribIndex].role_from_id;
                        $scope.selected.contribs[contribIndex].default_role = false;
                    } else {
                        $scope.selected.contribs[contribIndex].role = item.role;
                    }

                    if (item.role == DEFAULT_ROLE) {
                        delete $scope.selected.contribs[contribIndex].role;
                        $scope.selected.contribs[contribIndex].default_role = true;
                    } else {
                        $scope.selected.contribs[contribIndex].default_role = false;
                        delete $scope.selected.contribs[contribIndex].role_from_id;
                        item.default_role = false;
                    }
                }
                pipCollections.replaceBy($scope.selected.pending, 'party_id', item);
                pipCollections.replaceBy($scope.selected.accepted, 'party_id', item);

                $scope.entity.contribs = $scope.selected.contribs;
                $scope.isUpdate = true;

                update();
            }

            function onMenuClick($mdOpenMenu) {
                $mdOpenMenu();
            }

            function initData() {
                if (!$scope.entity) return;
                $scope.selected.party = $rootScope.$party;
                $scope.selected.settings = $scope.defaultEntityOptions;
                $scope.selected.showJoinMethod = $scope.showJoinMethod !== false;
                $scope.selected.showDefault = $scope.showDefault !== false;
                $scope.selected.showRole = $scope.showRole !== false;
                $scope.selected.joinMethod = ($scope.entity.join === undefined) ?  $scope.selected.settings.joinMethod : $scope.entity.join;
                setJoinMethodDescription();

                $scope.selected.parent = $scope.entity.parent_source;
                $scope.selected.contrib_def = $scope.entity.contrib_def;
                $scope.selected.contribs =  _.uniqBy($scope.entity.contribs, 'party_id');

                fillParticipantCollections($scope.selected.contribs);
                prepareParticipant($scope.parties);

            }

            function setJoinMethodDescription() {
                var item = _.find($scope.joinMethodCollection, {id: $scope.selected.joinMethod});
                item.subtitle = item.subtitle ? item.subtitle : '';
                $scope.selected.joinMethodDiscription = pipTranslate.translate(item.subtitle);
            }

            function prepareParticipant(parties) {
                $scope.selected.parties = _.map(
                    _.uniq(
                        _.filter(
                            parties,
                            function(item){
                                return (item.type != 'follow' && item.accept == 'accepted')
                            }),
                        'to_party_id'),
                    function (item) {
                        return {
                            party_id: item.to_party_id,
                            party_name: item.to_party_name
                        }
                    }
                );
            }

            function fillParticipantCollections(collection) {
                $scope.selected.pending = [];
                $scope.selected.accepted = [];

                collection = _.sortBy(collection, function (item) {
                    return item.party_name;
                });

                _.each(collection, function(party){
                    if (party.accept == 'accepted') {
                        $scope.selected.accepted.push(_.cloneDeep(party));
                    } else {
                        $scope.selected.pending.push(_.cloneDeep(party));
                    }
                });

                // show empty state if only party present in contribs
                if (collection.length == 1 && collection[0].party_id == $rootScope.$party.id) {
                    $scope.selected.onlyOwner = true;
                } else {
                    $scope.selected.onlyOwner = false;
                }
                
            }

            function replaceParticipants(newCollection) {
                var result = [];

                _.each(newCollection, function (item){
                    var party = _.find($scope.selected.contribs, {party_id: item.party_id});
                    if (!party || !party.party_id) {
                        result.push({
                            party_id: item.party_id,
                            party_name: item.party_name,
                            accept: $scope.addAcceptedParticipants || item.party_id == $rootScope.$user.id ? 'accepted' : ''
                        });
                    } else {
                        result.push(party);
                    }
                });

                return result;
            }

            function updateParticipants() {
                if (!$scope.isUpdate) {
                    $scope.isUpdate = true;
                    return;
                }
                if ($scope.saveEntity) $scope.saveEntity();
            }

            function removeCurrentParty(collection, party, successCallback) {
                pipConfirmationDialog.show(
                    {
                        event: event,
                        title: 'DELETE_PARTICIPANT_TITLE',
                        message: 'DELETE_CURRENT_PARTICIPANT_TEXT',
                        item: '',
                        ok: 'DELETE',
                        cancel: 'CANCEL'
                    },
                    function () {
                        if (successCallback) successCallback(collection, party, true);
                    },
                    function () {
                        return false;
                    }
                );
            }

            function removeParticipants(collection, party, current) {
                if ($scope.transaction.busy() || $scope.deleteParticipants) return;
                current = current || false;
                $scope.isUpdate = true;

                _.remove($scope.entity.contribs, {party_id: party.party_id});
                _.remove($scope.selected.contribs, {party_id: party.party_id});
                _.remove(collection, {party_id: party.party_id});
                if (!current) update();
                else {
                    updateParticipants();
                    if ($scope.deleteOwnerParticipantCallback)
                        $scope.deleteOwnerParticipantCallback($scope.entity.id);
                }
                /*pipPartiesData.manageParticipants(
                    {
                        transaction: $scope.transaction,
                        resource: resource,
                        item: {
                            id: $scope.entity.id,
                            party_id: $rootScope.$party.id,
                            action: 'reject',
                            contrib_id: party.party_id
                        }
                    },
                    function (result) {
                        _.remove($scope.selected.contribs, 'party_id', party.party_id);
                        _.remove(collection, 'party_id', party.party_id);
                        $scope.entity.updated = result.updated;
                        if (party.party_id == $rootScope.$party.id) {
                            if ($scope.deleteOwnerParticipantCallback)
                                $scope.deleteOwnerParticipantCallback($scope.entity.id);
                        } else {
                            if ($scope.updateCallback) $scope.updateCallback($scope.entity);
                        }
                        $scope.deleteParticipants = false;
                    },
                    function (error) {
                        $scope.deleteParticipants = false;
                        // todo show error
                    }
                );*/
            }

        }]
    );

})();

