/**
 * @file Error handle service
 * @copyright Digital Living Software Corp. 2015-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipErrorHandleService', []);

    thisModule.factory('pipErrorHandle',
        ['pipTranslate', 'pipToasts', function (pipTranslate, pipToasts) {

            // возможно три поведения, показать сообщение или показать страницу no_conection или панель. вторые два можно реализовать через callback
            function errorNoConnection(error, message, successCallback) {
                if (error && error.status == -1) {
                    if (message)  pipToasts.showError(pipTranslate.translate(message), null, null, null, error); 
                    if (successCallback && angular.isFunction(successCallback)) successCallback(error);

                    return true;
                }

                return false;
            }

            // entity not found, error code 1504
            function errorObjectNotFound(error, message, successCallback) {
                if (error && error.status == 400) { //error.data && error.data.code == 1500
                    if (message)  pipToasts.showError(pipTranslate.translate(message), null, null, null, error); 
                    if (successCallback && angular.isFunction(successCallback)) successCallback(error);

                    return true;
                }

                return false;                
            }

            // entity 
            function errorUnknown(error, message, successCallback) {
                if (error && error.data && error.data.code == 1000) { 
                    if (message)  pipToasts.showError(pipTranslate.translate(message), null, null, null, error); 
                    if (successCallback && angular.isFunction(successCallback)) successCallback(error);

                    return true;
                }

                return false;                
            } 
// errorOldEntity
            // entity old error
            // reReadParams - object {item, transaction, force, successCallback, errorCallback } conflict!!!
            function errorConflictObject(error, message, reReadFunction, reReadParams, successCallback) {
                if (error && error.status == 409)  { //(error.data && error.data.code == 1504) ||
                    if (reReadFunction && angular.isFunction(reReadFunction)) reReadFunction(reReadParams);
                    else {
                        if (message) pipToasts.showError(pipTranslate.translate(message), null, null, null, error); 
                        if (successCallback && angular.isFunction(successCallback)) successCallback(error);
                    }    

                    return true;
                }

                return false;                
            }

            // если другая ошибка
            function processError(error, message, messageFull, successCallback) {
                var errString
                if (error && error.data && error.data.code) errString = message + error.data.code;
                else if (error && error.status) errString = message + error.status;
                     else errString = messageFull;
                
                if (errString) pipToasts.showError(pipTranslate.translate(errString), null, null, null, error);
                if (successCallback && angular.isFunction(successCallback)) successCallback(error);
            }


            return {

                errorNoConnection: errorNoConnection,
                errorObjectNotFound: errorObjectNotFound,
                errorUnknown: errorUnknown,
                errorConflictObject: errorConflictObject,
                processError: processError

            }
        }]
    );

})();