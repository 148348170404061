/**
 * @file Messages data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipMessagesData', ['pipRest', 'pipDataModel', 'pipCacheTag', 'pipMessagesCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var messagesApi = {
                messages: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/messages/:id');
                },

                receivedMessages: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/messages/received',
                        {
                            party_id: '@party_id'
                        }
                    );
                },

                receivedManagedMessages: function (url) {
                    return this.createCollection(url, '/api/parties/:party_id/messages/received/managed',
                        {
                            party_id: '@party_id'
                        }
                    );
                },

                sentMessages: function (url) {
                    return this.createCollection(url, '/api/parties/:party_id/messages/sent',
                        {
                            party_id: '@party_id'
                        }
                    );
                },

                viewMessage: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/messages/:id/view');
                },

                sentManagedMessages: function (url) {
                    return this.createCollection(url, '/api/parties/:party_id/messages/sent/managed',
                        {
                            party_id: '@party_id'
                        }
                    );
                }
         };
        
         pipRestProvider.addApi(messagesApi);
    }]);
    
    thisModule.provider('pipMessagesData', function() {

        // this.readManagedPartiesResolver = function() {
        //     return /* @ngInject */ function ($stateParams, pipRest, $rootScope) {
        //         return pipRest.managedConnections().query({
        //             party_id: pipDataSession.userId()
        //         }).$promise.then(null,
        //             function(reason) {
        //                 if (reason && reason.code) {
        //                     if (reason.code == -1) $rootScope.$emit('pipRespondingError', {
        //                         error: rejection
        //                     });
        //                     else  $rootScope.$emit('pipUnhandledInternalError', {
        //                         error: rejection
        //                     });
        //                 }
        //             });
        //     };
        // };

        // // Read one message resolvers
        // this.readMessageResolver = function() {
        //     return /* @ngInject */ function ($stateParams, pipRest, $rootScope) {
        //         var id;
        //         if ($stateParams.ref_id && $stateParams.ref_type.toLowerCase() == enums.REFERENCE_TYPE.MESSAGE) {
        //             id = $stateParams.ref_id
        //         } else {
        //             if ($stateParams.message_id) {
        //                 id = $stateParams.message_id;
        //             } else {
        //                 return null
        //             }
        //         }
        //         return pipRest.messages().get({
        //             party_id: $stateParams.managed_party_id || pipDataSession.userId(),
        //             id: id
        //         }).$promise.then(null,
        //             function(reason) {
        //                 if (reason && reason.code) {
        //                     if (reason.code == -1) $rootScope.$emit('pipRespondingError', {
        //                         error: rejection
        //                     });
        //                     else  $rootScope.$emit('pipUnhandledInternalError', {
        //                         error: rejection
        //                     });
        //                 }
        //             });;
        //     };
        // };

        // // Read data resolvers
        // this.replayMessageResolver = function() {
        //     return /* @ngInject */ function ($stateParams, pipRest, $rootScope) {
        //         if (!$stateParams.replay_id)
        //             return null;
        //         else {
        //             return pipRest.messages().get({
        //                 party_id: $stateParams.managed_party_id || pipDataSession.userId(),
        //                 id: $stateParams.replay_id
        //             }).$promise.then(null,
        //                 function(reason) {
        //                     if (reason && reason.code) {
        //                         if (reason.code == -1) $rootScope.$emit('pipRespondingError', {
        //                             error: rejection
        //                         });
        //                         else  $rootScope.$emit('pipUnhandledInternalError', {
        //                             error: rejection
        //                         });
        //                     }
        //                 });;
        //         }
        //     };
        // };

        // // Read data resolvers
        // this.forwardMessageResolver = function() {
        //     return /* @ngInject */ function ($stateParams, pipRest, $rootScope) {
        //         if (!$stateParams.forward_id)
        //             return null;
        //         else {
        //             return pipRest.messages().get({
        //                 party_id: $stateParams.managed_party_id || pipDataSession.userId(),
        //                 id: $stateParams.forward_id
        //             }).$promise.then(null,
        //                 function(reason) {
        //                     if (reason && reason.code) {
        //                         if (reason.code == -1) $rootScope.$emit('pipRespondingError', {
        //                             error: rejection
        //                         });
        //                         else  $rootScope.$emit('pipUnhandledInternalError', {
        //                             error: rejection
        //                         });
        //                     }
        //                 });;
        //         }
        //     };
        // };

        // this.readReceivedMessagesResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, $rootScope) {
        //         return pipRest.receivedMessages().query({
        //             party_id: $stateParams.managed_party_id || pipDataSession.userId()
        //         }).$promise.then(null,
        //             function(reason) {
        //                 if (reason && reason.code) {
        //                     if (reason.code == -1) $rootScope.$emit('pipRespondingError', {
        //                         error: rejection
        //                     });
        //                     else  $rootScope.$emit('pipUnhandledInternalError', {
        //                         error: rejection
        //                     });
        //                 }
        //             });;
        //     };
        // };

        // this.readSentMessagesResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, $rootScope) {
        //         return pipRest.sentMessages().query({
        //             party_id: $stateParams.managed_party_id || pipDataSession.userId()
        //         }).$promise.then(null,
        //             function(reason) {
        //                 if (reason && reason.code) {
        //                     if (reason.code == -1) $rootScope.$emit('pipRespondingError', {
        //                         error: rejection
        //                     });
        //                     else  $rootScope.$emit('pipUnhandledInternalError', {
        //                         error: rejection
        //                     });
        //                 }
        //             });;
        //     };
        // };

        // this.readReceivedManagedMessagesResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, $rootScope) {
        //         return pipRest.receivedManagedMessages().query({
        //             party_id: pipDataSession.userId()
        //         }).$promise.then(null,
        //             function(reason) {
        //                 if (reason && reason.code) {
        //                     if (reason.code == -1) $rootScope.$emit('pipRespondingError', {
        //                         error: rejection
        //                     });
        //                     else  $rootScope.$emit('pipUnhandledInternalError', {
        //                         error: rejection
        //                     });
        //                 }
        //             });;
        //     };
        // };

        // this.readSentManagedMessagesResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, $rootScope) {
        //         return pipRest.sentManagedMessages().query({
        //             party_id: pipDataSession.userId()
        //         }).$promise.then(null,
        //             function(reason) {
        //                 if (reason && reason.code) {
        //                     if (reason.code == -1) $rootScope.$emit('pipRespondingError', {
        //                         error: rejection
        //                     });
        //                     else  $rootScope.$emit('pipUnhandledInternalError', {
        //                         error: rejection
        //                     });
        //                 }
        //             });;
        //     };
        // };

        // CRUD operations and other business methods
        this.$get = ['$stateParams', 'pipDataModel', 'pipCacheTag', 'pipMessagesCache', 'pipDataSession', function($stateParams, pipDataModel, pipCacheTag, pipMessagesCache, pipDataSession) {
            return {

                createMessage: function(params, successCallback, errorCallback) {
                    params.resource = 'messages';
                    params.item = params.item || {};
                    params.item.party_id = params.item.party_id ? params.item.party_id : $stateParams.managed_party_id || pipDataSession.userId();   

                    pipDataModel.create(
                        params,
                        pipMessagesCache.onMessageCreate(params, successCallback),
                        errorCallback
                    );
                },

                createMessageWithFiles: function(params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(params, function() {
                        params.resource = 'messages';
                        params.item = params.item || {};
                        params.item.party_id = params.item.party_id ? params.item.party_id : $stateParams.managed_party_id || pipDataSession.userId();                       
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;

                        pipDataModel.create(
                            params,
                            pipMessagesCache.onMessageCreate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                // view message updated into cache for recived  messages 
                viewMessage: function(params, successCallback, errorCallback) {
                    params.resource = 'viewMessage';
                    params.item = params.item || {};
                    params.item.party_id = params.item.party_id ? params.item.party_id : $stateParams.managed_party_id || pipDataSession.userId();   

                    pipDataModel.update(
                         params,
                         pipMessagesCache.onMessageUpdate(params, 'receivedManagedMessages', successCallback),
                         errorCallback
                    );
                },

                deleteMessage: function(params, successCallback, errorCallback) {
                    params.resource = 'messages';
                    params.category = params.category ? params.category : $stateParams.category || 'received';
                    params.item = params.item || {};                    
                    params.item.party_id = params.item.party_id ? params.item.party_id : $stateParams.managed_party_id || pipDataSession.userId();   
                    
                    // realize two function for delete recived and send messages, becouse now cache not updated
                    if (params.category == 'received') 
                    	pipDataModel.remove(params, pipMessagesCache.onMessageDelete(params, 'receivedManagedMessages', successCallback), errorCallback);
                    else     
                        pipDataModel.remove(params, pipMessagesCache.onMessageDelete(params, 'sentManagedMessages', successCallback), errorCallback);
                },

                readReceivedManagedMessages: function(params, successCallback, errorCallback) {
                    params.resource = 'receivedManagedMessages';
                    params.item = params.item || {};                    
                    params.item.party_id = params.item.party_id ? params.item.party_id : $stateParams.managed_party_id || pipDataSession.userId();   
  
                    return pipMessagesCache.readManagedMessages(params, successCallback, errorCallback);
                },

                readSentManagedMessages: function(params, successCallback, errorCallback) {
                    params.resource = 'sentManagedMessages';
                    params.item = params.item || {};                    
                    params.item.party_id = params.item.party_id ? params.item.party_id : $stateParams.managed_party_id || pipDataSession.userId();      
                    
                    return pipMessagesCache.readManagedMessages(params, successCallback, errorCallback);
                },

                readMessage: function(params, successCallback, errorCallback) {
                    params.resource = 'messages';
                    params.category = params.category ? params.category : $stateParams.category || null;                    
                    params.item = params.item || {};                    
                    params.item.party_id = params.item.party_id ? params.item.party_id : $stateParams.managed_party_id || pipDataSession.userId();      

                    if (!params.item.id) {
                        // todo: exception error
                    }

                    if (params.category == 'received') 
                        return pipDataModel.readOne(params, pipMessagesCache.onMessageUpdate(params, 'receivedManagedMessages', successCallback), errorCallback);
                    else  if (params.category == 'sent')   
                        return pipDataModel.readOne(params, pipMessagesCache.onMessageUpdate(params, 'sentManagedMessages', successCallback), errorCallback);
                        else pipDataModel.readOne(params, pipMessagesCache.onMessageUpdate(params, 'messages', successCallback), errorCallback);
                },

                // todo rename into search
                getMessagesPage: function(params, successCallback, errorCallback) {
                    params.resource = 'receivedMessages';
                    params.item = params.item || {};
                    params.item.party_id = params.item.party_id ? params.item.party_id : $stateParams.managed_party_id || pipDataSession.userId();   

                    pipDataModel.page(params, successCallback, errorCallback);
                }

                // todo: not using
                // getMessages: function(params, successCallback, errorCallback) {
                //     params.resource = 'receivedMessages';
                //     params.item = params.item || {};
                //     params.item.party_id = $stateParams.managed_party_id || params.maneged_party_id  || pipDataSession.userId();
                //     pipDataModel.query(params, successCallback, errorCallback);
                // },

                // todo: not using
                // getSentMessages: function(params, successCallback, errorCallback) {
                //     params.resource = 'sentMessages';
                //     params.item = params.item || {};
                //     params.item.party_id = $stateParams.managed_party_id  || params.maneged_party_id  || pipDataSession.userId();
                //     pipDataModel.query(params, successCallback, errorCallback);
                // },


                // todo: not using
                // readReceivedMessages: function(params, successCallback, errorCallback) {
                //     params.resource = 'receivedMessages';
                //     params.item = params.item || {};
                //     params.item.party_id = $stateParams.managed_party_id || params.maneged_party_id  || pipDataSession.userId();

                //     pipDataModel.read(params, successCallback, errorCallback);
                // },

            }
        }];
        this.$get.$inject = ['$stateParams', 'pipDataModel', 'pipCacheTag', 'pipMessagesCache', 'pipDataSession'];
    });

})();
