/**
 * @file New Record Wizard Header panel
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global _, angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipRecordWizardHeaderPanel',
        []);

    thisModule.config(['pipTranslateProvider', function (pipTranslateProvider) {

        pipTranslateProvider.translations('en', {
            'HEADER_STEP': 'Step %d of %d',
            'STEP_FIRST': 'Fill record details',
            'STEP_PARTICIPANTS': 'Set participants and roles',
            'STEP_VISIBILITY': 'Set visability  options',
            'WIZARD_SKIP_AND_SAVE': 'Skip and save',
            'WIZARD_ADD_PARTICIPANTS': 'Add participants',
            'WIZARD_NEXT': 'Next',
            'WIZARD_SKIP_AND_CREATE': 'Skip and create',
            'WIZARD_BACK': 'Back',
            'WIZARD_SKIP_AND_CREATE_ANOTHER': 'Add another' // Skip, save and add another
        });

        pipTranslateProvider.translations('ru', {
            'HEADER_STEP': 'Шаг %d из %d',
            'STEP_FIRST': 'Заполните запись',
            'STEP_PARTICIPANTS': 'Задайте участников и роли',
            'STEP_VISIBILITY': 'Задайте видимость',
            'WIZARD_SKIP_AND_SAVE': 'Пропустить и сохранить',
            'WIZARD_ADD_PARTICIPANTS': 'Добавить участников',
            'WIZARD_NEXT': 'Дальше',
            'WIZARD_SKIP_AND_CREATE': 'Пропустить и создать',
            'WIZARD_BACK': 'Назад',
            'WIZARD_SKIP_AND_CREATE_ANOTHER': 'Добавить еще'
        });

    }]);

    thisModule.directive('pipRecordWizardHeaderPanel',
        function () {
            return {
                restrict: 'E',
                scope: {
                    entity: '=pipEntity',
                    parents: '=pipParents',
                    headerState: '=?pipHeaderState',
                    showVisability: '=?pipShowVisability',
                    showParticipants: '=?pipShowParticipants'
                },
                templateUrl: 'new_record_wizard/record_wizard_header.html',
                controller: 'pipRecordWizardHeaderPanelController'
            };
        }
    );

    thisModule.controller('pipRecordWizardHeaderPanelController',
        ['$scope', '$element', 'pipEnums', '$rootScope', '$mdMedia', 'pipTranslate', 'pipParticipantsFormat', 'pipVisibilityFormat', 'pipUtils', 'pipCollections', function ($scope, $element, pipEnums, $rootScope, $mdMedia, pipTranslate, pipParticipantsFormat,
                  pipVisibilityFormat, pipUtils, pipCollections) {

            $scope.$mdMedia = $mdMedia;
            $scope.headerCollection = {
                'first': {
                    title: 'STEP_FIRST',
                    subTitle: '',
                    visible: true
                },
                'participants': {
                    title: 'STEP_PARTICIPANTS',
                    subTitle: '',
                    visible: true
                },
                'visability': {
                    title: 'STEP_VISIBILITY',
                    subTitle: '',
                    visible: true
                }
            };

            $scope.onStateClick = onStateClick;

            $scope.state = $scope.headerState ? $scope.headerState : 1;
            prepareData();

            $scope.$watch('headerState', function (newValue) {
                if ($scope.state !== newValue) {
                    $scope.state = $scope.headerState ? $scope.headerState : 1;
                    prepareData();
                }
            });

            $scope.$watchCollection('parents', function () {
                prepareData();
            });

            return;

            function onStateClick(index) {
                if (index >= $scope.state) { return; }
                $scope.headerState = index + 1;
            }

            function getRole(party1, party2) {
                if (party1.role === pipEnums.CONTRIBUTOR_ROLE.ACCOUNTABLE ||
                    party2.role === pipEnums.CONTRIBUTOR_ROLE.ACCOUNTABLE) {
                    return pipEnums.CONTRIBUTOR_ROLE.ACCOUNTABLE;
                }
                if (party1.role === pipEnums.CONTRIBUTOR_ROLE.RESPONSIBLE ||
                    party2.role === pipEnums.CONTRIBUTOR_ROLE.RESPONSIBLE) {
                    return pipEnums.CONTRIBUTOR_ROLE.RESPONSIBLE;
                }
                if (party1.role === pipEnums.CONTRIBUTOR_ROLE.CONSULTED ||
                    party2.role === pipEnums.CONTRIBUTOR_ROLE.CONSULTED) {
                    return pipEnums.CONTRIBUTOR_ROLE.CONSULTED;
                }
                if (party1.role === pipEnums.CONTRIBUTOR_ROLE.INFORMED ||
                    party2.role === pipEnums.CONTRIBUTOR_ROLE.INFORMED) {
                    return pipEnums.CONTRIBUTOR_ROLE.INFORMED;
                }

                return pipEnums.CONTRIBUTOR_ROLE.UNDEFINED;
            }

            function fillContribs(parents) {
                var contribs = [], role, newParty, party, i, j, parent;
   
                for (i = 0; i < parents.length; i++) {
                    parent = parents[i];

                    if (contribs.length === 0) {
                        contribs = !!parent.contribs ? _.cloneDeep(parent.contribs) : [];
                    } else {
                        for (j = 0; j < parent.contribs; j++) {
                            newParty = parent.contribs[i];
                            party = _.find(contribs, { party_id: newParty.party_id });
                            if (party && party.party_id) {
                                if (party.accept === pipEnums.ACCEPTANCE.ACCEPTED ||
                                    newParty.accept === pipEnums.ACCEPTANCE.ACCEPTED) {
                                    party.inviter_id = party.accept === pipEnums.ACCEPTANCE.ACCEPTED
                                        ? party.inviter_id : newParty.inviter_id;
                                    party.accept = pipEnums.ACCEPTANCE.ACCEPTED;
                                }
                                if (party.role && newParty.role) {
                                    role = getRole(party, newParty);
                                    party.role_from_id = party.role === role
                                        ? party.role_from_id : newParty.role_from_id;
                                    party.role = role;
                                }
                                party.default_role = party.default_role && newParty.default_role;

                                pipCollections.replaceBy(contribs, 'party_id', party.party_id, party);
                            } else {
                                contribs.push(party);
                            }
                        }
                    }
                }

                return contribs;
            }

            function getConfidMin(parents) {
                var i, confidMin;

                if (!parents || parents.length === 0) {
                    return 0;
                }

                confidMin = parents[0].confid_min;

                for (i = 1; i < parents.length; i++) {
                    if (confidMin < parents[i].confid_min) {
                        confidMin = parents[i].confid_min;
                    }
                }

                return confidMin;
            }

            function getConfid(parents, result) {
                var i, confid;

                if (!parents || parents.length === 0) {
                    return 0;
                }

                confid = parents[0].confid;

                for (i = 1; i < parents.length; i++) {
                    if (confid < parents[i].confid) {
                        confid = parents[i].confid;
                    }
                }

                if (result && result.confidMin > confid) {
                    confid = result.confidMin;
                }

                return confid;
            }

            function getJoinMethod(parents, result) {
                var i, joinMethod, joinMethod1;

                if (!parents || parents.length === 0) {
                    return pipEnums.JOIN_METHOD.INVITE;
                }
                if (parents.length === 1) {
                    return parents[0].join;
                }

                joinMethod = parents[0].join;

                for (i = 1; i < parents.length; i++) {
                    joinMethod1 = parents[i].join;

                    if (joinMethod === pipEnums.JOIN_METHOD.INVITE || joinMethod1 === pipEnums.JOIN_METHOD.INVITE) {
                        joinMethod === pipEnums.JOIN_METHOD.INVITE;
                    } else if (joinMethod === pipEnums.JOIN_METHOD.APPROVE ||
                        joinMethod1 === pipEnums.JOIN_METHOD.APPROVE) {
                        joinMethod === pipEnums.JOIN_METHOD.APPROVE;
                    } else if (joinMethod === pipEnums.JOIN_METHOD.OPEN || joinMethod1 === pipEnums.JOIN_METHOD.OPEN) {
                        joinMethod === pipEnums.JOIN_METHOD.OPEN;
                    }
                }

                return joinMethod;
            }

            function getShareLevel(parents, result) {
                var i, shareLevel;

                if (!parents || parents.length === 0) {
                    return 3;
                }

                shareLevel = parents[0].share_level;

                for (i = 1; i < parents.length; i++) {
                    if (shareLevel < parents[i].share_level) {
                        shareLevel = parents[i].share_level;
                    }
                }

                if (result.confid === pipEnums.CONFIDENTIALITY.SENSITIVE) {
                    shareLevel = shareLevel < pipEnums.SHARE_LEVEL.OUTER ? pipEnums.SHARE_LEVEL.OUTER : shareLevel;
                }
                if (result.confid === pipEnums.CONFIDENTIALITY.CLASSIFIED) {
                    shareLevel = pipEnums.SHARE_LEVEL.PRIVATE;
                }
                if (result.confid === pipEnums.CONFIDENTIALITY.SECRET) {
                    shareLevel = pipEnums.SHARE_LEVEL.PRIVATE;
                }

                return shareLevel;
            }

            function getShareParties(parents, result) {
                var i, parties = [], newParties;

                if (!parents || parents.length === 0) {
                    return parties;
                }
                if (parents.length === 1) {
                    parties = _.cloneDeep(parents[0].share_parties);

                    return parties;
                } else {
                    for (i = 0; i < parents.length; i++) {
                        newParties = _.cloneDeep(parents[i].share_parties);
                        parties = _.union(parties, newParties);
                    }

                    parties = _.uniqBy(parties, 'party_id');
                }

                return parties;
            }

            function getShareGroups(parents, result) {
                var i, groups = [], newGroups;

                if (!parents || parents.length === 0) {
                    return groups;
                }
                if (parents.length === 1) {
                    groups = _.cloneDeep(parents[0].share_groups);

                    return groups;
                } else {
                    for (i = 0; i < parents.length; i++) {
                        newGroups = _.cloneDeep(parents[i].share_groups);
                        groups = _.union(groups, newGroups);
                    }

                    groups = _.uniqBy(groups, 'group_id');
                }

                return groups;
            }

            function getDefault() {
                var result = {
                    contribs: [
                        {
                            party_name: $rootScope.$party.name,
                            party_id: $rootScope.$party.id,
                            role: 'accountable'
                        }
                    ],
                    share_parties: [],
                    share_groups: [],
                    contrib_def: true,
                    share_def: true,
                    confid: null,
                    confid_min: null,
                    join: null,
                    share_level: null
                };

                return result;
            }

            function fillParent(parents) {
                var result = getDefault();

                if (!parents) {
                    return result;
                }

                if (parents && !angular.isArray(parents) && angular.isObject(parents) && parents.id) {
                    return _.cloneDeep(parents);
                }

                if (parents && angular.isArray(parents)) {
                    if (parents.length === 0) {
                        return result;
                    } else if (parents.length === 1) {
                        result = _.cloneDeep(parents[0]);

                        return result;
                    } else {
                        result = {};
                        result.contribs = fillContribs(parents);
                        result.confid_min = getConfidMin(parents);
                        result.confid = getConfid(parents, result);
                        result.contrib_def = result.contrib_def === undefined ? true : result.contrib_def;
                        result.share_def = result.share_def === undefined ? true : result.share_def;
                        result.join = getJoinMethod(parents, result);
                        result.share_level = getShareLevel(parents, result);
                        result.share_parties = getShareParties(parents, result);
                        result.share_groups = getShareGroups(parents, result);
                    }
                }

                return result;
            }

            function setShared(acceptor, donor) {
                if (!acceptor) { return; }

                acceptor.confid_min = donor && donor.confid_min !== undefined ? donor.confid_min : null;
                acceptor.confid = donor && donor.confid !== undefined ? donor.confid : null;
                acceptor.share_def = acceptor.share_def === undefined ? true : acceptor.share_def;
                acceptor.share_level = donor && donor.share_level !== undefined ? donor.share_level : null;
                acceptor.share_parties = donor && donor.share_parties ? _.cloneDeep(donor.share_parties) : acceptor.share_parties || [];
                acceptor.share_groups = donor && donor.share_groups ? _.cloneDeep(donor.share_groups) : acceptor.share_groups || [];
            }

            function setContribs(acceptor, donor) {
                if (!acceptor) { return; }

                acceptor.contribs = donor && donor.contribs ? _.cloneDeep(donor.contribs) : acceptor.contribs || [];
                acceptor.contrib_def = acceptor.contrib_def === undefined ? true : acceptor.contrib_def;
                acceptor.join = donor && donor.join !== undefined ? donor.join : null;
            }

            function setEntity(acceptor, donor) {
                if (!acceptor) { return; }

                if (acceptor.contrib_def) {
                    setContribs(acceptor, donor);

                }
                if (acceptor.share_def) {
                    setShared(acceptor, donor);
                }
            }

            function prepareData() {
                var key, item;

                $scope.parent = fillParent($scope.parents);

                setEntity($scope.entity, $scope.parent);

                $scope.entity.combinedParent = $scope.parent || {};
                $scope.headerCollection['participants'].subTitle =
                    pipParticipantsFormat.formatParticipants($scope.entity);
                $scope.headerCollection['participants'].visible =
                    $scope.showParticipants === false ? $scope.showParticipants : true;
                $scope.headerCollection['visability'].subTitle =
                    pipVisibilityFormat.formatDetailVisibility($scope.entity);
                $scope.headerCollection['visability'].visible =
                    $scope.showVisability === false ? $scope.showVisability : true;

                $scope.titleCollection = [];
                for (key in $scope.headerCollection) {
                    item = $scope.headerCollection[key];
                    if (item.visible) {
                        $scope.titleCollection.push(item);
                    }
                }

                $scope.headerStep = pipUtils.sprintf(
                    pipTranslate.translate('HEADER_STEP'), $scope.state, $scope.titleCollection.length);
            }

        }]
    );

})();
