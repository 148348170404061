/**
 * @file Notifications data model
 * @copyright Digital Living Software Corp. 2014-2016
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipNotificationsData', ['pipRest', 'pipDataModel']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var notificationsApi = {
               notifications: function (url) {
                    return this.createCollection(url, '/api/parties/:party_id/notifications',
                        {
                            party_id: '@party_id'
                        }
                    );
                },

                managedNotifications: function (url) {
                    return this.createCollection(url, '/api/parties/:party_id/notifications/managed', {
                        party_id: '@party_id'
                    });
                },

                stats: function (url) {
                    return this.createPagedCollection(url, '/api/parties/:party_id/counters');
                }
         };
        
         pipRestProvider.addApi(notificationsApi);
    }]);
    
    thisModule.provider('pipNotificationsData', function () {

        // this.readManagedPartiesResolver = function() {
        //     return /* @ngInject */ function ($stateParams, pipRest, pipDataSession) {
        //         return pipRest.managedConnections().query({
        //             party_id: pipDataSession.userId()
        //         }).$promise;
        //     };
        // };

        // this.readNotificationsResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, pipDataSession) {
        //         return pipRest.notifications().query({
        //             party_id: pipDataSession.userId() //$stateParams.managed_party_id || pipDataSession.userId()
        //         }).$promise;
        //     };
        // };

        // // чтание notifications/managed
        // this.readManagedNotificationsResolver = function() {
        //     return /* @ngInject */ function ($stateParams, pipRest, pipDataSession) {
        //         // todo
        //         return pipRest.managedNotifications().query({
        //             party_id: pipDataSession.userId()
        //         }).$promise;
        //     };
        // };

        // CRUD operations and other business methods
        this.$get = ['$stateParams', 'pipDataModel', 'pipTranslate', 'pipNotificationsCache', 'pipDataSession', function($stateParams, pipDataModel, pipTranslate, pipNotificationsCache, pipDataSession) {
            return {
                replayNotification: function(params, successCallback, errorCallback) {
                    params.resource = 'notifications';
                    params.item.party_id = params.item.party_id || pipDataSession.userId(); // todo user.id заменить на party_id
                    pipDataModel.create(params, successCallback, errorCallback);
                },
                
                readManagedNotifications: function(params, successCallback, errorCallback) {
                    params.resource = 'managedNotifications';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.userId();
                    return pipNotificationsCache.readManagedNotifications(params, successCallback, errorCallback);
                },
                
                readNotifications: function(params, successCallback, errorCallback) {
                    params.resource = 'notifications';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.userId();
                    pipDataModel.read(params, successCallback, errorCallback);
                },
                
                readStats: function(params, successCallback, errorCallback) {
                    params.resource = 'stats';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.userId();
                    pipDataModel.readOne(params, successCallback, errorCallback);
                },
                
                readManagedParty: function(params, successCallback, errorCallback) {
                    params.resource = 'managedConnections';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.userId();
                    pipDataModel.read(params, successCallback, errorCallback);
                },
                
                getNotification: function(params, successCallback, errorCallback) {
                    params.resource = 'managedNotifications';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.userId();
                    pipDataModel.query(params, successCallback, errorCallback);
                }
            }
        }];
        this.$get.$inject = ['$stateParams', 'pipDataModel', 'pipTranslate', 'pipNotificationsCache', 'pipDataSession'];
    });

})();
