/**
 * @file Entry signin controller
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo
 * - Remove hack with guide_intro redirect
 * - Fix error handling
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipMobileEntry.Signin', ['pipEntry.Common', "pipSigninPanel"]);

    thisModule.controller('pipSigninController',
        ['$scope', '$rootScope', '$state', 'pipUtils', 'pipTranslate', 'pipDataSession', 'pipMobileEntry', 'AccessoryService', function ($scope, $rootScope, $state, pipUtils, pipTranslate, pipDataSession, pipMobileEntry, AccessoryService) {

            $scope.lnLocal = [{id: 'en', name: 'ENGLISH'}, {id: 'ru', name: 'RUSSIAN'}];
            $scope.ln = pipTranslate.use();

            $scope.styleClass = pipMobileEntry.getClass();
            $scope.appLogo = pipMobileEntry.getAppLogo();
            $scope.isIe = AccessoryService.isIe();

            $scope.gotoSignup = gotoSignup;
            $scope.gotoForgotPassword = gotoForgotPassword;
            $scope.ignoreClick = gotoForgotPassword;
            $scope.setLanguage = setLanguage;

            if (!$rootScope.isSignin) pipDataSession.signout(); // hak for set language

            $rootScope.isSignin = false;
            
            return;

            function setLanguage(ln) {
                setTimeout(function () {
                    pipTranslate.use(ln.id);
                    $rootScope.$apply();
                }, 0);
            }

            function ignoreClick($event) {
                if ($event) $event.stopPropagation();
            }

            function gotoSignup(){
                $state.go('signup', {});
            }

            function gotoForgotPassword(){
                $state.go('recover_password', {});
            }            

        }]
    );

})();