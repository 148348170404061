/**
 * @file Priority/status edit control
 * @copyright Digital Living Software Corp. 2014-2016
 * @todo
 * - Add sample into sampler app
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module("pipPriorityStatusEdit", 
        ['pipRest', 'pipCore', 'pipPriorityFormat']);


    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {
        pipTranslateProvider.translations('en', {
            'UPDATE_STATUS' : 'Change status',
            'URGENCY': 'Urgency',
            'IMPORTANCE': 'Importance',
            'STATUS': 'Status',
            'PROGRESS': 'Progress',
            'CANCEL': 'Cancel',
            'APPLY': 'Change'
        });
        pipTranslateProvider.translations('ru', {
            'UPDATE_STATUS' : 'Измените статус',
            'URGENCY': 'Срочность',
            'IMPORTANCE': 'Важность',
            'STATUS': 'Статус',
            'PROGRESS': 'Прогресс',
            'CANCEL': 'Отменить',
            'APPLY': 'Изменить'
        });
    }]);

    /**
     * pipChange
     * pipEntity
     * pipEntity
     *
     * pipShowProgress
     * pipShowStatus
     *
     * pipShowImportance
     * pipShowUrgency
     */

    thisModule.directive('pipPriorityStatusEdit',
        ['$parse', '$rootScope', '$state', 'pipUtils', 'pipEnums', 'pipTranslate', 'pipPriorityFormat', 'pipPriorityEditDialog', 'pipStatusEditDialog', function ($parse, $rootScope, $state, pipUtils, pipEnums, pipTranslate, pipPriorityFormat,
                  pipPriorityEditDialog, pipStatusEditDialog) {
            return {
                restrict: 'EAC',
                scope: {
                    pipChange: '&',
                    pipEntity: '=',
                    ngDisabled: '&'
                },
                templateUrl: 'priority_status/priority_status_edit.html',
                link: function ($scope, $element, $attrs) {
                    var entityGetter = $parse($attrs.pipEntity),
                        importanceCollection = [
                            {id: pipEnums.IMPORTANCE.LOW, name: 'IMPORTANCE_1'},
                            {id: pipEnums.IMPORTANCE.NORMAL, name: 'IMPORTANCE_2'},
                            {id: pipEnums.IMPORTANCE.HIGH, name: 'IMPORTANCE_3'}
                        ],
                        urgencyCollection = [
                            {id: pipEnums.URGENCY.LOW, name: 'URGENCY_1'},
                            {id: pipEnums.URGENCY.NORMAL, name: 'URGENCY_2'},
                            {id: pipEnums.URGENCY.HIGH, name: 'URGENCY_3'}
                        ];
                    $scope.statusCollection = pipTranslate.translateStringsSet(pipEnums.EXECUTION_STATUSES, 'STATUS');
                    initData();

                    if (pipUtils.toBoolean($attrs.pipRebind)) {
                        $scope.$parent.$watchCollection(entityGetter, function (newValue) {
                            initData();
                        });
                    }

                    $scope.isDisabled = isDisabled;
                    $scope.onPriorityClick = onPriorityClick;
                    $scope.onStatusClick = onStatusClick;

                    $element.addClass('w-stretch');
                    if (pipUtils.toBoolean($attrs.pipWatchOnly)) {
                        $element.addClass('pip-watch-only');
                    }

                    return;

                    function updateEntity() {
                        if ($scope.showImportance) $scope.pipEntity.importance = $scope.importance;
                        if ($scope.showUrgency) $scope.pipEntity.urgency = $scope.urgency;
                        $scope.pipEntity.status = $scope.status;
                        $scope.pipEntity.progress = $scope.progress != -1 ? $scope.progress : null;
                    }

                    function formatData() {
                        var status;
                        if ($scope.status != pipEnums.EXECUTION_STATUS.ASSIGNED)  status = $scope.status;
                        else status = pipEnums.EXECUTION_STATUS.IN_PROGRESS;

                        var translate = _.find($scope.statusCollection, function (s) { return s.id == status; });
                        if (translate) $scope.translatedStatus = translate.name;
                        $scope.priority = pipPriorityFormat.formatPriority($scope.importance, $scope.urgency, $scope.showImportance, $scope.showUrgency);
                    }

                    function initData() {
                        if (!$scope.pipEntity) return;
                        $scope.showProgressLine = !!($attrs.pipShowProgress == undefined || pipUtils.toBoolean($attrs.pipShowProgress) == true);// ? true : false;
                        $scope.showStatus = !!($attrs.pipShowStatus == undefined || pipUtils.toBoolean($attrs.pipShowStatus) == true);// ? true : false;

                        $scope.showImportance = !!($attrs.pipShowImportance == undefined || pipUtils.toBoolean($attrs.pipShowImportance) == true);
                        $scope.showUrgency = !!($attrs.pipShowUrgency == undefined || pipUtils.toBoolean($attrs.pipShowUrgency) == true);

                        $scope.importance = $scope.showImportance ? $scope.pipEntity.importance : 500;
                        $scope.urgency = $scope.showUrgency ? $scope.pipEntity.urgency : 500;
                        $scope.status = $scope.pipEntity.status;
                        $scope.progress = ($scope.pipEntity.progress !== null && $scope.pipEntity.progress !== undefined) ? $scope.pipEntity.progress : -1;
                        formatData();
                    };

                    function isDisabled() {
                        var disabled = $scope.ngDisabled ? !!$scope.ngDisabled() : false;
                        return disabled;
                    };

                    function onPriorityClick() {
                        if (($attrs.pipWatchOnly && pipUtils.toBoolean($attrs.pipWatchOnly)) || isDisabled()) return;

                        pipPriorityEditDialog.show(
                            {
                                urgency: $scope.urgency,
                                showUrgency: $scope.showUrgency,
                                importance: $scope.importance,
                                showImportance: $scope.showImportance,
                                importanceCollection: importanceCollection,
                                urgencyCollection: urgencyCollection
                            },
                            function (results) {
                                if ($scope.showUrgency) $scope.urgency = results.urgency;
                                if ($scope.showImportance) $scope.importance = results.importance;
                                formatData();
                                updateEntity();
                                $scope.pipChange();
                            }
                        );
                    };

                    function onStatusClick() {
                        if (($attrs.pipWatchOnly && pipUtils.toBoolean($attrs.pipWatchOnly)) || isDisabled()) return;

                        pipStatusEditDialog.show(
                            {
                                status: $scope.status,
                                progress: $scope.progress,
                                statusCollection: $scope.statusCollection
                            },
                            function (results) {
                                $scope.status = results.status;
                                $scope.progress = results.progress;
                                formatData();
                                updateEntity();
                                $scope.pipChange();
                            }
                        );
                    };

                }
            }
        }]
    );

})();

