<!--
@file Password reset page
@copyright Digital Living Software Corp. 2014-2016
-->

<div class="pip-card-container pip-outer-scroll pip-entry">
    <pip-card width="400">

        <pip-reset-password-panel
                pip-data="data"
                pip-created="$panel = $control">

        </pip-reset-password-panel>
        <div class="pip-footer">
            <md-button ng-hide="transaction.busy()" ng-click="goBack()" class="rm8" aria-label="CANCEL">
                {{::'CANCEL' | translate}}
            </md-button>
            <md-button ng-hide="transaction.busy()" ng-click="onReset()" aria-label="ENTRY_SET_PASSWORD"
                       ng-disabled="(form.$pristine && !data.email) || data.serverUrl.length == 0 ||
                       data.email.length == 0 || data.code.length == 0 || data.password.length < 6"
                       class="md-accent" type="submit">
                {{::'ENTRY_SET_PASSWORD' | translate}}
            </md-button>
            <md-button class="md-warn" ng-show="transaction.busy()" ng-click="transaction.abort()" aria-label="ABORT">
                {{::'CANCEL' | translate}}
            </md-button>
        </div>
    </pip-card>
</div>