<div class="w-stretch layout-column">
        <!-- confident options -->
        <div class="h72 flex-fixed pip-accented-box rp24-flex lp24-flex layout-row layout-align-start-center"
             ng-if="showConfident">
            <div class="text-subhead1 flex opacity-primary rm16" ng-bind-html="selected.confidDiscription">
            </div>
            <md-button class="m0 md-raised md-accent flat flex-fixed"
                       ng-click="onConfidentChange($event)"
                       ng-disabled="entity.share_def || transaction.busy()"
                       aria-label="CHANGE_TYPE">

                {{::'VISIBILITY_CHANGE' | translate}}
            </md-button>
        </div>
        <!-- default switch -->
        <div class="h64 flex-fixed rp24-flex lp24-flex layout-row layout-align-start-center"
             ng-if="!selected.hideDefault && entity.confid !== confidentSecret">
           
            <md-checkbox class="m0" 
                id="checkbox_id"
                ng-model="entity.share_def" 
                ng-disabled="transaction.busy()"
                ng-click="ignoreClick($event)"
                ng-change="onCheckboxClick(entity.share_def)"
                aria-label="CHECK">

                <span class="text-body2 color-secondary-text lm8">
                    {{::'VISIBILITY_DETAILS_DEFAULT_VISIBILITY' | translate}}
                </span>                
            </md-checkbox>      
            <!--<label class="text-body2 color-secondary-text" flex>
                {{::'VISIBILITY_DETAILS_DEFAULT_VISIBILITY' | translate}}
            </label>-->
        </div>
        <!-- Share level options -->
        <div class="tm16 bm8 rp24-flex lp24-flex"
             ng-if="(entity.confid !== confidentClassified) && (entity.confid !== confidentSecret)">
            <p class="text-caption opacity-disabled m0">
                {{::'VISIBILITY_DETAILS_SHARE_LEVEL' | translate}}
            </p>
            <md-select  class="tm0 bm16 text-subhead1" style="max-width: 320px;"
                        ng-model="entity.share_level"
                        ng-disabled="entity.share_def || transaction.busy()"
                        ng-class="{'cursor-auto color-secondary-text': entity.share_def, 'ie': isIe()}"
                        ng-change="onChangeShareLevel()">
                <md-option ng-value="opt.id" ng-repeat="opt in shareLevelCollection | filter : {visible: true}" >
                    <md-icon class="share-level-icon rm8" md-svg-icon="icons:{{opt.icon}}"></md-icon>
                    <span class="flex">{{ ::opt.nameLocal }}</span>
                </md-option>
            </md-select>
        </div>
        <div class="flex=fixed rp24-flex lp24-flex" ng-show="entity.confid !== confidentSecret && !entity.share_def && !transaction.busy()">
            <p class="text-caption opacity-disabled m0">
                {{::'VISIBILITY_DETAILS_CONTACTS' | translate}}
            </p>
            <md-contact-chips ng-model="selected.share_connections"
                              ng-disabled="entity.share_def && transaction.busy()"
                              md-contacts="getConnections($query)"
                              md-contact-name="party_name"
                              md-contact-image="image"
                              placeholder="{{::'VISIBILITY_DETAILS_CONTACT_PLACEHOLDER' | translate}}"
                              class="w-stretch visibility-contact">
            </md-contact-chips>
        </div>
        <div style="padding-top:9px; padding-left: 3px;" class="flex=fixed rp24-flex lp24-flex"
             ng-hide="entity.confid !== confidentSecret && !transaction.busy() && !entity.share_def">
            <pip-party-list
                    pip-parties="selected.share_connections" pip-rebind="true">
            </pip-party-list>
        </div>
        <div class="h72">

        </div>
        <div class="w-stretch rp24-flex lp24-flex layout-column layout-align-center-center flex" pip-composite-focused>
            <div class="pip-visibility-empty  flex layout-column layout-align-center-center">
                <div ng-if="entity.confid === confidentSecret"
                    class="pip-empty layout-column layout-align-center-center">
                    <img src="images/ES_VisibilitySecret.svg" class="pip-pic block">
                </div>
            </div>
        </div>


</div>  