/**
 * @file Filter to format Participants
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipParticipantsFilters', ['pipParticipantsFormat']);

    thisModule.filter('formatParticipants',
        ['pipParticipantsFormat', function (pipParticipantsFormat) {
            return function(value, format) {
                return pipParticipantsFormat.formatParticipants(value);
            };
        }]
    );


})();
