/**
 * @file Quotes data model
 * @copyright Digital Living Software Corp. 2014-2016
 */
 
 /* global angular */
 
(function () {
    'use strict';
    
    var thisModule = angular.module('pipQuotesData', ['pipRest', 'pipDataModel', 'pipQuotesCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var quotesApi = {
                quotes: function (url) {
                    return this.createPagedCollection(url, '/api/quotes/:id');
                },

                randomQuote: function (url) {
                    return this.createResource(url, '/api/quotes/random');
                }
         };
        
         pipRestProvider.addApi(quotesApi);
    }]);
    
    thisModule.provider('pipQuotesData', function () {
        var PAGE_SIZE = 100;

        // // Read all quotes
        // this.readQuotesResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipRest) {
        //         return pipRest.quotes().query().$promise;
        //     };
        // };

        // this.readQuoteResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipRest) {
        //         return pipRest.quotes().get({
        //             id: $stateParams.id
        //         }).$promise;
        //     };
        // };

        // CRUD operations and other business methods
        this.$get = ['$stateParams', 'pipDataModel', 'pipQuotesCache', 'pipDataSession', function ($stateParams, pipDataModel, pipQuotesCache, pipDataSession) {

            return {
                partyId: pipDataSession.partyId(),
                
                // todo rename after optimization 
                readQuotes: function (params, successCallback, errorCallback) {
                    params.resource = 'quotes';
                    params.item =  params.item || {};
                    params.item.search = $stateParams.search;
                    params.item.tags = $stateParams.search;
                    params.item.party_id = pipDataSession.partyId();
                    return pipQuotesCache.readQuotes(params, successCallback, errorCallback);
                 },
                readQuote: function (params, successCallback, errorCallback) {
                    params.resource = 'quotes';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    if (!params.item.id) { params.item.id = $stateParams.id; }
                    return pipDataModel.readOne(params, pipQuotesCache.onQuoteUpdate(params, successCallback), errorCallback);
                },

                createQuote: function (params, successCallback, errorCallback) {
                    params.resource = 'quotes';
                    params.item =  params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.create(
                        params,
                        pipQuotesCache.onQuoteCreate(params, successCallback),
                        errorCallback
                    );
                },

                createQuoteWithFiles: function(params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    params.item =  params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.saveFiles(params, function() {
                        params.resource = 'quotes';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;
                        
                        params.item.party_id = pipDataSession.partyId();
                        pipDataModel.create(
                            params,
                            pipQuotesCache.onQuoteCreate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                updateQuote: function (params, successCallback, errorCallback) {
                    params.resource = 'quotes';
                    params.item =  params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.update(
                        params,
                        pipQuotesCache.onQuoteUpdate(params, successCallback),
                        errorCallback
                    );
                },

                updateQuoteWithFiles: function (params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    params.item =  params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.saveFiles(params, function () {
                        params.resource = 'quotes';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;

                        pipDataModel.update(
                            params,
                            pipQuotesCache.onQuoteUpdate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                deleteQuote: function(params, successCallback, errorCallback) {
                    params.resource = 'quotes';
                    pipDataModel.remove(params, pipQuotesCache.onQuoteDelete(params, successCallback), errorCallback);
                }


            }
        }];
        this.$get.$inject = ['$stateParams', 'pipDataModel', 'pipQuotesCache', 'pipDataSession'];
    });

})();
