<md-dialog class="pip-dialog pip-goal-multi-selection layout-column" width="600" min-height="550" md-theme="{{theme}}">
    <md-progress-linear ng-show="transaction.busy()"  md-mode="indeterminate" class="position-top">
    </md-progress-linear>

    <div class="pip-body p0" >
        <div class="pip-header bp8 layout-column">
            <h3 class= "w-stretch m0 text-title bm16">{{ ::title | translate}}</h3>
            <div class="w-stretch divider-bottom layout-row layout-start-center"
                 ng-if="selected.itemList.length > 0">
                <input  style="border: none;" class="color-content-bg rm8 text-primary flex"
                        ng-disabled="transaction.busy()"
                        ng-model="selected.nameFilter"
                        ng-keypress="onSearchKeyPress($event)"
                        placeholder="{{::'GOAL_AREA_FILTER_BY_NAME' | translate}}"
                        type="text" tabindex="1"/>
                <md-button class="md-icon-button md-icon-button-square p0 rm0"
                           ng-click="onNameFilterChange()">
                    <md-icon md-svg-icon="icons:search-square"></md-icon>
                </md-button>
            </div>
        </div>
        <md-dialog-content class="pip-menu-container" ng-if="selected.itemList.length > 0">
            <md-list class="pip-menu w-stretch" style="border: 0px!important;">

                <md-list-item ng-repeat="item in selected.itemList"
                         ng-if="(item.showCurrent && !(item.id == 1 && item.sub_goals_count < 1)) || selected.breakdown"
                         class="pip-ref-item flex-fixed layout-row layout-align-start-center pip-level-{{ item.level > 6 ? 6 : item.level }}"
                         ng-class="{'pip-no-child': item.sub_goals_count < 1}"
                         pip-id="{{ item.id }}">
                    <md-button aria-label="show" ng-if="item.sub_goals_count > 0"
                               ng-click="onArrowClick($index, $event)" class="flex-fixed md-icon-button">
                        <md-icon md-svg-icon="icons:triangle-up" ng-if="item.showGoals"></md-icon>
                        <md-icon md-svg-icon="icons:triangle-down" ng-if="!item.showGoals"></md-icon>
                    </md-button>
                        <pip-avatar pip-id="item.id"
                                    class="pip-pic pip-face hide-xs"
                                    pip-rebind="true"
                                    ng-if="item.type != 'category'"
                                    pip-party-id="party.id"
                                    pip-entity-type="item.type">
                        </pip-avatar>

                        <div class="pip-content rm8 flex">
                            <div class="pip-title"> {{::item.title| translate}} </div>

                            <pip-priority-status-edit class="pip-subtitle"
                                                      pip-entity="item"
                                                      pip-rebind="true"
                                                      pip-show-progress="false"
                                                      pip-show-status="{{item.type != 'dream'}}"
                                                      pip-watch-only="true">
                            </pip-priority-status-edit>
                        </div>
                        <div class="tm16">
                            <md-checkbox id="checkbox_id"
                                         ng-model="item.checkItem" ng-click="ignoreClick($event)"
                                         ng-change="onCheckboxClick(item)"
                                         aria-label="CHECK">
                            </md-checkbox>
                        </div>

                </md-list-item>
                <div class="h72"></div>
            </md-list>
        </md-dialog-content>

        <div ng-if="selected.itemList.length == 0"
             class="h110 pip-empty layout-column layout-align-center-center flex">
            <div class="pip-text m0">
                {{::'PLANS_NO_AREAS'| translate}}
            </div>
        </div>
    </div>


    <div class="pip-footer lp8 rp8">
        <div ng-show="numberSelectedGoals > 0" class="flex-fixed">
            <md-button ng-click="onChangeCriteria($event)" >
                {{ criteria == 'selected' ? 'ALL' : 'SELECTED' | translate }}
                <span ng-hide="criteria == 'selected'">({{ numberSelectedGoals }})</span>
            </md-button>
        </div>
        <span class="flex"></span>
        <md-button class="pip-cancel" ng-click="onCancel()" aria-label="CANCEL">
            {{::'CANCEL' | translate}}
        </md-button>
        <md-button class="pip-submit md-accent" ng-disabled="numberSelectedGoals == 0"
                   ng-click="onChoose()" aria-label="SELECT">
            {{::'APPLY' | translate}}
        </md-button>
    </div>
</md-dialog>
