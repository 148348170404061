/**
 * @file pipDataGenerators
 * @copyright Digital Living Software Corp. 2014-2016
 */

(function () {
    'use strict';

    var thisModule = angular.module('pipConvertRecord', []);

    thisModule.factory('pipConvertRecord', ['$log', function ($log) {

        var convertRcord = function(params) {

            //
            this.params = params;
            //
            this.record = record;

            this.initRecord = function () {
                var record = {};

                return record;
            }

            this.fillRecord = function () {

            }

            this.saveRecord = function () {

            }

            this.openNew = function () {

            }

            this.openNewApplication = function () {

            }

            this.viewRecord = function () {

            }

            this.viewRecordApplication = function () {

            }

            this.openMagazine = function () {

            }

            this.deleteOriginalRecord = function () {

            }
        }

        return convertRecord;

    }]);

})();
