/*
 * edit_titles.js
 * Edit titles  dialog for dashboard
 * (с) Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipChooseTilesDialog', [
        'ngMaterial', 'pipAppBar', 'pipRest.State',
        'pipTranslate', 'pipDataModel', 'pipLifeCommons.Templates'
    ]);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {

        // Set translation strings for the module
        pipTranslateProvider.translations('en', {
            'CHOOSE_TITLES_TITLE' : 'Add titles',
            'CHOOSE_TITLES_PARTNERS_TITLE' : 'Partner or team',
            'CHOOSE_TITLES_PARTNERS_CONTENT' : 'Always be aware what your partners or team are working on at the moment',
            'CHOOSE_TITLES_NOTES_TITLE' : 'Notes',
            'CHOOSE_TITLES_NOTES_CONTENT' : "Keep control of all your ideas",
            'CHOOSE_TITLES_POSTS_TITLE' : 'Posts',
            'CHOOSE_TITLES_POSTS_CONTENT' : "Be aware of what's happening around you",
            'CHOOSE_TITLES_EVENTS_TITLE' : 'Events',
            'CHOOSE_TITLES_EVENTS_CONTENT' : "Don't miss your events",
            'CHOOSE_TITLES_GOALS_TITLE' : 'Areas or goal',
            'CHOOSE_TITLES_GOALS_CONTENT' : "Look up to something you strive to achieve",
            'CHOOSE_TITLES_MESSAGES_TITLE' : 'Messages',
            'CHOOSE_TITLES_MESSAGES_CONTENT' : "Do not miss important messages from partners",
            'CHOOSE_TITLES_TIMELINE_TITLE' :  'Timeline',
            'CHOOSE_TITLES_TIMELINE_CONTENT' : "Always be aware on what you're working on"
        });

        pipTranslateProvider.translations('ru', {
            'CHOOSE_TITLES_TITLE':'',
            'CHOOSE_TITLES_PARTNERS_TITLE' : 'Партнер или команда',
            'CHOOSE_TITLES_PARTNERS_CONTENT' : 'Всегда будьте в курсе того, над чем работают ваши партнеры или команда в данный момент',
            'CHOOSE_TITLES_NOTES_TITLE' : 'Заметки',
            'CHOOSE_TITLES_NOTES_CONTENT' : "Держите под контролем все ваши идеи",
            'CHOOSE_TITLES_POSTS_TITLE' : 'Посты',
            'CHOOSE_TITLES_POSTS_CONTENT' : "Держите руку на пульсе того, что актуально для ваших друзей или интересных людей",
            'CHOOSE_TITLES_EVENTS_TITLE' : 'События',
            'CHOOSE_TITLES_EVENTS_CONTENT' : "Не пропускайте важные дела",
            'CHOOSE_TITLES_GOALS_TITLE' : 'Области или цели',
            'CHOOSE_TITLES_GOALS_CONTENT' : "Держите перед глазами то, к чему стремитесь",
            'CHOOSE_TITLES_MESSAGES_TITLE' : 'Сообщения',
            'CHOOSE_TITLES_MESSAGES_CONTENT' : "Не пропускайте важные сообщения от партнеров",
            'CHOOSE_TITLES_TIMELINE_TITLE' :  'Хронология',
            'CHOOSE_TITLES_TIMELINE_CONTENT' : 'Всегда будь в курсе того, над чем работают'
        });

    }]);

    thisModule.run(['$rootScope', 'pipAppBar', 'ChooseTilesDialog', function ($rootScope, pipAppBar, ChooseTilesDialog) {

        }
    ]);

    thisModule.factory('ChooseTilesDialog', ['$state', '$http', '$mdDialog', '$rootScope', function ($state, $http, $mdDialog, $rootScope) {
                return {
                    show: function (params, successCallback, cancelCallback) {
                        $mdDialog.show({
                            targetEvent: params.event,
                            controller: 'PipChooseTilesDialogController',
                            templateUrl: 'choose_titles_dialog/choose_titles_dialog.html',
                            locals: {

                            }
                        })
                            .then(function (result) {
                                if (successCallback) {
                                    successCallback(result);
                                }
                            }, function () {
                                if (cancelCallback) {
                                    cancelCallback();
                                }
                            });
                    }
                };
            }
        ]);

    thisModule.controller('PipChooseTilesDialogController',
        ['$scope', '$rootScope', '$state', '$http', '$mdDialog', 'pipTransaction', 'pipTranslate', function ($scope, $rootScope, $state, $http, $mdDialog, pipTransaction, pipTranslate) {
                $scope.options= [
                    {
                        party_name:'Kate Negrienko',
                        party_id:''
                    },{
                        party_name:'Kate Negrienko',
                        party_id:'543570da469a0d0257b9090a'
                    },
                    {
                        party_name:'Kate Negrienko',
                        party_id:'543570da469a0d0257b9090a'
                    }
                ]


            }
        ]);

})();
