/*
 * edit_titles.js
 * Edit titles  dialog for dashboard
 * (с) Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipEditTitlesDialog', [
        'ngMaterial', 'pipAppBar', 'pipRest.State',
        'pipTranslate', 'pipDataModel', 
        'pipLifeCommons.Templates'
    ]);

    thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {

        // Set translation strings for the module
        pipTranslateProvider.translations('en', {
            'EDIT_TITLES_TITLE':'Edit title',

        });

        pipTranslateProvider.translations('ru', {
            'EDIT_TITLES_TITLE':'',

        });

    }]);

    thisModule.factory('EditTitlesDialog',
        ['$state', '$http', '$mdDialog', '$rootScope', function ($state, $http, $mdDialog, $rootScope) {
            return {
                show: function (params, successCallback, cancelCallback) {
                    $mdDialog.show({
                        targetEvent: params.event,
                        controller: 'PipEditTitlesDialogController',
                        templateUrl: 'edit_titles_dialog/edit_titles_dialog.html',
                        locals: {}
                    })
                    .then(function (result) {
                        if (successCallback) {
                            successCallback(result);
                        }
                    }, function () {
                        if (cancelCallback) {
                            cancelCallback();
                        }
                    });
                }
            };
        }]
    );

    thisModule.controller('PipEditTitlesDialogController',
        ['$scope', '$rootScope', '$state', '$http', '$mdDialog', 'pipTransaction', 'pipTranslate', function ($scope, $rootScope, $state, $http, $mdDialog, pipTransaction, pipTranslate) {
            $scope.options= [
                {
                    party_name:'Kate Negrienko',
                    party_id:''
                },{
                    party_name:'Kate Negrienko',
                    party_id:'543570da469a0d0257b9090a'
                },
                {
                    party_name:'Kate Negrienko',
                    party_id:'543570da469a0d0257b9090a'
                }
            ]
            $scope.types=[
                {
                    id:'partners',
                    name:'PARTNERS_TEAMS'
                },
                {
                    id:'posts',
                    name:'POSTS'
                },
                {
                    id:'notes',
                    name:'NOTES'
                },
                {
                    id:'events',
                    name:'EVENTS'
                },
                {
                    id:'areas',
                    name:'AREA_OR_GOALS'
                }
            ]
            $scope.tileSize=[
                    'SMALL','WIDE','LARGE'
            ];
            $scope.due=[
                {
                    id:'year',
                    name:'1'
                },
                {
                    id:'year2',
                    name:'2'
                },
                {
                    id:'year5',
                    name:'5'
                }
            ];
            $scope.display = [
                {
                    id:'activity',
                    name:'Recent activities'
                },
                {
                    id:'details',
                    name:'Details'
                },
                {
                    id:'brea',
                    name:'Brea'
                },
                {
                    id:'metrics',
                    name:'Metrics'
                }

            ]
        }]
    );

})();
