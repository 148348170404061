/**
 * @file Events data model
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipEventsData',
        ['pipUtils', 'pipRest', 'pipDataModel', 'pipCacheTag', 'pipEventsCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var eventsApi = {
            events: function (url) {
                return this.createPartyCollection(url, '/api/parties/:party_id/events/:id');
            },

            acceptEvent: function (url) {
                return this.createPartyCollection(url, '/api/parties/:party_id/events/:id/accept');
            },

            rejectEvent: function (url) {
                return this.createPartyCollection(url, '/api/parties/:party_id/events/:id/reject');
            }
        };

        pipRestProvider.addApi(eventsApi);
    }]);

    thisModule.provider('pipEventsData', function () {

        // // todo del after
        // this.readEventsResolver = function (nextPeriod) {
        //     return /* @ngInject */ ['$stateParams', 'pipEnums', 'pipRest', 'pipDataSession', 'pipDates', function ($stateParams, pipEnums, pipRest, pipDataSession, pipDates) {

        //         function getEventsParams(currentCategory, currentDate) {
        //             var category = currentCategory ? currentCategory : pipEnums.EVENT_CATEGORY.DAILY;
        //             var date = currentDate;
        //             var from, to;

        //             switch (category) {
        //                 case pipEnums.EVENT_CATEGORY.WEEKLY:
        //                     var dayOfWeek = date.getUTCDay() ? date.getUTCDay() : 7;
        //                     from = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, -(dayOfWeek - 1) * 24 * 3600000);
        //                     to = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 7, 0, 0, 0, -(dayOfWeek - 1) * 24 * 3600000);
        //                     from = new Date(from.getTime() - from.getTimezoneOffset() * 60000);
        //                     to = new Date(to.getTime() - to.getTimezoneOffset() * 60000);
        //                     break;
        //                 case pipEnums.EVENT_CATEGORY.MONTHLY:
        //                     from = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
        //                     to = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 1);
        //                     from = new Date(from.getTime() - from.getTimezoneOffset() * 60000);
        //                     to = new Date(to.getTime() - to.getTimezoneOffset() * 60000);
        //                     break;
        //                 case pipEnums.EVENT_CATEGORY.YEARLY:
        //                     from = new Date(date.getUTCFullYear(), 0, 1);
        //                     to = new Date(date.getUTCFullYear() + 1, 0, 1);
        //                     from = new Date(from.getTime() - from.getTimezoneOffset() * 60000);
        //                     to = new Date(to.getTime() - to.getTimezoneOffset() * 60000);
        //                     break;
        //                 default:
        //                     var dd = pipDates.fromUTCDate(date);
        //                     from = pipDates.toStartDay(dd);
        //                     to = pipDates.toEndDay(dd);
        //                     break;
        //             }
        //             return {
        //                 category: category,
        //                 from: from.toJSON(),
        //                 to: to.toJSON()
        //             }
        //         };

        //         function nextCategory(category) {
        //             switch (category) {
        //                 case pipEnums.EVENT_CATEGORY.WEEKLY:
        //                     return pipEnums.EVENT_CATEGORY.MONTHLY;
        //                 case pipEnums.EVENT_CATEGORY.MONTHLY:
        //                     return pipEnums.EVENT_CATEGORY.YEARLY;
        //                 case pipEnums.EVENT_CATEGORY.DAILY:
        //                     return pipEnums.EVENT_CATEGORY.WEEKLY;
        //                 default:
        //                     return null;
        //             }
        //         };

        //         var category = $stateParams.category
        //             ? $stateParams.category : pipEnums.EVENT_CATEGORY.DAILY;

        //         if (category == pipEnums.EVENT_CATEGORY.YEARLY && nextPeriod) return [];

        //         if (nextPeriod) category = nextCategory(category);

        //         var date = $stateParams.start ? new Date($stateParams.start) : new Date();

        //         var newParams = getEventsParams(category, date);

        //         return pipRest.events().query({
        //             party_id: pipDataSession.partyId(),
        //             category: newParams.category,
        //             from: newParams.from,
        //             to: newParams.to
        //         }).$promise;
        //     }];
        // };
        // // todo del after
        // this.readAllYearlyResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipEnums, pipRest) {
        //         return pipRest.events().query({
        //             party_id: pipDataSession.partyId(),
        //             category: pipEnums.EVENT_CATEGORY.YEARLY
        //         }).$promise;
        //     };
        // };

        // this.readTodayEventsResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipDates, pipEnums, pipRest) {
        //         var date = new Date();
        //         var from = pipDates.toStartDay(date);
        //         var to = pipDates.toEndDay(date, -1);

        //         return pipRest.events().query({
        //             party_id: pipDataSession.partyId(),
        //             category: pipEnums.EVENT_CATEGORY.DAILY,
        //             from: from.toJSON(),
        //             to: to.toJSON()
        //         }).$promise;
        //     };
        // };

        // this.readThisWeekEventsResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipDates, pipEnums, pipRest, pipDataSession) {
        //         var date = new Date();
        //         var from = pipDates.toUTCStartWeek(date);
        //         var to = pipDates.toUTCEndWeek(date);

        //         return pipRest.events().query({
        //             party_id: pipDataSession.partyId(),
        //             category: pipEnums.EVENT_CATEGORY.WEEKLY,
        //             from: from.toJSON(),
        //             to: to.toJSON()
        //         }).$promise;
        //     };
        // };

        // this.readEventResolver = function () {
        //     return /* @ngInject */ function ($stateParams, pipEnums, pipRest, pipDataSession) {
        //         var category = $stateParams.category ? $stateParams.category : null;
        //         return pipRest.events().get({
        //             party_id: pipDataSession.partyId(),
        //             id: $stateParams.id
        //         }).$promise;
        //     };
        // };

        this.$get = ['$stateParams', 'pipDataSession', 'pipDataModel', 'pipCacheTag', 'pipEventsCache', 'pipDates', 'pipEnums', function ($stateParams, pipDataSession, pipDataModel, pipCacheTag, pipEventsCache, pipDates, pipEnums) {
            return {
                partyId: pipDataSession.partyId(),

                readEvents: function (params, successCallback, errorCallback) {
                    params.resource = 'events';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.query(params, successCallback, errorCallback);
                },

                readTimeEvents: function (params, successCallback, errorCallback) {
                    function getEventsParams(currentCategory, currentDate, params) {
                        var category = currentCategory ? currentCategory : pipEnums.EVENT_CATEGORY.DAILY;
                        var date = currentDate;
                        var from, to;

                        if (params.from && params.to) {
                            from = params.from;
                            to = params.to;
                        } else {
                            switch (category) {
                                case pipEnums.EVENT_CATEGORY.WEEKLY:
                                    var dayOfWeek = date.getUTCDay() ? date.getUTCDay() : 7;
                                    from = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, -(dayOfWeek - 1) * 24 * 3600000);
                                    to = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 7, 0, 0, 0, -(dayOfWeek - 1) * 24 * 3600000);
                                    from = new Date(from.getTime() - from.getTimezoneOffset() * 60000);
                                    to = new Date(to.getTime() - to.getTimezoneOffset() * 60000);
                                    break;
                                case pipEnums.EVENT_CATEGORY.MONTHLY:
                                    from = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
                                    to = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 1);
                                    from = new Date(from.getTime() - from.getTimezoneOffset() * 60000);
                                    to = new Date(to.getTime() - to.getTimezoneOffset() * 60000);
                                    break;
                                case pipEnums.EVENT_CATEGORY.YEARLY:
                                    from = new Date(date.getUTCFullYear(), 0, 1);
                                    to = new Date(date.getUTCFullYear() + 1, 0, 1);
                                    from = new Date(from.getTime() - from.getTimezoneOffset() * 60000);
                                    to = new Date(to.getTime() - to.getTimezoneOffset() * 60000);
                                    break;
                                default:
                                    var dd = pipDates.fromUTCDate(date);
                                    from = pipDates.toStartDay(dd);
                                    to = pipDates.toEndDay(dd);
                                    break;
                            }
                        }


                        return {
                            category: category,
                            from: from.toJSON(),
                            to: to.toJSON()
                        }
                    };

                    function nextCategory(category) {
                        switch (category) {
                            case pipEnums.EVENT_CATEGORY.WEEKLY:
                                return pipEnums.EVENT_CATEGORY.MONTHLY;
                            case pipEnums.EVENT_CATEGORY.MONTHLY:
                                return pipEnums.EVENT_CATEGORY.YEARLY;
                            case pipEnums.EVENT_CATEGORY.DAILY:
                                return pipEnums.EVENT_CATEGORY.WEEKLY;
                            default:
                                return null;
                        }
                    };

                    var category = params.category || $stateParams.category || pipEnums.EVENT_CATEGORY.DAILY;

                    if (category == pipEnums.EVENT_CATEGORY.YEARLY && params.nextPeriod) return [];

                    if (params.nextPeriod) category = nextCategory(category);

                    var date = params.start ? new Date(params.start) : $stateParams.start ? new Date($stateParams.start) : new Date();

                    var newParams = getEventsParams(category, date, params);

                    var item = params.item || {};
                    item.party_id = pipDataSession.partyId();
                    item.category = newParams.category;
                    item.from = newParams.from;
                    item.to = newParams.to;

                    return pipEventsCache.readEvents({
                        resource: 'events',
                        item: item,
                        force: params.force
                    }, successCallback, errorCallback);

                },

                readYearEvents: function (params, successCallback, errorCallback) {
                    params.resource = 'events';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.category = pipEnums.EVENT_CATEGORY.YEARLY;
                    return pipEventsCache.readEvents(params, successCallback, errorCallback);

                },

                readTodayEvents: function (params, successCallback, errorCallback) {
                    var date = new Date();
                    var from = pipDates.toStartDay(date);
                    var to = pipDates.toEndDay(date, -1);
                    params.resource = 'events';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.category = pipEnums.EVENT_CATEGORY.DAILY;
                    params.item.from = from.toJSON();
                    params.item.to = to.toJSON();
                    return pipEventsCache.readEvents(params, successCallback, errorCallback);
                },

                readThisWeekEvents: function (params, successCallback, errorCallback) {
                    var date = new Date();
                    var from = pipDates.toStartDay(date);
                    var to = pipDates.toEndDay(date, -1);
                    params.resource = 'events';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.category = pipEnums.EVENT_CATEGORY.WEEKLY;
                    params.item.from = from.toJSON();
                    params.item.to = to.toJSON();
                    return pipEventsCache.readEvents(params, successCallback, errorCallback);
                },

                readEvent: function (params, successCallback, errorCallback) {
                    params.resource = 'events';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    params.item.id = params.item.id || $stateParams.id;
                    return pipDataModel.readOne(params, pipEventsCache.onEventUpdate(params, successCallback), errorCallback);
                },

                getEventsPage: function (params, successCallback, errorCallback) {
                    params.resource = 'events';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.page(params, successCallback, errorCallback);
                },

                createEvent: function (params, successCallback, errorCallback) {
                    params.resource = 'events';
                    params.item.party_id = pipDataSession.partyId();
                    params.item.creator_id = pipDataSession.partyId();
                    pipDataModel.create(
                        params,
                        pipCacheTag.onTagsUpdate(params, successCallback),
                        errorCallback
                    );
                },

                createEventWithFiles: function (params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(params, function () {
                        params.resource = 'events';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;
                        params.item.party_id = pipDataSession.partyId();
                        params.item.creator_id = pipDataSession.partyId();
                        pipDataModel.create(
                            params,
                            pipCacheTag.onTagsUpdate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                updateEvent: function (params, successCallback, errorCallback) {
                    params.resource = 'events';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.update(
                        params,
                        pipCacheTag.onTagsUpdate(params, successCallback),
                        errorCallback
                    );
                },

                updateEventWithFiles: function (params, successCallback, errorCallback) {
                    params.skipTransactionEnd = true;
                    pipDataModel.saveFiles(params, function () {
                        params.resource = 'events';
                        params.skipTransactionBegin = true;
                        params.skipTransactionEnd = false;
                        params.item.party_id = pipDataSession.partyId();
                        pipDataModel.update(
                            params,
                            pipCacheTag.onTagsUpdate(params, successCallback),
                            errorCallback
                        );
                    }, errorCallback);
                },

                deleteEvent: function (params, successCallback, errorCallback) {
                    params.resource = 'events';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.remove(
                        params,
                        pipEventsCache.onEventDelete(params, successCallback),
                        errorCallback
                    );
                },

                acceptEvent: function (params, successCallback, errorCallback) {
                    params.resource = 'acceptEvent';
                    pipDataModel.create(params, successCallback, errorCallback);
                },

                rejectEvent: function (params, successCallback, errorCallback) {
                    params.resource = 'rejectEvent';
                    pipDataModel.create(params, successCallback, errorCallback);
                },

                manageEvent: function (params, successCallback, errorCallback) {
                    params.resource = 'manageEvent';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.create(params, successCallback, errorCallback);
                }
            };

        }];
        this.$get.$inject = ['$stateParams', 'pipDataSession', 'pipDataModel', 'pipCacheTag', 'pipEventsCache', 'pipDates', 'pipEnums'];
    });

})();
