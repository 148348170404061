/**
 * @file Application Side Nav component
 * @copyright Digital Living Software Corp. 2014-2015
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipLifeSideNav',
        ['ngMaterial', 'pipTranslate', 'pipFocused', 'pipData', 'pipBasicControls.Templates', 'pipLifeSideNav.Service']);

    thisModule.config(['pipTranslateProvider', function (pipTranslateProvider) {

        pipTranslateProvider.translations('en', {
            'SIDENAV_SINCE': 'since'
        });

        pipTranslateProvider.translations('ru', {
            'SIDENAV_SINCE': 'с'
        });

    }]);

    // Main application sidenav directive
    thisModule.directive('pipLifeSidenav', function () {
        return {
            restrict: 'EA',
            scope: false,
            replace: false,
            templateUrl: 'sidenav/pip-life_sidenav.html',
            controller: 'pipLifeSideNavController'
        };
    });

    thisModule.controller('pipLifeSideNavController',
        ['$scope', '$element', '$state', '$rootScope', '$window', '$location', '$timeout', 'pipState', 'pipTranslate', 'pipLifeSideNav', 'pipPartiesData', 'pipConnectionsData', function ($scope, $element, $state, $rootScope, $window, $location, $timeout, pipState,
                  pipTranslate, pipLifeSideNav, pipPartiesData, pipConnectionsData) {

            // Apply class and call resize
            $element.addClass('pip-life-sidenav');

            $scope.config = pipLifeSideNav.config();
            // Config connections
            $scope.connectionsOpen = false;

            $scope.oldParty = $rootScope.$party;

            $rootScope.$on('pipAppBarNavIconClicked', onAppBarNavIconClick);
            $rootScope.$on('pipLifeSideNavChanged', onConfigChanged);

            $scope.itemVisible = itemVisible;
            $scope.onUserClick = onUserClick;
            $scope.onPartyClick = onPartyClick;
            $scope.onLinkClick = onLinkClick;
            $scope.isSectionEmpty = isSectionEmpty;
            $scope.openConnections = openConnections;
            $scope.onPersonClick = onPersonClick;

            return;

            //----------------------------------------------------------------------------------------

            function onPersonClick(event, connection) {
                var connectionsBlock = $($element.find('.pip-connections'));
                connectionsBlock.addClass('person-clicked');

                pipPartiesData.getParty({item: {id: connection.to_party_id}}, function (res) {
                    $rootScope.$party = res;

                    $scope.connectionsOpen = !$scope.connectionsOpen;
                    $rootScope.$broadcast('pipLifeSideNavPartyClicked');

                    $timeout(function () {
                        connectionsBlock.removeClass('person-clicked');
                    }, 1000);

                })
            }

            function getConnections() {
                if (!$rootScope.$user) return;

                pipConnectionsData.readConnections({item:{party_id: $rootScope.$user.id}}, function (result) {
                    $scope.connections = result;

                    result = _.filter(result, function (item) {
                        return item.manager || item.type == 'member'
                    });
                    result = _.uniq(result);
                    result = _.sortBy(result , function(item){ return  item.to_party_name.toUpperCase()});

                    $scope.connections = [];
                    if($rootScope.$user.id != $rootScope.$party.id){
                        $scope.connections.push({
                            to_party_id: $rootScope.$user.id,
                            to_party_name: $rootScope.$user.name});
                        _.remove(result, {to_party_id: $rootScope.$party.id});
                    }
                    _.each(result, function(item){ $scope.connections.push(item); })
                })
            }

            function openConnections() {
                $scope.connectionsOpen = !$scope.connectionsOpen;
            }

            function itemVisible(item) {
                return item && item.access && !item.access($rootScope.$party, $rootScope.$user, item);
            }

            function isSectionEmpty(linkCollection) {
                var result = true;
                _.each(linkCollection, function (link) {
                    if (!itemVisible(link))
                        result = false;
                });
                return result;
            }

            function onAppBarNavIconClick(event) {
                getConnections();
                pipLifeSideNav.open();
            }

            function onConfigChanged(event, config) {
                $scope.config = config;
            }

            function onUserClick() {
                $rootScope.$broadcast('pipLifeSideNavUserClicked');
                pipPartiesData.getParty({item: {id: $rootScope.$user.id}}, function (res) {
                    $rootScope.$party = res;
                    $scope.connectionsOpen = false;
                    getConnections();
                });
            }

            function onPartyClick() {
                $rootScope.$broadcast('pipLifeSideNavPartyClicked');
                pipLifeSideNav.close();
            }

            function processStateParams(params) {
                if (params == null) return null;

                var result = {};
                for (var prop in params) {
                    if (params.hasOwnProperty(prop)) {
                        if (params[prop] == ':party_id') {
                            result[prop] = $rootScope.$party ? $rootScope.$party.id : null;
                        } else if (params[prop] == ':user_id') {
                            result[prop] = $rootScope.$user ? $rootScope.$user.id : null;
                        } else {
                            result[prop] = params[prop];
                        }
                    }
                }
                return result;
            }

            function processUrlParams(url) {
                if (url == null) return null;

                var result = url.replace(':party_id', $rootScope.$party ? $rootScope.$party.id : '');
                result = result.replace(':user_id', $rootScope.$user ? $rootScope.$user.id : '');

                return result;
            }

            function onLinkClick(event, link) {
                event.stopPropagation();

                if (!link) {
                    pipLifeSideNav.close();
                    return;
                }

                if (link.href) {
                    if (link.href.split('?')[0] === $window.location.href.split('?')[0]) {
                        pipLifeSideNav.close();
                        return;
                    }

                    pipLifeSideNav.close();

                    $timeout(function() {
                        $window.location.href = processUrlParams(link.href);
                    }, 300);

                    return;
                }
                else if (link.url) {
                    if (link.url.split(/[\s/?]+/)[1] === $location.url().split(/[\s/?]+/)[1]) {
                        pipLifeSideNav.close();
                        return;
                    }

                    pipLifeSideNav.close();

                    $timeout(function() {
                       $location.url(processUrlParams(link.url));
                    }, 300);

                    return;                    
                }
                else if (link.state) {
                    if ($state.current.name === link.state) {
                        pipLifeSideNav.close();
                        return;
                    }

                    pipLifeSideNav.close();

                    $timeout(function() {
                        pipState.go(link.state, processStateParams(link.stateParams));
                    }, 300);

                    return;                     
                }
                else if (link.event)
                    $rootScope.$broadcast('pipLifeSideNavLinkClicked', link.event);

                pipLifeSideNav.close();
            }
        }]
    );

})();
