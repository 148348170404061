/*
 * notifications.js
 * Notifications dialog
 * (с) Digital Living Software Corp. 2014-2015
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipGoalsMultiSelectionDialog', [
        'ngMaterial', 'pipUtils', 'pipRest.Enums', 'pipAppBar', 'pipRest.State',
        'pipTranslate', 'pipDataModel', 'pipGoalsData'
    ]);

    thisModule.factory('GoalMultiSelectionDialog',
        ['$state', '$http', '$mdDialog', function ($state, $http, $mdDialog) {
            return {
                show: function (params, successCallback, cancelCallback) {
                    $mdDialog.show({
                        targetEvent: params.event,
                        controller: 'PipGoalMultiSelectionDialogController',
                        templateUrl: 'goal_multi_selection_dialog/goal_multi_selection_dialog.html',
                        clickOutsideToClose: true,
                        locals: {
                            selectedOptions: params.selectedOptions,
                            options: params.options,
                            party: params.party,
                            title: params.title
                        }
                    })
                        .then(function (result) {
                            if (successCallback) {
                                successCallback(result);
                            }
                        }, function () {
                            if (cancelCallback) {
                                cancelCallback();
                            }
                        });
                }
            };
        }]
    );

    thisModule.controller('PipGoalMultiSelectionDialogController',
        ['$scope', '$rootScope', '$state', '$http', '$mdDialog', 'pipCollections', 'pipEnums', 'pipTransaction', 'pipTranslate', 'party', 'options', 'selectedOptions', 'pipGoalsData', 'GoalsUtilsService', 'title', '$mdConstant', function ($scope, $rootScope, $state, $http, $mdDialog, pipCollections, pipEnums, pipTransaction, pipTranslate,
            party, options, selectedOptions, pipGoalsData, GoalsUtilsService, title, $mdConstant) {

            $scope.theme = $rootScope.$theme;
            $scope.selectedOptions = _.uniq($scope.selectedOptions);
            $scope.options = _.uniq($scope.options);

            $scope.createGoalTree = GoalsUtilsService.createGoalTree;
            $scope.createGoalList = GoalsUtilsService.createGoalList;
            $scope.setComplited = GoalsUtilsService.setComplited;
            $scope.filterComplited = GoalsUtilsService.filterComplited;
                        
            $scope.createGoalShiftList = GoalsUtilsService.createGoalShiftList;
            $scope.getSubGoals = GoalsUtilsService.getSubGoals;
            $scope.resetSubGoalsCounts = GoalsUtilsService.resetSubGoalsCounts;
            $scope.getParentCategoryId = GoalsUtilsService.getParentCategoryId;
            $scope.goalIcons = GoalsUtilsService.getGoalIcons();
            $scope.openItem = GoalsUtilsService.openItem;
            $scope.changeShowItems = GoalsUtilsService.changeShowItems;
            $scope.prepareGoalListAcceptance = GoalsUtilsService.prepareGoalListAcceptance;

            $scope.title = title;
            //------------------------

            $scope.isChooseGoal = true;
            $scope.numberSelectedGoals = selectedOptions ? selectedOptions.length : 0;
            $scope.importanceCollection = pipTranslate.translateSetById(pipEnums.IMPORTANCES, 'IMPORTANCE');
            $scope.urgencyCollection = pipTranslate.translateSetById(pipEnums.URGENCIES, 'URGENCY');
            $scope.statusCollection = pipTranslate.translateSet(pipEnums.EXECUTION_STATUSES);
            $scope.statusArray = [];
            _.each(pipEnums.EXECUTION_STATUSES, function (item){
                $scope.statusArray[item] = pipTranslate.translateById('STATUS', item);
            });

            $scope.party = party;
            $scope.transaction = pipTransaction('GOALS_MULTI_SELECT', $scope);
            $scope.selected = {};
            $scope.criteria = 'all';
            $scope.statusCompleted = pipEnums.EXECUTION_STATUS.COMPLETED;
            $scope.selected.nameFilter = '';
            $scope.overall = {};
            $scope.prepareGoalListAcceptance(options);

            var itemCollection = _.filter(options, function (item) {
                return item.accept;
            });

            $scope.overall.itemType = 'goal';
            $scope.overall.itemCollection = _.map(itemCollection, function (item) {
                var goal = _.find(selectedOptions, { id: item.id });
                item.checkItem = (goal && goal.id) ? true : false;
                return item;
            });

            $scope.prepareGoalShiftList = GoalsUtilsService.prepareGoalShiftList($scope.overall.itemCollection);
            var itemTree = $scope.createGoalTree($scope.overall.itemCollection);
            $scope.selected.itemCollection = $scope.createGoalList(itemTree, null);
            $scope.setComplited($scope.selected.itemCollection);
            $scope.selected.itemCollection = $scope.filterComplited($scope.selected.itemCollection);  
                        
            $scope.prepareGoalListAcceptance($scope.selected.itemCollection);
            $scope.selected.itemList = [];
            selectItems();

            $scope.onItemSelect = onItemSelect;
            $scope.onChangeCriteria = onChangeCriteria;
            $scope.onCheckboxClick = onCheckboxClick;

            $scope.onCancelClick = onCancelClick;
            $scope.onNameFilterChange = _.debounce(function() {
                selectItems();
            }, 500);
            $scope.onSearchKeyPress = onSearchKeyPress;

            $scope.onCancel = onCancel;
            $scope.onArrowClick = onArrowClick;
            $scope.onItemClick = onItemClick;
            $scope.ignoreClick = ignoreClick;
            $scope.onChoose = onChoose;

            return;

            // -------------------------------------------------------------------------------------

            function onSearchKeyPress($event) {
                if ($mdConstant.KEY_CODE.ENTER == $event.keyCode) {
                    selectItems();
                }
            }

            function onChangeCriteria ($event) {
                if ($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                }

                $scope.criteria = $scope.criteria == 'all' ? 'selected' : 'all';
                selectItems();
            }

            function filterOption (option) {
                if (!option.checkItem && $scope.criteria == 'selected') return false;

                if ($scope.selected.nameFilter == null || $scope.selected.nameFilter == '') return true;
                var
                    value1 = option.title.toLowerCase(),
                    value2 = $scope.selected.nameFilter.toLowerCase();
                return value1.indexOf(value2) >= 0;
            }

            function selectItems () {
                $scope.transaction.begin();

                $scope.selected.nameFilter = $scope.selected.nameFilter ? $scope.selected.nameFilter.toLowerCase() : '';

                $scope.selected.itemList = _.filter($scope.selected.itemCollection, filterOption);
                $scope.resetSubGoalsCounts($scope.selected.itemList);

                // Correct selected item position
                $scope.selected.item = _.find($scope.selected.itemList, {id: $scope.selected.itemId});
                if ($scope.selected.item == null) {
                    if (!$scope.selected.parentCategoryId || $scope.selected.parentCategoryId == 1) {
                        $scope.selected.item = $scope.selected.itemList[1];
                    } else {
                        $scope.selected.item = _.find($scope.selected.itemList, {id:$scope.selected.parentCategoryId});
                    }
                }

                $scope.overall.selectedItem = $scope.selected.item;
                $scope.selected.itemId = $scope.overall.selectedItem ? $scope.overall.selectedItem.id : null;
                $scope.selected.listIndex = $scope.selected.item
                    ? _.findIndex($scope.selected.itemList, {id:$scope.selected.item.id}) : null;
                $scope.selected.itemIndex = $scope.selected.listIndex;
                if ($scope.overall.selectedItem && $scope.selected.item.level != 0) {
                    $scope.openItem($scope.overall.selectedItem, $scope.selected.itemList);
                }

                $scope.transaction.end();
            }

            function onItemClick (item) {
                item.checkItem = !item.checkItem;
                item.checkItem ? $scope.numberSelectedGoals++ : $scope.numberSelectedGoals--;

                if ($scope.criteria == 'selected') {
                    if ($scope.numberSelectedGoals == 0) $scope.criteria = 'all';

                    selectItems();
                }
            }

            function onCheckboxClick(item) {
                item.checkItem ? $scope.numberSelectedGoals++ : $scope.numberSelectedGoals--;

                if ($scope.criteria == 'selected') {
                    if ($scope.numberSelectedGoals == 0) $scope.criteria = 'all';

                    selectItems();
                }
            }

            function onArrowClick (index, $event) {
                if ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                }

                GoalsUtilsService.changeShowItems(index, $scope.selected.itemList);
            }

            function onItemSelect (event) {
                var index = _.findIndex($scope.selected.itemList, {id: event.id});
                if ($scope.selected.itemList[index].id == 1) {
                    $scope.selected.itemIndex = 0;
                    $scope.selected.listIndex = 0;
                    return;
                }

                $scope.selected.itemIndex = index;
                $scope.selected.listIndex = index;
                $scope.selected.item = $scope.selected.itemList[index];
                $scope.selected.itemId = $scope.selected.item ? $scope.selected.item.id : null;
                $scope.overall.selectedItem = $scope.selected.item;
            }

            function onCancelClick () {
                $scope.selected.nameFilter = '';
                $scope.selected.itemCollection = selectItems();
                $scope.$apply();
            }

            function onCancel () {
                $mdDialog.cancel();
            }

            function ignoreClick (event) {
                if (event) event.stopPropagation();
            }

            function onChoose () {
                var result = [];
                _.each($scope.selected.itemCollection, function (item) {
                    if (item.checkItem)
                        result.push(item);
                });

                $mdDialog.hide({
                    selectedOptions: result
                });
            }
        }]
    );

})();
