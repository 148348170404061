<div class="pip-chip"
     ng-class="{'pointer': partyState}"
     ng-repeat="party in parties track by party.party_id"
     ng-click="onPartyClick($event, party.party_id)">

    <pip-avatar pip-party-id="party.party_id"
                pip-party-name="party.party_name"
                pip-rebind="true"
                class="pip-face">
    </pip-avatar>
    <span>{{::party.party_name}}</span>
</div>