/**
 * @file Connection groups data model
 * @copyright Digital Living Software Corp. 2014-2016
 */
 
/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipGroupsData', ['pipRest', 'pipDataModel', 'pipGroupsCache']);

    thisModule.config(['pipRestProvider', function (pipRestProvider) {
        var groupsApi = {
                groups: function (url) {
                    return this.createPartyCollection(url, '/api/parties/:party_id/groups/:id');
                }
         };
        
         pipRestProvider.addApi(groupsApi);
    }]);
    
    thisModule.provider('pipGroupsData', function () {

        // this.readGroupsResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, pipGroupsCache) {
        //         var params = {
        //             item: {
        //                 party_id: pipDataSession.partyId()
        //             }
        //         };

        //         return pipGroupsCache.readGroups(params);
        //     };
        // };

        // this.readGroupResolver = function() {
        //     return /* @ngInject */ function($stateParams, pipRest, pipGroupsCache) {
        //         var params = {
        //             party_id: pipDataSession.partyId(),
        //             id: $stateParams.id
        //         };

        //         return pipRest.groups().get(
        //             params,
        //             pipGroupsCache.onGroupUpdate(params)
        //         ).$promise;
        //     };
        // };

        // CRUD operations and other business methods
        this.$get = ['$stateParams', 'pipDataModel', 'pipGroupsCache', 'pipDataSession', function($stateParams, pipDataModel, pipGroupsCache, pipDataSession) {
            return {
                partyId: pipDataSession.partyId(),

                readGroups: function (params, successCallback, errorCallback) {
                    params.resource = 'groups';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    return pipGroupsCache.readGroups(params, successCallback, errorCallback);
                },

                readGroup: function (params, successCallback, errorCallback) {
                    params.resource = 'groups';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    if (!params.item.id) { params.item.id = $stateParams.id; }
                    return pipDataModel.readOne(params, pipGroupsCache.onGroupUpdate(params, successCallback), errorCallback);
                },
                
                createGroup : function (params, successCallback, errorCallback) {
                    params.resource = 'groups';
                    params.item = params.item || {};
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.create(
                        params, 
                        pipGroupsCache.onGroupCreate(params, successCallback),
                        errorCallback
                    );
                },

                updateGroup : function (params, successCallback, errorCallback) {
                    params.resource = 'groups';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.update(
                        params, 
                        pipGroupsCache.onGroupUpdate(params, successCallback),
                        errorCallback
                    );
                },

                deleteGroup : function (params, successCallback, errorCallback) {
                    params.resource = 'groups';
                    params.item.party_id = pipDataSession.partyId();
                    pipDataModel.remove(
                        params, 
                        pipGroupsCache.onGroupDelete(params, successCallback),
                        errorCallback
                    );
                }
            }
        }];
        this.$get.$inject = ['$stateParams', 'pipDataModel', 'pipGroupsCache', 'pipDataSession'];
    });

})();