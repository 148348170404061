/**
 * @file Visibility formatting service
 * @copyright Digital Living Software Corp. 2014-2016
 */
 
 /* global _, angular */
 
(function () {
    'use strict';

    var thisModule = angular.module('pipVisibilityFormat', ['pipCore']);

	thisModule.config(['pipTranslateProvider', function(pipTranslateProvider) {

        pipTranslateProvider.translations('en', {
            // Shared sensitive format
            'SHARED_PUBLICLY': 'Visible to everybody',
            'SHARED_FAMILIAR': 'Visible to known parties',
            'SHARED_TRUSTED': 'Visible to trusted parties',
            'NOT_SHARED': 'Visible to nobody',
            'VISIBLE_PRIVATELY': 'Visible privately',
            'SHARED_FAMILIAR_DETAILS': 'Known parties',
            'SHARED_TRUSTED_DETAILS': 'Trusted parties',
            'VISIBLE_PRIVATELY_DETAILS': 'Privately',
        });

        pipTranslateProvider.translations('ru', {
            // Visible format
            'SHARED_PUBLICLY': 'Видно всем',
            'SHARED_FAMILIAR': 'Видно знакомым',
            'SHARED_TRUSTED': 'Видно доверенных лицам',
            'NOT_SHARED': 'Никому не видно',
            'VISIBLE_PRIVATELY': 'Видно при личном доступе',
            'SHARED_FAMILIAR_DETAILS': 'Знакомые',
            'SHARED_TRUSTED_DETAILS': 'Доверенные лица',
            'VISIBLE_PRIVATELY_DETAILS': 'Личный доступ',            
        });
		
	}]);

    thisModule.factory('pipVisibilityFormat',
        ['pipEnums', 'pipTranslate', function (pipEnums, pipTranslate) {

            return {
                formatVisibility: formatVisibility,
                formatDetailVisibility: formatDetailVisibility 
            };
            
            function getVisibilityParties(entity) {
                var result = '', group = '', party = ''; 

                for ( var i = 0; i < entity.share_groups.length; i++ )
                    group = group ? group + ', ' +  entity.share_groups[i].group_name : group + entity.share_groups[i].group_name;
                
                for ( var i = 0; i < entity.share_parties.length; i++ )
                    party = party ? party + ', ' +  entity.share_parties[i].party_name : party + entity.share_parties[i].party_name;
                
                result = group;
                result = group && party ? result + ' • ' + party : result + party;
                
                return result;  
            }

            function formatVisibility(entity) { 
                if (entity === null || entity === undefined)
                    return '';
                    
                var
                    level = entity.share_level === undefined || entity.share_level === null ? pipEnums.SHARE_LEVEL.PRIVATE : entity.share_level,
                    groups = entity.share_groups || [],
                    parties = entity.share_parties || [],
                    result = '';

                switch (level) {
                    
                    case pipEnums.SHARE_LEVEL.WORLD:
                        return pipTranslate.translate('SHARED_PUBLICLY');
                    case pipEnums.SHARE_LEVEL.OUTER:
                        return pipTranslate.translate('SHARED_FAMILIAR');
                    case pipEnums.SHARE_LEVEL.INNER:
                        return pipTranslate.translate('SHARED_TRUSTED');
                    case pipEnums.SHARE_LEVEL.PRIVATE:
                        if (groups.length == 0 && parties.length == 0) {
                            return pipTranslate.translate('NOT_SHARED');
                        } else {
                            return pipTranslate.translate('VISIBLE_PRIVATELY');
                        }
                    default:
                        return '';
                }
            };
            
            function formatDetailVisibility(entity) {
                if (entity === null || entity === undefined)
                    return '';

                var
                    level = entity.share_level === undefined || entity.share_level === null ? pipEnums.SHARE_LEVEL.PRIVATE : entity.share_level,
                    groupsCollection = entity.share_groups || [],
                    partiesCollection = entity.share_parties || [],
                    result = '', 
                    parties = '';

                switch (level) {
                    case pipEnums.SHARE_LEVEL.WORLD:
                        result = pipTranslate.translate('SHARED_PUBLICLY');
                        break;
                    case pipEnums.SHARE_LEVEL.OUTER:
                        result = pipTranslate.translate('SHARED_FAMILIAR_DETAILS');
                        parties = getVisibilityParties(entity);
                        break;
                    case pipEnums.SHARE_LEVEL.INNER:
                        result = pipTranslate.translate('SHARED_TRUSTED_DETAILS');
                        parties = getVisibilityParties(entity);
                        break;
                    case pipEnums.SHARE_LEVEL.PRIVATE:
                        if (groupsCollection.length == 0 && partiesCollection.length == 0) {
                            result = pipTranslate.translate('NOT_SHARED');
                        } else {
                            result = pipTranslate.translate('VISIBLE_PRIVATELY_DETAILS');
                            parties = getVisibilityParties(entity);
                        }
                        break;
                    default:
                        result = '';
                        break;
                }
                
                if (result && parties) 
                    result = result + ': ' + parties;
                
                return result;    
            };

        }]
    );
    
})();
