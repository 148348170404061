/**
 * @file Goals data cache
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipGoalsCache', ['pipDataCache', 'pipCacheTag']);

    thisModule.service('pipGoalsCache',
        ['pipEnums', 'pipDataCache', 'pipCacheTag', function(pipEnums, pipDataCache, pipCacheTag) {

            return {
                readGoals: readGoals,
                readTasks: readTasks,
                onGoalCreate: onGoalCreate,
                onGoalUpdate: onGoalUpdate,
                onGoalDelete: onGoalDelete
            };
            //-------------

            function readGoals(params, successCallback, errorCallback) {
                params.resource = 'goals';
                params.item = params.item || {};
                params.item.type = 'goal,aspiration,objective,dream,habit,chore,accomplishment';
                params.filter = function(data) {
                    return _.filter(data, function(item) {
                        return item.type != pipEnums.GOAL_TYPE.TASK;
                    });
                };
                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function readTasks(params, successCallback, errorCallback) {
                params.resource = 'goals';
                params.item = params.item || {};
                params.item.type = pipEnums.GOAL_TYPE.TASK;
                // params.filter = function(data) {
                //     return _.filter(data, function(item) {
                //         return item.type == pipEnums.GOAL_TYPE.TASK;
                //     });
                // };
                return pipDataCache.retrieveOrLoad(params, successCallback, errorCallback);
            };

            function onGoalCreate(params, successCallback) {
                return pipDataCache.addDecorator(
                    'goals', params, 
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onGoalUpdate(params, successCallback) {
                return pipDataCache.updateDecorator(
                    'goals', params,
                    pipCacheTag.tagsUpdateDecorator(params, successCallback)
                );
            };

            function onGoalDelete(params, successCallback) {
                return pipDataCache.removeDecorator('goals', params, successCallback);
            };
        }]
    );

})();

