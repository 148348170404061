/**
 * @file Common services to be clean up
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    var thisModule = angular.module('pipCommonServices', ['pipParentsService']);

    thisModule.factory('EventsDateService',
        ['pipEnums', 'pipCollections', 'pipDates', function (pipEnums, pipCollections, pipDates) {

            var getStartDate = function (value, category) {
                var date;
                if (_.isDate(value)) date = _.cloneDeep(value);
                else {
                    date = new Date(value);
                    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                }
                switch (category) {
                    case pipEnums.EVENT_CATEGORY.WEEKLY:
                        var dayOfWeek = date.getUTCDay() ? date.getUTCDay() : 7;
                        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0,
                            0, - (dayOfWeek - 1) * 24 * 3600000);
                        break;
                    case pipEnums.EVENT_CATEGORY.MONTHLY:
                        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
                        break;
                    case pipEnums.EVENT_CATEGORY.YEARLY:
                        date = new Date(date.getUTCFullYear(), 0, 1);
                        break;
                    case pipEnums.EVENT_CATEGORY.DAILY:
                        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
                        break;
                }
                date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

                return date;
            };

            // todo check
            var getEndDate = function (value, category) {
                return getNextDate(value, category);
            };

            function getNextDate(value, category) {
                var date;
                if (_.isDate(value)) date = _.cloneDeep(value);
                else {
                    date = new Date(value);
                    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                }
                switch (category) {
                    case pipEnums.EVENT_CATEGORY.WEEKLY:
                        var dayOfWeek = date.getUTCDay() ? date.getUTCDay() : 7;
                        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 7, 0, 0,
                            0, - (dayOfWeek - 1) * 24 * 3600000);
                        break;
                    case pipEnums.EVENT_CATEGORY.MONTHLY:
                        date = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 1);
                        break;
                    case pipEnums.EVENT_CATEGORY.YEARLY:
                        date = new Date(date.getUTCFullYear() + 1, 0, 1);
                        break;
                    case pipEnums.EVENT_CATEGORY.DAILY:
                        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1);
                        break;
                }
                date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

                return date;
            };

            function getPrevDate(value, category) {
                var date;
                if (_.isDate(value)) date = _.cloneDeep(value);
                else {
                    date = new Date();
                    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                }
                switch (category) {
                    case pipEnums.EVENT_CATEGORY.WEEKLY:
                        var dayOfWeek = date.getUTCDay() ? date.getUTCDay() : 7;
                        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - 7, 0, 0,
                            0, - (dayOfWeek - 1) * 24 * 3600000);
                        break;
                    case pipEnums.EVENT_CATEGORY.MONTHLY:
                        date = new Date(date.getUTCFullYear(), date.getUTCMonth() - 1, 1);
                        break;
                    case pipEnums.EVENT_CATEGORY.YEARLY:
                        date = new Date(date.getUTCFullYear() - 1, 0, 1);
                        break;
                    default:
                        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - 1);
                        break;
                }
                date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

                return date;
            };

            function getNowDate(category) {
                var date = new Date();
                switch (category) {
                    case pipEnums.EVENT_CATEGORY.WEEKLY:
                        date = pipDates.toUTCStartWeek(date);
                        break;
                    case pipEnums.EVENT_CATEGORY.MONTHLY:
                        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
                        break;
                    case pipEnums.EVENT_CATEGORY.YEARLY:
                        date = new Date(Date.UTC(date.getFullYear(), 0, 1));
                        break;
                    case pipEnums.EVENT_CATEGORY.DAILY:
                        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                        break;
                }

                return date;
            };

            function getStartHalfHours(date) {
                if (!_.isDate(date)) {
                    return 0;
                }
                if (date.getMinutes() <= 30) {
                    return date.getHours() * 60 + 30;
                } else {
                    return (date.getHours() + 1) * 60;
                }
            };

            return {

                getStartDate: getStartDate,
                getEndDate: getEndDate,
                getNextDate: getNextDate ,
                getPrevDate: getPrevDate,
                getNowDate: getNowDate ,
                getStartHalfHours: getStartHalfHours

            }
        }]
    );
    
    thisModule.factory('EventsCreateNewService',
        ['EventsDateService', 'pipEnums', 'pipCollections', 'pipDates', 'ParentsService', function (EventsDateService, pipEnums, pipCollections, pipDates, ParentsService) {

            // Создание из более большой задачи (для дня - неделя, и т.п.)
            function createFromMajorEvent(params) {
                if (!params.event) return null;

                var event = params.event;
                ParentsService.addParentEvent(event, event, 'event', false, false);

                event.start = params.startDate;
                event.end = params.endDate;
                event.all_day = true;

                // if (event.status == pipEnums.EXECUTION_STATUS.COMPLETED
                //     || event.status == pipEnums.EXECUTION_STATUS.CANCELED) {
                //     event.status = pipEnums.EXECUTION_STATUS.NEW;
                //     event.progress = 0;
                // }
                
                event.status = pipEnums.EXECUTION_STATUS.NEW;
                event.progress = 0;
                
                event.category = params.category;
                event.isComplete = false;

                delete event.id;
                delete event.checkItem;
                return event;
            };

            function copyFromAction(params) {
                var event = {
                    category: params.category,
                    title: params.action.title,
                    end: params.endDate,
                    start: params.startDate,
                    type: pipEnums.EVENT_TYPE.INSTANCE,
                    all_day: true,
                    urgency: params.action.urgency || pipEnums.URGENCY.NORMAL,
                    importance: params.action.importance || pipEnums.IMPORTANCE.NORMAL,
                    progress: 0,
                    status: pipEnums.EXECUTION_STATUS.NEW,
                    content: [],
                    contrib_def: false,
                    isComplete: false
                };
                return event;
            };

            function setFromGoal(event, params, source) {
                if (!source) return;
                event.title = '';
                event.importance = source.importance ? source.importance : event.importance ? event.importance : pipEnums.IMPORTANCE.NORMAL;
                event.urgency = source.urgency ? source.urgency : event.urgency ? event.urgency : pipEnums.URGENCY.NORMAL;

                event.all_day = true;

                event.refs.push({
                    ref_id: source.id,
                    ref_type: source.ref_type,
                    ref_name: source.title,
                    parent: true
                });
            };

            function setFromAction(event, params, source) {
                if (!source) return;

                event.title = source.title || '';
                event.importance = source.importance ? source.importance : event.importance ? event.importance : pipEnums.IMPORTANCE.NORMAL;
                event.urgency = source.urgency ? source.urgency : event.urgency ? event.urgency : pipEnums.URGENCY.NORMAL;
                event.all_day = true;

                event.refs = [];

                event.refs.push({
                    ref_id: source.id,
                    ref_type: pipEnums.GOAL_TYPE.GOAL,
                    ref_name: source.title,
                    ref_sub_type: pipEnums.GOAL_TYPE.TASK,
                    parent: true
                });
            };

            function setFromSimpleEntity(event, params, simpleEntity) {
                if (!simpleEntity) return;

                event.title = simpleEntity.title || '';
                event.content = _.cloneDeep(simpleEntity.content);

                event.share_def = true;
                event.share_groups = [];
                event.share_parties = [];
                event.contrib_def = false;
                event.contribs = [{
                    party_id: params.party.id,
                    party_name: params.party.name,
                    accept: 'accepted',
                    role: 'accountable'
                }];
            };

            function setFromVision(event, params, source) {
                if (!source) return;
                event.title = source.title || '';
                event.content = _.cloneDeep(simpleEntity.content) || [];
                event.start = source.start;
                event.end = source.end;

                event.all_day = true;
                event.startDate = EventsDateService.getStartDate(source.start, pipEnums.EVENT_CATEGORY.YEARLY);

                event.share_def = true;
                event.share_groups = _.cloneDeep(source.share_groups);
                event.share_parties = _.cloneDeep(source.share_parties);
                event.contrib_def = false;
                event.contribs = [{
                    party_id: params.party.id,
                    party_name: params.party.name,
                    accept: 'accepted'
                }];

                event.refs.push({
                    ref_id: source.id,
                    ref_type: pipEnums.REFERENCE_TYPE.VISION,
                    ref_name: source.combineName,
                    parent: false
                });
            };

            function clearEvent(event) {
                if (!event) return;

                delete event.source;
                delete event.partyId;
                delete event.parent_id;
                delete event.parent_name;
                delete event.parent_ref_type;
                delete event.ref_type;
                delete event.parent_source;
                delete event.combinedParent;
                delete event.visions;
                delete event.duration;
                delete event.task;
                delete event.icon;
            };

            return {

                createFromMajorEvent: createFromMajorEvent ,
                copyFromAction: copyFromAction,
                setFromGoal: setFromGoal,
                setFromAction: setFromAction,
                setFromSimpleEntity: setFromSimpleEntity,
                setFromVision: setFromVision,
                clearEvent: clearEvent

            }
        }]
    );

})();